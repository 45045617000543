import { useRef, useState, useEffect, useMemo } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import {
  MarqueeSelection,
  Label,
  Pivot,
  PivotItem,
  Checkbox,
  Toggle,
  Panel,
  SpinButton,
  Spinner,
  IPivotStyleProps,
  IStyleFunctionOrObject,
  IPivotStyles,
  IChoiceGroupOption,
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  IDropdownStyles,
  TextField,
  mergeStyleSets,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IDetailsRowProps,
  DetailsRow,
  IDropdownOption,
  IDetailsListStyles,
  PanelType,
  CheckboxVisibility,
  IconButton,
  IColumn,
  TooltipHost,
  ITooltipHostStyles,
  ConstrainMode,
  IToggleStyleProps,
  IToggleProps,
  IToggleStyles,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

import { SparkleFilled } from "@fluentui/react-icons";

import {
  embeddingOptions,
  EXAMPLESV,
  deploymentTypeOptions,
  searchTypes,
  promptTypeOptionsOpenChat,
  promptTypeOptionsChatGpt,
  ChatDataPrompts,
  OpenChatBingPrompts,
  chainType,
} from "../../utils/appObjects";
import {
  Approaches,
  AskResponse,
  ChatRequest,
  ChatTurn,
  refreshIndex,
  getSpeechApi,
  clearblobsIndex,
  chatStreamApi,
  chatDataApi,
  openchatBingApi,
  SearchTypes,
  getIndexSession,
  getIndexSessionDetail,
  deleteIndexSession,
  renameIndexSession,
  getAllIndexSessions,
} from "../../api";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { scopes } from "../../auth/authConfig";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import {
  AnalysisPanel,
  AnalysisPanelTabs,
} from "../../components/AnalysisPanel";
import { ClearChatButton } from "../../components/ClearChatButton";
import { SettingsButton } from "../../components/SettingsButton";

import { ExampleList, ExampleModel } from "../../components/Example";
import styles from "./Chat.module.css";

const rowHeight = 42;
const rowHeaderHeight = 48;
const defaultItemsPerPage = 10;

import { ChatSession } from "../../api/models";
import { SessionButton } from "../../components/SessionButton";
import DraggableSidebar from "../../components/DraggableSidebar/DraggableSidebar";
import { MenuButton } from "@fluentui/react-components";
import { MenuSettingButton } from "../../components/MenuSettingButton";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-dune-dark";
import { msalInstance } from "../..";

var audio = new Audio();

type outletContextType = {
  isOpenOCSessionPanel: boolean;
  isOpenOCConfigPanel: boolean;
  isConfigPanelOpenStream: boolean;
  isOpenOSSessionPanelStream: boolean;
  onOCSessionOpen: Function;
  onOCConfigOpen: Function;
  onOSConfigOpen: Function;
  onOSSessionOpen: Function;
};

const Chat = () => {
  const { instance, inProgress, accounts } = useMsal();
  const context = useOutletContext<outletContextType>();
  // const userGroups = JSON.parse(
  //   sessionStorage.getItem("userRoles") || "[]"
  // ) as string[];
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [userGroups, setUserGroups] = useState<string[] | null>(null);
  const [userName, setUserName] = useState<string | undefined>("");
  const params = useParams();
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  const [isOpenOCConfigPanel, setIsConfigPanelOpen] = useState(false);
  const [isOpenOCSessionPanel, setIsOpenOCSessionPanel] = useState(false);

  /* For Open Chat */
  const lastQuestionRefOpenChat = useRef<string>("");
  const [temperatureOpenChat, setTemperatureOpenChat] = useState<number>(0.3);
  const [tokenLengthOpenChat, setTokenLengthOpenChat] = useState<number>(500);
  const [thoughtProcessClickedTab, setThoughtProcessClickedTab] = useState<number>(-1);
  const [supportingContentClickedTab, setSupportingContentClickedTab] = useState<number>(-1); 
  const [citationClickedTab, setCitationClickedTab] = useState<number>(-1); 
  const [answersOpenChat, setAnswersOpenChat] = useState<
    [user: string, response: AskResponse, speechUrl: string | null][]
  >([]);

  // Examples loading flag for open Chat GPT where the examples are in the appObjects file
  const [exampleLoadingOpenChat, setExampleLoadingOpenChat] = useState(false);
  const [exampleListOpenChat, setExampleListOpenChat] = useState<
    ExampleModel[]
  >([{ text: "", value: "" }]);

  const [selectedEmbeddingItemOpenChat, setSelectedEmbeddingItemOpenChat] =
    useState<IDropdownOption>();
  const [isConfigPanelOpenOpenChat, setIsConfigPanelOpenOpenChat] =
    useState(false);
  const [
    isSessionConfigPanelOpenOpenChat,
    setIsSessionConfigPanelOpenOpenChat,
  ] = useState(false);
  const [isLoadingOpenChat, setIsLoadingOpenChat] = useState<boolean>(false);
  const [errorOpenChat, setErrorOpenChat] = useState<unknown>();

  const [activeCitationOpenChat, setActiveCitationOpenChat] =
    useState<string>();
  const [activeAnalysisPanelTabOpenChat, setActiveAnalysisPanelTabOpenChat] =
    useState<AnalysisPanelTabs | undefined>(undefined);

  const [selectedItemsOpenChat, setSelectedItemsOpenChat] = useState<any[]>([]);

  const [selectedDeploymentTypeOpenChat, setSelectedDeploymentTypeOpenChat] =
    useState<IDropdownOption>();
  const [selectedPromptTypeItemOpenChat, setSelectedPromptTypeItemOpenChat] =
    useState<IDropdownOption>();
  const [selectedChunkSizeOpenChat, setSelectedChunkSizeOpenChat] =
    useState<string>();
  const [selectedChunkOverlapOpenChat, setSelectedChunkOverlapOpenChat] =
    useState<string>();
  const [selectedPromptTypeOpenChat, setSelectedPromptTypeOpenChat] =
    useState<string>();
  const [selectedChainOpenChat, setSelectedChainOpenChat] =
    useState<IDropdownOption>();
  const [chainTypeOptionsOpenChat, setChainTypeOptionsOpenChat] = useState<any>(
    []
  );
  const [searchTypeOptionsOpenChat, setSearchTypeOptionsOpenChat] =
    useState<SearchTypes>(SearchTypes.Similarity);
  const [indexMappingOpenChat, setIndexMappingOpenChat] = useState<
    {
      key: string;
      iType: string;
      indexName: string;
      namespace: string;
      groupName: string;
      owner: string;
      qa: string;
      summary: string;
      indexType: string;
      textSpitterType: string;
      chunkOverlap: string;
      chunkSize: string;
      promptType: string;
      singleFile: string;
    }[]
  >();

  const [promptTemplateOpenChat, setPromptTemplateOpenChat] =
    useState<string>("");
  const [retrieveCountOpenChat, setRetrieveCountOpenChat] = useState<number>(3);
  const [useSemanticRankerOpenChat, setUseSemanticRankerOpenChat] =
    useState<boolean>(true);
  const [useSemanticCaptionsOpenChat, setUseSemanticCaptionsOpenChat] =
    useState<boolean>(false);
  const [excludeCategoryOpenChat, setExcludeCategoryOpenChat] =
    useState<string>("");
  const [
    useSuggestFollowupQuestionsOpenChat,
    setUseSuggestFollowupQuestionsOpenChat,
  ] = useState<boolean>(true);
  const [useAutoSpeakAnswersOpenChat, setUseAutoSpeakAnswersOpenChat] =
    useState<boolean>(false);

  const [selectedItemOpenChat, setSelectedItemOpenChat] =
    useState<IDropdownOption>();

  const [runningIndexOpenChat, setRunningIndexOpenChat] = useState<number>(-1);
  const [selectedAnswerOpenChat, setSelectedAnswerOpenChat] =
    useState<number>(0);

  const [selectedIndexOpenChat, setSelectedIndexOpenChat] = useState<string>();
  const [summaryOpenChat, setSummaryOpenChat] = useState<string>();
  const [qaOpenChat, setQaOpenChat] = useState<string>("");

  const [functionCall, setFunctionCall] = useState<boolean>(true);

  const chatMessageStreamEndOpenChat = useRef<HTMLDivElement | null>(null);

  const [chatSessionOpenChat, setChatSessionOpenChat] =
    useState<ChatSession | null>(null);
  const [sessionIdOpenChat, setSessionIdOpenChat] = useState<string>();
  const [sessionListOpenChat, setSessionListOpenChat] = useState<any[]>();

  const [sessionNameOpenChat, setSessionNameOpenChat] = useState<string>("");
  const [oldSessionNameOpenChat, setOldSessionNameOpenChat] =
    useState<string>("");

  //********************************************************************************************************* */

  /* For Chat GPT */
  const lastQuestionRefChatGpt = useRef<string>("");
  const [temperatureChatGpt, setTemperatureChatGpt] = useState<number>(0.3);
  const [tokenLengthChatGpt, setTokenLengthChatGpt] = useState<number>(500);
  const [answersChatGpt, setAnswersChatGpt] = useState<
    [user: string, response: AskResponse, speechUrl: string | null][]
  >([]);

  // Examples loading flag for open Chat GPT where the examples are in the appObjects file
  const [exampleLoadingChatGpt, setExampleLoadingChatGpt] = useState(false);
  const [exampleListChatGpt, setExampleListChatGpt] = useState<ExampleModel[]>([
    { text: "", value: "" },
  ]);

  const [selectedEmbeddingItemChatGpt, setSelectedEmbeddingItemChatGpt] =
    useState<IDropdownOption>();
  const [isConfigPanelOpenChatGpt, setIsConfigPanelOpenChatGpt] =
    useState(false);

  const [isLoadingChatGpt, setIsLoadingChatGpt] = useState<boolean>(false);
  const [errorChatGpt, setErrorChatGpt] = useState<unknown>();

  const [activeCitationChatGpt, setActiveCitationChatGpt] = useState<string>();
  const [activeAnalysisPanelTabChatGpt, setActiveAnalysisPanelTabChatGpt] =
    useState<AnalysisPanelTabs | undefined>(undefined);

  const [selectedItemsChatGpt, setSelectedItemsChatGpt] = useState<any[]>([]);

  const [selectedDeploymentTypeChatGpt, setSelectedDeploymentTypeChatGpt] =
    useState<IDropdownOption>();
  const [selectedPromptTypeItemChatGpt, setSelectedPromptTypeItemChatGpt] =
    useState<IDropdownOption>();
  const [selectedChunkSizeChatGpt, setSelectedChunkSizeChatGpt] =
    useState<string>();
  const [selectedChunkOverlapChatGpt, setSelectedChunkOverlapChatGpt] =
    useState<string>();
  const [selectedPromptTypeChatGpt, setSelectedPromptTypeChatGpt] =
    useState<string>();
  const [selectedChainChatGpt, setSelectedChainChatGpt] =
    useState<IDropdownOption>();
  const [chainTypeOptionsChatGpt, setChainTypeOptionsChatGpt] = useState<any>(
    []
  );
  const [searchTypeOptionsChatGpt, setSearchTypeOptionsChatGpt] =
    useState<SearchTypes>(SearchTypes.Similarity);
  const [indexMappingChatGpt, setIndexMappingChatGpt] = useState<
    {
      key: string;
      iType: string;
      indexName: string;
      namespace: string;
      groupName: string;
      owner: string;
      qa: string;
      summary: string;
      indexType: string;
      textSpitterType: string;
      chunkOverlap: string;
      chunkSize: string;
      promptType: string;
      singleFile: string;
    }[]
  >();

  const [promptTemplateChatGpt, setPromptTemplateChatGpt] =
    useState<string>("");
  const [retrieveCountChatGpt, setRetrieveCountChatGpt] = useState<number>(3);
  const [useSemanticRankerChatGpt, setUseSemanticRankerChatGpt] =
    useState<boolean>(true);
  const [useSemanticCaptionsChatGpt, setUseSemanticCaptionsChatGpt] =
    useState<boolean>(false);
  const [excludeCategoryChatGpt, setExcludeCategoryChatGpt] =
    useState<string>("");
  const [
    useSuggestFollowupQuestionsChatGpt,
    setUseSuggestFollowupQuestionsChatGpt,
  ] = useState<boolean>(true);
  const [useAutoSpeakAnswersChatGpt, setUseAutoSpeakAnswersChatGpt] =
    useState<boolean>(false);

  const [selectedItemChatGpt, setSelectedItemChatGpt] =
    useState<IDropdownOption>();

  const [runningIndexChatGpt, setRunningIndexChatGpt] = useState<number>(-1);
  const [selectedAnswerChatGpt, setSelectedAnswerChatGpt] = useState<number>(0);

  const [selectedIndexChatGpt, setSelectedIndexChatGpt] = useState<string>();
  const [summaryChatGpt, setSummaryChatGpt] = useState<string>();
  const [qaChatGpt, setQaChatGpt] = useState<string>("");

  const chatMessageStreamEndChatGpt = useRef<HTMLDivElement | null>(null);

  const [chatSessionChatGpt, setChatSessionChatGpt] =
    useState<ChatSession | null>(null);
  const [sessionIdChatGpt, setSessionIdChatGpt] = useState<string>();
  const [sessionListChatGpt, setSessionListChatGpt] = useState<any[]>();

  const [sessionNameChatGpt, setSessionNameChatGpt] = useState<string>("");
  const [oldSessionNameChatGpt, setOldSessionNameChatGpt] =
    useState<string>("");

  /* For Chat Data Streaming*/
  const lastQuestionRefStream = useRef<string>("");
  const [temperatureStream, setTemperatureStream] = useState<number>(0.3);
  const [tokenLengthStream, setTokenLengthStream] = useState<number>(500);
  const [answersStream, setAnswersStream] = useState<
    [user: string, response: AskResponse, speechUrl: string | null][]
  >([]);

  // Examples loading flag for open Chat GPT where the examples are in the appObjects file
  const [exampleLoadingStream, setExampleLoadingStream] = useState(false);
  const [exampleListStream, setExampleListStream] = useState<ExampleModel[]>([
    { text: "", value: "" },
  ]);

  const [selectedEmbeddingItemStream, setSelectedEmbeddingItemStream] =
    useState<IDropdownOption>();
  const [isConfigPanelOpenStream, setIsConfigPanelOpenStream] = useState(false);
  const [isOpenOSSessionPanelStream, setIsOpenOSSessionPanelStream] =
    useState(false);

  const [isLoadingStream, setIsLoadingStream] = useState<boolean>(false);
  const [errorStream, setErrorStream] = useState<unknown>();

  const [activeCitationStream, setActiveCitationStream] = useState<string>();
  const [activeAnalysisPanelTabStream, setActiveAnalysisPanelTabStream] =
    useState<AnalysisPanelTabs | undefined>(undefined);

  const [selectedItemsStream, setSelectedItemsStream] = useState<any[]>([]);

  const [selectedDeploymentTypeStream, setSelectedDeploymentTypeStream] =
    useState<IDropdownOption>();
  const [selectedPromptTypeItemStream, setSelectedPromptTypeItemStream] =
    useState<IDropdownOption>();
  const [selectedChunkSizeStream, setSelectedChunkSizeStream] =
    useState<string>();
  const [selectedChunkOverlapStream, setSelectedChunkOverlapStream] =
    useState<string>();
  const [selectedPromptTypeStream, setSelectedPromptTypeStream] =
    useState<string>();
  const [selectedChainStream, setSelectedChainStream] =
    useState<IDropdownOption>();
  const [chainTypeOptionsStream, setChainTypeOptionsStream] = useState<any>([]);
  const [searchTypeOptionsStream, setSearchTypeOptionsStream] =
    useState<SearchTypes>(SearchTypes.Similarity);
  const [indexMappingStream, setIndexMappingStream] = useState<
    {
      key: string;
      iType: string;
      indexName: string;
      namespace: string;
      groupName: string;
      owner: string;
      qa: string;
      summary: string;
      indexType: string;
      textSpitterType: string;
      chunkOverlap: string;
      chunkSize: string;
      promptType: string;
      singleFile: string;
    }[]
  >();

  const [promptTemplateStream, setPromptTemplateStream] = useState<string>("");
  const [retrieveCountStream, setRetrieveCountStream] = useState<number>(3);
  const [useSemanticRankerStream, setUseSemanticRankerStream] =
    useState<boolean>(true);
  const [useSemanticCaptionsStream, setUseSemanticCaptionsStream] =
    useState<boolean>(false);
  const [excludeCategoryStream, setExcludeCategoryStream] =
    useState<string>("");
  const [
    useSuggestFollowupQuestionsStream,
    setUseSuggestFollowupQuestionsStream,
  ] = useState<boolean>(true);
  const [useAutoSpeakAnswersStream, setUseAutoSpeakAnswersStream] =
    useState<boolean>(false);

  const [selectedItemStream, setSelectedItemStream] =
    useState<IDropdownOption>();

  const [runningIndexStream, setRunningIndexStream] = useState<number>(-1);
  const [selectedAnswerStream, setSelectedAnswerStream] = useState<number>(0);

  const [selectedIndexStream, setSelectedIndexStream] = useState<string>();
  const [summaryStream, setSummaryStream] = useState<string>();
  const [qaStream, setQaStream] = useState<string>("");

  const chatMessageStreamEndStream = useRef<HTMLDivElement | null>(null);

  const [chatSessionStream, setChatSessionStream] =
    useState<ChatSession | null>(null);
  const [sessionIdStream, setSessionIdStream] = useState<string>();
  const [sessionListStream, setSessionListStream] = useState<any[]>();

  const [sessionNameStream, setSessionNameStream] = useState<string>("");
  const [oldSessionNameStream, setOldSessionNameStream] = useState<string>("");

  /* *****************************************
        blobs related
       *****************************************
    */

  const [isBlobsLoaded, setIsBlobsLoaded] = useState<boolean>(false);
  const [blobsCount, setBlobsCount] = useState<number>(-1);
  const [bloboptions, setBlobOptions] = useState<any>([]);

  //********************************************************************************************************* */
  const [activeItem, setActiveItem] = useState(null);


  const generateQuickGuid = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const onItemInvoked = (item: any) => {
    setActiveItem(item);
    setAnimated(true);
    setTimeout(() => setAnimated(false), 1000); // Reset animation after 1 second
    onSessionClickedOpenChat(item);
  };

  const [isAnimated, setAnimated] = useState(false);
  const onDeleteRow = (item: any) => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 1000); // Reset animation after 1 second
    triggerOpenChatdelete(item);
    item["Session Name"] = "Deleting...";
    setActiveItem(null);
  };

  const sessionListColumn = [
    {
      key: "Session Name",
      name: "Session Name",
      fieldName: "Session Name",
      minWidth: 0,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <div
            className={isAnimated ? "animated-icon-button" : ""}
            onClick={() => onItemInvoked(item)}
            style={{
              display: "flex",
              justifyItems: "center",
              alignContent: "center",
              cursor: "pointer",
              paddingTop: "8px",
              fontSize: "1rem",
              fontWeight: activeItem === item ? "bold" : "normal",
              minWidth: 0,
            }}
          >
            {item["Session Name"]}
          </div>
        );
      },
    },
    {
      key: "SessionId",
      name: "SessionId",
      fieldName: "SessionId",
      minWidth: 0,
      maxWidth: 0,
      isResizable: true,
      isHidden: true,
    },
    {
      key: "Delete",
      name: "",
      fieldName: "Delete",
      minWidth: 0,
      maxWidth: 0,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <IconButton
            className={isAnimated ? "animated-icon-button" : ""}
            iconProps={{ iconName: "Delete" }}
            style={{
              display: "flex",
              justifyItems: "center",
              alignContent: "center",
              cursor: "pointer",
              color: "#e20074",
              fontWeight: activeItem === item ? "bold" : "normal",
              padding: "0px",
            }}
            onClick={() => {
              onDeleteRow(item);
            }}
            title="Delete"
            ariaLabel="Delete"
          />
        );
      },
    },
  ];

  /* Open Chat GPT Bing */
  const makeApiRequestOpenChatBing = async (question: string) => {
    let currentSession = chatSessionOpenChat;
    let firstSession = false;
    if (!lastQuestionRefOpenChat.current || currentSession === null) {
      currentSession = handleNewConversationOpenChat(question);
      firstSession = true;
      let sessionLists = sessionListOpenChat;
      sessionLists?.unshift({
        "Session Name": currentSession.name,
        SessionId: currentSession.sessionId,
      });
      setSessionListOpenChat(sessionLists?.reverse());
    }
    let promptTemplate = "";
    if (firstSession) {
      let obj =
        OpenChatBingPrompts.find(
          (x) => x.key === selectedPromptTypeItemOpenChat?.key
        ) || OpenChatBingPrompts[0];
      setPromptTemplateOpenChat(obj.text);
      promptTemplate = obj.text;
    } else {
      promptTemplate = answersOpenChat[0][0];
    }
    lastQuestionRefOpenChat.current = question;

    errorOpenChat && setErrorOpenChat(undefined);
    setIsLoadingOpenChat(true);
    setActiveCitationOpenChat(undefined);
    setActiveAnalysisPanelTabOpenChat(undefined);

    try {
      const history: ChatTurn[] = answersOpenChat.map((a) => ({
        user: a[0],
        bot: a[1].answer,
      }));
      const request: ChatRequest = {
        history: [...history, { user: question, bot: undefined }],
        approach: Approaches.ReadRetrieveRead,
        overrides: {
          promptTemplate: promptTemplateOpenChat,
          excludeCategory:
            excludeCategoryOpenChat.length === 0
              ? undefined
              : excludeCategoryOpenChat,
          top: retrieveCountOpenChat,
          temperature: temperatureOpenChat,
          semanticRanker: useSemanticRankerOpenChat,
          semanticCaptions: useSemanticCaptionsOpenChat,
          suggestFollowupQuestions: useSuggestFollowupQuestionsOpenChat,
          tokenLength: tokenLengthOpenChat,
          autoSpeakAnswers: useAutoSpeakAnswersOpenChat,
          embeddingModelType: String(selectedEmbeddingItemOpenChat?.key),
          functionCall: functionCall,
          firstSession: firstSession,
          session: JSON.stringify(currentSession),
          sessionId: currentSession.sessionId,
          deploymentType: String(selectedDeploymentTypeOpenChat?.key),
          searchType: searchTypeOptionsOpenChat,
          userName: userName,
        },
      };
      const result = await openchatBingApi(
        question,
        request,
        String(selectedItemOpenChat?.key),
        String(selectedIndexOpenChat)
      );
      setAnswersOpenChat([...answersOpenChat, [question, result, null]]);
      if (useAutoSpeakAnswersOpenChat) {
        const speechUrl = await getSpeechApi(result.answer);
        setAnswersOpenChat([...answersOpenChat, [question, result, speechUrl]]);
        startOrStopSynthesisOpenChat("gpt4", speechUrl, answersOpenChat.length);
      }
      getCosmosSession("openchat", "openchat", "openchat");
    } catch (e) {
      setErrorOpenChat(e);
    } finally {
      setIsLoadingOpenChat(false);
    }
  };

  /* with data.*/
  const makeApiRequestChatGptData = async (question: string) => {
    let currentSession = chatSessionChatGpt;
    let firstSession = false;
    if (!lastQuestionRefChatGpt.current || currentSession === null) {
      currentSession = handleNewConversationChatGpt(question);
      firstSession = true;
      let sessionLists = sessionListChatGpt;
      sessionLists?.unshift({
        "Session Name": currentSession.name,
        SessionId: currentSession.sessionId,
      });
      setSessionListChatGpt(sessionLists);
    }
    let promptTemplate = "";
    if (firstSession) {
      let obj =
        ChatDataPrompts.find(
          (x) => x.key === selectedPromptTypeItemChatGpt?.key
        ) || ChatDataPrompts[0];
      setPromptTemplateChatGpt(obj.text);
      promptTemplate = obj.text;
    } else {
      promptTemplate = answersChatGpt[0][0];
    }
    lastQuestionRefChatGpt.current = question;

    errorChatGpt && setErrorChatGpt(undefined);
    setIsLoadingChatGpt(true);
    setActiveCitationChatGpt(undefined);
    setActiveAnalysisPanelTabChatGpt(undefined);

    try {
      const history: ChatTurn[] = answersChatGpt.map((a) => ({
        user: a[0],
        bot: a[1].answer,
      }));
      const request: ChatRequest = {
        history: [...history, { user: question, bot: undefined }],
        approach: Approaches.ReadRetrieveRead,
        overrides: {
          promptTemplate:
            promptTemplateChatGpt.length === 0
              ? undefined
              : promptTemplateChatGpt,
          excludeCategory:
            excludeCategoryChatGpt.length === 0
              ? undefined
              : excludeCategoryChatGpt,
          top: retrieveCountChatGpt,
          temperature: temperatureChatGpt,
          semanticRanker: useSemanticRankerChatGpt,
          semanticCaptions: useSemanticCaptionsChatGpt,
          suggestFollowupQuestions: useSuggestFollowupQuestionsChatGpt,
          tokenLength: tokenLengthChatGpt,
          autoSpeakAnswers: useAutoSpeakAnswersChatGpt,
          embeddingModelType: String(selectedEmbeddingItemChatGpt?.key),
          firstSession: firstSession,
          session: JSON.stringify(currentSession),
          sessionId: currentSession.sessionId,
          deploymentType: String(selectedDeploymentTypeChatGpt?.key),
          chainType: String(selectedChainChatGpt?.key),
          searchType: searchTypeOptionsChatGpt,
          userName: userName,
        },
      };
      const result = await chatDataApi(
        question,
        request,
        String(selectedItemChatGpt?.key),
        String(selectedIndexChatGpt)
      );
      setAnswersChatGpt([...answersChatGpt, [question, result, null]]);
      if (useAutoSpeakAnswersChatGpt) {
        const speechUrl = await getSpeechApi(result.answer);
        setAnswersChatGpt([...answersChatGpt, [question, result, speechUrl]]);
        startOrStopSynthesisChatGpt("gpt4", speechUrl, answersChatGpt.length);
      }
    } catch (e) {
      setErrorChatGpt(e);
    } finally {
      setIsLoadingChatGpt(false);
    }
  };

  /* streaming with data */
  const makeApiRequestStream = async (question: string) => {
    let currentSession = chatSessionStream;
    let firstSession = false;
    if (!lastQuestionRefStream.current || currentSession === null) {
      currentSession = handleNewConversationStream(question);
      firstSession = true;
      let sessionLists = sessionListStream;
      sessionLists?.unshift({
        "Session Name": currentSession.name,
        SessionId: currentSession.sessionId,
      });
      setSessionListStream(sessionLists);
    }
    let promptTemplate = "";
    if (firstSession) {
      let obj =
        ChatDataPrompts.find(
          (x) => x.key === selectedPromptTypeItemStream?.key
        ) || ChatDataPrompts[0];
      setPromptTemplateStream(obj.text);
      promptTemplate = obj.text;
    } else {
      promptTemplate = answersStream[0][0];
    }
    lastQuestionRefStream.current = question;

    errorStream && setErrorStream(undefined);
    setIsLoadingStream(true);
    setActiveCitationStream(undefined);
    setActiveAnalysisPanelTabStream(undefined);
    try {
      const history: ChatTurn[] = answersStream.map((a) => ({
        user: a[0],
        bot: a[1].answer,
      }));
      const request: ChatRequest = {
        history: [...history, { user: question, bot: undefined }],
        approach: Approaches.ReadRetrieveRead,
        overrides: {
          promptTemplate:
            promptTemplateStream.length === 0
              ? undefined
              : promptTemplateStream,
          excludeCategory:
            excludeCategoryStream.length === 0
              ? undefined
              : excludeCategoryStream,
          top: retrieveCountStream,
          temperature: temperatureStream,
          semanticRanker: useSemanticRankerStream,
          semanticCaptions: useSemanticCaptionsStream,
          suggestFollowupQuestions: useSuggestFollowupQuestionsStream,
          tokenLength: tokenLengthStream,
          autoSpeakAnswers: useAutoSpeakAnswersStream,
          embeddingModelType: String(selectedEmbeddingItemStream?.key),
          firstSession: firstSession,
          session: JSON.stringify(currentSession),
          sessionId: currentSession.sessionId,
          deploymentType: String(selectedDeploymentTypeStream?.key),
          chainType: String(selectedChainStream?.key),
          searchType: searchTypeOptionsStream,
          userName: userName,
        },
      };
      let result: any = {};
      let answer1: string = "";
      let answer: string = "";
      let nextQuestion: string = "";
      const response = await chatStreamApi(
        request,
        String(selectedItemStream?.key),
        String(selectedIndexStream)
      );
      let askResponse: AskResponse = {} as AskResponse;
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach(async (obj) => {
            try {
              runningText += obj;
              if (obj != "") {
                result = JSON.parse(runningText);
                if (result["data_points"]) {
                  askResponse = result;
                } else if (
                  result["choices"] &&
                  result["choices"][0]["delta"]["content"]
                ) {
                  answer += result["choices"][0]["delta"]["content"];
                  if (answer.indexOf("NEXT QUESTIONS") > -1) {
                    nextQuestion =
                      answer.indexOf("NEXT QUESTIONS") > -1
                        ? answer.substring(
                            answer.indexOf("NEXT QUESTIONS") + 15
                          )
                        : "";
                  }
                  let latestResponse: AskResponse = {
                    ...askResponse,
                    answer: answer
                      .replace(nextQuestion, "")
                      .replace("NEXT QUESTIONS:", ""),
                    nextQuestions: nextQuestion,
                  };
                  setIsLoadingStream(false);
                  setAnswersStream([
                    ...answersStream,
                    [question, latestResponse, null],
                  ]);
                  if (useAutoSpeakAnswersStream) {
                    const speechUrl = await getSpeechApi(result.answer);
                    setAnswersStream([
                      ...answersStream,
                      [question, latestResponse, speechUrl],
                    ]);
                    startOrStopSynthesisStream(
                      "gpt4",
                      speechUrl,
                      answersStream.length
                    );
                  }
                }
              }
              runningText = "";
            } catch {
              ////console.log("Error parsing JSON: " + obj);
            }
          });
        }
      }
    } catch (e) {
      setErrorStream(e);
    } finally {
      setIsLoadingStream(false);
    }
  };

  const clearOpenChat = () => {
    lastQuestionRefOpenChat.current = "";
    errorOpenChat && setErrorOpenChat(undefined);
    setChatSessionOpenChat(null);
    setAnswersOpenChat([]);
    setSelectedItemsOpenChat([]);
    setSessionNameOpenChat("");
    let obj =
      OpenChatBingPrompts.find((x) => x.key === "custom") ||
      OpenChatBingPrompts[0];
    setSelectedPromptTypeItemOpenChat(obj);
    setPromptTemplateOpenChat(obj.text);
  };

  const clearChatGpt = () => {
    lastQuestionRefChatGpt.current = "";
    errorChatGpt && setErrorChatGpt(undefined);
    setActiveCitationChatGpt(undefined);
    setActiveAnalysisPanelTabChatGpt(undefined);
    setChatSessionChatGpt(null);
    setAnswersChatGpt([]);
    setSelectedItemsChatGpt([]);
    let obj =
      ChatDataPrompts.find((x) => x.key === "custom") || ChatDataPrompts[0];
    setSelectedPromptTypeItemChatGpt(obj);
    setPromptTemplateChatGpt(obj.text);
  };

  const clearStreamChat = () => {
    lastQuestionRefStream.current = "";
    errorStream && setErrorStream(undefined);
    setActiveCitationStream(undefined);
    setActiveAnalysisPanelTabStream(undefined);
    setChatSessionStream(null);
    setAnswersStream([]);
    setSelectedItemsStream([]);
    let obj =
      ChatDataPrompts.find((x) => x.key === "custom") || ChatDataPrompts[0];
    setSelectedPromptTypeItemStream(obj);
    setPromptTemplateStream(obj.text);
  };

  const getCosmosSession = async (
    indexNs: string,
    indexType: string,
    pivotType: string
  ) => {
    try {
      await getAllIndexSessions(indexNs, indexType, pivotType, "Session").then(
        async (response: any) => {
          const sessionLists = [];
          if (response.length === 0) {
            sessionLists.push({
              "Session Name": "No Sessions found",
              SessionId: null,
            });
          } else {
            for (const session of response) {
              sessionLists.push({
                "Session Name": session.name,
                SessionId: session.sessionId,
              });
            }
          }
          if (pivotType.toLocaleLowerCase() == "openchat") {
            setSessionListOpenChat(sessionLists.reverse()); // reverse list so that last session is on top
          } else if (pivotType.toLocaleLowerCase() == "chatgpt") {
            setSessionListChatGpt(sessionLists);
          } else if (pivotType.toLocaleLowerCase() == "stream") {
            setSessionListStream(sessionLists);
          }
        }
      );
    } catch (e) {
      pivotType.toLocaleLowerCase() == "openchat"
        ? setErrorOpenChat(e)
        : pivotType.toLocaleLowerCase() == "chatgpt"
        ? setErrorChatGpt(e)
        : setErrorStream(e);
    } finally {
      pivotType.toLocaleLowerCase() == "openchat"
        ? setIsLoadingOpenChat(false)
        : pivotType.toLocaleLowerCase() == "chatgpt"
        ? setIsLoadingChatGpt(false)
        : setIsLoadingStream(false);
    }
  };

  const triggerOpenChatdelete = async (itemseleted: any) => {
    let sessionId = itemseleted?.SessionId;
    await deleteIndexSession(
      "openchat",
      "openchat",
      itemseleted["Session Name"],
      sessionId ?? ""
    ).then(async (sessionResponse: any) => {
      const defaultKey = selectedItemOpenChat?.key;
      indexMappingOpenChat?.findIndex((item) => {
        if (item.key == defaultKey) {
          getCosmosSession("openchat", "openchat", "openchat");
        }
      });
      clearOpenChat();
    });
  };

  const deleteSessionOpenChat = async () => {
    //const sessionName = String(selectedItems[0]?.['Session Name'])
    if (
      sessionNameOpenChat === "No Sessions found" ||
      sessionNameOpenChat === "" ||
      sessionNameOpenChat === undefined
    ) {
      alert("Select Session to delete");
    }
    let sessionId = chatSessionOpenChat?.sessionId;
    await deleteIndexSession(
      "openchat",
      "openchat",
      sessionNameOpenChat,
      sessionId ?? ""
    ).then(async (sessionResponse: any) => {
      const defaultKey = selectedItemOpenChat?.key;
      indexMappingOpenChat?.findIndex((item) => {
        if (item.key == defaultKey) {
          getCosmosSession("openchat", "openchat", "openchat");
        }
      });
      clearOpenChat();
    });
  };

  const deleteSessionChatGpt = async () => {
    //const sessionName = String(selectedItems[0]?.['Session Name'])
    if (
      sessionNameChatGpt === "No Sessions found" ||
      sessionNameChatGpt === "" ||
      sessionNameChatGpt === undefined
    ) {
      alert("Select Session to delete");
    }
    let sessionId = chatSessionChatGpt?.sessionId;
    await deleteIndexSession(
      String(selectedItemChatGpt?.key),
      String(selectedIndexChatGpt),
      sessionNameChatGpt,
      sessionId ?? ""
    ).then(async (sessionResponse: any) => {
      const defaultKey = selectedItemChatGpt?.key;
      indexMappingChatGpt?.findIndex((item) => {
        if (item.key == defaultKey) {
          getCosmosSession(item?.key, item?.iType, "chatgpt");
        }
      });
      clearChatGpt();
    });
  };

  const deleteSessionStream = async () => {
    //const sessionName = String(selectedItems[0]?.['Session Name'])
    if (
      sessionNameStream === "No Sessions found" ||
      sessionNameStream === "" ||
      sessionNameStream === undefined
    ) {
      alert("Select Session to delete");
    }
    let sessionId = chatSessionStream?.sessionId;
    await deleteIndexSession(
      String(selectedItemStream?.key),
      String(selectedIndexStream),
      sessionNameStream,
      sessionId ?? ""
    ).then(async (sessionResponse: any) => {
      const defaultKey = selectedItemStream?.key;
      indexMappingStream?.findIndex((item) => {
        if (item.key == defaultKey) {
          getCosmosSession(item?.key, item?.iType, "stream");
        }
      });
      clearStreamChat();
    });
  };

  const renameSessionOpenChat = async () => {
    //const oldSessionName = String(selectedItems[0]?.['Session Name'])
    if (
      oldSessionNameOpenChat === "No Sessions found" ||
      oldSessionNameOpenChat === undefined ||
      sessionNameOpenChat === "" ||
      sessionNameOpenChat === undefined ||
      oldSessionNameOpenChat === "" ||
      sessionNameOpenChat === "No Sessions found"
    ) {
      alert("Select valid session to rename");
    } else {
      let sessionId = chatSessionOpenChat?.sessionId;
      await renameIndexSession(
        oldSessionNameOpenChat,
        sessionNameOpenChat,
        sessionId ?? ""
      ).then(async (sessionResponse: any) => {
        const defaultKey = selectedItemOpenChat?.key;
        indexMappingOpenChat?.findIndex((item) => {
          if (item.key == defaultKey) {
            getCosmosSession("openchat", "openchat", "openchat");
          }
        });
        clearOpenChat();
      });
    }
  };
  const renameSessionChatGpt = async () => {
    //const oldSessionName = String(selectedItems[0]?.['Session Name'])
    if (
      oldSessionNameChatGpt === "No Sessions found" ||
      oldSessionNameChatGpt === undefined ||
      sessionNameChatGpt === "" ||
      sessionNameChatGpt === undefined ||
      oldSessionNameChatGpt === "" ||
      sessionNameChatGpt === "No Sessions found"
    ) {
      alert("Select valid session to rename");
    } else {
      let sessionId = chatSessionChatGpt?.sessionId;
      await renameIndexSession(
        oldSessionNameChatGpt,
        sessionNameChatGpt,
        sessionId ?? ""
      ).then(async (sessionResponse: any) => {
        const defaultKey = selectedItemChatGpt?.key;
        indexMappingChatGpt?.findIndex((item) => {
          if (item.key == defaultKey) {
            getCosmosSession(item?.key, item?.iType, "chatgpt");
          }
        });
        clearChatGpt();
      });
    }
  };
  const renameSessionStream = async () => {
    //const oldSessionName = String(selectedItems[0]?.['Session Name'])
    if (
      oldSessionNameStream === "No Sessions found" ||
      oldSessionNameStream === undefined ||
      sessionNameStream === "" ||
      sessionNameStream === undefined ||
      oldSessionNameStream === "" ||
      sessionNameStream === "No Sessions found"
    ) {
      alert("Select valid session to rename");
    } else {
      let sessionId = chatSessionStream?.sessionId;
      await renameIndexSession(
        oldSessionNameStream,
        sessionNameStream,
        sessionId ?? ""
      ).then(async (sessionResponse: any) => {
        const defaultKey = selectedItemStream?.key;
        indexMappingStream?.findIndex((item) => {
          if (item.key == defaultKey) {
            getCosmosSession(item?.key, item?.iType, "stream");
          }
        });
        clearStreamChat();
      });
    }
  };
  const onSessionNameChangeOpenChat = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const oldSessionName = String(selectedItemsOpenChat[0]?.["Session Name"]);
    if (newValue === undefined || newValue === "") {
      alert("Provide session name");
    }
    setSessionNameOpenChat(newValue || oldSessionName);
  };
  const onSessionNameChangeChatGpt = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const oldSessionName = String(selectedItemsChatGpt[0]?.["Session Name"]);
    if (newValue === undefined || newValue === "") {
      alert("Provide session name");
    }
    setSessionNameChatGpt(newValue || oldSessionName);
  };
  const onSessionNameChangeStream = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const oldSessionName = String(selectedItemsStream[0]?.["Session Name"]);
    if (newValue === undefined || newValue === "") {
      alert("Provide session name");
    }
    setSessionNameStream(newValue || oldSessionName);
  };

  const onEnableAutoSpeakAnswersChangeOpenChat = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseAutoSpeakAnswersOpenChat(!!checked);
  };
  const onEnableAutoSpeakAnswersChangeChatGpt = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseAutoSpeakAnswersChatGpt(!!checked);
  };
  const onEnableAutoSpeakAnswersChangeStream = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseAutoSpeakAnswersStream(!!checked);
  };

  const onExampleClickedOpenChat = (example: string) => {
    makeApiRequestOpenChatBing(example);
  };

  const onExampleClickedChatGpt = (example: string) => {
    makeApiRequestChatGptData(example);
  };

  const onExampleStreamClicked = (example: string) => {
    makeApiRequestStream(example);
  };

  const onSearchTypeChangeOpenChat = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setSearchTypeOptionsOpenChat(
      (option?.key as SearchTypes) || SearchTypes.Similarity
    );
  };

  const onSearchTypeChangeChatGpt = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setSearchTypeOptionsChatGpt(
      (option?.key as SearchTypes) || SearchTypes.Similarity
    );
  };

  const onSearchTypeChangeStream = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setSearchTypeOptionsStream(
      (option?.key as SearchTypes) || SearchTypes.Similarity
    );
  };

  const startOrStopSynthesisOpenChat = async (
    answerType: string,
    url: string | null,
    index: number
  ) => {
    if (runningIndexOpenChat === index) {
      audio.pause();
      setRunningIndexOpenChat(-1);
      return;
    }

    if (runningIndexOpenChat !== -1) {
      audio.pause();
      setRunningIndexOpenChat(-1);
    }

    if (url === null) {
      let speechAnswer;
      if (answerType === "gpt4") {
        answersOpenChat.map((answer) => {
          speechAnswer = answer[1].answer;
        });
      }
      const speechUrl = await getSpeechApi(speechAnswer || "");
      if (speechUrl === null) {
        return;
      }
      audio = new Audio(speechUrl);
      audio.play();
      setRunningIndexOpenChat(index);
      audio.addEventListener("ended", () => {
        setRunningIndexOpenChat(-1);
      });
    } else {
      audio = new Audio(url);
      audio.play();
      setRunningIndexOpenChat(index);
      audio.addEventListener("ended", () => {
        setRunningIndexOpenChat(-1);
      });
    }
  };
  const startOrStopSynthesisChatGpt = async (
    answerType: string,
    url: string | null,
    index: number
  ) => {
    if (runningIndexChatGpt === index) {
      audio.pause();
      setRunningIndexChatGpt(-1);
      return;
    }

    if (runningIndexChatGpt !== -1) {
      audio.pause();
      setRunningIndexChatGpt(-1);
    }

    if (url === null) {
      let speechAnswer;
      if (answerType === "gpt4") {
        answersChatGpt.map((answer) => {
          speechAnswer = answer[1].answer;
        });
      }
      const speechUrl = await getSpeechApi(speechAnswer || "");
      if (speechUrl === null) {
        return;
      }
      audio = new Audio(speechUrl);
      audio.play();
      setRunningIndexChatGpt(index);
      audio.addEventListener("ended", () => {
        setRunningIndexChatGpt(-1);
      });
    } else {
      audio = new Audio(url);
      audio.play();
      setRunningIndexChatGpt(index);
      audio.addEventListener("ended", () => {
        setRunningIndexChatGpt(-1);
      });
    }
  };
  const startOrStopSynthesisStream = async (
    answerType: string,
    url: string | null,
    index: number
  ) => {
    if (runningIndexStream === index) {
      audio.pause();
      setRunningIndexStream(-1);
      return;
    }

    if (runningIndexStream !== -1) {
      audio.pause();
      setRunningIndexStream(-1);
    }

    if (url === null) {
      let speechAnswer;
      if (answerType === "gpt4") {
        answersStream.map((answer) => {
          speechAnswer = answer[1].answer;
        });
      }
      const speechUrl = await getSpeechApi(speechAnswer || "");
      if (speechUrl === null) {
        return;
      }
      audio = new Audio(speechUrl);
      audio.play();
      setRunningIndexStream(index);
      audio.addEventListener("ended", () => {
        setRunningIndexStream(-1);
      });
    } else {
      audio = new Audio(url);
      audio.play();
      setRunningIndexStream(index);
      audio.addEventListener("ended", () => {
        setRunningIndexStream(-1);
      });
    }
  };

  const processblobs = (loadedblobs: any) => {
    const files = [];
    const indexType = [];
    const blobs = loadedblobs;

    for (const blob of blobs.values) {
      if (blob.embedded == "true") {
        files.push({
          text: blob.indexName,
          key: blob.namespace,
        });
        indexType.push({
          key: blob.namespace,
          iType: blob.indexType,
          indexName: blob.indexName,
          namespace: blob.namespace,
          groupName: blob.groupName,
          owner: blob.owner,
          qa: blob.qa,
          summary: blob.summary,
          indexType: blob.indexType,
          textSpitterType: blob.textSpitterType,
          chunkSize: blob.chunkSize,
          promptType: blob.promptType || "custom",
          singleFile: blob.singleFile,
          chunkOverlap: blob.chunkOverlap,
        });
      }
    }

    var uniqFiles = files.filter(
      (v, i, a) => a.findIndex((v2) => v2.key === v.key) === i
    );
    setBlobOptions(uniqFiles);
    setBlobsCount(uniqFiles.length);

    setSelectedItemOpenChat(uniqFiles[0]);
    setSelectedItemChatGpt(uniqFiles[0]);
    setSelectedItemStream(uniqFiles[0]);

    var defaultKey = "";
    if (uniqFiles.length > 0) {
      defaultKey = uniqFiles[0].key;
      var uniqIndexType = indexType.filter(
        (v, i, a) => a.findIndex((v2) => v2.key === v.key) === i
      );
      const regex = /\\n[0-9]+./g;
      const regex1 = /\\n/g;
      for (const item of uniqIndexType) {
        if (item.key == defaultKey) {
          setSelectedIndexOpenChat(item.iType);
          setSelectedIndexChatGpt(item.iType);
          setSelectedIndexStream(item.iType);

          setSummaryOpenChat(item.summary);
          setSummaryChatGpt(item.summary);
          setSummaryStream(item.summary);

          setQaOpenChat(item.qa);
          setQaChatGpt(item.qa);
          setQaStream(item.qa);

          setSelectedChunkOverlapOpenChat(item.chunkOverlap);
          setSelectedChunkOverlapChatGpt(item.chunkOverlap);
          setSelectedChunkOverlapStream(item.chunkOverlap);

          setSelectedChunkSizeOpenChat(item.chunkSize);
          setSelectedChunkSizeChatGpt(item.chunkSize);
          setSelectedChunkSizeStream(item.chunkSize);

          setSelectedPromptTypeOpenChat(item.promptType);
          setSelectedPromptTypeChatGpt(item.promptType);
          setSelectedPromptTypeStream(item.promptType);

          setSelectedPromptTypeItemOpenChat(promptTypeOptionsOpenChat[0]);
          setSelectedPromptTypeItemChatGpt(
            promptTypeOptionsChatGpt.find((x) => x.key === item.promptType)
          );
          setSelectedPromptTypeItemStream(
            promptTypeOptionsChatGpt.find((x) => x.key === item.promptType)
          );

          updatePromptOpenChat("custom", "openchat");
          updatePromptChatGpt(item.promptType, "chatgpt");
          updatePromptChatGpt(item.promptType, "stream");

          if (Number(item.chunkSize) > 4000) {
            setSelectedDeploymentTypeOpenChat(deploymentTypeOptions[1]);
            setSelectedDeploymentTypeChatGpt(deploymentTypeOptions[1]);
            setSelectedDeploymentTypeStream(deploymentTypeOptions[1]);
          } else {
            setSelectedDeploymentTypeOpenChat(deploymentTypeOptions[0]);
            setSelectedDeploymentTypeChatGpt(deploymentTypeOptions[0]);
            setSelectedDeploymentTypeStream(deploymentTypeOptions[0]);
          }

          getCosmosSession("openchat", "openchat", "openchat");
          getCosmosSession(item?.key, item?.iType, "chatgpt");
          getCosmosSession(item?.key, item?.iType, "stream");

          const sampleQuestion = [];
          let qastring = item.qa;
          qastring = qastring?.replace(regex, "|").replace(regex1, "|");
          // const questionList = qastring.includes('|') ? qastring.split('|') : qastring.split('\\n');
          const questionList = qastring?.split("|");
          for (const item of questionList) {
            if (item != "") {
              sampleQuestion.push({
                text: item,
                value: item,
                questionList,
              });
            }
          }
          //Set for only gpt Data and Stream
          const generatedExamples: ExampleModel[] = sampleQuestion;

          // setExampleListOpenChat(generatedExamples)

          setExampleListChatGpt(generatedExamples);
          setExampleLoadingChatGpt(false);
          setExampleListStream(generatedExamples);
          setExampleLoadingStream(false);
        }
      }
      setIndexMappingOpenChat(uniqIndexType);
      setIndexMappingChatGpt(uniqIndexType);
      setIndexMappingStream(uniqIndexType);
    }
    setIsBlobsLoaded(true);
  };

  const refreshBlob = async () => {
    const blobs1 = sessionStorage.getItem("blobs");
    const userRoles = JSON.parse(
      sessionStorage.getItem("userRoles") || "[]"
    ) as string[];
    if (blobs1 != null && blobs1 != undefined) {
      const blobs = JSON.parse(blobs1);
      if (blobs.values != null && blobs.values.length > 0) processblobs(blobs);
      else {
        const blobs = await refreshIndex(userRoles || []);
        if (blobs != "Error") {
          sessionStorage.setItem("blobs", JSON.stringify(blobs));
          processblobs(blobs);
        }
      }
    } else {
      const blobs = await refreshIndex(userRoles || []);
      if (blobs != "Error") {
        sessionStorage.setItem("blobs", JSON.stringify(blobs));
        processblobs(blobs);
      }
    }
  };

  const ReLoadBlobs = async () => {
    // Remove blobs from session storage and state
    sessionStorage.removeItem("blobs");
    try {
      const clearblobs = await clearblobsIndex(userGroups || []);
    } catch (exception) {
      console.log(exception);
      // and continue
    }

    try {
      const blobs = await refreshIndex(userGroups || []);
      // if not error than set the blobs in session storage and state
      if (blobs != "Error") {
        sessionStorage.setItem("blobs", JSON.stringify(blobs));
        processblobs(blobs);
      }
    } catch (exception) {
      console.log(exception);
      // and continue
    }
  };

  const ExamplesOpenChat = () => {
    const generatedVExamples: ExampleModel[] = EXAMPLESV;
    setExampleListOpenChat(generatedVExamples);
    setExampleLoadingOpenChat(false);
  };

  const onChangeChatGpt = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedItemChatGpt(item);
    clearChatGpt();
    const defaultKey = item?.key;
    let indexType = "pinecone";
    const regex = /\\n[0-9]+./g;
    const regex1 = /\\n/g;
    indexMappingChatGpt?.findIndex((item) => {
      if (item.key == defaultKey) {
        indexType = item.iType;
        setSelectedIndexChatGpt(item.iType);
        setSummaryChatGpt(item.summary);
        setQaChatGpt(item.qa);
        setSelectedChunkSizeChatGpt(item.chunkSize);
        setSelectedChunkOverlapChatGpt(item.chunkOverlap);
        setSelectedPromptTypeChatGpt(item.promptType);
        setSelectedPromptTypeItemChatGpt(
          promptTypeOptionsChatGpt.find((x) => x.key === item.promptType)
        );
        updatePromptChatGpt(item.promptType, "chatgpt");

        if (Number(item.chunkSize) > 4000) {
          setSelectedDeploymentTypeChatGpt(deploymentTypeOptions[1]);
        } else {
          setSelectedDeploymentTypeChatGpt(deploymentTypeOptions[0]);
        }

        getCosmosSession(item?.key, item?.iType, "chatgpt");

        const sampleQuestion = [];
        let qastring = item.qa;
        qastring = qastring.replace(regex, "|").replace(regex1, "|");
        // const questionList = qastring.includes('|') ? qastring.split('|') : qastring.split('\\n');
        const questionList = qastring.split("|");
        for (const item of questionList) {
          if (item != "") {
            sampleQuestion.push({
              text: item,
              value: item,
              // text: item.replace(/[0-9]./g, ''),
              // value: item.replace(/[0-9]./g, '')
            });
          }
        }
        const generatedExamples: ExampleModel[] = sampleQuestion;
        setExampleListChatGpt(generatedExamples.slice(0, 3));
        setExampleLoadingChatGpt(false);
      }
    });
  };

  const onChangeStream = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedItemStream(item);
    clearStreamChat();
    const defaultKey = item?.key;
    let indexType = "pinecone";
    const regex = /\\n[0-9]+./g;
    const regex1 = /\\n/g;
    indexMappingStream?.findIndex((item) => {
      if (item.key == defaultKey) {
        indexType = item.iType;
        setSelectedIndexStream(item.iType);
        setSummaryStream(item.summary);
        setQaStream(item.qa);
        setSelectedChunkSizeStream(item.chunkSize);
        setSelectedChunkOverlapStream(item.chunkOverlap);
        setSelectedPromptTypeStream(item.promptType);
        setSelectedPromptTypeItemStream(
          promptTypeOptionsChatGpt.find((x) => x.key === item.promptType)
        );
        updatePromptChatGpt(item.promptType, "stream");

        if (Number(item.chunkSize) > 4000) {
          setSelectedDeploymentTypeStream(deploymentTypeOptions[1]);
        } else {
          setSelectedDeploymentTypeStream(deploymentTypeOptions[0]);
        }

        getCosmosSession(item?.key, item?.iType, "stream");
        const sampleQuestion = [];
        let qastring = item.qa;
        qastring = qastring.replace(regex, "|").replace(regex1, "|");
        // const questionList = qastring.includes('|') ? qastring.split('|') : qastring.split('\\n');
        const questionList = qastring.split("|");
        for (const item of questionList) {
          if (item != "") {
            sampleQuestion.push({
              text: item,
              value: item,
              // text: item.replace(/[0-9]./g, ''),
              // value: item.replace(/[0-9]./g, '')
            });
          }
        }
        const generatedExamples: ExampleModel[] = sampleQuestion;
        setExampleListStream(generatedExamples.slice(0, 3));
        setExampleLoadingStream(false);
      }
    });
  };

  const onPromptTemplateChangeOpenChat = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplateOpenChat(newValue || "");
  };

  const onPromptTemplateChangeChatGpt = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplateChatGpt(newValue || "");
  };

  const onPromptTemplateChangeStream = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplateStream(newValue || "");
  };

  const onFunctionCallChanged = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    toggleOn?: boolean
  ): void => {
    setFunctionCall(!!toggleOn);
    clearOpenChat();
  };

  const handleNewConversationOpenChat = (question: string) => {
    const sessId = generateQuickGuid(); //uuidv4();
    // const sessId = question;
    setSessionIdOpenChat(sessId);

    const newSession: ChatSession = {
      id: generateQuickGuid(),
      type: "Session",
      sessionId: sessId,
      name: question, //sessId,
      chainType: "stuff",
      feature: "openchat",
      indexId: "openchat",
      indexType: "openchat",
      indexName: "openchat",
      llmModel: "gpt-4",
      userName: userName || "",
      timestamp: String(new Date().getTime()),
      tokenUsed: 0,
      embeddingModelType: String(selectedEmbeddingItemOpenChat?.key),
    };
    setChatSessionOpenChat(newSession);
    return newSession;
  };

  const handleNewConversationChatGpt = (question: string) => {
    const sessId = generateQuickGuid(); //uuidv4();
    //const sessId = question;
    setSessionIdChatGpt(sessId);

    const newSession: ChatSession = {
      id: generateQuickGuid(),
      type: "Session",
      sessionId: sessId,
      name: question, //sessId,
      chainType: "stuff",
      feature: "chatGpt",
      indexId: String(selectedItemChatGpt?.key),
      indexType: String(selectedIndexChatGpt),
      indexName: String(selectedItemChatGpt?.text),
      llmModel: "gpt-4",
      timestamp: String(new Date().getTime()),
      tokenUsed: 0,
      userName: userName || "",
      embeddingModelType: String(selectedEmbeddingItemChatGpt?.key),
    };
    setChatSessionChatGpt(newSession);
    return newSession;
  };
  const handleNewConversationStream = (question: string) => {
    const sessId = generateQuickGuid(); //uuidv4();
    //  const sessId = question;
    setSessionIdStream(sessId);

    const newSession: ChatSession = {
      id: generateQuickGuid(),
      type: "Session",
      sessionId: sessId,
      name: question, //sessId,
      chainType: "stuff",
      feature: "chatStream",
      indexId: String(selectedItemStream?.key),
      indexType: String(selectedIndexStream),
      indexName: String(selectedItemStream?.text),
      llmModel: "gpt-4",
      timestamp: String(new Date().getTime()),
      tokenUsed: 0,
      userName: userName || "",
      embeddingModelType: String(selectedEmbeddingItemStream?.key),
    };
    setChatSessionStream(newSession);
    return newSession;
  };
  const onRetrieveCountChangeOpenChat = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setRetrieveCountOpenChat(parseInt(newValue || "3"));
  };
  const onRetrieveCountChangeChatGpt = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setRetrieveCountChatGpt(parseInt(newValue || "3"));
  };
  const onRetrieveCountChangeStream = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setRetrieveCountStream(parseInt(newValue || "3"));
  };

  const onUseSemanticRankerChangeOpenChat = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticRankerOpenChat(!!checked);
  };
  const onUseSemanticRankerChangeChatGpt = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticRankerChatGpt(!!checked);
  };
  const onUseSemanticRankerChangeStream = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticRankerStream(!!checked);
  };

  const onUseSemanticCaptionsChangeOpenChat = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticCaptionsOpenChat(!!checked);
  };
  const onUseSemanticCaptionsChangeChatGot = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticCaptionsChatGpt(!!checked);
  };
  const onUseSemanticCaptionsChangeStream = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticCaptionsStream(!!checked);
  };

  const onChainChangeChatGpt = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedChainChatGpt(item);
  };
  const onChainChangeOpenChat = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedChainOpenChat(item);
  };
  const onChainChangeStream = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedChainStream(item);
  };
  const onDeploymentTypeChangeChatGpt = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedDeploymentTypeChatGpt(item);
  };
  const onDeploymentTypeChangeOpenChat = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedDeploymentTypeOpenChat(item);
  };
  const onDeploymentTypeChangeStream = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedDeploymentTypeStream(item);
  };
  const onEmbeddingChangeChatGpt = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedEmbeddingItemChatGpt(item);
  };
  const onEmbeddingChangeOpenChat = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedEmbeddingItemOpenChat(item);
  };
  const onEmbeddingChangeStream = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedEmbeddingItemStream(item);
  };
  const onExcludeCategoryChangedChatGpt = (
    _ev?: React.FormEvent,
    newValue?: string
  ) => {
    setExcludeCategoryChatGpt(newValue || "");
  };
  const onExcludeCategoryChangedOpenChat = (
    _ev?: React.FormEvent,
    newValue?: string
  ) => {
    setExcludeCategoryOpenChat(newValue || "");
  };
  const onExcludeCategoryChangedStream = (
    _ev?: React.FormEvent,
    newValue?: string
  ) => {
    setExcludeCategoryStream(newValue || "");
  };
  const onTemperatureChangeChatGpt = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTemperatureChatGpt(parseInt(newValue || "0.3"));
  };
  const onTemperatureChangeOpenChat = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTemperatureOpenChat(parseInt(newValue || "0.3"));
  };
  const onTemperatureChangeStream = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTemperatureStream(parseInt(newValue || "0.3"));
  };
  const onTokenLengthChangeChatGpt = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTokenLengthChatGpt(parseInt(newValue || "500"));
  };
  const onTokenLengthChangeOpenChat = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTokenLengthOpenChat(parseInt(newValue || "500"));
  };
  const onTokenLengthChangeStream = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTokenLengthStream(parseInt(newValue || "500"));
  };
  const onUseSuggestFollowupQuestionsChangeChatGpt = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSuggestFollowupQuestionsChatGpt(!!checked);
  };
  const onUseSuggestFollowupQuestionsChangeOpenChat = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSuggestFollowupQuestionsOpenChat(!!checked);
  };
  const onUseSuggestFollowupQuestionsChangeStream = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSuggestFollowupQuestionsStream(!!checked);
  };

  const onPromptTypeChangeOpenChat = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    clearOpenChat();
    setSelectedPromptTypeItemOpenChat(item);
    updatePromptOpenChat(String(item?.key), "openchat");
  };

  const onPromptTypeChangeChatGpt = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    clearChatGpt();
    setSelectedPromptTypeItemChatGpt(item);
    updatePromptChatGpt(String(item?.key), "chatgpt");
  };
  const onPromptTypeChangeStream = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    clearStreamChat();
    setSelectedPromptTypeItemStream(item);
    updatePromptChatGpt(String(item?.key), "stream");
  };

  const onShowCitationOpenChat = (citation: string, index: number) => {
    if (citation.indexOf("http") > -1 || citation.indexOf("https") > -1) {
      //window.open(citation.replace('/content/', ''), '_blank');
      window.open(
        citation.replace(citation.substr(0, citation.indexOf("http")), ""),
        "_blank"
      );
    } else {
      if (
        activeCitationOpenChat === citation &&
        activeAnalysisPanelTabOpenChat === AnalysisPanelTabs.CitationTab &&
        selectedAnswerOpenChat === index
      ) {
        setActiveAnalysisPanelTabOpenChat(undefined);
      } else {
        setActiveCitationOpenChat(citation);
        setActiveAnalysisPanelTabOpenChat(AnalysisPanelTabs.CitationTab);
      }
    }
    setSelectedAnswerOpenChat(index);
  };
  const onShowCitationChatGpt = (citation: string, index: number) => {
    if (citation.indexOf("http") > -1 || citation.indexOf("https") > -1) {
      //window.open(citation.replace('/content/', ''), '_blank');
      window.open(
        citation.replace(citation.substr(0, citation.indexOf("http")), ""),
        "_blank"
      );
    } else {
      if (
        activeCitationChatGpt === citation &&
        activeAnalysisPanelTabChatGpt === AnalysisPanelTabs.CitationTab &&
        selectedAnswerChatGpt === index
      ) {
        setActiveAnalysisPanelTabChatGpt(undefined);
      } else {
        setActiveCitationChatGpt(citation);
        setActiveAnalysisPanelTabChatGpt(AnalysisPanelTabs.CitationTab);
      }
    }
    setSelectedAnswerChatGpt(index);
  };
  const onShowCitationStream = (citation: string, index: number) => {
    if (citation.indexOf("http") > -1 || citation.indexOf("https") > -1) {
      //window.open(citation.replace('/content/', ''), '_blank');
      window.open(
        citation.replace(citation.substr(0, citation.indexOf("http")), ""),
        "_blank"
      );
    } else {
      if (
        activeCitationStream === citation &&
        activeAnalysisPanelTabStream === AnalysisPanelTabs.CitationTab &&
        selectedAnswerStream === index
      ) {
        setActiveAnalysisPanelTabStream(undefined);
      } else {
        setActiveCitationStream(citation);
        setActiveAnalysisPanelTabStream(AnalysisPanelTabs.CitationTab);
      }
    }
    setSelectedAnswerStream(index);
  };

  const onToggleTabOpenChat = (tab: AnalysisPanelTabs, index: number) => {
    if (
      activeAnalysisPanelTabOpenChat === tab &&
      selectedAnswerOpenChat === index
    ) {
      setActiveAnalysisPanelTabOpenChat(undefined);
    } else {
      setActiveAnalysisPanelTabOpenChat(tab);
    }

    setSelectedAnswerOpenChat(index);
  };
  const onToggleTabChatGpt = (tab: AnalysisPanelTabs, index: number) => {
    if (
      activeAnalysisPanelTabChatGpt === tab &&
      selectedAnswerChatGpt === index
    ) {
      setActiveAnalysisPanelTabChatGpt(undefined);
    } else {
      setActiveAnalysisPanelTabChatGpt(tab);
    }

    setSelectedAnswerChatGpt(index);
  };
  const onToggleTabStream = (tab: AnalysisPanelTabs, index: number) => {
    if (
      activeAnalysisPanelTabStream === tab &&
      selectedAnswerStream === index
    ) {
      setActiveAnalysisPanelTabStream(undefined);
    } else {
      setActiveAnalysisPanelTabStream(tab);
    }

    setSelectedAnswerStream(index);
  };

  const onSessionClickedOpenChat = async (sessionFromList: any) => {
    //makeApiRequest(sessionFromList.name);
    const sessionName = sessionFromList["Session Name"];
    const sessionId = sessionFromList["SessionId"];

    //console.log(sessionName)

    if (sessionName != "No Session Found") {
      try {
        setSessionNameOpenChat(sessionName);
        setOldSessionNameOpenChat(sessionName);
        setSessionIdOpenChat(sessionId);
        setAnswersOpenChat([]);
        setIsLoadingOpenChat(true);

        await getIndexSession(
          "openchat",
          "openchat",
          sessionName,
          sessionId
        ).then(async (sessionResponse: any) => {
          //console.log(sessionResponse)
          const sessionId = sessionResponse[0].sessionId;
          setSessionIdChatGpt(sessionId);
          const newSession: ChatSession = {
            id: sessionResponse[0].id,
            type: sessionResponse[0].type,
            sessionId: sessionResponse[0].sessionId,
            name: sessionResponse[0].name,
            chainType: sessionResponse[0].chainType,
            feature: sessionResponse[0].feature,
            indexId: sessionResponse[0].indexId,
            indexType: sessionResponse[0].indexType,
            indexName: sessionResponse[0].indexName,
            llmModel: sessionResponse[0].llmModel,
            timestamp: sessionResponse[0].timestamp,
            tokenUsed: sessionResponse[0].tokenUsed,
            userName: sessionResponse[0].userName,
            embeddingModelType: sessionResponse[0].embeddingModelType,
          };
          setChatSessionOpenChat(newSession);
          //console.log(newSession)

          //console.log('gettomg session detail')

          await getIndexSessionDetail(sessionId).then(async (response: any) => {
            //console.log(response)
            const rows = response.reduce(function (
              rows: any[][],
              key: any,
              index: number
            ) {
              return (
                (index % 2 == 0
                  ? rows.push([key])
                  : rows[rows.length - 1].push(key)) && rows
              );
            },
            []);
            //console.log(rows)

            const sessionLists: [string, AskResponse, string | null][] = [];
            for (const session of rows) {
              sessionLists.push([session[0].content, session[1].content, null]);
            }
            //console.log('sessionLists')
            //console.log(sessionLists)

            lastQuestionRefOpenChat.current =
              sessionLists[sessionLists.length - 1][0];
            //console.log('lastQuestionRefOpenChat')
            //console.log(lastQuestionRefOpenChat.current)

            //console.log('setAnswersOpenChat')
            //console.log(sessionLists)
            setAnswersOpenChat(sessionLists);
          });
        });
      } catch (e) {
        //console.log(e)
        setErrorOpenChat(e);
      } finally {
        setIsLoadingOpenChat(false);
      }
    }
  };

  const onSessionClickedChatGpt = async (sessionFromList: any) => {
    //makeApiRequest(sessionFromList.name);
    const sessionName = sessionFromList["Session Name"];
    const sessionId = sessionFromList["SessionId"];
    if (sessionName != "No Session Found") {
      try {
        setSessionNameChatGpt(sessionName);
        setOldSessionNameChatGpt(sessionName);
        setSessionIdChatGpt(sessionId);
        setAnswersChatGpt([]);
        setIsLoadingChatGpt(true);
        await getIndexSession(
          String(selectedItemChatGpt?.key),
          String(selectedIndexChatGpt),
          sessionName,
          sessionId
        ).then(async (sessionResponse: any) => {
          const sessionId = sessionResponse[0].sessionId;
          const newSession: ChatSession = {
            id: sessionResponse[0].id,
            type: sessionResponse[0].type,
            sessionId: sessionResponse[0].sessionId,
            name: sessionResponse[0].name,
            chainType: sessionResponse[0].chainType,
            feature: sessionResponse[0].feature,
            indexId: sessionResponse[0].indexId,
            indexType: sessionResponse[0].indexType,
            indexName: sessionResponse[0].indexName,
            llmModel: sessionResponse[0].llmModel,
            timestamp: sessionResponse[0].timestamp,
            tokenUsed: sessionResponse[0].tokenUsed,
            userName: sessionResponse[0].userName,
            embeddingModelType: sessionResponse[0].embeddingModelType,
          };
          setChatSessionChatGpt(newSession);
          await getIndexSessionDetail(sessionId).then(async (response: any) => {
            const rows = response.reduce(function (
              rows: any[][],
              key: any,
              index: number
            ) {
              return (
                (index % 2 == 0
                  ? rows.push([key])
                  : rows[rows.length - 1].push(key)) && rows
              );
            },
            []);
            const sessionLists: [string, AskResponse, string | null][] = [];
            for (const session of rows) {
              sessionLists.push([session[0].content, session[1].content, null]);
            }
            lastQuestionRefChatGpt.current =
              sessionLists[sessionLists.length - 1][0];
            setAnswersChatGpt(sessionLists);
          });
        });
      } catch (e) {
        setErrorChatGpt(e);
      } finally {
        setIsLoadingChatGpt(false);
      }
    }
  };

  const onSessionClickedStream = async (sessionFromList: any) => {
    //makeApiRequest(sessionFromList.name);
    const sessionName = sessionFromList["Session Name"];
    const sessionId = sessionFromList["SessionId"];
    if (sessionName != "No Session Found") {
      try {
        setSessionNameStream(sessionName);
        setOldSessionNameStream(sessionName);
        setSessionIdStream(sessionId);
        setAnswersStream([]);
        setIsLoadingStream(true);
        await getIndexSession(
          String(selectedItemStream?.key),
          String(selectedIndexStream),
          sessionName,
          sessionId
        ).then(async (sessionResponse: any) => {
          const sessionId = sessionResponse[0].sessionId;
          const newSession: ChatSession = {
            id: sessionResponse[0].id,
            type: sessionResponse[0].type,
            sessionId: sessionResponse[0].sessionId,
            name: sessionResponse[0].name,
            chainType: sessionResponse[0].chainType,
            feature: sessionResponse[0].feature,
            indexId: sessionResponse[0].indexId,
            indexType: sessionResponse[0].indexType,
            indexName: sessionResponse[0].indexName,
            llmModel: sessionResponse[0].llmModel,
            timestamp: sessionResponse[0].timestamp,
            tokenUsed: sessionResponse[0].tokenUsed,
            userName: sessionResponse[0].userName,
            embeddingModelType: sessionResponse[0].embeddingModelType,
          };
          setChatSessionStream(newSession);
          await getIndexSessionDetail(sessionId).then(async (response: any) => {
            const rows = response.reduce(function (
              rows: any[][],
              key: any,
              index: number
            ) {
              return (
                (index % 2 == 0
                  ? rows.push([key])
                  : rows[rows.length - 1].push(key)) && rows
              );
            },
            []);
            const sessionLists: [string, AskResponse, string | null][] = [];
            for (const session of rows) {
              sessionLists.push([session[0].content, session[1].content, null]);
            }
            lastQuestionRefStream.current =
              sessionLists[sessionLists.length - 1][0];
            setAnswersStream(sessionLists);
          });
        });
      } catch (e) {
        setErrorStream(e);
      } finally {
        setIsLoadingStream(false);
      }
    }
  };

  const updatePromptChatGpt = (promptType: string, whichone: string) => {
    let obj =
      ChatDataPrompts.find((x) => x.key === promptType) || ChatDataPrompts[0];

    if (whichone.toLowerCase() == "chatgpt") {
      setPromptTemplateChatGpt(obj.text);
    } else if (whichone.toLowerCase() == "stream") {
      setPromptTemplateStream(obj.text);
    }
  };

  const updatePromptOpenChat = (promptType: string, whichone: string) => {
    let obj =
      OpenChatBingPrompts.find((x) => x.key === promptType) ||
      OpenChatBingPrompts[0];
    setPromptTemplateOpenChat(obj.text);
  };

  const selectionOpenChat = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          //console.log('selectionOpenChat');
          let sessionselected = selectionOpenChat.getSelection();
          setSelectedItemsOpenChat(sessionselected);
        },
        selectionMode: SelectionMode.single,
      }),
    []
  );

  const selectionChatGpt = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          let sessionselected = selectionChatGpt.getSelection();
          setSelectedItemsChatGpt(sessionselected);
        },
        selectionMode: SelectionMode.single,
      }),
    []
  );

  const selectionStream = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          let sessionselected = selectionStream.getSelection();
          setSelectedItemsStream(sessionselected);
        },
        selectionMode: SelectionMode.single,
      }),
    []
  );

  // Conditionally hide the 'ID' column by removing it from the column array
  const columnsToShow: IColumn[] = sessionListColumn.filter(
    (column) => column.key !== "SessionId"
  );

  const detailsListOpenChat = useMemo(
    () => (
      <MarqueeSelection selection={selectionOpenChat}>
        <DetailsList
          // className={styles.DetailsListStyle}
          items={sessionListOpenChat || []}
          columns={columnsToShow}
          selectionMode={SelectionMode.single}
          getKey={(item: any) => item.key}
          setKey="single"
          //isHeaderVisible={false}
          constrainMode={ConstrainMode.unconstrained}
          onActiveItemChanged={(item: any) => onSessionClickedOpenChat(item)}
          // focusZoneProps={focusZoneProps}

          layoutMode={DetailsListLayoutMode.fixedColumns}
          ariaLabelForSelectionColumn="Toggle selection"
          checkButtonAriaLabel="select row"
          selection={selectionOpenChat}
          selectionPreservedOnEmptyClick={false}
          // onRenderRow={onRenderRow}
          checkboxVisibility={CheckboxVisibility.hidden} // Hide the checkboxes
        />
      </MarqueeSelection>
    ),
    [selectionOpenChat, sessionListColumn, sessionListOpenChat]
  );

  const detailsListChatGpt = useMemo(
    () => (
      <MarqueeSelection selection={selectionChatGpt}>
        <DetailsList
          selection={selectionChatGpt}
          className={styles.DetailsListStyle}
          items={sessionListChatGpt || []}
          columns={sessionListColumn}
          selectionMode={SelectionMode.single}
          getKey={(item: any) => item.key}
          setKey="single"
          onActiveItemChanged={(item: any) => onSessionClickedChatGpt(item)}
          // onRenderRow={onRenderRow}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          ariaLabelForSelectionColumn="Toggle selection"
          checkButtonAriaLabel="select row"
          selectionPreservedOnEmptyClick={false}
        />
      </MarqueeSelection>
    ),
    [selectionChatGpt, sessionListColumn, sessionListChatGpt]
  );

  const detailsListStream = useMemo(
    () => (
      <MarqueeSelection selection={selectionStream}>
        <DetailsList
          selection={selectionStream}
          // className={styles.DetailsListStyle}
          items={sessionListStream || []}
          columns={sessionListColumn}
          selectionMode={SelectionMode.single}
          getKey={(item: any) => item.key}
          setKey="single"
          onActiveItemChanged={(item: any) => onSessionClickedStream(item)}
          // onRenderRow={onRenderRow}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          ariaLabelForSelectionColumn="Toggle selection"
          checkButtonAriaLabel="select row"
          selectionPreservedOnEmptyClick={false}
          checkboxVisibility={CheckboxVisibility.hidden}
        />
      </MarqueeSelection>
    ),
    [selectionStream, sessionListColumn, sessionListStream]
  );

  useEffect(() => {
    if (inProgress === InteractionStatus.None && instance) {
      const account: AccountInfo | null = instance.getActiveAccount();
      setUserName(account?.username);
      setIsAuthenticated(true);
      if (sessionStorage.getItem("userRoles")) {
        const ur = JSON.parse(sessionStorage.getItem("userRoles") || "[]") as string[];
        setUserGroups(ur);
      }
    }
  }, [inProgress, instance]);

  useEffect(() => {
    if (userName && userGroups) {
      refreshBlob();
    }
  }, [userName, userGroups])

  useEffect(() => {
    setIsOpenOCSessionPanel(context.isOpenOCSessionPanel);
    setIsConfigPanelOpen(context.isOpenOCConfigPanel);
    setIsConfigPanelOpenStream(context.isConfigPanelOpenStream);
    setIsOpenOSSessionPanelStream(context.isOpenOSSessionPanelStream);
  }, [context]);

  useEffect(() => {
    chatMessageStreamEndOpenChat.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [isLoadingOpenChat]);

  useEffect(() => {
    chatMessageStreamEndChatGpt.current?.scrollIntoView({ behavior: "smooth" });
  }, [isLoadingChatGpt]);

  useEffect(() => {
    chatMessageStreamEndStream.current?.scrollIntoView({ behavior: "smooth" });
  }, [isLoadingStream]);

  // This code will run when the component is mounted (initial render)
  // and whenever the specified dependencies change.
  useEffect(() => {
    setBlobOptions([]);

    setChainTypeOptionsOpenChat(chainType);
    setChainTypeOptionsChatGpt(chainType);
    setChainTypeOptionsStream(chainType);

    setSelectedChainOpenChat(chainType[0]);
    setSelectedChainChatGpt(chainType[0]);
    setSelectedChainStream(chainType[0]);

    setSelectedEmbeddingItemOpenChat(embeddingOptions[0]);
    setSelectedEmbeddingItemChatGpt(embeddingOptions[0]);
    setSelectedEmbeddingItemStream(embeddingOptions[0]);

    setSelectedDeploymentTypeOpenChat(deploymentTypeOptions[0]);
    setSelectedDeploymentTypeChatGpt(deploymentTypeOptions[0]);
    setSelectedDeploymentTypeStream(deploymentTypeOptions[0]);

    setSelectedDeploymentTypeOpenChat(deploymentTypeOptions[0]);
    setSelectedDeploymentTypeChatGpt(deploymentTypeOptions[0]);
    setSelectedDeploymentTypeStream(deploymentTypeOptions[0]);

    setSelectedPromptTypeItemOpenChat(promptTypeOptionsOpenChat[0]);
    setSelectedPromptTypeItemChatGpt(promptTypeOptionsChatGpt[0]);
    setSelectedPromptTypeItemStream(promptTypeOptionsChatGpt[0]);
    ExamplesOpenChat();
    // getUserName();
    // refreshBlob();
    setFunctionCall(true);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  const panelStyles = {
    main: {
      width: "300px",
      height: "calc(90% - 60px)",
      top: "80px",
      // maxWidth: '100%',
      right: "20px",
      backgroundColor: "#fff",
      color: "#35383e",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px #e20074",
      borderRadius: "8px",
    },
  };
  const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    link: {
      selectors: {
        "&.is-selected::before": {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          background: "#e20074",
          // color: '#e20074',
          //borderBottom: '2px solid #e20074', // Customize the underline style here
        },
      },
    },
    // linkIsSelected: {
    //     background: 'white',
    //     color: '#e20074 !important',
    // }
  };
  const includeWebResultsStyles: IStyleFunctionOrObject<
    IToggleProps,
    IToggleStyles
  > = {
    root: {
      alignSelf: "end",
      background: "transparent",
      border: "none",
      minWidth: "16px",
      padding: 0,
      margin: 0,
      label: {
        fontWeight: "normal",
        fontSize: "0.8rem",
        margin: "2px",
      },
      "button:hover": {
        borderColor: "rgb(96, 94, 92)",
        span: {
          background: "rgb(96, 94, 92) !important",
        },
      },
      "&.is-checked": {
        button: {
          background: "#e20074",
        },
      },
    },
  };
  const showTabTchatbing = true;
  const showTabchatstream = true;
  const showTabTMobiledataplayground = false;
  const tooltipId = useId("tooltip");
  const allowedUserRoles = [
    "OpenAIUsers",
    "OpenAIDemoUsers",
    "OpenAIHRAdmin",
    "OpenAIHRUsers",
    "OpenAIAdmin",
    "OpenAIFinanceAdmin",
    "OpenAIFinanceUsers",
  ];

  return (
    <>
      <AuthenticatedTemplate>
        {inProgress === InteractionStatus.None &&
          (userGroups || []).some((userRole) =>
            allowedUserRoles.includes(userRole)
          ) ? (
          <div className={styles.rootMain}>
            <div className={styles.container}>
              {/* Chat GPT4  */}
              {document.URL.indexOf("chatGpt/0") > -1 ||
                !(document.URL.indexOf("chatGpt/1") > -1) ? (
                <div className={styles.parentcommandrootcontainer}>
                  <div className={styles.commandsContainer}>
                    {/* <div className={styles.commandButton} > </div> */}
                    {/* <MenuSettingButton title="User History" className={styles.commandButton1} onClick={() => setIsSessionConfigPanelOpenOpenChat(!isSessionConfigPanelOpenOpenChat)} /> */}
                    {/* <div className={styles.settingsContainer}> */}
                    {/* <Checkbox styles={checkBoxStyles} label="Web search results" checked={functionCall} onChange={onFunctionCallChanged} /> */}
                    {/* <SettingsButton className={styles.commandButtonSettings} onClick={() => setIsConfigPanelOpenOpenChat(!isConfigPanelOpenOpenChat)} /> */}
                    {/* </div> */}
                  </div>
                  <div className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                      {!lastQuestionRefOpenChat.current ? (
                        <div className={styles.chatEmptyState}>
                          {exampleLoadingOpenChat ? (
                            <div>
                              <span>
                                Please wait, Generating Sample Question
                              </span>
                              <Spinner />
                            </div>
                          ) : null}
                          <div className={styles.staticInfo}>
                            <div className={styles.staticContainer}>
                              <div className={styles.chatGPTOpenHeader}>
                                <div className={styles.staticInfoHeader}>
                                  {" "}
                                  <div>T-Mobile Chat GPT 4</div>
                                  <div
                                    className={styles.chatGPTSecureLogo}
                                  ></div>
                                </div>
                              </div>
                              <p className={styles.staticInfoDesc}>
                                {" "}
                                Powered by Azure Open AI GPT 4
                              </p>
                              <p className={styles.staticInfoDetail}>
                                {" "}
                                T-Mobile ChatGPT is an internal secure version
                                of GPT 4 that can supercharge your imagination,
                                boost your productivity, and help you bring your
                                ideas to work. Your chat data are protected in
                                this application.
                              </p>
                            </div>
                            <div className={styles.chatEmptyStateSubtitle}>
                              <div className={styles.chatEmptyStateHeader}>
                                <SparkleFilled
                                  fontSize={"45px"}
                                  primaryFill={"rgb(226, 0, 116)"}
                                  aria-hidden="true"
                                  aria-label="Chat logo"
                                />
                                <div className={styles.chatExampleHeader}>
                                  Try from the following examples{" "}
                                </div>
                              </div>
                              <ExampleList
                                onExampleClicked={onExampleClickedOpenChat}
                                EXAMPLES={exampleListOpenChat}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.chatMessageGpt}>
                          {exampleLoadingOpenChat ? (
                            <div>
                              <span>
                                Please wait, Generating Sample Question
                              </span>
                              <Spinner />
                            </div>
                          ) : null}
                          <div className={styles.staticInfo}>
                            <div className={styles.staticContainer}>
                              <div className={styles.staticInfoHeader}>
                                {" "}
                                <div>T-Mobile Chat GPT 4</div>
                                <div className={styles.chatGPTSecureLogo}></div>
                              </div>
                              <p className={styles.staticInfoDesc}>
                                {" "}
                                Powered by Azure Open AI GPT 4
                              </p>
                              <p className={styles.staticInfoDetail}>
                                {" "}
                                T-Mobile ChatGPT is an internal secure version
                                of GPT 4 that can supercharge your imagination,
                                boost your productivity, and help you bring your
                                ideas to work. Your chat data are protected in
                                this application.
                              </p>
                            </div>
                            <ExampleList
                              onExampleClicked={onExampleClickedOpenChat}
                              EXAMPLES={exampleListOpenChat}
                            />
                          </div>
                          <div>
                            {answersOpenChat.map((answer, index) => (
                              <div
                                className={styles.chatMessageGptUsermsg}
                                key={index}
                              >
                                <UserChatMessage message={answer[0]} />
                                <div className={styles.chatMessageGptAnswer}>
                                  <div className={ answersOpenChat.length > 0 &&
                                      activeAnalysisPanelTabOpenChat && 
                                      index === thoughtProcessClickedTab ? styles.width50 : styles.width100}>
                                    <Answer
                                      key={index}
                                      answer={answer[1]}
                                      isSpeaking={
                                        runningIndexOpenChat === index
                                      }
                                      isSelected={
                                        selectedAnswerOpenChat === index &&
                                        activeAnalysisPanelTabOpenChat !==
                                        undefined
                                      }
                                      onCitationClicked={(c) =>
                                        onShowCitationOpenChat(c, index)
                                      }
                                      onThoughtProcessClicked={() => {
                                        setThoughtProcessClickedTab(index); // This may have to be implemented for Chat Streaming part later
                                        onToggleTabOpenChat(
                                          AnalysisPanelTabs.ThoughtProcessTab,
                                          index
                                        );
                                      }}
                                      onSupportingContentClicked={() => {
                                        // The above index setting would be required here as well
                                        setSupportingContentClickedTab(index);
                                        onToggleTabOpenChat(
                                          AnalysisPanelTabs.SupportingContentTab,
                                          index
                                        )}
                                      }
                                      onFollowupQuestionClicked={(q) =>
                                        makeApiRequestOpenChatBing(q)
                                      }
                                      onSpeechSynthesisClicked={() =>
                                        startOrStopSynthesisOpenChat(
                                          "openchat",
                                          answer[2],
                                          index
                                        )
                                      }
                                      showFollowupQuestions={
                                        useSuggestFollowupQuestionsOpenChat &&
                                        answersOpenChat.length - 1 === index
                                      }
                                      showSupportingContentIcon={false}
                                    />
                                  </div>
                                  <div className={answersOpenChat.length > 0 &&
                                      activeAnalysisPanelTabOpenChat && 
                                      index === thoughtProcessClickedTab ? styles.width50 : styles.none}>
                                    {answersOpenChat.length > 0 &&
                                      activeAnalysisPanelTabOpenChat &&
                                      index === thoughtProcessClickedTab && (
                                        <AnalysisPanel
                                          className={styles.chatAnalysisPanel}
                                          activeCitation={
                                            activeCitationOpenChat
                                          }
                                          onActiveTabChanged={(x) =>
                                            onToggleTabOpenChat(
                                              x,
                                              selectedAnswerOpenChat
                                            )
                                          }
                                          citationHeight="810px"
                                          answer={
                                            answersOpenChat[
                                            selectedAnswerOpenChat
                                            ][1]
                                          }
                                          activeTab={
                                            activeAnalysisPanelTabOpenChat
                                          }
                                          showCitationTab={false}
                                          showSupportingContentTab={false}
                                        />
                                      )}{" "}
                                  </div>
                                </div>
                              </div>
                            ))}
                            {isLoadingOpenChat && (
                              <div className={styles.isloading}>
                                <UserChatMessage
                                  message={lastQuestionRefOpenChat.current}
                                />
                                <div className={styles.chatMessageGptMinWidth}>
                                  <AnswerLoading />
                                </div>
                              </div>
                            )}
                            {errorOpenChat ? (
                              <div className={styles.error}>
                                <UserChatMessage
                                  message={lastQuestionRefOpenChat.current}
                                />
                                <div className={styles.chatMessageGptMinWidth}>
                                  <AnswerError
                                    error={errorOpenChat.toString()}
                                    onRetry={() =>
                                      makeApiRequestOpenChatBing(
                                        lastQuestionRefOpenChat.current
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ) : null}
                            <div ref={chatMessageStreamEndOpenChat} />
                          </div>
                          <div> </div>
                          {/* <div className={styles.questionInputMainContainer}>

                                                        <div className={styles.chatInput}>
                                                            <TooltipHost
                                                                content="This is the tooltip content"
                                                                calloutProps={calloutProps}
                                                                styles={hostStyles}
                                                                id={tooltipId}>
                                                                <ClearChatButton className={styles.commandButton} onClick={clearOpenChat} disabled={!lastQuestionRefOpenChat.current || isLoadingOpenChat} />
                                                            </TooltipHost>
                                                            <QuestionInput
                                                                clearOnSend
                                                                placeholder="Type a new question"
                                                                disabled={isLoadingOpenChat}
                                                                onSend={question => makeApiRequestOpenChatBing(question)}
                                                            />
                                                        </div>
                                                        <div className={styles.disclaimer}>T-Mobile Chat GPT may display inaccurate or offensive information that doesn’t represent T-Mobile’s views.</div>
                                                    </div> */}
                        </div>
                      )}
                    </div>
                    {/* {answersOpenChat.length > 0 && activeAnalysisPanelTabOpenChat && (
                                            <AnalysisPanel
                                                className={styles.chatAnalysisPanel}
                                                activeCitation={activeCitationOpenChat}
                                                onActiveTabChanged={x => onToggleTabOpenChat(x, selectedAnswerOpenChat)}
                                                citationHeight="810px"
                                                answer={answersOpenChat[selectedAnswerOpenChat][1]}
                                                activeTab={activeAnalysisPanelTabOpenChat}
                                                showCitationTab={false}
                                                showSupportingContentTab={false}
                                            />
                                        )} */}
                    <Panel
                      styles={panelStyles}
                      isOpen={isOpenOCSessionPanel}
                      // isBlocking={true}
                      isLightDismiss={true}
                      // type={PanelType.smallFixedNear}
                      onDismiss={() => context.onOCSessionOpen(false)}
                      closeButtonAriaLabel="Close"
                      // isHiddenOnDismiss={true}
                      isFooterAtBottom={true}
                    >
                      {/* <div className={styles.commandsContainer}> */}
                      {/* <SessionButton className={styles.commandButton} onClick={deleteSessionOpenChat} disabled={!lastQuestionRefOpenChat.current || isLoadingOpenChat} /> */}
                      {/* <RenameButton className={styles.commandButton} onClick={renameSessionOpenChat} text="Rename Session" disabled={!lastQuestionRefOpenChat.current || isLoadingOpenChat} />
                                                        {(!lastQuestionRefOpenChat.current || isLoadingOpenChat) ? null :
                                                            <TextField className={styles.commandButton} value={sessionNameOpenChat} onChange={onSessionNameChangeOpenChat} />
                                                        } */}
                      {/* </div> */}

                      {detailsListOpenChat}
                    </Panel>
                    <Panel
                      styles={panelStyles}
                      isLightDismiss={true}
                      headerText="Configure Chat Interaction"
                      isOpen={isOpenOCConfigPanel}
                      // isBlocking={true}
                      onDismiss={() => context.onOCConfigOpen(false)}
                      closeButtonAriaLabel="Close"
                      // onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpenOpenChat(false)}>Close</DefaultButton>}
                      isFooterAtBottom={true}
                    >
                      <br />
                      <div>
                        <Label>LLM Model</Label>
                        <Dropdown
                          selectedKey={
                            selectedEmbeddingItemOpenChat
                              ? selectedEmbeddingItemOpenChat.key
                              : undefined
                          }
                          onChange={onEmbeddingChangeOpenChat}
                          placeholder="Select LLM Model"
                          options={embeddingOptions}
                          disabled={false}
                          styles={dropdownStyles}
                        />
                      </div>
                      <div>
                        <Label>Deployment Type</Label>
                        <Dropdown
                          selectedKey={
                            selectedDeploymentTypeOpenChat
                              ? selectedDeploymentTypeOpenChat.key
                              : undefined
                          }
                          onChange={onDeploymentTypeChangeOpenChat}
                          placeholder="Select Deployment Type"
                          options={deploymentTypeOptions}
                          disabled={
                            (selectedEmbeddingItemOpenChat?.key == "openai"
                              ? true
                              : false) ||
                            (Number(selectedChunkSizeOpenChat) > 4000
                              ? true
                              : false)
                          }
                          styles={dropdownStyles}
                        />
                      </div>
                      <div>
                        <Label>Prompt Type</Label>
                        <Dropdown
                          selectedKey={
                            selectedPromptTypeItemOpenChat
                              ? selectedPromptTypeItemOpenChat.key
                              : undefined
                          }
                          onChange={onPromptTypeChangeOpenChat}
                          placeholder="Prompt Type"
                          options={promptTypeOptionsOpenChat}
                          disabled={false}
                          styles={dropdownStyles}
                        />
                        <TextField
                          className={styles.oneshotSettingsSeparator}
                          value={promptTemplateOpenChat}
                          label="Override prompt template"
                          multiline
                          autoAdjustHeight
                          onChange={onPromptTemplateChangeOpenChat}
                        />
                      </div>
                      <div>
                        <SpinButton
                          className={styles.oneshotSettingsSeparator}
                          label="Set the Temperature:"
                          min={0.0}
                          max={1.0}
                          defaultValue={temperatureOpenChat.toString()}
                          onChange={onTemperatureChangeOpenChat}
                        />
                      </div>
                      <div>
                        <SpinButton
                          className={styles.oneshotSettingsSeparator}
                          label="Max Length (Tokens):"
                          min={0}
                          max={4000}
                          defaultValue={tokenLengthOpenChat.toString()}
                          onChange={onTokenLengthChangeOpenChat}
                        />
                      </div>
                    </Panel>
                  </div>
                  <div className={styles.questionInputContainerOpenChat}>
                    <div className={styles.webResultsToggle}>
                      <Toggle
                        label="Include web results"
                        defaultChecked
                        inlineLabel
                        onChange={onFunctionCallChanged}
                        styles={includeWebResultsStyles}
                      />
                    </div>
                    <div className={styles.chatInput}>
                      {/* <div className={styles.newSessionStyles}> */}
                      <ClearChatButton
                        className={styles.commandButton}
                        onClick={clearOpenChat}
                        disabled={
                          !lastQuestionRefOpenChat.current || isLoadingOpenChat
                        }
                      />
                      {/* </div> */}
                      <QuestionInput
                        clearOnSend
                        placeholder="Type a new question"
                        disabled={isLoadingOpenChat}
                        onSend={(question) =>
                          makeApiRequestOpenChatBing(question)
                        }
                      />
                    </div>
                    <div className={styles.disclaimer}>
                      T-Mobile Chat GPT may display inaccurate or offensive
                      information that doesn’t represent T-Mobile’s views.
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* TMobile Content  */}
              {document.URL.indexOf("chatGpt/1") > -1 ? (
                <div className={styles.parentcommandrootcontainer}>
                  <div className={styles.commandsContainerOpenStream}>
                    {/* <SettingsButton className={styles.commandButtonSettings} onClick={() => setIsConfigPanelOpenStream(!isConfigPanelOpenStream)} /> */}
                    <div className={styles.commandButtonStreamDoc}>
                      {selectedItemStream
                        ? "Document Set : " + selectedItemStream.text
                        : "Document Name : Not Available"}
                    </div>
                  </div>

                  {selectedItemStream ? (
                    <div className={styles.chatRoot}>
                      <div className={styles.chatContainer}>
                        {!lastQuestionRefStream.current ? (
                          <div className={styles.chatEmptyStateStream}>
                            {exampleLoadingStream ? (
                              <div>
                                <span>
                                  Please wait, Generating Sample Question
                                </span>
                                <Spinner />
                              </div>
                            ) : null}
                            <div className={styles.staticInfo}>
                              <div className={styles.staticContainer}>
                                <p className={styles.staticInfoHeader}>
                                  {" "}
                                  T-Mobile Data Streaming
                                </p>
                                <p className={styles.staticInfoDesc}>
                                  {" "}
                                  Powered by Azure Open AI GPT 4
                                </p>
                                <p className={styles.staticInfoDetail}>
                                  {" "}
                                  T-Mobile ChatGPT is an internal secure version
                                  of GPT 4 that can supercharge your
                                  imagination, boost your productivity, and help
                                  you bring your ideas to work. Your chat data
                                  are protected in this application.
                                </p>
                              </div>
                            </div>
                            <div className={styles.chatEmptyStateSubtitle}>
                              <div className={styles.chatEmptyStateHeader}>
                                <SparkleFilled
                                  fontSize={"45px"}
                                  primaryFill={"rgb(226, 0, 116)"}
                                  aria-hidden="true"
                                  aria-label="Chat logo"
                                />
                                <div className={styles.chatExampleHeader}>
                                  Try from the following examples{" "}
                                </div>
                              </div>
                              <ExampleList
                                onExampleClicked={onExampleStreamClicked}
                                EXAMPLES={exampleListStream}
                              />
                            </div>
                            {/* <div className={styles.questionInputMainContainer}>
                                                            <div className={styles.chatInput}>
                                                                <TooltipHost
                                                                    content="This is the tooltip content"
                                                                    calloutProps={calloutProps}
                                                                    styles={hostStyles}
                                                                    id={tooltipId}>
                                                                    <ClearChatButton className={styles.commandButton} onClick={clearStreamChat} disabled={!lastQuestionRefStream.current || isLoadingStream} />
                                                                </TooltipHost>
                                                                <QuestionInput
                                                                    clearOnSend
                                                                    placeholder="Type a new question"
                                                                    disabled={isLoadingStream}
                                                                    onSend={question => makeApiRequestStream(question)}
                                                                />
                                                            </div>
                                                            <div className={styles.disclaimer}>T-Mobile Chat GPT may display inaccurate or offensive information that doesn’t represent T-Mobile’s views.</div>
                                                        </div> */}
                          </div>
                        ) : (
                          <div className={styles.chatMessageStream}>
                            {exampleLoadingStream ? (
                              <div>
                                <span>
                                  Please wait, Generating Sample Question
                                </span>
                                <Spinner />
                              </div>
                            ) : null}
                            <div className={styles.staticInfo}>
                              <div className={styles.staticContainer}>
                                <p className={styles.staticInfoHeader}>
                                  {" "}
                                  T-Mobile Data Streaming
                                </p>
                                <p className={styles.staticInfoDesc}>
                                  {" "}
                                  Powered by Azure Open AI GPT 4
                                </p>
                                <p className={styles.staticInfoDetail}>
                                  {" "}
                                  T-Mobile ChatGPT is an internal secure version
                                  of GPT 4 that can supercharge your
                                  imagination, boost your productivity, and help
                                  you bring your ideas to work. Your chat data
                                  are protected in this application.
                                </p>
                              </div>
                              <ExampleList
                                onExampleClicked={onExampleStreamClicked}
                                EXAMPLES={exampleListStream}
                              />
                            </div>
                            {answersStream.map((answer, index) => (
                              <div
                                className={styles.chatMessageStreamUsermsg}
                                key={index}
                              >
                                <UserChatMessage message={answer[0]} />
                                <div className={styles.chatMessageGptStreamAnswer}>
                                  <div className={ answersStream.length > 0 &&
                                      activeAnalysisPanelTabStream && 
                                      (index === thoughtProcessClickedTab || index === supportingContentClickedTab || index === citationClickedTab) ? styles.width50 : styles.none}>
                                    <Answer
                                      key={index}
                                      answer={answer[1]}
                                      isSpeaking={runningIndexStream === index}
                                      isSelected={
                                        selectedAnswerStream === index &&
                                        activeAnalysisPanelTabStream !==
                                        undefined
                                      }
                                      onCitationClicked={(c) => {
                                        setCitationClickedTab(index);
                                        onShowCitationStream(c, index)
                                      }
                                    }
                                      onThoughtProcessClicked={() => {
                                        setThoughtProcessClickedTab(index); 
                                        onToggleTabStream(
                                          AnalysisPanelTabs.ThoughtProcessTab,
                                          index
                                        )
                                      }
                                      }
                                      onSupportingContentClicked={() => {
                                        // The above index setting would be required here as well
                                        setSupportingContentClickedTab(index);
                                        onToggleTabStream(
                                          AnalysisPanelTabs.SupportingContentTab,
                                          index
                                        )
                                      }
                                      }
                                      onFollowupQuestionClicked={(q) =>
                                        makeApiRequestStream(q)
                                      }
                                      onSpeechSynthesisClicked={() =>
                                        startOrStopSynthesisStream(
                                          "gpt4",
                                          answer[2],
                                          index
                                        )
                                      }
                                      showFollowupQuestions={
                                        useSuggestFollowupQuestionsStream &&
                                        answersStream.length - 1 === index
                                      }
                                      showSupportingContentIcon={true}
                                    />
                                  </div>
                                  <div className={answersStream.length > 0 &&
                                      activeAnalysisPanelTabStream && 
                                      (index === thoughtProcessClickedTab || index === supportingContentClickedTab || index === citationClickedTab) ? styles.width50 : styles.none}>
                                    {answersStream.length > 0 &&
                                      activeAnalysisPanelTabStream && 
                                      (index === thoughtProcessClickedTab || index === supportingContentClickedTab || index === citationClickedTab) && 
                                      (
                                        <AnalysisPanel
                                          className={
                                            styles.chatStreamAnalysisPanel
                                          }
                                          activeCitation={activeCitationStream}
                                          onActiveTabChanged={(x) =>
                                            onToggleTabStream(
                                              x,
                                              selectedAnswerStream
                                            )
                                          }
                                          citationHeight="810px"
                                          answer={
                                            answersStream[
                                            selectedAnswerStream
                                            ][1]
                                          }
                                          activeTab={
                                            activeAnalysisPanelTabStream
                                          }
                                          showCitationTab={true}
                                          showSupportingContentTab={true}
                                        />
                                      )}
                                  </div>
                                </div>
                              </div>
                            ))}
                            {isLoadingStream && (
                              <div className={styles.isloading}>
                                <UserChatMessage
                                  message={lastQuestionRefStream.current}
                                />
                                <div className={styles.chatMessageGptMinWidth}>
                                  <AnswerLoading />
                                </div>
                              </div>
                            )}
                            {errorStream ? (
                              <div className={styles.error}>
                                <UserChatMessage
                                  message={lastQuestionRefStream.current}
                                />
                                <div className={styles.chatMessageGptMinWidth}>
                                  <AnswerError
                                    error={errorStream.toString()}
                                    onRetry={() =>
                                      makeApiRequestStream(
                                        lastQuestionRefStream.current
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ) : null}
                            <div ref={chatMessageStreamEndStream} />
                            {/* <div className={styles.chatInput}>
                                                            <TooltipHost
                                                                content="This is the tooltip content"
                                                                calloutProps={calloutProps}
                                                                styles={hostStyles}
                                                                id={tooltipId}>
                                                                <ClearChatButton className={styles.commandButton} onClick={clearStreamChat} disabled={!lastQuestionRefStream.current || isLoadingStream} />
                                                            </TooltipHost>
                                                            <QuestionInput
                                                                clearOnSend
                                                                placeholder="Type a new question"
                                                                disabled={isLoadingStream}
                                                                onSend={question => makeApiRequestStream(question)}
                                                            />
                                                        </div> */}
                            {/* <div className={styles.disclaimer}>T-Mobile Chat GPT may display inaccurate or offensive information that doesn’t represent T-Mobile’s views.</div> */}
                          </div>
                        )}
                      </div>
                      {/* {answersStream.length > 0 && activeAnalysisPanelTabStream && (
                                                <AnalysisPanel
                                                    className={styles.chatStreamAnalysisPanel}
                                                    activeCitation={activeCitationStream}
                                                    onActiveTabChanged={x => onToggleTabStream(x, selectedAnswerStream)}
                                                    citationHeight="810px"
                                                    answer={answersStream[selectedAnswerStream][1]}
                                                    activeTab={activeAnalysisPanelTabStream}
                                                    showCitationTab={true}
                                                    showSupportingContentTab={true}
                                                />
                                            )} */}

                      <Panel
                        styles={panelStyles}
                        headerText="Configure Chat Stream Interaction"
                        isOpen={isConfigPanelOpenStream}
                        // isBlocking={false}
                        isLightDismiss={true}
                        onDismiss={() => context.onOSConfigOpen(false)}
                        closeButtonAriaLabel="Close"
                        // onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpenStream(false)}>Close</DefaultButton>}
                        isFooterAtBottom={true}
                      >
                        <br />
                        <div>
                          <DefaultButton
                            onClick={ReLoadBlobs}
                            className={styles.refreshDocsButton}
                          >
                            Refresh Docs
                          </DefaultButton>
                          <Dropdown
                            selectedKey={
                              selectedItemStream
                                ? selectedItemStream.key
                                : undefined
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={onChangeStream}
                            placeholder="Select a PDF"
                            options={bloboptions}
                            styles={dropdownStyles}
                          />
                          &nbsp;
                          <Label className={styles.commandsContainer}>
                            Index Type : {selectedIndexStream}
                          </Label>
                          <Label className={styles.commandsContainer}>
                            Chunk Size : {selectedChunkSizeStream} / Chunk
                            Overlap : {selectedChunkOverlapStream}
                          </Label>
                        </div>
                        <br />
                        <div>
                          <Label>LLM Model</Label>
                          <Dropdown
                            selectedKey={
                              selectedEmbeddingItemStream
                                ? selectedEmbeddingItemStream.key
                                : undefined
                            }
                            onChange={onEmbeddingChangeStream}
                            placeholder="Select LLM Model"
                            options={embeddingOptions}
                            disabled={false}
                            styles={dropdownStyles}
                          />
                        </div>
                        <div>
                          <Label>Deployment Type</Label>
                          <Dropdown
                            selectedKey={
                              selectedDeploymentTypeStream
                                ? selectedDeploymentTypeStream.key
                                : undefined
                            }
                            onChange={onDeploymentTypeChangeStream}
                            placeholder="Select Deployment Type"
                            options={deploymentTypeOptions}
                            disabled={
                              (selectedEmbeddingItemStream?.key == "openai"
                                ? true
                                : false) ||
                              (Number(selectedChunkSizeStream) > 4000
                                ? true
                                : false)
                            }
                            styles={dropdownStyles}
                          />
                        </div>
                        <div>
                          <Label>Prompt Type</Label>
                          <Dropdown
                            selectedKey={
                              selectedPromptTypeItemStream
                                ? selectedPromptTypeItemStream.key
                                : undefined
                            }
                            onChange={onPromptTypeChangeStream}
                            placeholder="Prompt Type"
                            options={promptTypeOptionsChatGpt}
                            disabled={false}
                            styles={dropdownStyles}
                          />
                          <TextField
                            className={styles.oneshotSettingsSeparator}
                            value={promptTemplateStream}
                            label="Override prompt template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplateChangeStream}
                          />
                        </div>
                        <ChoiceGroup
                          className={styles.oneshotSettingsSeparator}
                          label="Search Type"
                          options={searchTypes}
                          defaultSelectedKey={searchTypeOptionsStream}
                          onChange={onSearchTypeChangeStream}
                        />
                        <SpinButton
                          className={styles.chatSettingsSeparator}
                          label="Retrieve this many documents from search:"
                          min={1}
                          max={7}
                          defaultValue={retrieveCountStream.toString()}
                          onChange={onRetrieveCountChangeStream}
                        />
                        <SpinButton
                          className={styles.oneshotSettingsSeparator}
                          label="Set the Temperature:"
                          min={0.0}
                          max={1.0}
                          defaultValue={temperatureStream.toString()}
                          onChange={onTemperatureChangeStream}
                        />
                        <SpinButton
                          className={styles.oneshotSettingsSeparator}
                          label="Max Length (Tokens):"
                          min={0}
                          max={4000}
                          defaultValue={tokenLengthStream.toString()}
                          onChange={onTokenLengthChangeStream}
                        />
                        <Dropdown
                          label="Chain Type"
                          onChange={onChainChangeStream}
                          selectedKey={
                            selectedChainStream
                              ? selectedChainStream.key
                              : "stuff"
                          }
                          options={chainTypeOptionsStream}
                          defaultSelectedKey={"stuff"}
                          styles={dropdownStyles}
                        />
                        <Checkbox
                          className={styles.chatSettingsSeparator}
                          checked={useSuggestFollowupQuestionsStream}
                          label="Suggest follow-up questions"
                          onChange={onUseSuggestFollowupQuestionsChangeStream}
                        />
                        <Checkbox
                          className={styles.chatSettingsSeparator}
                          checked={useAutoSpeakAnswersStream}
                          label="Automatically speak answers"
                          onChange={onEnableAutoSpeakAnswersChangeStream}
                        />
                      </Panel>
                      <Panel
                        styles={panelStyles}
                        isOpen={isOpenOSSessionPanelStream}
                        // isBlocking={true}
                        isLightDismiss={true}
                        // type={PanelType.smallFixedNear}
                        onDismiss={() => context.onOSSessionOpen(false)}
                        closeButtonAriaLabel="Close"
                        // isHiddenOnDismiss={true}
                        isFooterAtBottom={true}
                      >
                        {/* <div className={styles.commandsContainer}> */}
                        {/* <SessionButton className={styles.commandButton} onClick={deleteSessionOpenChat} disabled={!lastQuestionRefOpenChat.current || isLoadingOpenChat} /> */}
                        {/* <RenameButton className={styles.commandButton} onClick={renameSessionOpenChat} text="Rename Session" disabled={!lastQuestionRefOpenChat.current || isLoadingOpenChat} />
                                                        {(!lastQuestionRefOpenChat.current || isLoadingOpenChat) ? null :
                                                            <TextField className={styles.commandButton} value={sessionNameOpenChat} onChange={onSessionNameChangeOpenChat} />
                                                        } */}
                        {/* </div> */}
                        {detailsListStream}
                        <div> {JSON.stringify(sessionListStream)}</div>
                      </Panel>
                    </div>
                  ) : null}
                  {!isBlobsLoaded ? (
                    <div className={styles.chatRoot}>
                      <div className={styles.chatContainer}>
                        <div className={styles.chatEmptyState}>
                          <div className={styles.chatEmptyStateSubtitle}>
                            <h2>Please wait, Loading Documents</h2>
                          </div>
                          <div className={styles.NotAuthorizedText}>
                            <Spinner />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {isBlobsLoaded && blobsCount <= 0 ? (
                    <div className={styles.chatRoot}>
                      <div className={styles.chatContainer}>
                        <div className={styles.chatEmptyState}>
                          <div className={styles.chatEmptyStateSubtitle}>
                            <h2>Documents Status</h2>
                          </div>
                          <div className={styles.NotAuthorizedText}>
                            Please Upload documents to explore or reach out to
                            your Admin for uploading documents.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className={styles.questionInputMainContainer}>
                    <div className={styles.chatInput}>
                      <TooltipHost
                        content="This is the tooltip content"
                        calloutProps={calloutProps}
                        styles={hostStyles}
                        id={tooltipId}
                      >
                        <ClearChatButton
                          className={styles.commandButton}
                          onClick={clearStreamChat}
                          disabled={
                            !lastQuestionRefStream.current || isLoadingStream
                          }
                        />
                      </TooltipHost>
                      <QuestionInput
                        clearOnSend
                        placeholder="Type a new question"
                        disabled={isLoadingStream}
                        onSend={(question) => makeApiRequestStream(question)}
                      />
                    </div>
                    <div className={styles.disclaimer}>
                      T-Mobile Chat GPT may display inaccurate or offensive
                      information that doesn’t represent T-Mobile’s views.
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          // null}
          // {
          inProgress === InteractionStatus.None &&
          userGroups != null &&
          // (userGroups || []).length &&
          !(userGroups || []).some((userRole) =>
            allowedUserRoles.includes(userRole)
          ) && (
            // || ((isAuthenticated && (userGroups || []).length === 0))
            <div className={styles.parentcontainer}>
              <div className={styles.topcontainer}>
                <div className={styles.accessdeniedcontainer}>
                  <div className={styles.accessdeniedbox}>
                    <div className={styles.launchercontainer}>
                      <div className={styles.launcheritemheader}>
                        <h1 className={styles.accessdeniedtext}>
                          No access to the page
                        </h1>
                      </div>
                    </div>
                    <div className={styles.launcheritemdesc}>
                      You don't have authorization to access the website.{" "}
                      <p>
                        Please reach out to site admin requesting access to the
                        website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          //:null
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className={styles.parentcontainer}>
          <div className={styles.topcontainer}>
            <div className={styles.svgcontainer}></div>
          </div>
          <div className={styles.authcontainer}>
            <div className={styles.box}>
              <div className={styles.launchercontainer}>
                <div className={styles.launcheritemheader}>
                  <h2>T-Mobile Authorization</h2>
                </div>
              </div>
              <div className={styles.launcheritemdesc}>
                Please wait... Application is loading...
              </div>
              {/* <a className={styles.launcherlink} onClick={()=>handleLogin("redirect")}>Sign In</a> */}
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Chat;
