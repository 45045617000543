import { IChoiceGroupOption } from "@fluentui/react";
import { SearchTypes } from "../api/models";
import { ExampleModel } from "../components/Example";

export const embeddingOptions = [
    {
        key: 'azureopenai',
        text: 'Azure Open AI'
    }

]

export const deploymentTypeOptions = [
    {
        key: 'gpt4',
        text: 'GPT 4'
    }
]

export const EXAMPLESV: ExampleModel[] = [
    {
        text: "Act as a copy writer and write a marketing copy for T-Mobile’s great 5G network",
        value: "Act as a copy writer and write a marketing copy for T-Mobile’s great 5G network"
    },
    {
        text: "What sets T-Mobile apart from others?",
        value: "What sets T-Mobile apart from others?"
    },
    {
        text: "What is T-Mobile known for?",
        value: "What is T-Mobile known for?"
    }
];

export const textSplitterOptions = [
    {
        key: 'recursive',
        text: 'Recursive Character Text Splitter'
    },
    {
        key: 'tiktoken',
        text: 'Tik Token'
    },
    {
        key: 'nltk',
        text: 'NLTK Text Splitter'
    },
    {
        key: 'formrecognizer',
        text: 'Form Recognizer'
    }
]

export const uploadconnectors = [
    // { key: 's3file', text: 'Amazon S3 File' },
    // { key: 's3Container', text: 'Amazon S3 Container' },
    // { key: 'rds', text: 'Amazon RDS' },
    { key: 'adlscontainer', text: 'Azure Blob Container' }
    // ,{ key: 'adlsfile', text: 'Azure Blob File' },
]

export const uploadoptions = [
    {
        key: 'cogsearchvs',
        text: 'Cognitive Search Vector Store'
    }
    // ,{
    //     key: 'pinecone',
    //     text: 'Pinecone'
    // },
    // {
    //     key: 'redis',
    //     text: 'Redis Stack'
    // },
    // {
    //     key: 'cogsearch',
    //     text: 'Cognitive Search'
    // }
    // {
    //   key: 'chroma',
    //   text: 'Chroma'
    // }
    // ,
    // {
    //   key: 'weaviate',
    //   text: 'Weaviate'
    // }
]
export const ChatDataPrompts = [
    {
        key: 'custom',
        text: `You are an AI assistant that helps people find information.  Include the sources of the answer. Generate
            three brief follow-up questions that the user would likely to ask. Try not to 
            repeat questions that have already been asked.
             
            {summaries}
            Question: {question}
        `
    },
    {
        key: 'genericPrompt',
        text: `Given the following extracted parts of a long document and a question, create a final answer. 
        If you don't know the answer, just say that you don't know. Don't try to make up an answer. 
        If the answer is not contained within the text below, say \"I don't know\".

        {summaries}
        Question: {question}
        `
    },
    {
        key: 'medicalPrompt',
        text: `You are an AI assistant tasked with answering questions and summarizing information from medical records documents. 
        Your answer should accurately capture the key information in the document while avoiding the omission of any domain-specific words. 
        Please generate a concise and comprehensive information that includes details such as patient information, medical history, 
        allergies, chronic conditions, previous surgeries, prescribed medications, and upcoming appointments. 
        Ensure that it is easy to understand for healthcare professionals and provides an accurate representation of the patient's medical history 
        and current health status. 
        
        Begin with a brief introduction of the patient, followed by the main points of their medical records.
        Please remember to use clear language and maintain the integrity of the original information without missing any important details
        {summaries}
        Question: {question}
        `
    },
    {
        key: 'financialPrompt',
        text: `You are an AI assistant tasked with answering questions and summarizing information from
        earning call transcripts, annual reports, SEC filings and financial statements.
        Your answer should accurately capture the key information in the document while avoiding the omission of any domain-specific words. 
        Please generate a concise and comprehensive information that includes details such as reporting year and amount in millions.
        Ensure that it is easy to understand for business professionals and provides an accurate representation of the financial statement history. 
        
        Please remember to use clear language and maintain the integrity of the original information without missing any important details

        QUESTION: {question}
        =========
        {summaries}
        =========
        `
    },
    {
        key: 'financialTablePrompt',
        text: `You are an AI assistant tasked with answering questions and summarizing information from
        financial statements like income statement, cashflow and balance sheets. 
        Additionally you may also be asked to answer questions about financial ratios and other financial metrics.
        The data that you are presented will be in table format or structure.
        Your answer should accurately capture the key information in the document while avoiding the omission of any domain-specific words. 
        Please generate a concise and comprehensive information that includes details such as reporting year and amount in millions.
        Ensure that it is easy to understand for business professionals and provides an accurate representation of the financial statement history. 
        
        Please remember to use clear language and maintain the integrity of the original information without missing any important details

        QUESTION: {question}
        =========
        {summaries}
        =========
        `
    },
    {
        key: 'prospectusPrompt',
        text: `"""You are an AI assistant tasked with summarizing documents from large documents that contains information about Initial Public Offerings.
        IPO document contains sections with information about the company, its business, strategies, risk, management structure, financial, and other information.
        Your summary should accurately capture the key information in the document while avoiding the omission of any domain-specific words. 
        Please remember to use clear language and maintain the integrity of the original information without missing any important details:
        QUESTION: {question}
        =========
        {summaries}
        =========

        """`
    },
    {
        key: 'productDocMdPrompt',
        text: `"""You are an AI assistant tasked with answering questions and summarizing information for 
        product or service from documentations and knowledge base.
        Your answer should accurately capture the key information in the document while avoiding the omission of any domain-specific words. 
        Please generate a concise and comprehensive information that includes details about the product or service.
        Please remember to use clear language and maintain the integrity of the original information without missing any important details
        QUESTION: {question}
        =========
        {summaries}
        =========

        """`
    },

]

export const promptTypeOptionsChatGpt = [
    {
        key: 'custom',
        text: 'custom'
    },
    {
        key: 'generic',
        text: 'generic'
    },
    {
        key: 'medical',
        text: 'medical'
    },
    {
        key: 'financial',
        text: 'financial'
    },
    {
        key: 'financialtable',
        text: 'financialtable'
    },
    {
        key: 'prospectus',
        text: 'prospectus'
    },
    {
        key: 'productdocmd',
        text: 'productdocmd'
    },
    {
        key: 'insurance',
        text: 'insurance'
    }
]


export const OpenChatBingPrompts = [
    {
        key: 'custom',
        text: `You are an AI assistant that helps people find information.  Include the sources of the answer. Generate
            three brief follow-up questions that the user would likely to ask. Try not to 
            repeat questions that have already been asked.
            QUESTION: {question}
            =========
            {summaries}
            ==========
            
            Output format:
              {{
                "answer":"",
                "nextQuestions": [],
                "sources":[]
              }}`
    }
]

export const promptTypeOptionsOpenChat = [
    {
        key: 'custom',
        text: 'custom'
    },

]


export const chainType = [
    { key: 'stuff', text: 'Stuff' },
    // { key: 'map_rerank', text: 'Map ReRank' },
    // { key: 'map_reduce', text: 'Map Reduce' },
    // { key: 'refine', text: 'Refine' },
]

export const searchTypes: IChoiceGroupOption[] = [
    {
        key: SearchTypes.Similarity,
        text: "Similarity"
    },
    {
        key: SearchTypes.Hybrid,
        text: "Hybrid"
    },
    {
        key: SearchTypes.HybridReRank,
        text: "Hybrid with ReRank"
    }
];
