import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { DefaultButton, Dropdown, IDropdownOption, IDropdownStyles, ISpinButtonStyles, IStackItemStyles, IStackTokens, IStyleFunctionOrObject, IToggleProps, IToggleStyles, IconButton, Label, Panel, SpinButton, Stack, TextField, Toggle, Modal, IComboBoxStyles, IComboBoxOption, ITextFieldStyleProps, ITextFieldStyles, } from "@fluentui/react";
import { DropdownSearch } from "../../components/DropdownSearch/DropdownSearch";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, } from "@azure/msal-react";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { userAuthorization } from "../../auth/AuthUtils";
import { chainType, deploymentTypeOptions, embeddingOptions, promptTypeOptionsChatGpt, } from "../../utils/appObjects";
import { career_bands, findJobFamilyByName, job_family, job_family_details, } from "../../utils/HRObjects";
import { Approaches, HRJDAnswer, HRJDClearblobsIndex, HRJDINDApi, HRJDRefreshIndex, HRJDRequest, HRJD_Revision_Api, SearchTypes, educationResponseType, experienceResponseType, jobresponsibilityResponseType, ksasResponseType, licenseOrCertificationResponseType, } from "../../api";
import styles from "./HomeHR.module.css";
import { CustomizePrompts } from "../../components/CustomizePrompts/CustomizePrompts";
import { CustomizeSystemPrompt } from "../../components/CustomizeSystemPrompt/CustomizeSystemPrompt";
import { CustomizeCogSearchPrompts } from "../../components/CustomizeCogSearchPrompts/CustomizeCogSearchPrompts";
import MailMergeComponent from "../../components/MailMerge/MailMerge";
import { SparkleFilled } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react";

type outletContextType = { isHRConfigPanel: boolean; onHRConfigOpen: Function; isHRCustomizationPanel: boolean; onHRCustomizationOpen: Function; };

const HomeHR = () => {

  interface IjobresponsibilityType { index?: number; responsibility: string; percentOfTime: string; }
  interface IeducationInfoType { index?: number; educationLevel: string; areaOfStudy: string; requirementLevel: string; }
  interface IexperienceInfoType { index?: number; experience: string; experienceTime: string; requirementLevel: string; }
  interface IksasType { index?: number; knowledgeSkillOrAbility: string; details: string; requirementLevel: string; }
  interface IlicenseOrCertificationType { index?: number; licenseOrCertification: string; details: string; requirementLevel: string; }

  //  Define the regular expression pattern
  const regexPatternNumberdot: RegExp = /\d+\.\s/;

  const [isSectionGenerating, setIsSectionGenerating] = useState(false);
  const [primaryFillColor, setPrimaryFillColor] = useState("white");
  const [secondaryFillColor, setSecondaryFillColor] = useState("white");

  const { instance, inProgress } = useMsal();
  const [userName, setUserName] = useState<string | undefined>("");
  const [userGroups, setUserGroups] = useState<string[] | null>(null);

  const context = useOutletContext<outletContextType>();
  const [openContentPage, setOpenContentPage] = useState("JobDetails");
  const [isHRConfigPanel, setIsHRConfigPanel] = useState(false);
  const [isHRCustomizationPanel, setIsHRCustomizationPanel] = useState(false);

  const [openCreateNewJD, setOpenCreateNewJD] = useState(false);
  const [openModifyJD, setModifyJD] = useState(false);
  const [isContentGenerated, setIsContentGenerated] = useState(false);

  const [warningPanelOpen, setWarningPanelOpen] = useState(false);

  const [generatingJDPanelOpen, setGeneratingJDPanelOpen] = useState(false);
  const [generatingJRPanelOpen, setGeneratingJRPanelOpen] = useState(false);
  const [generatingEDUPanelOpen, setGeneratingEDUPanelOpen] = useState(false);
  const [generatingEXPPanelOpen, setGeneratingEXPPanelOpen] = useState(false);
  const [generatingKSPanelOpen, setGeneratingKSPanelOpen] = useState(false);
  const [generatingLCPanelOpen, setGeneratingLCPanelOpen] = useState(false);

  const [clickedItem, setClickedItem] = useState("");
  const [jobProfileName, setJobProfileName] = useState<string>("");
  const [missingJobProfileName, setMissingJobProfileName] = useState(false);
  const [missingExistingJD, setMissingExistingJD] = useState(false);
  const [missingJobFamilyGrp, setMissingJobFamilyGrp] = useState(false);
  const [missingJobFamily, setMissingJobFamily] = useState(false);
  const [missingJDFeedback, setMissingJDFeedback] = useState(false);
  const [missingJobSummary, setMissingJobSummary] = useState(false);
  const [missingResponsibilitiesFeedback, setMissingResponsibilitiesFeedback] = useState(false);
  const [missingEducationFeedback, setMissingEducationFeedback] = useState(false);
  const [missingExperienceFeedback, setMissingExperienceFeedback] = useState(false);
  const [missingKnowledgeFeedback, setMissingKnowledgeFeedback] = useState(false);
  const [missingCertificationFeedback, setMissingCertificationFeedback] = useState(false);
  const [selectedJobFamilyGrp, setSelectedJobFamilyGrp] = useState<IDropdownOption>();
  const [selectedJobFamily, setSelectedJobFamily] = useState<IDropdownOption>();
  const [selectedJobCareerBand, setSelectedJobCareerBand] = useState<IDropdownOption>();
  const [selectedExistingJD, setSelectedExistingJD] = useState<IDropdownOption>();
  const [addlJobDetails, setAddlJobDetails] = useState<string>("");
  const [dynamicOptions, setdynamicOptions] = useState<any>([]);
  const [isBlobsLoaded, setIsBlobsLoaded] = useState<boolean>(false);

  //  for global HRHome options with namespace (hr_jd_sample)
  const [blobsCount, setBlobsCount] = useState<number>(-1);
  const [bloboptions, setBlobOptions] = useState<any>([]);
  const [selectedItemHRJD, setSelectedItemHRJD] = useState<IDropdownOption>();
  const [blobNamesoptions, setBlobNamesOptions] = useState<any>([]);
  const [blobNamessCount, setBlobNamesCount] = useState<number>(-1);
  const [selectedBlobItemHRJD, setSelectedBlobItemHRJD] = useState<IDropdownOption>();
  const [selectedIndexHRJD, setSelectedIndexHRJD] = useState<string>();
  const [selectedEmbeddingItemHRJD, setSelectedEmbeddingItemHRJD] = useState<IDropdownOption>();
  const [selectedDeploymentTypeHRJD, setSelectedDeploymentTypeHRJD] = useState<IDropdownOption>();
  const [selectedPromptTypeItemHRJD, setSelectedPromptTypeItemHRJD] = useState<IDropdownOption>();
  const [selectedChunkSizeHRJD, setSelectedChunkSizeHRJD] = useState<string>();
  const [selectedChunkOverlapHRJD, setSelectedChunkOverlapHRJD] = useState<string>();
  const [selectedPromptTypeHRJD, setSelectedPromptTypeHRJD] = useState<string>();
  const [selectedChainHRJD, setSelectedChainHRJD] = useState<IDropdownOption>();
  const [chainTypeOptionsHRJD, setChainTypeOptionsHRJD] = useState<any>([]);
  const [searchTypeOptionsHRJD, setSearchTypeOptionsHRJD] = useState<SearchTypes>(SearchTypes.Similarity);
  const [isJobResponsibilitiesAPICalled, setJobResponsibilitiesAPICalled] = useState(false);
  const [isJobEducationAPICalled, setJobEducationAPICalled] = useState(false);
  const [isJobExperienceAPICalled, setJobExperienceAPICalled] = useState(false);
  const [isJobKSASAPICalled, setJobKSASAPICalled] = useState(false);
  const [isJobLCAPICalled, setJobLCAPICalled] = useState(false);
  const defaultindexmapping = [{ key: "", iType: "", indexName: "", namespace: "", name: "", groupName: "", owner: "", qa: "", summary: "", indexType: "", textSpitterType: "", chunkOverlap: "", chunkSize: "", promptType: "", singleFile: "", job_profile_name: "", job_family_grp: "", job_family: "", job_career_band: "", job_summary: "", job_details_optional: "", },];
  const [indexMappingHRJD, setIndexMappingHRJD] = useState<{ key: string; iType: string; indexName: string; namespace: string; name: string; groupName: string; owner: string; qa: string; summary: string; indexType: string; textSpitterType: string; chunkOverlap: string; chunkSize: string; promptType: string; singleFile: string; job_profile_name: string; job_family_grp: string; job_family: string; job_career_band: string; job_summary: string; job_details_optional: string; }[]>(defaultindexmapping);
  const [allindexMappingHRJD, setAllIndexMappingHRJD] = useState<{ key: string; iType: string; indexName: string; namespace: string; name: string; groupName: string; owner: string; qa: string; summary: string; indexType: string; textSpitterType: string; chunkOverlap: string; chunkSize: string; promptType: string; singleFile: string; job_profile_name: string; job_family_grp: string; job_family: string; job_career_band: string; job_summary: string; job_details_optional: string; }[]>(defaultindexmapping);
  const [promptTemplateHRJD, setPromptTemplateHRJD] = useState<string>("");
  const [temperatureHRJD, setTemperatureHRJD] = useState<number>(0.3);
  const [tokenLengthHRJD, setTokenLengthHRJD] = useState<number>(3000);
  const [isTimeAllocationError, setTimeAllocationError] = useState(false);
  const [isAllocationValuesAreZeroError, setAllocationValuesAreZeroError] = useState(false);
  const [isResponsibilityEmptyError, setResponsibilityEmptyError] = useState(false);
  const [isExperienceEmptyError, setExperienceEmptyError] = useState(false);
  const [isExperienceDetailsEmptyError, setExperienceDetailsEmptyError] = useState(false);
  const [isEducationLevelEmptyError, setEducationLevelEmptyError] = useState(false);
  const [isEducationAreaOfStudyEmptyError, setEducationAreaOfStudyEmptyError] = useState(false);
  const [isKnowledgeEmptyError, setKnowledgeEmptyError] = useState(false);
  const [isKnowledgeDetailsEmptyError, setKnowledgeDetailsEmptyError] = useState(false);
  const [isCertificationEmptyError, setCertificationEmptyError] = useState(false);
  const [isCertificationDetailsEmptyError, setCertificationDetailsEmptyError] = useState(false);
  const [isJobSummaryReady, setIsJobSummaryReady] = useState(false);
  const [isJobSummaryLoading, setIsJobSummaryLoading] = useState(false);
  const allocationStep = 5;
  const [answersHRJD, setAnswersHRJD] = useState<HRJDAnswer>({ answer: { jobSummary: "", jobEducation: [], jobExperience: [], jobResponsibilities: [], jobKsas: [], jobLc: [], jobSummaryException: "", jobEducationException: "", jobExperienceException: "", jobResponsibilitiesException: "", jobKsasException: "", jobLcException: "", }, error: "", data_points: "", thoughts: "", sources: "", nextQuestions: "", });

  const [retrieveCountHRJD, setRetrieveCountHRJD] = useState<number>(3);
  const [errorHRJD, setErrorHRJD] = useState<string>("");
  const [isJobDescriptionGenerateError, setJobDescriptionGenerateError] = useState<boolean>(false);
  const [errorHRResponsibilities, setErrorHRResponsibilities] = useState<string>("");
  const [isResponsibilityGenerateError, setResponsibilityGenerateError] = useState<boolean>(false);
  const [errorHREducation, setErrorHREducation] = useState<string>("");
  const [isEducationGenerateError, setEducationGenerateError] = useState<boolean>(false);
  const [errorHRExperience, setErrorHRExperience] = useState<string>("");
  const [isExperienceGenerateError, setExperienceGenerateError] = useState<boolean>(false);
  const [errorHRKSAS, setErrorHRKSAS] = useState<string>("");
  const [isKSASGenerateError, setKSASGenerateError] = useState<boolean>(false);
  const [errorHRLC, setErrorHRLC] = useState<string>("");
  const [isLCGenerateError, setLCGenerateError] = useState<boolean>(false);
  const [jobsummary, setJobSummary] = useState<string>("");
  const [jobResponsibilities, setJobResponsibilities] = useState<any>([]);
  const [educationInfo, setEducationInfo] = useState<any>([]);
  const [experienceInfo, setExperienceInfo] = useState<any>([]);
  const [KSASInfo, setKSASInfo] = useState<any>([]);
  const [licenseOrCertificationInfo, setLCInfo] = useState<any>([]);
  const [totalAllocationPercentage, setTotalAllocationPercentage] = useState<number>(0);

  const [isAnimated, setAnimated] = useState(false);
  const requiredRoles = ["OpenAIHRAdmin", "OpenAIHRUsers", "OpenAIAdmin"];
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [isGroupUser, setIsGroupUser] = useState(false);
  const [isGenericAdmin, setIsGenericAdmin] = useState(true);

  const [isReviseJDPanelOpen, setReviseJDPanelOpen] = useState(false);
  const [isReviseResponsibilitiesPanelOpen, setReviseResponsibilitiesPanelOpen,] = useState(false);
  const [isReviseEducationPanelOpen, setReviseEducationPanelOpen] = useState(false);
  const [isReviseExperiencePanelOpen, setReviseExperiencePanelOpen] = useState(false);
  const [isReviseKnowledgePanelOpen, setReviseKnowledgePanelOpen] = useState(false);
  const [isReviseCertificationsPanelOpen, setReviseCertificationssPanelOpen] = useState(false);
  const [summaryFeedback, setSummaryFeedback] = useState("");
  const [responsibilitiesFeedback, setResponsibilitiesFeedback] = useState("");
  const [educationFeedback, setEducationFeedback] = useState("");
  const [experienceFeedback, setExperienceFeedback] = useState("");
  const [knowledgeFeedback, setKnowledgeFeedback] = useState("");
  const [certificationFeedback, setCertificationFeedback] = useState("");

  //  Tokens definition
  const outerStackTokens: IStackTokens = { childrenGap: 5 };
  const innerStackTokens: IStackTokens = { childrenGap: 5, padding: 10 };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: "flex",
      flexDirection: "column",
      label: { color: "white" },
    },
  };
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const spinButtonStyles: Partial<ISpinButtonStyles> = {
    spinButtonWrapper: {
      "::after": { borderColor: "rgb(164, 38, 44) !important" },
    },
  };
  const panelStyles = {
    main: {
      width: "300px",
      height: "calc(90% - 60px)",
      top: "80px",
      right: "20px",
      backgroundColor: "#fff",
      color: "#35383e",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px #e20074",
      borderRadius: "8px",
    },
    headerText: { textAlign: "center" },
  };
  const reviseJDPanelStyles = {
    main: {
      backgroundColor: "#fff",
      color: "#35383e",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px #e20074",
      borderRadius: "8px",
    },
  };
  const educationReqdStyles: IStyleFunctionOrObject<IToggleProps, IToggleStyles> = {
    root: {
      alignSelf: "end",
      background: "transparent",
      border: "none",
      minWidth: "16px",
      padding: 0,
      margin: 0,
      label: {
        fontWeight: "normal",
        fontSize: "0.8rem",
        margin: "2px",
        color: "black",
      },
      "button:hover": {
        borderColor: "rgb(96, 94, 92)",
        span: { background: "rgb(96, 94, 92) !important" },
      },
      "&.is-checked": { button: { background: "#e20074" } },
    },
  };

  const onDeleteResponsibility = (item: any) => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 1000); //  Reset animation after 1 second
    const list = jobResponsibilities;
    setJobResponsibilities(
      list.filter(
        (listItem: IjobresponsibilityType) => listItem.index !== item.index
      )
    );

    //  disable the error message
    setAllocationValuesAreZeroError(false);
    setResponsibilityEmptyError(false);
  };

  const onDeleteEducation = (item: any) => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 1000);
    const list = educationInfo;
    setEducationInfo(
      list.filter(
        (listItem: IeducationInfoType) => listItem.index !== item.index
      )
    );

    setEducationAreaOfStudyEmptyError(false);
    setEducationLevelEmptyError(false);
  };

  const onDeleteExperience = (item: any) => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 1000);
    const list = experienceInfo;
    setExperienceInfo(
      list.filter(
        (listItem: IexperienceInfoType) => listItem.index !== item.index
      )
    );

    setExperienceEmptyError(false);
    setExperienceDetailsEmptyError(false);
  };

  const onDeleteKnowledge = (item: any) => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 1000);
    const list = KSASInfo;
    setKSASInfo(
      list.filter((listItem: IksasType) => listItem.index !== item.index)
    );

    setKnowledgeEmptyError(false);
    setKnowledgeDetailsEmptyError(false);
  };

  const onDeleteCertification = (item: any) => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 1000);
    const list = licenseOrCertificationInfo;
    setLCInfo(
      list.filter(
        (listItem: IlicenseOrCertificationType) => listItem.index !== item.index
      )
    );

    setCertificationEmptyError(false);
    setCertificationDetailsEmptyError(false);
  };

  const responsibilityString = () => {
    let customString = "";
    for (const item of jobResponsibilities as IjobresponsibilityType[]) {
      customString += `RESPONSIBILITY:${item.responsibility}\nPERCENT OF TIME:${item.percentOfTime}\n\n`;
    }
    return customString;
  };

  const experienceString = () => {
    let customString = "";
    for (const item of experienceInfo as IexperienceInfoType[]) {
      customString += `EXPERIENCE:${item.experience}\nEXPERIENCE TIME:${item.experienceTime}\nREQUIREMENT LEVEL:${item.requirementLevel}\n\n`;
    }
    return customString;
  };

  const educationString = () => {
    let customString = "";
    for (const item of educationInfo as IeducationInfoType[]) {
      customString += `EDUCATION LEVEL:${item.educationLevel}\nAREA OF STUDY:${item.areaOfStudy}\nREQUIREMENT LEVEL:${item.requirementLevel}\n\n`;
    }
    return customString;
  };

  const ksasString = () => {
    let customString = "";
    for (const item of KSASInfo as IksasType[]) {
      customString += `KNOWLEDGE, SKILL, OR ABILITY:${item.knowledgeSkillOrAbility}\nDETAILS:${item.details}\nREQUIREMENT LEVEL:${item.requirementLevel}\n\n`;
    }
    return customString;
  };

  const licenseOrCertificationString = () => {
    let customString = "";
    for (const item of licenseOrCertificationInfo as IlicenseOrCertificationType[]) {
      customString += `LICENSE OR CERTIFICATION:${item.licenseOrCertification}\nDETAILS:${item.details}\nREQUIREMENT LEVEL:${item.requirementLevel}\n\n`;
    }
    return customString;
  };

  const responsibilityResponse = (
    outputResponse: jobresponsibilityResponseType[]
  ) => {
    const responsibilities: IjobresponsibilityType[] = outputResponse.map(
      (block) => {
        return {
          index: block.index,
          responsibility: block.responsibility,
          percentOfTime: block.percent_of_time.replaceAll("%", ""),
        };
      }
    );
    if (responsibilities.length > 0) setJobResponsibilities(responsibilities);
    else appendResponsibility();
  };

  const experienceResponse = (outputResponse: experienceResponseType[]) => {
    const experienceInfo: IexperienceInfoType[] = outputResponse.map(
      (block) => {
        return {
          index: block.index,
          experience: block.experience,
          experienceTime: block.experience_time,
          requirementLevel: block.requirement_level,
        };
      }
    );

    if (experienceInfo.length > 0) setExperienceInfo(experienceInfo);
    else appendExperience();
  };

  const educationResponse = (outputResponse: educationResponseType[]) => {
    const educationInfo: IeducationInfoType[] = outputResponse.map(
      (block) => {
        return {
          index: block.index,
          educationLevel: block.education_level,
          areaOfStudy: block.area_of_study,
          requirementLevel: block.requirement_level,
        };
      }
    );

    if (educationInfo.length > 0) setEducationInfo(educationInfo);
    else appendEducation();
  };

  const ksasResponse = (outputResponse: ksasResponseType[]) => {
    const ksasInfo: IksasType[] = outputResponse.map((block) => {
      return {
        index: block.index,
        knowledgeSkillOrAbility: block.knowledge_skill_or_ability,
        details: block.details,
        requirementLevel: block.requirement_level,
      };
    });

    if (ksasInfo.length > 0) setKSASInfo(ksasInfo);
    else appendKnowledge();
  };

  const licenseOrCertificationResponse = (
    outputResponse: licenseOrCertificationResponseType[]
  ) => {
    const licenseOrCertificationInfo: IlicenseOrCertificationType[] =
      outputResponse.map((block) => {
        return {
          index: block.index,
          licenseOrCertification: block.license_or_certification,
          details: block.details,
          requirementLevel: block.requirement_level,
        };
      });

    if (licenseOrCertificationInfo.length > 0)
      setLCInfo(licenseOrCertificationInfo);
    else appendCertification();
  };

  const generateNewJDPostFeedback = async () => {
    if (summaryFeedback === "") {
      setMissingJDFeedback(true);
      return;
    }
    if (
      jobProfileName &&
      selectedJobFamily &&
      selectedJobFamilyGrp &&
      summaryFeedback
    ) {
      setMissingJDFeedback(false);
      setIsSectionGenerating(true);
      await makeApiRequestHRJDFeedbackJS();
    }

    //  Code for generate new jd post feedback
    setIsSectionGenerating(false);
    setReviseJDPanelOpen(false);
  };

  const generateResponsibilitiesPostFeedback = async () => {
    if (responsibilitiesFeedback === "") {
      setMissingResponsibilitiesFeedback(true);
      return;
    }
    if (responsibilitiesFeedback) {
      setMissingResponsibilitiesFeedback(false);
      setIsSectionGenerating(true);
      await makeApiRequestHRJDFeedbackJR();
    }

    //  Code for generate new jd post feedback
    setIsSectionGenerating(false);
    // now close the panel
    setReviseResponsibilitiesPanelOpen(false);
  };

  const generateEducationPostFeedback = async () => {
    if (educationFeedback === "") {
      setMissingEducationFeedback(true);
      return;
    }
    if (educationFeedback) {
      setMissingEducationFeedback(false);
      setIsSectionGenerating(true);
      await makeApiRequestHRJDFeedbackEDU();
    }
    setIsSectionGenerating(false);
    setReviseEducationPanelOpen(false);
  };

  const generateExperiencePostFeedback = async () => {
    if (experienceFeedback === "") {
      setMissingExperienceFeedback(true);
      return;
    }
    if (experienceFeedback) {
      setMissingExperienceFeedback(false);
      setIsSectionGenerating(true);
      await makeApiRequestHRJDFeedbackEX();
    }
    setIsSectionGenerating(false);
    setReviseExperiencePanelOpen(false);
  };

  const generateKnowledgePostFeedback = async () => {
    if (knowledgeFeedback === "") {
      setMissingKnowledgeFeedback(true);
      return;
    }
    if (knowledgeFeedback) {
      setMissingKnowledgeFeedback(false);
      setIsSectionGenerating(true);
      await makeApiRequestHRJDFeedbackKSAS();
    }
    setIsSectionGenerating(false);
    setReviseKnowledgePanelOpen(false);
  };

  const generateCertificationPostFeedback = async () => {
    if (certificationFeedback === "") {
      setMissingCertificationFeedback(true);
      return;
    }
    if (certificationFeedback) {
      setMissingCertificationFeedback(false);
      setIsSectionGenerating(true);
      await makeApiRequestHRJDFeedbackLC();
    }
    setIsSectionGenerating(false);
    setReviseCertificationssPanelOpen(false);
  };

  /* New JD api call .*/
  const makeApiRequestHRJDNew = async () => {
    setJobDescriptionGenerateError(false);
    setErrorHRJD(``);
    setIsJobSummaryReady(false);

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,

        job_summary: "",
        promptKey: "job_summary",
        responseKey: "jobSummary",
      },
    };
    try {
      const response = await HRJDINDApi(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      if (response.error) {
        setJobDescriptionGenerateError(true);
        setIsJobSummaryReady(true);
        setPrimaryFillColor("white");
        setSecondaryFillColor("white");
        setIsJobSummaryLoading(false);
        setErrorHRJD(`There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`);
        setGeneratingJDPanelOpen(false);
      } else {
        let result = response.answer;
        try {
          // if jobsummaryException is not empty then there is an error from api
          if (result.jobSummaryException.trim() != "") {
            setJobDescriptionGenerateError(true);
            setErrorHRJD(result.jobSummaryException);
          } else {
            // console.log('parsing job summary')
            setJobSummary(result.jobSummary);
          }
        } catch (e: any) {
          setJobDescriptionGenerateError(true);
          setIsJobSummaryReady(false);
          setGeneratingJDPanelOpen(false);
          setIsJobSummaryLoading(false);
          setErrorHRJD(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10006). Please retry your request.`
          );
        }

        //  Finally
        // console.log('setIsJobSummaryReady to true')
        if (result.jobSummaryException.trim() != "") {
          setIsJobSummaryReady(false);
          setIsJobSummaryLoading(false);
          setGeneratingJDPanelOpen(false);

          setPrimaryFillColor("white");
          setSecondaryFillColor("white");

          if (openModifyJD) {
            setIsContentGenerated(false);
          }
        } else {
          setIsJobSummaryReady(true);
          setIsJobSummaryLoading(false);
          setGeneratingJDPanelOpen(false);

          setPrimaryFillColor("white");
          setSecondaryFillColor("white");

          if (openModifyJD) {
            setIsContentGenerated(true);
          }
        }
      }
    } catch (error: any) {
      console.log(
        `getting into error while working with makeApiRequestHRJDNew ${error.message}`
      );
      // await HRJDLogException(userName, "makeApiRequestHRJDNew", JSON.stringify(request), error.message )
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        setJobDescriptionGenerateError(true);
        setIsJobSummaryReady(false);
        setIsJobSummaryLoading(false);
        setGeneratingJDPanelOpen(false);
        setErrorHRJD(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (1001F). Please retry your request."
        );
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        setJobDescriptionGenerateError(true);
        setIsJobSummaryReady(false);
        setIsJobSummaryLoading(false);
        setGeneratingJDPanelOpen(false);
        setErrorHRJD(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (1001T). Please retry your request."
        );
        break;
      default:
        setJobDescriptionGenerateError(true);
        setIsJobSummaryReady(false);
        setIsJobSummaryLoading(false);
        setGeneratingJDPanelOpen(false);
        setErrorHRJD(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (1001O)). Please retry your request."
        );
        break;
      }
    }
  };

  /* New JD api call .*/
  const makeApiRequestHRJDInd = async (
    promptkey: string,
    responsekey: string
  ) => {
    if (responsekey === "jobResponsibilities") {
      if (isJobResponsibilitiesAPICalled) return;
      setResponsibilityGenerateError(false);
      setErrorHRResponsibilities(``);
      setJobResponsibilities([]);
      setJobResponsibilitiesAPICalled(true);

      // enable th job responsibilities section popup as processing
      setGeneratingJRPanelOpen(true);
    }
    if (responsekey === "jobEducation") {
      if (isJobEducationAPICalled) return;
      setEducationGenerateError(false);
      setErrorHREducation(``);
      setEducationInfo([]);

      setJobEducationAPICalled(true);
      // enable th job education section popup as processing
      setGeneratingEDUPanelOpen(true);
    }
    if (responsekey === "jobExperience") {
      if (isJobExperienceAPICalled) return;
      setExperienceGenerateError(false);
      setErrorHRExperience(``);
      setExperienceInfo([]);

      setJobExperienceAPICalled(true);

      // enable th job Experience section popup as processing
      setGeneratingEXPPanelOpen(true);
    }
    if (responsekey === "jobKsas") {
      if (isJobKSASAPICalled) return;
      setKSASGenerateError(false);
      setErrorHRKSAS(``);
      setKSASInfo([]);

      setJobKSASAPICalled(true);
      // enable th job Experience section popup as processing
      setGeneratingKSPanelOpen(true);
    }
    if (responsekey === "jobLc") {
      if (isJobLCAPICalled) return;
      setLCGenerateError(false);
      setErrorHRLC(``);
      setLCInfo([]);

      setJobLCAPICalled(true);
      // enable th job Lc section popup as processing
      setGeneratingLCPanelOpen(true);
    }

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,

        job_summary: jobsummary,
        promptKey: promptkey,
        responseKey: responsekey,
      },
    };
    try {
      // console.log('submitting')
      // console.log(request)
      const response = await HRJDINDApi(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      // console.log('got the result')
      setAnswersHRJD(response);
      // console.log(response)

      if (response.error) {
        setPrimaryFillColor("white");
        setSecondaryFillColor("white");
        if (responsekey === "jobResponsibilities") {
          setResponsibilityGenerateError(true);

          // as per Rashid's and Olav's request, we are not showing the error message from the API
          setErrorHRResponsibilities(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10007A). Please retry your request.`
          );

          setGeneratingJRPanelOpen(false);
        }
        if (responsekey === "jobExperience") {
          setExperienceGenerateError(true);
          setErrorHRExperience(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10008A). Please retry your request.`
          );
          setGeneratingEXPPanelOpen(false);
        }
        if (responsekey === "jobEducation") {
          setEducationGenerateError(true);
          setErrorHREducation(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10009A). Please retry your request.`
          );
          setGeneratingEDUPanelOpen(false);
        }
        if (responsekey === "jobKsas") {
          setKSASGenerateError(true);
          setErrorHRKSAS(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10010A). Please retry your request.`
          );
          setGeneratingKSPanelOpen(false);
        }
        if (responsekey === "jobLc") {
          setLCGenerateError(true);
          setErrorHRLC(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10011A). Please retry your request.`
          );
          setGeneratingLCPanelOpen(false);
        }
      } else {
        let result = response.answer;

        if (responsekey === "jobResponsibilities") {
          setGeneratingJRPanelOpen(false);
          try {
            if (result.jobResponsibilitiesException.trim() != "") {
              setResponsibilityGenerateError(true);
              setErrorHRResponsibilities(result.jobResponsibilitiesException);
              setGeneratingJRPanelOpen(false);
            } else {
              // console.log('parsing job responsibilities')
              if (result.jobResponsibilities.length > 0) {
                responsibilityResponse(result.jobResponsibilities);
              }
            }
          } catch (e: any) {
            setResponsibilityGenerateError(true);
            setErrorHRResponsibilities(
              `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
              //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10007). Please retry your request.`
            );
          }
        }

        if (responsekey === "jobExperience") {
          setGeneratingEXPPanelOpen(false);
          try {
            if (result.jobExperienceException.trim() != "") {
              setExperienceGenerateError(true);
              setErrorHRExperience(result.jobExperienceException);
              setGeneratingEXPPanelOpen(false);
            } else {
              // console.log('parsing job experience')
              if (result.jobExperience.length > 0) {
                experienceResponse(result.jobExperience);
              }
            }
          } catch (e: any) {
            setExperienceGenerateError(true);
            setErrorHRExperience(
              `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
              //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10008). Please retry your request.`
            );
          }
        }

        if (responsekey === "jobEducation") {
          setGeneratingEDUPanelOpen(false);
          try {
            if (result.jobEducationException.trim() != "") {
              setEducationGenerateError(true);
              setErrorHREducation(result.jobEducationException);
              setGeneratingEDUPanelOpen(false);
            } else {
              // console.log('parsing job education')
              if (result.jobEducation.length > 0) {
                educationResponse(result.jobEducation);
              }
            }
          } catch (e: any) {
            setEducationGenerateError(true);
            setErrorHREducation(
              `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
              //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10009). Please retry your request.`
            );
          }
        }

        if (responsekey === "jobKsas") {
          setGeneratingKSPanelOpen(false);
          try {
            if (result.jobKsasException.trim() != "") {
              setKSASGenerateError(true);
              setErrorHRKSAS(result.jobKsasException);
              setGeneratingKSPanelOpen(false);
            } else {
              // console.log('parsing job ksas')
              if (result.jobKsas.length > 0) {
                ksasResponse(result.jobKsas);
              }
            }
          } catch (e: any) {
            setKSASGenerateError(true);
            setErrorHRKSAS(
              `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
              //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10010). Please retry your request.`
            );
          }
        }

        if (responsekey === "jobLc") {
          setGeneratingLCPanelOpen(false);
          try {
            if (result.jobLcException.trim() != "") {
              setLCGenerateError(true);
              setErrorHRLC(result.jobLcException);
              setGeneratingLCPanelOpen(false);
            } else {
              // console.log('parsing job lc')
              if (result.jobLc.length > 0) {
                licenseOrCertificationResponse(result.jobLc);
              }
            }
          } catch (e: any) {
            setLCGenerateError(true);
            setErrorHRLC(
              `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
              //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10011). Please retry your request.`
            );
          }
        }
      }
    } catch (error: any) {
      console.log(
        `getting into error while working with makeApiRequestHRJDInd ${error.message}`
      );
      // await HRJDLogException(userName, "makeApiRequestHRJDInd", JSON.stringify(request), error.message )
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        if (responsekey === "jobResponsibilities") {
          setResponsibilityGenerateError(true);
          setErrorHRResponsibilities(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100012F). Please retry your request.`
          );
          setGeneratingJRPanelOpen(false);
        }
        if (responsekey === "jobExperience") {
          setExperienceGenerateError(true);
          setErrorHRExperience(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100013F). Please retry your request.`
          );
          setGeneratingEXPPanelOpen(false);
        }
        if (responsekey === "jobEducation") {
          setEducationGenerateError(true);
          setErrorHREducation(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100014F). Please retry your request.`
          );
          setGeneratingEDUPanelOpen(false);
        }
        if (responsekey === "jobKsas") {
          setKSASGenerateError(true);
          setErrorHRKSAS(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100015F). Please retry your request.`
          );
          setGeneratingKSPanelOpen(false);
        }
        if (responsekey === "jobLc") {
          setLCGenerateError(true);
          setErrorHRLC(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100016F). Please retry your request.`
          );
          setGeneratingLCPanelOpen(false);
        }
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        if (responsekey === "jobResponsibilities") {
          setResponsibilityGenerateError(true);

          // same as failed response
          setErrorHRResponsibilities(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100012T). Please retry your request.`
          );
          setGeneratingJRPanelOpen(false);
        }
        if (responsekey === "jobExperience") {
          setExperienceGenerateError(true);
          setErrorHRExperience(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100013T). Please retry your request.`
          );
          setGeneratingEXPPanelOpen(false);
        }
        if (responsekey === "jobEducation") {
          setEducationGenerateError(true);
          setErrorHREducation(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100014T). Please retry your request.`
          );
          setGeneratingEDUPanelOpen(false);
        }
        if (responsekey === "jobKsas") {
          setKSASGenerateError(true);
          setErrorHRKSAS(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100015T). Please retry your request.`
          );
          setGeneratingKSPanelOpen(false);
        }
        if (responsekey === "jobLc") {
          setLCGenerateError(true);
          setErrorHRLC(
            `The Service did not respond in a timely manner. Please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100016T). Please retry your request.`
          );
          setGeneratingLCPanelOpen(false);
        }
        break;
      default:
        // any other error
        if (responsekey === "jobResponsibilities") {
          setResponsibilityGenerateError(true);
          setErrorHRResponsibilities(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            // `The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100012O). Please retry your request.`
          );
          setGeneratingJRPanelOpen(false);
        }
        if (responsekey === "jobExperience") {
          setExperienceGenerateError(true);
          setErrorHRExperience(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100013O). Please retry your request.`
          );
          setGeneratingEXPPanelOpen(false);
        }
        if (responsekey === "jobEducation") {
          setEducationGenerateError(true);
          setErrorHREducation(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100014O). Please retry your request.`
          );
          setGeneratingEDUPanelOpen(false);
        }
        if (responsekey === "jobKsas") {
          setKSASGenerateError(true);
          setErrorHRKSAS(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100015O). Please retry your request.`
          );
          setGeneratingKSPanelOpen(false);
        }
        if (responsekey === "jobLc") {
          setLCGenerateError(true);
          setErrorHRLC(
            `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
            //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (100016O). Please retry your request.`
          );
          setGeneratingLCPanelOpen(false);
        }
        break;
      }
    }
  };

  /* with job summary revision data.*/
  const makeApiRequestHRJDFeedbackJS = async () => {
    setJobDescriptionGenerateError(false);
    setErrorHRJD(``);

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRFeedbackPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,
        job_summary: jobsummary,
        userFeedback: summaryFeedback,
        existingdata: jobsummary,
        promptKey: "job_summary",
        responseKey: "jobSummary",
      },
    };

    try {
      // console.log('submitting')
      const response = await HRJD_Revision_Api(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      // console.log('got the result')
      // console.log(response)

      // console.log('parsing the result')
      if (response.error) {
        setJobDescriptionGenerateError(true);
        setErrorHRJD(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue.The App admin have been automatically notified and will be looking into it (10015). Please retry your request."
        );
      } else {
        // console.log('parsing job summary')
        const result = response.answer;
        setJobSummary(result.jobSummary);
      }
    } catch (error: any) {
      // await HRJDLogException(userName, "makeApiRequestHRJDFeedbackJS", JSON.stringify(request), error.message )
      // console.log('getting into error while working with makeApiRequestHRJDFeedbackJS')
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        setJobDescriptionGenerateError(true);
        setGeneratingJDPanelOpen(false);
        setErrorHRJD(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10016). Please retry your request."
        );
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        setJobDescriptionGenerateError(true);
        setGeneratingJDPanelOpen(false);
        setErrorHRJD(
          `The Service did not respond in a timely manner. Please try again.`
          //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10017). Please retry your request.`
        );
        break;
      default:
        setJobDescriptionGenerateError(true);
        setGeneratingJDPanelOpen(false);
        setErrorHRJD(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10018). Please retry your request.`
        );
        break;
      }
    }
  };

  /* with job responsibilities revision data.*/
  const makeApiRequestHRJDFeedbackJR = async () => {
    setResponsibilityGenerateError(false);
    setErrorHRResponsibilities(``);

    let stringData = responsibilityString();

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRFeedbackPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,

        userFeedback: responsibilitiesFeedback,
        existingdata: stringData,
        job_summary: jobsummary,
        promptKey: "job_responsibilities",
        responseKey: "jobResponsibilities",
      },
    };

    try {
      // console.log('submitting Regenerating for job Responsibilities')
      const response = await HRJD_Revision_Api(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      // console.log('got the Revised job Responsibilities')
      // console.log(response)

      // console.log('parsing the Revised job Responsibilities')
      if (response.error) {
        setResponsibilityGenerateError(true);
        setErrorHRResponsibilities(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10019). Please retry your request."
        );
      } else {
        const result = response.answer;
        responsibilityResponse(result.jobResponsibilities);
      }
    } catch (error: any) {
      // await HRJDLogException(userName, "makeApiRequestHRJDFeedbackJR", JSON.stringify(request), error.message )
      // console.log('getting into error while working with makeApiRequestHRJDFeedbackJR')
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        setResponsibilityGenerateError(true);
        setReviseResponsibilitiesPanelOpen(false);
        setErrorHRResponsibilities(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10020F). Please retry your request."
        );
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        setResponsibilityGenerateError(true);
        setReviseResponsibilitiesPanelOpen(false);
        setErrorHRResponsibilities(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10021T). Please retry your request."
        );
        break;
      default:
        setResponsibilityGenerateError(true);
        setReviseResponsibilitiesPanelOpen(false);
        setErrorHRResponsibilities(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10022O). Please retry your request."
        );
        break;
      }
    }
  };

  /* with job education revision data.*/
  const makeApiRequestHRJDFeedbackEDU = async () => {
    setEducationGenerateError(false);
    setErrorHREducation(``);

    let stringData = educationString();

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRFeedbackPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,
        job_summary: jobsummary,
        userFeedback: educationFeedback,
        existingdata: stringData,
        promptKey: "job_education",
        responseKey: "jobEducation",
      },
    };

    try {
      // console.log('submitting Regenerating for job education')
      const response = await HRJD_Revision_Api(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      // console.log('got the Revised job education')
      // console.log(response)

      // console.log('parsing the Revised job education')
      if (response.error) {
        setEducationGenerateError(true);
        setErrorHREducation(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10023). Please retry your request."
        );
      } else {
        const result = response.answer;
        educationResponse(result.jobEducation);
      }
    } catch (error: any) {
      // await HRJDLogException(userName, "makeApiRequestHRJDFeedbackEDU", JSON.stringify(request), error.message )
      // console.log('getting into error while working with makeApiRequestHRJDFeedbackEDU')
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        setEducationGenerateError(true);
        setReviseEducationPanelOpen(false);
        setErrorHREducation(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10024). Please retry your request."
        );
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        setEducationGenerateError(true);
        setReviseEducationPanelOpen(false);
        setErrorHREducation(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10025). Please retry your request."
        );
        break;
      default:
        setEducationGenerateError(true);
        setReviseEducationPanelOpen(false);
        setErrorHREducation(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10026). Please retry your request."
        );
        break;
      }
    }
  };

  /* with job experience revision data.*/
  const makeApiRequestHRJDFeedbackEX = async () => {
    setExperienceGenerateError(false);
    setErrorHRExperience(``);

    let stringData = experienceString();

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRFeedbackPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,
        job_summary: jobsummary,
        userFeedback: experienceFeedback,
        existingdata: stringData,
        promptKey: "job_experience",
        responseKey: "jobExperience",
      },
    };

    try {
      // console.log('submitting Regenerating for job experience')
      const response = await HRJD_Revision_Api(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      // console.log('got the Revised job experience')
      // console.log(response)

      // console.log('parsing the Revised job experience')
      if (response.error) {
        setExperienceGenerateError(true);
        setErrorHRExperience(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10027). Please retry your request."
        );
      } else {
        const result = response.answer;
        experienceResponse(result.jobExperience);
      }
    } catch (error: any) {
      // await HRJDLogException(userName, "makeApiRequestHRJDFeedbackEXP", JSON.stringify(request), error.message )
      // console.log('getting into error while working with makeApiRequestHRJDFeedbackEXP')
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        setExperienceGenerateError(true);
        setReviseExperiencePanelOpen(false);
        setErrorHRExperience(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10028). Please retry your request."
        );
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        setExperienceGenerateError(true);
        setReviseExperiencePanelOpen(false);
        setErrorHRExperience(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10029). Please retry your request."
        );
        break;
      default:
        setExperienceGenerateError(true);
        setReviseExperiencePanelOpen(false);
        setErrorHRExperience(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10030). Please retry your request."
        );
        break;
      }
    }
  };

  /* with job knowledge and skills revision data.*/
  const makeApiRequestHRJDFeedbackKSAS = async () => {
    setKSASGenerateError(false);
    setErrorHRKSAS(``);

    let stringData = ksasString();

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRFeedbackPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,
        job_summary: jobsummary,
        userFeedback: knowledgeFeedback,
        existingdata: stringData,
        promptKey: "job_ksas",
        responseKey: "jobKsas",
      },
    };

    try {
      // console.log('submitting Regenerating for job KSAS')
      const response = await HRJD_Revision_Api(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      // console.log('got the Revised job KSAS')
      // console.log(response)

      // console.log('parsing the Revised job KSAS')
      if (response.error) {
        setKSASGenerateError(true);
        setErrorHRKSAS(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10031). Please retry your request."
        );
      } else {
        const result = response.answer;
        ksasResponse(result.jobKsas);
      }
    } catch (error: any) {
      // await HRJDLogException(userName, "makeApiRequestHRJDFeedbackKSAS", JSON.stringify(request), error.message )
      // console.log('getting into error while working with makeApiRequestHRJDFeedbackKSAS')
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        setKSASGenerateError(true);
        setReviseKnowledgePanelOpen(false);
        setErrorHRKSAS(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10032). Please retry your request."
        );
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        setKSASGenerateError(true);
        setReviseKnowledgePanelOpen(false);
        setErrorHRKSAS(
          `The Service did not respond in a timely manner. Please try again.`
          //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10033). Please retry your request.`
        );
        break;
      default:
        setKSASGenerateError(true);
        setReviseKnowledgePanelOpen(false);
        setErrorHRKSAS(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10034). Please retry your request.`
        );
        break;
      }
    }
  };

  /* with job Certifications revision data.*/
  const makeApiRequestHRJDFeedbackLC = async () => {
    setLCGenerateError(false);
    setErrorHRLC(``);

    let stringData = licenseOrCertificationString();

    const request: HRJDRequest = {
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: "", // JSON.stringify(HRFeedbackPromptTemplates),
        top: retrieveCountHRJD,
        temperature: temperatureHRJD,
        tokenLength: tokenLengthHRJD,
        embeddingModelType: String(selectedEmbeddingItemHRJD?.key),
        deploymentType: String(selectedDeploymentTypeHRJD?.key),
        chainType: String(selectedChainHRJD?.key),
        userName: userName,

        job_profile_name: jobProfileName,
        job_family_group: selectedJobFamilyGrp?.text,
        job_family: selectedJobFamily?.text,
        job_career_band: selectedJobCareerBand?.text,
        job_details_optional: addlJobDetails,
        job_summary: jobsummary,
        userFeedback: certificationFeedback,
        existingdata: stringData,
        promptKey: "job_lc",
        responseKey: "jobLc",
      },
    };

    try {
      // console.log('submitting Regenerating for job LC')
      const response = await HRJD_Revision_Api(
        request,
        String(selectedItemHRJD?.key),
        String(selectedIndexHRJD)
      );
      // console.log('got the Revised job LC')
      // console.log(response)

      // console.log('parsing the Revised job LC')
      if (response.error) {
        setLCGenerateError(true);
        setErrorHRLC(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10034). Please retry your request."
        );
      } else {
        const result = response.answer;
        licenseOrCertificationResponse(result.jobLc);
      }
    } catch (error: any) {
      // await HRJDLogException(userName, "makeApiRequestHRJDFeedbackLC", JSON.stringify(request), error.message )
      // console.log('getting into error while working with makeApiRequestHRJDFeedbackLC')
      switch (error.message) {
      case "Failed":
        // console.log(`Request Failed`)
        setLCGenerateError(true);
        setReviseCertificationssPanelOpen(false);
        setErrorHRLC(
          `The Service did not respond in a timely manner. Please try again.`
          //"The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10035). Please retry your request."
        );
        break;
      case "TimeOut":
        // console.log(`Request Timed Out`)
        setLCGenerateError(true);
        setReviseCertificationssPanelOpen(false);
        setErrorHRLC(
          `The Service did not respond in a timely manner. Please try again.`
          // `The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10036). Please retry your request.`
        );
        break;
      default:
        setLCGenerateError(true);
        setReviseCertificationssPanelOpen(false);
        setErrorHRLC(
          `There was an issue with the response from the Large Language Model. We are sorry for the inconvenience, please try again.`
          //`The application has encountered an issue. The App admin have been automatically notified and will be looking into it (10037). Please retry your request.`
        );
        break;
      }
    }
  };

  const GetUseraccessRoles = () => {
    let userGroups1 = sessionStorage.getItem("userRoles");
    if (userGroups1 != null && userGroups1 != undefined) {
      const roleStatus = userAuthorization("HomeHR", requiredRoles);

      //  Check if the user has the specific role you want
      const isGroupAdmin = roleStatus[0];
      const isGroupUser = roleStatus[1];
      const isGenericAdmin = roleStatus[2];

      setIsGenericAdmin(isGenericAdmin);
      setIsGroupAdmin(isGroupAdmin);
      setIsGroupUser(isGroupUser);
    }
  };

  const clearDetails = () => {
    onJobFamilyGrpChange(null);
    onJobCareerBandChange(null);
    onJobFamilyChange(null);
    onExistingBlobNameChange(null);

    setMissingJobProfileName(false);
    setMissingExistingJD(false);

    setJobSummary("");
    setJobProfileName("");
    setAddlJobDetails("");
    setJobResponsibilities([]);
    setEducationInfo([]);
    setExperienceInfo([]);
    setKSASInfo([]);
    setLCInfo([]);

    setIsContentGenerated(false);

    setJobDescriptionGenerateError(false);
    setResponsibilityGenerateError(false);
    setEducationGenerateError(false);
    setExperienceGenerateError(false);
    setKSASGenerateError(false);
    setLCGenerateError(false);

    setJobResponsibilitiesAPICalled(false);
    setJobEducationAPICalled(false);
    setJobExperienceAPICalled(false);
    setJobKSASAPICalled(false);
    setJobLCAPICalled(false);

    if (openModifyJD) setSelectedBlobItemHRJD({ key: "", text: "" });
  };

  const reviseJobSummary = () => {
    //  if (jobsummary === "") { setMissingJobSummary(true); return; } else setMissingJobSummary(false);
    setReviseJDPanelOpen(true);
  };

  const reviseResponsibilities = () => {
    setReviseResponsibilitiesPanelOpen(true);
  };

  const reviseEducation = () => {
    setReviseEducationPanelOpen(true);
  };

  const reviseExperience = () => {
    setReviseExperiencePanelOpen(true);
  };

  const reviseCertifications = () => {
    setReviseCertificationssPanelOpen(true);
  };

  const reviseKnowledge = () => {
    setReviseKnowledgePanelOpen(true);
  };

  const appendResponsibility = () => {
    setJobResponsibilities((jobResponsibilities: any) => [
      ...jobResponsibilities,
      {
        index: jobResponsibilities?.length > 0 ? jobResponsibilities[jobResponsibilities.length - 1].index + 1 : 0,
        responsibility: "",
        percentOfTime: "0",
      },
    ]);
  };

  const appendEducation = () => {
    setEducationInfo((educationInfo: any) => [
      ...educationInfo,
      {
        index: educationInfo?.length > 0? educationInfo[educationInfo.length - 1].index + 1 : 0,
        educationLevel: "",
        areaOfStudy: "",
      },
    ]);
  };

  const appendExperience = () => {
    setExperienceInfo((experienceInfo: any) => [
      ...experienceInfo,
      {
        index: experienceInfo?.length > 0 ? experienceInfo[experienceInfo.length - 1].index + 1 : 0,
        experienceTime: "",
        experience: "",
      },
    ]);
  };

  const appendKnowledge = () => {
    setKSASInfo((ksasInfo: any) => [
      ...ksasInfo,
      {
        index: ksasInfo?.length > 0 ? ksasInfo[ksasInfo.length - 1].index + 1 : 0,
        knowledgeSkillOrAbility: "",
        details: "",
      },
    ]);
  };

  const appendCertification = () => {
    setLCInfo((licenseOrCertificationInfo: any) => [
      ...licenseOrCertificationInfo,
      {
        index:
          licenseOrCertificationInfo?.length > 0 ? licenseOrCertificationInfo[licenseOrCertificationInfo.length - 1].index + 1 : 0,
        licenseOrCertification: "",
        details: "",
      },
    ]);
  };

  const onAllocationChange = (
    respItem: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = jobResponsibilities;
    setJobResponsibilities(
      list.map((item: IjobresponsibilityType) => {
        if (item.index === index) {
          return { ...item, percentOfTime: value };
        }
        return item;
      })
    );
  };

  const onResponsibilityChange = (
    respItem: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = jobResponsibilities;
    setJobResponsibilities(
      list.map((item: IjobresponsibilityType) => {
        if (item.index === index) {
          return { ...item, responsibility: value };
        }
        return item;
      })
    );
  };

  const onExperienceLevelChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = experienceInfo;
    setExperienceInfo(
      list.map((item: IexperienceInfoType) => {
        if (item.index === index) {
          return { ...item, experienceTime: value };
        }
        return item;
      })
    );
  };

  const onExperienceChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = experienceInfo;
    setExperienceInfo(
      list.map((item: IexperienceInfoType) => {
        if (item.index === index) {
          return { ...item, experience: value };
        }
        return item;
      })
    );
  };

  const onExperienceRequired = (
    item: any,
    value: boolean | undefined,
    index: number | undefined
  ) => {
    const list = experienceInfo;
    setExperienceInfo(
      list.map((item: IexperienceInfoType) => {
        if (item.index === index) {
          return {
            ...item,
            requirementLevel: value ? "Required" : "Preferred",
          };
        }
        return item;
      })
    );
  };

  const onKnowledgeChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = KSASInfo;
    setKSASInfo(
      list.map((item: IksasType) => {
        if (item.index === index) {
          return { ...item, knowledgeSkillOrAbility: value };
        }
        return item;
      })
    );
  };

  const onKnowledgeRequired = (
    item: any,
    value: boolean | undefined,
    index: number | undefined
  ) => {
    const list = KSASInfo;
    setKSASInfo(
      list.map((item: IksasType) => {
        if (item.index === index) {
          return {
            ...item,
            requirementLevel: value ? "Required" : "Preferred",
          };
        }
        return item;
      })
    );
  };

  const onKnowledgeDetailsChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = KSASInfo;
    setKSASInfo(
      list.map((item: IksasType) => {
        if (item.index === index) {
          return { ...item, details: value };
        }
        return item;
      })
    );
  };

  const onEducationLevelChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = educationInfo;
    setEducationInfo(
      list.map((item: IeducationInfoType) => {
        if (item.index === index) {
          return { ...item, educationLevel: value };
        }
        return item;
      })
    );
  };

  const onEducationRequired = (
    item: any,
    value: boolean | undefined,
    index: number | undefined
  ) => {
    const list = educationInfo;
    setEducationInfo(
      list.map((item: IeducationInfoType) => {
        if (item.index === index) {
          return {
            ...item,
            requirementLevel: value ? "Required" : "Preferred",
          };
        }
        return item;
      })
    );
  };

  const onAreaOfStudyChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = educationInfo;
    setEducationInfo(
      list.map((item: IeducationInfoType) => {
        if (item.index === index) {
          return { ...item, areaOfStudy: value };
        }
        return item;
      })
    );
  };

  const onCertificationChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = licenseOrCertificationInfo;
    setLCInfo(
      list.map((item: IlicenseOrCertificationType) => {
        if (item.index === index) {
          return { ...item, licenseOrCertification: value };
        }
        return item;
      })
    );
  };

  const onCertificationRequired = (
    item: any,
    value: boolean | undefined,
    index: number | undefined
  ) => {
    const list = licenseOrCertificationInfo;
    setLCInfo(
      list.map((item: IlicenseOrCertificationType) => {
        if (item.index === index) {
          return {
            ...item,
            requirementLevel: value ? "Required" : "Preferred",
          };
        }
        return item;
      })
    );
  };

  const onCertificationDetailsChange = (
    item: any,
    value: string | undefined,
    index: number | undefined
  ) => {
    const list = licenseOrCertificationInfo;
    setLCInfo(
      list.map((item: IlicenseOrCertificationType) => {
        if (item.index === index) {
          return { ...item, details: value };
        }
        return item;
      })
    );
  };

  const summaryFeedbackChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setSummaryFeedback(newValue ? newValue : "");
  };

  const responsibilityFeedbackChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setResponsibilitiesFeedback(newValue ? newValue : "");
  };

  const educationFeedbackChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setEducationFeedback(newValue ? newValue : "");
  };

  const experienceFeedbackChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setExperienceFeedback(newValue ? newValue : "");
  };

  const knowledgeFeedbackChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setKnowledgeFeedback(newValue ? newValue : "");
  };

  const certificationsFeedbackChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setCertificationFeedback(newValue ? newValue : "");
  };

  const validateResponsibilities = async () => {
    const list = jobResponsibilities;
    const percentOfTimeAllocated = (list || []).reduce(
      (sumOfAllocation: number, curr: IjobresponsibilityType) => {
        return (
          sumOfAllocation +
          (curr && curr.percentOfTime ? parseInt(curr?.percentOfTime) : 0)
        );
      },
      0
    );
    percentOfTimeAllocated === 100
      ? setTimeAllocationError(false)
      : setTimeAllocationError(true);

    const itemsWithZeroAllocation = list.filter(
      (item: IjobresponsibilityType) => {
        if (item.percentOfTime === "0") {
          return item;
        }
      }
    );
    itemsWithZeroAllocation.length > 0
      ? setAllocationValuesAreZeroError(true)
      : setAllocationValuesAreZeroError(false);

    const itemsWithEmptyResponsibility = list.filter(
      (item: IjobresponsibilityType) => {
        if (item.responsibility === "") {
          return item;
        }
      }
    );
    itemsWithEmptyResponsibility.length > 0
      ? setResponsibilityEmptyError(true)
      : setResponsibilityEmptyError(false);

    if (
      percentOfTimeAllocated === 100 &&
      itemsWithZeroAllocation?.length === 0 &&
      itemsWithEmptyResponsibility.length === 0
    ) {
      await makeApiRequestHRJDInd("job_education", "jobEducation");
      setOpenContentPage("Education");
    }
  };

  const validateEducation = async () => {
    const list = educationInfo;

    const itemsWithEmptyEducationLevel = list.filter(
      (item: IeducationInfoType) => {
        if (item.educationLevel === "") {
          return item;
        }
      }
    );
    itemsWithEmptyEducationLevel.length > 0
      ? setEducationLevelEmptyError(true)
      : setEducationLevelEmptyError(false);

    const itemsWithEmptyAreaofStudy = list.filter(
      (item: IeducationInfoType) => {
        if (item.areaOfStudy === "") {
          return item;
        }
      }
    );
    itemsWithEmptyAreaofStudy.length > 0
      ? setEducationAreaOfStudyEmptyError(true)
      : setEducationAreaOfStudyEmptyError(false);

    if (
      itemsWithEmptyAreaofStudy?.length === 0 &&
      itemsWithEmptyEducationLevel?.length === 0
    ) {
      await makeApiRequestHRJDInd("job_experience", "jobExperience");
      setOpenContentPage("Experience");
    }
  };

  const validateExperience = async () => {
    const list = experienceInfo;

    const itemsWithEmptyExperienceDetails = list.filter(
      (item: IexperienceInfoType) => {
        if (item.experience === "") {
          return item;
        }
      }
    );
    itemsWithEmptyExperienceDetails.length > 0
      ? setExperienceDetailsEmptyError(true)
      : setExperienceDetailsEmptyError(false);

    const itemsWithEmptyExperience = list.filter(
      (item: IexperienceInfoType) => {
        if (item.experienceTime === "") {
          return item;
        }
      }
    );
    itemsWithEmptyExperience.length > 0
      ? setExperienceEmptyError(true)
      : setExperienceEmptyError(false);

    if (
      itemsWithEmptyExperience?.length === 0 &&
      itemsWithEmptyExperienceDetails?.length === 0
    ) {
      await makeApiRequestHRJDInd("job_ksas", "jobKsas");
      setOpenContentPage("Knowledge");
    }
  };

  const validateKnowledge = async () => {
    const list = KSASInfo;

    const itemsWithEmptyKnowledge = list.filter((item: IksasType) => {
      if (item.knowledgeSkillOrAbility === "") {
        return item;
      }
    });
    itemsWithEmptyKnowledge.length > 0
      ? setKnowledgeEmptyError(true)
      : setKnowledgeEmptyError(false);

    const itemsWithEmptyKnowledgeDetails = list.filter((item: IksasType) => {
      if (item.details === "") {
        return item;
      }
    });
    itemsWithEmptyKnowledgeDetails.length > 0
      ? setKnowledgeDetailsEmptyError(true)
      : setKnowledgeDetailsEmptyError(false);

    if (
      itemsWithEmptyKnowledge?.length === 0 &&
      itemsWithEmptyKnowledgeDetails?.length === 0
    ) {
      await makeApiRequestHRJDInd("job_lc", "jobLc");
      setOpenContentPage("Certifications");
    }
  };

  const validateCertifications = async () => {
    const list = licenseOrCertificationInfo;

    const itemsWithEmptyCertificationDetails = list.filter(
      (item: IlicenseOrCertificationType) => {
        if (item.details === "") {
          return item;
        }
      }
    );
    itemsWithEmptyCertificationDetails.length > 0
      ? setCertificationDetailsEmptyError(true)
      : setCertificationDetailsEmptyError(false);

    const itemsWithEmptyCertification = list.filter(
      (item: IlicenseOrCertificationType) => {
        if (item.licenseOrCertification === "") {
          return item;
        }
      }
    );
    itemsWithEmptyCertification.length > 0
      ? setCertificationEmptyError(true)
      : setCertificationEmptyError(false);

    if (
      itemsWithEmptyCertification?.length === 0 &&
      itemsWithEmptyCertificationDetails?.length === 0
    ) {
      setOpenContentPage("ReviewPage");
    }
  };

  const goToResponsibilities = () => {
    if (jobsummary === "") {
      setMissingJobSummary(true);
      return;
    } else setMissingJobSummary(false);
    setOpenContentPage("Responsibilities");
  };

  const processJRFetch = async () => {
    await makeApiRequestHRJDInd("job_responsibilities", "jobResponsibilities");
    setOpenContentPage("Responsibilities");
  };

  const displayJobProfileDetails = () => {
    return (
      <Stack
        className={styles.panel1}
        enableScopedSelectors
        tokens={outerStackTokens}
      >
        <Stack enableScopedSelectors tokens={innerStackTokens}>
          <Stack.Item
            grow
            styles={stackItemStyles}
            className={styles.slackItemTextBoxStyles}
          >
            <Label className={styles.labelname}>
              Job Profile Name:<span className={styles.FieldMandatory}>*</span>
            </Label>
            <TextField
              onChange={onChangeJobProfileName}
              value={jobProfileName}
              disabled={
                (openCreateNewJD && isJobSummaryReady) ||
                (openModifyJD && isContentGenerated)
                  ? true
                  : false
              }
              errorMessage={
                !missingJobProfileName
                  ? ""
                  : "A Job Profile Name is required to generate content."
              }
            />
          </Stack.Item>
          <Stack.Item grow className={styles.stackItemStyles}>
            <Label className={styles.labelname}>
              Job Family Group:<span className={styles.FieldMandatory}>*</span>
            </Label>
            <DropdownSearch
              items={job_family}
              placeHolder="Select a job family group"
              value={selectedJobFamilyGrp && selectedJobFamilyGrp?.text}
              noMatchText="No matches found. Please refine search."
              onChange={(item: IDropdownOption<any> | undefined) => {
                onJobFamilyGrpChange(undefined, item);
              }}
              width={document.getElementById("HRJDFormSection")?.clientWidth}
              maxHeight={200}
              errorMessage={
                missingJobFamilyGrp
                  ? "A Job Family Group is required to generate content."
                  : ""
              }
              disabled={
                (openCreateNewJD && isJobSummaryReady) ||
                (openModifyJD && isContentGenerated)
                  ? true
                  : false
              }
            />
          </Stack.Item>
          <Stack.Item grow styles={stackItemStyles}>
            <Label className={styles.labelname}>
              Job Family:<span className={styles.FieldMandatory}>*</span>
            </Label>
            <DropdownSearch
                items={dynamicOptions}
                placeHolder="Select a job family"
                value={selectedJobFamily?.text ? selectedJobFamily?.text : ""}
                noMatchText="No matches found. Please refine search."
                onChange={(item: IDropdownOption<any> | undefined) => {
                  onJobFamilyChange(undefined, item);
                }}
                width={document.getElementById("HRJDFormSection")?.clientWidth}
                maxHeight={200}
                disabled={((openCreateNewJD && isJobSummaryReady) || (openModifyJD && isContentGenerated)) ? true : false}
                errorMessage={
                  missingJobFamily
                    ? "A Job Family is required to generate content." : ""
                }
              />
          </Stack.Item>
          <Stack.Item grow styles={stackItemStyles}>
            <Label className={styles.labelname}>Career Band:</Label>
            <Dropdown
              disabled={
                (openCreateNewJD && isJobSummaryReady) ||
                  (openModifyJD && isContentGenerated)
                  ? true
                  : false
              }
              styles={dropdownStyles}
              className={styles.stackItemStyles1}
              selectedKey={
                selectedJobCareerBand ? selectedJobCareerBand.key : -1
              }
              onChange={onJobCareerBandChange}
              placeholder="Select a career band"
              options={career_bands}
            />
          </Stack.Item>
          <Stack.Item
            grow
            styles={stackItemStyles}
            className={styles.slackItemTextAreaStyles}
          >
            <Label className={styles.labelname}>Additional Details:</Label>
            <TextField
              onChange={onJobDetailsChange}
              multiline
              autoAdjustHeight
              styles={{ root: { minWidth: "80% " } }}
              value={addlJobDetails}
              placeholder="Please list any narrative about the job you are creating, based on your unique understanding of the nature of the job (e.g., what are some core responsibilities of the job, key areas of expertise, knowledge skills or abilities.) AI will use this prompt to fine tune the Job Summary and other areas of the Job Description."
              readOnly={
                (openCreateNewJD && isJobSummaryReady) ||
                (openModifyJD && isContentGenerated)
                  ? true
                  : false
              }
            />
          </Stack.Item>
          {isJobSummaryReady && (
            <Stack.Item
              grow
              styles={stackItemStyles}
              className={styles.slackItemTextAreaStyles}
            >
              <Label className={styles.labelname}>
                Job Summary:<span className={styles.FieldMandatory}>*</span>
              </Label>
              <TextField
                multiline
                autoAdjustHeight
                styles={{ root: { minWidth: "80% " } }}
                value={jobsummary}
                errorMessage={
                  !missingJobSummary ? "" : "Job Summary is required to proceed"
                }
                onChange={onJobSummaryChange}
              />
            </Stack.Item>
          )}

          {isJobDescriptionGenerateError && (
            <div className={styles.GenerateErrorSection}>
              <div className={styles.GenerateErrorText}>{errorHRJD || ""}</div>
            </div>
          )}

          {openCreateNewJD ? (
            isJobSummaryReady ? (
              <div>
                <Stack.Item grow className={styles.ReviseWarningContainer}>
                  <p className={styles.ReviseWarning}>
                    Not quite right? Suggest changes and improve your job
                    description.
                  </p>
                </Stack.Item>
                <Stack.Item grow className={styles.StackButtonsContainer}>
                  <DefaultButton
                    onClick={() => reviseJobSummary()}
                    className={`${styles.HRPrimaryButton} ${styles.HRNoMarginTop}`}
                    onMouseEnter={() => setPrimaryFillColor("black")}
                    onMouseLeave={() => setPrimaryFillColor("white")}
                  >
                    {" "}
                    <p className={styles.width100}>
                      <span>
                        <SparkleFilled
                          fontSize={"18px"}
                          primaryFill={primaryFillColor}
                          aria-hidden="true"
                          aria-label="HR JD Logo"
                        />
                      </span>
                      <span>Regenerate Job Summary</span>
                    </p>
                  </DefaultButton>
                </Stack.Item>
                <Stack.Item grow className={styles.StackButtonsContainer}>
                  {openContentPage !== "Responsibilities" ? (
                    <DefaultButton
                      onClick={() => {
                        processJRFetch();
                      }}
                      className={styles.HRNextButton}
                    >
                      <div className={styles.NextIconContainer}>
                        <span>Continue</span>
                        <span className={styles.NextIcon}></span>
                      </div>
                    </DefaultButton>
                  ) : (
                    ""
                  )}
                </Stack.Item>
              </div>
            ) : (
              <Stack.Item grow className={styles.StackButtonsContainer}>
                <DefaultButton
                  onClick={() => clearDetails()}
                  className={styles.HRSecondaryButton}
                >
                  {" "}
                  Clear Details{" "}
                </DefaultButton>
                <DefaultButton
                  onClick={() => onGenerateJobDescClicked("openCreateNewJD")}
                  className={styles.HRPrimaryButton}
                  onMouseEnter={() => setPrimaryFillColor("black")}
                  onMouseLeave={() => setPrimaryFillColor("white")}
                >
                  {isJobSummaryLoading && !isReviseJDPanelOpen ? (
                    <p className={styles.width100}>
                      <span>
                        <SparkleFilled
                          fontSize={"18px"}
                          primaryFill={primaryFillColor}
                          aria-hidden="true"
                          aria-label="HR JD Logo"
                        />
                      </span>
                      <span className={styles.generating}>Generating</span>
                      <span className={styles.loadingdots}></span>
                    </p>
                  ) : (
                    <p className={styles.width100}>
                      <span>
                        {" "}
                        <SparkleFilled
                          fontSize={"18px"}
                          primaryFill={primaryFillColor}
                          aria-hidden="true"
                          aria-label="HR JD Logo"
                        />
                      </span>
                      <span>Generate Description</span>
                    </p>
                  )}
                </DefaultButton>
              </Stack.Item>
            )
          ) : (
            ""
          )}

          {openModifyJD ? (
            isJobSummaryReady && isContentGenerated ? (
              <div>
                <Stack.Item grow className={styles.StackButtonsContainer}>
                  <DefaultButton
                    onClick={() => reviseJobSummary()}
                    className={styles.HRPrimaryButton}
                    onMouseEnter={() => setPrimaryFillColor("black")}
                    onMouseLeave={() => setPrimaryFillColor("white")}
                  >
                    <p className={styles.width100}>
                      <span>
                        <SparkleFilled
                          fontSize={"18px"}
                          primaryFill={primaryFillColor}
                          aria-hidden="true"
                          aria-label="HR JD Logo"
                        />
                      </span>
                      <span>Regenerate Job Summary</span>
                    </p>
                  </DefaultButton>
                </Stack.Item>
                <Stack.Item grow className={styles.StackButtonsContainer}>
                  {openContentPage !== "Responsibilities" ? (
                    <DefaultButton
                      onClick={() => {
                        processJRFetch();
                      }}
                      className={styles.HRNextButton}
                    >
                      <div className={styles.NextIconContainer}>
                        <span>Continue</span>
                        <span className={styles.NextIcon}></span>
                      </div>
                    </DefaultButton>
                  ) : (
                    ""
                  )}
                </Stack.Item>
              </div>
            ) : (
              <Stack.Item grow className={styles.StackButtonsContainer}>
                <DefaultButton
                  onClick={() => clearDetails()}
                  className={styles.HRSecondaryButton}
                >
                  {" "}
                  Clear Details{" "}
                </DefaultButton>
                <DefaultButton
                  onClick={() => onGenerateJobDescClicked("modifyExistingJD")}
                  className={styles.HRPrimaryButton}
                  onMouseEnter={() => setPrimaryFillColor("black")}
                  onMouseLeave={() => setPrimaryFillColor("white")}
                >
                  {isJobSummaryLoading && !isReviseJDPanelOpen ? (
                    <p className={styles.width100}>
                      <span>
                        <SparkleFilled
                          fontSize={"18px"}
                          primaryFill={primaryFillColor}
                          aria-hidden="true"
                          aria-label="HR JD Logo"
                        />
                      </span>
                      <span className={styles.generating}>Generating</span>
                      <span className={styles.loadingdots}></span>
                    </p>
                  ) : (
                    <p className={styles.width100}>
                      <span>
                        {" "}
                        <SparkleFilled
                          fontSize={"18px"}
                          primaryFill={primaryFillColor}
                          aria-hidden="true"
                          aria-label="HR JD Logo"
                        />
                      </span>
                      <span> Generate Job Summary </span>
                    </p>
                  )}
                </DefaultButton>
              </Stack.Item>
            )
          ) : (
            ""
          )}
        </Stack>
      </Stack>
    );
  };

  const displayCreateNewJDContent = () => {
    return (
      <div className={styles.JobDetails}>
        <div>Job Details:</div>
        {displayJobProfileDetails()}
      </div>
    );
  };

  const displayModifyJDContent = () => {
    return (
      <div className={styles.JobDetails}>
        <Stack
          className={styles.panel1}
          enableScopedSelectors
          tokens={outerStackTokens}
        >
          <Stack enableScopedSelectors tokens={innerStackTokens}>
            <Stack.Item
              grow
              className={styles.stackItemStyles}
            >
              <Label className={styles.labelname}>
                Modify an Existing Job Description:
                <span className={styles.FieldMandatory}>*</span>
              </Label>
              <DropdownSearch
                items={blobNamesoptions}
                placeHolder="Select an existing description to modify"
                value={selectedBlobItemHRJD?.text}
                noMatchText="No matches found. Please refine search."
                onChange={(item: IDropdownOption<any> | undefined) => {
                  onExistingBlobNameChange(undefined, item);
                }}
                width={document.getElementById("HRJDFormSection")?.clientWidth}
                maxHeight={200}
                disabled={((openCreateNewJD && isJobSummaryReady) || (openModifyJD && isContentGenerated)) ? true : false}
                errorMessage={
                  missingExistingJD
                    ? "An existing job desciption is required to generate content." : ""
                }
              />
              {/* Update variable job_family with existing JD pdf array */}
            </Stack.Item>
          </Stack>
        </Stack>
        {displayJobProfileDetails()}
      </div>
    );
  };

  const displayBreadCrumb = () => {
    {
      return (isJobResponsibilitiesAPICalled &&
        isJobResponsibilitiesAPICalled &&
        isJobEducationAPICalled &&
        isJobExperienceAPICalled &&
        isJobKSASAPICalled &&
        isJobLCAPICalled)
        ?
        (<ul className={styles.breadcrumb}>
          <li onClick={() => setOpenContentPage("JobDetails")}>Job Summary</li>
          <li onClick={() => setOpenContentPage("Responsibilities")}>
            Responsibilities
          </li>
          <li onClick={() => setOpenContentPage("Education")}>Education</li>
          <li onClick={() => setOpenContentPage("Experience")}>Experience</li>
          <li onClick={() => setOpenContentPage("Knowledge")}>Knowledge</li>
          <li onClick={() => setOpenContentPage("Certifications")}>
            Certifications
          </li>
          <li onClick={() => setOpenContentPage("ReviewPage")}>Review</li>
        </ul>) : ""
    }
  }

  const displayJobDetailPage = () => {
    return (
      <div className={styles.Section}>
        <div className={styles.ScrollableSection}>
          <div>{displayBreadCrumb()}</div>
          <div className={styles.SectionHeading}>
            <span className={styles.HRJDItemHeading}>
              What do you want to do today?{" "}
            </span>
            <span className={styles.WhatDoYouWantToDoTodaySpan}>*</span>
          </div>
          <div className={styles.RevisedOrCreateNew}>
            <div className={styles.Options}>
              <div
                className={
                  openCreateNewJD ? styles.SelectedButton : styles.SelectButton
                }
                onClick={() => {
                  setClickedItem("create_new");
                  if (isJobSummaryReady) {
                    setWarningPanelOpen(true);
                    return;
                  }
                  createNewJDClicked();
                }}
              >
                <div className={styles.AddIcon}>
                  <div className={styles.CreateNewVector}></div>
                </div>
                <div className={styles.CreateANewDescription}>
                  Create a New Description
                </div>
              </div>
              <div
                className={
                  openModifyJD ? styles.SelectedButton : styles.SelectButton
                }
                onClick={() => {
                  setClickedItem("modify_existing");
                  if (isJobSummaryReady) {
                    setWarningPanelOpen(true);
                    return;
                  }
                  modifyJDClicked();
                }}
              >
                <div className={styles.EditDocument}>
                  <div className={styles.BoundingBox}></div>
                </div>
                <div className={styles.ModifyDescription}>
                  Modify Existing Description
                </div>
              </div>
            </div>
          </div>
          <div className={styles.FormSection} id="HRJDFormSection">
            {openCreateNewJD && displayCreateNewJDContent()}
            {openModifyJD && displayModifyJDContent()}
          </div>
        </div>
      </div>
    );
  };

  const displayResponsibilitiesPage = () => {
    return (
      <div className={styles.Section}>
        <div>{displayBreadCrumb()}</div>
        <div className={styles.SectionHeading}>
          <span className={styles.HRJDItemHeading}>Responsibilities:</span>
        </div>
        <div className={styles.FormSection}>
          <Stack
            className={styles.panel1}
            enableScopedSelectors
            tokens={outerStackTokens}
          >
            <Stack enableScopedSelectors tokens={innerStackTokens}>
              {jobResponsibilities.map((item: IjobresponsibilityType) => {
                return (
                  <Stack.Item
                    grow
                    styles={stackItemStyles}
                    className={styles.slackItemTextAreaStyles}
                  >
                    <div className={styles.ResponsibilityItem}>
                      <div className={styles.ResponsibilityText}>
                        <Label className={styles.labelname}>
                          Responsibility:
                        </Label>
                        <TextField
                          value={item.responsibility}
                          onChange={(e, value) =>
                            onResponsibilityChange(e, value, item.index)
                          }
                          multiline
                          autoAdjustHeight
                          styles={{ root: { width: "90% " } }}
                          placeholder="Add/Update Responsibility and allocation."
                          errorMessage={
                            isResponsibilityEmptyError &&
                              item.responsibility === ""
                              ? "Responsibility must not be empty"
                              : ""
                          }
                        />
                      </div>
                      <div className={styles.ResponsibilityAllocation}>
                        <Label className={styles.labelname}>
                          Allocation %:
                        </Label>
                        <SpinButton
                          styles={
                            isAllocationValuesAreZeroError &&
                              item.percentOfTime === "0"
                              ? spinButtonStyles
                              : undefined
                          }
                          min={0}
                          max={100}
                          step={allocationStep}
                          value={item.percentOfTime}
                          onChange={(e, value) =>
                            onAllocationChange(e, value, item.index)
                          }
                        />
                        {isAllocationValuesAreZeroError &&
                          item.percentOfTime === "0" ? (
                          <span id="TextFieldDescription10816">
                            <div role="alert">
                              <p className="ms-TextField-errorMessage errorMessage-124">
                                <span className={styles.errorText}>
                                  Allocation % must not be 0
                                </span>
                              </p>
                            </div>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={styles.ResponsibilityDelete}>
                        <IconButton
                          className={isAnimated ? "animated-icon-button" : ""}
                          iconProps={{ iconName: "Delete" }}
                          style={{
                            display: "flex",
                            justifyItems: "end",
                            alignContent: "center",
                            cursor: "pointer",
                            color: "#e20074",
                            padding: "0px",
                          }}
                          onClick={() => {
                            onDeleteResponsibility(item);
                          }}
                          title="Delete"
                          ariaLabel="Delete"
                        />
                      </div>
                    </div>
                    <div className={styles.ReviewItemDividers}>
                      <div className={styles.ReviewItemRectangle8} />
                    </div>
                  </Stack.Item>
                );
              })}
              <div className={styles.ResponsibilityAllocationSum}>
                {" "}
                <Label className={styles.labelname}>
                  Total Allocation:{" "}
                  <span
                    className={
                      totalAllocationPercentage !== 100
                        ? styles.errorText
                        : styles.successText
                    }
                  >
                    {totalAllocationPercentage}%
                  </span>
                </Label>
              </div>
              {isTimeAllocationError && (
                <div className={styles.PercentAllocationErrorSection}>
                  <div className={styles.PercentAllocationErrorText}>
                    Uh-oh! Total allocation must be equal to 100%.
                  </div>
                </div>
              )}
              {isAllocationValuesAreZeroError && (
                <div className={styles.PercentAllocationErrorSection}>
                  <div className={styles.PercentAllocationErrorText}>
                    Allocation % must not be 0. Please modify/delete field.
                  </div>
                </div>
              )}
              {isResponsibilityEmptyError && (
                <div className={styles.PercentAllocationErrorSection}>
                  <div className={styles.PercentAllocationErrorText}>
                    Responsibility cannot be empty. Please modify/delete field.
                  </div>
                </div>
              )}
              {isResponsibilityGenerateError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    {errorHRResponsibilities || ""}
                  </div>
                </div>
              )}
              <Stack.Item grow className={styles.StackButtonsContainer}>
                <DefaultButton
                  onClick={reviseResponsibilities}
                  className={styles.HRPrimaryButton}
                  onMouseEnter={() => setPrimaryFillColor("black")}
                  onMouseLeave={() => setPrimaryFillColor("white")}
                >
                  <p className={styles.width100}>
                    <span>
                      <SparkleFilled
                        fontSize={"18px"}
                        primaryFill={primaryFillColor}
                        aria-hidden="true"
                        aria-label="HR JD Logo"
                      />
                    </span>
                    <span>Regenerate Responsibilities</span>
                  </p>
                </DefaultButton>
              </Stack.Item>
              <Stack.Item grow className={styles.ThreeButtonContainer}>
                <DefaultButton
                  onClick={() => appendResponsibility()}
                  className={styles.HRAddButton}
                >
                  + Add Responsibility
                </DefaultButton>
                <div className={styles.NextButtonsContainer}>
                  <DefaultButton
                    onClick={() => {
                      setOpenContentPage("JobDetails");
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.PreviousIconContainer}>
                      <span>Previous</span>
                      <span className={styles.PreviousIcon}></span>
                    </div>
                  </DefaultButton>

                  <DefaultButton
                    onClick={() => {
                      validateResponsibilities();
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.NextIconContainer}>
                      <span>Continue</span>
                      <span className={styles.NextIcon}></span>
                    </div>
                  </DefaultButton>
                </div>
              </Stack.Item>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  };

  const displayEducationPage = () => {
    return (
      <div className={styles.Section}>
        <div>{displayBreadCrumb()}</div>
        <div className={styles.SectionHeading}>
          <span className={styles.HRJDItemHeading}>Education:</span>
        </div>
        <div className={styles.FormSection}>
          <Stack
            className={styles.panel1}
            enableScopedSelectors
            tokens={outerStackTokens}
          >
            <Stack enableScopedSelectors tokens={innerStackTokens}>
              {educationInfo.map((item: IeducationInfoType) => {
                return (
                  <Stack.Item
                    grow
                    styles={stackItemStyles}
                    className={styles.slackItemTextAreaStyles}
                  >
                    <div className={styles.QualificationItem}>
                      <div className={styles.EducationLevel}>
                        <div className={styles.EducationLevelText}>
                          <Label className={styles.labelname}>
                            Education Level:
                          </Label>
                          <TextField
                            onChange={(e, value) =>
                              onEducationLevelChange(e, value, item.index)
                            }
                            value={item.educationLevel}
                            placeholder="Add/Update Education level."
                            errorMessage={
                              isEducationLevelEmptyError &&
                                item.educationLevel === ""
                                ? "Education Level must not be empty"
                                : ""
                            }
                          />
                        </div>
                        <div className={styles.EducationToggle}>
                          <Toggle
                            title="set as Required or unset as Preferred"
                            label="Required"
                            checked={item.requirementLevel === "Required"}
                            inlineLabel
                            onChange={(e, value) => {
                              onEducationRequired(e, value, item.index);
                            }}
                            styles={educationReqdStyles}
                          />
                        </div>
                        <div className={styles.QualificationDelete}>
                          <IconButton
                            className={isAnimated ? "animated-icon-button" : ""}
                            iconProps={{ iconName: "Delete" }}
                            style={{
                              display: "flex",
                              justifyItems: "center",
                              alignContent: "center",
                              cursor: "pointer",
                              color: "#e20074",
                              padding: "0px",
                            }}
                            onClick={() => {
                              onDeleteEducation(item);
                            }}
                            title="Delete"
                            ariaLabel="Delete"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.AreaOfStudy}>
                      <Label className={styles.labelname}>Area of Study:</Label>
                      <TextField
                        value={item.areaOfStudy}
                        onChange={(e, value) =>
                          onAreaOfStudyChange(e, value, item.index)
                        }
                        multiline
                        autoAdjustHeight
                        styles={{ root: { width: "90% " } }}
                        placeholder="Add/Update details of education (Area of Study)"
                        errorMessage={
                          isEducationAreaOfStudyEmptyError &&
                            item.areaOfStudy === ""
                            ? "Area of Study must not be empty"
                            : ""
                        }
                      />
                    </div>
                    <div className={styles.ReviewItemDividers}>
                      <div className={styles.ReviewItemRectangle8} />
                    </div>
                  </Stack.Item>
                );
              })}
              {isEducationGenerateError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    {errorHREducation || ""}
                  </div>
                </div>
              )}
              {isEducationLevelEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    Education Level cannot be empty. Please modify/delete field.
                  </div>
                </div>
              )}
              {isEducationAreaOfStudyEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    Area Of Study cannot be empty. Please modify/delete field.
                  </div>
                </div>
              )}
              <Stack.Item grow className={styles.StackButtonsContainer}>
                <DefaultButton
                  onClick={reviseEducation}
                  className={styles.HRPrimaryButton}
                  onMouseEnter={() => setPrimaryFillColor("black")}
                  onMouseLeave={() => setPrimaryFillColor("white")}
                >
                  <p className={styles.width100}>
                    <span>
                      <SparkleFilled
                        fontSize={"18px"}
                        primaryFill={primaryFillColor}
                        aria-hidden="true"
                        aria-label="HR JD Logo"
                      />
                    </span>
                    <span>Regenerate Education</span>
                  </p>
                </DefaultButton>
              </Stack.Item>
              <Stack.Item grow className={styles.ThreeButtonContainer}>
                <DefaultButton
                  onClick={() => appendEducation()}
                  className={styles.HRAddButton}
                >
                  + Add Education
                </DefaultButton>
                <div className={styles.NextButtonsContainer}>
                  <DefaultButton
                    onClick={() => {
                      goToResponsibilities();
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.PreviousIconContainer}>
                      <span>Previous</span>
                      <span className={styles.PreviousIcon}></span>
                    </div>
                  </DefaultButton>
                  <DefaultButton
                    onClick={() => {
                      validateEducation();
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.NextIconContainer}>
                      <span>Continue</span>
                      <span className={styles.NextIcon}></span>
                    </div>
                  </DefaultButton>
                </div>
              </Stack.Item>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  };

  const displayExperiencePage = () => {
    return (
      <div className={styles.Section}>
        <div>{displayBreadCrumb()}</div>
        <div className={styles.SectionHeading}>
          <span className={styles.HRJDItemHeading}>Experience:</span>
        </div>
        <div className={styles.FormSection}>
          <Stack
            className={styles.panel1}
            enableScopedSelectors
            tokens={outerStackTokens}
          >
            <Stack enableScopedSelectors tokens={innerStackTokens}>
              {experienceInfo.map((item: IexperienceInfoType) => {
                return (
                  <Stack.Item
                    grow
                    styles={stackItemStyles}
                    className={styles.slackItemTextAreaStyles}
                  >
                    <div className={styles.ExperienceItem}>
                      <div className={styles.ExperienceLevel}>
                        <div className={styles.ExperienceLevelText}>
                          <Label className={styles.labelname}>
                            Experience:
                          </Label>
                          <TextField
                            onChange={(e, value) =>
                              onExperienceLevelChange(e, value, item.index)
                            }
                            value={item.experienceTime}
                            placeholder="Add/Update Experience Level"
                            errorMessage={
                              isExperienceEmptyError &&
                                item.experienceTime === ""
                                ? "Experience must not be empty"
                                : ""
                            }
                          />
                        </div>
                        <div className={styles.ExperienceToggle}>
                          <Toggle
                            label="Required"
                            checked={item.requirementLevel == "Required"}
                            inlineLabel
                            onChange={(e, value) => {
                              onExperienceRequired(e, value, item.index);
                            }}
                            styles={educationReqdStyles}
                            title="set as Required or unset as Preferred"
                          />
                        </div>
                        <div className={styles.ExperienceDelete}>
                          <IconButton
                            className={isAnimated ? "animated-icon-button" : ""}
                            iconProps={{ iconName: "Delete" }}
                            title="Delete"
                            ariaLabel="Delete"
                            style={{
                              display: "flex",
                              justifyItems: "center",
                              alignContent: "center",
                              cursor: "pointer",
                              color: "#e20074",
                              padding: "0px",
                            }}
                            onClick={() => {
                              onDeleteExperience(item);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.ExperienceDetails}>
                      <Label className={styles.labelname}>
                        {" "}
                        Experience Details:
                      </Label>
                      <TextField
                        value={item.experience}
                        onChange={(e, value) =>
                          onExperienceChange(e, value, item.index)
                        }
                        multiline
                        autoAdjustHeight
                        styles={{ root: { width: "90% " } }}
                        placeholder="Add/update Experience Details"
                        errorMessage={
                          isExperienceDetailsEmptyError &&
                            item.experience === ""
                            ? "Experience Details must not be empty"
                            : ""
                        }
                      />
                    </div>
                    <div className={styles.ReviewItemDividers}>
                      <div className={styles.ReviewItemRectangle8} />
                    </div>
                  </Stack.Item>
                );
              })}
              {isExperienceGenerateError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    {errorHRExperience || ""}
                  </div>
                </div>
              )}
              {isExperienceEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    Experience cannot be empty. Please modify/delete field.
                  </div>
                </div>
              )}
              {isExperienceDetailsEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    Experience Details cannot be empty. Please modify/delete
                    field.
                  </div>
                </div>
              )}
              <Stack.Item grow className={styles.StackButtonsContainer}>
                <DefaultButton
                  onClick={reviseExperience}
                  className={styles.HRPrimaryButton}
                  onMouseEnter={() => setPrimaryFillColor("black")}
                  onMouseLeave={() => setPrimaryFillColor("white")}
                >
                  <p className={styles.width100}>
                    <span>
                      <SparkleFilled
                        fontSize={"18px"}
                        primaryFill={primaryFillColor}
                        aria-hidden="true"
                        aria-label="HR JD Logo"
                      />
                    </span>
                    <span>Regenerate Experience</span>
                  </p>
                </DefaultButton>
              </Stack.Item>

              <Stack.Item grow className={styles.ThreeButtonContainer}>
                <DefaultButton
                  onClick={() => appendExperience()}
                  className={styles.HRAddButton}
                >
                  + Add Experience
                </DefaultButton>
                <div className={styles.NextButtonsContainer}>
                  <DefaultButton
                    onClick={() => {
                      setOpenContentPage("Education");
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.PreviousIconContainer}>
                      <span>Previous</span>
                      <span className={styles.PreviousIcon}></span>
                    </div>
                  </DefaultButton>
                  <DefaultButton
                    onClick={() => {
                      validateExperience();
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.NextIconContainer}>
                      <span>Continue</span>
                      <span className={styles.NextIcon}></span>
                    </div>
                  </DefaultButton>
                </div>
              </Stack.Item>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  };

  const displayKnowledgePage = () => {
    return (
      <div className={styles.Section}>
        <div>{displayBreadCrumb()}</div>
        <div className={styles.SectionHeading}>
          <span className={styles.HRJDItemHeading}>Knowledge:</span>
        </div>
        <div className={styles.FormSection}>
          <Stack
            className={styles.panel1}
            enableScopedSelectors
            tokens={outerStackTokens}
          >
            <Stack enableScopedSelectors tokens={innerStackTokens}>
              {KSASInfo.map((item: IksasType) => {
                return (
                  <Stack.Item
                    grow
                    styles={stackItemStyles}
                    className={styles.slackItemTextAreaStyles}
                  >
                    <div className={styles.KnowledgeItem}>
                      <div className={styles.KnowledgeLevel}>
                        <div className={styles.KnowledgeLevelText}>
                          <Label className={styles.labelname}>
                            Knowledge, Skill, Ability:
                          </Label>
                          <TextField
                            onChange={(e, value) =>
                              onKnowledgeChange(e, value, item.index)
                            }
                            value={item.knowledgeSkillOrAbility}
                            placeholder="Add/Update type"
                            errorMessage={
                              isKnowledgeEmptyError &&
                                item.knowledgeSkillOrAbility === ""
                                ? "Knowledge/Skill/Ability must not be empty"
                                : ""
                            }
                          />
                        </div>
                        <div className={styles.EducationToggle}>
                          <Toggle
                            title="set as Required or unset as Preferred"
                            label="Required"
                            checked={item.requirementLevel == "Required"}
                            inlineLabel
                            onChange={(e, value) => {
                              onKnowledgeRequired(e, value, item.index);
                            }}
                            styles={educationReqdStyles}
                          />
                        </div>
                        <div className={styles.KnowledgeDelete}>
                          <IconButton
                            className={isAnimated ? "animated-icon-button" : ""}
                            iconProps={{ iconName: "Delete" }}
                            style={{
                              display: "flex",
                              justifyItems: "center",
                              alignContent: "center",
                              cursor: "pointer",
                              color: "#e20074",
                              padding: "0px",
                            }}
                            onClick={() => {
                              onDeleteKnowledge(item);
                            }}
                            title="Delete"
                            ariaLabel="Delete"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.AreaOfStudy}>
                      <Label className={styles.labelname}>Description:</Label>
                      <TextField
                        value={item.details}
                        onChange={(e, value) =>
                          onKnowledgeDetailsChange(e, value, item.index)
                        }
                        multiline
                        autoAdjustHeight
                        styles={{ root: { width: "90% " } }}
                        placeholder="Add/Update description"
                        errorMessage={
                          isKnowledgeDetailsEmptyError && item.details === ""
                            ? "Description must not be empty"
                            : ""
                        }
                      />
                    </div>
                    <div className={styles.ReviewItemDividers}>
                      <div className={styles.ReviewItemRectangle8} />
                    </div>
                  </Stack.Item>
                );
              })}
              {isKSASGenerateError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    {errorHRKSAS || ""}
                  </div>
                </div>
              )}
              {isKnowledgeEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    Knowledge/Skill/Ability cannot be empty. Please
                    modify/delete field.
                  </div>
                </div>
              )}
              {isKnowledgeDetailsEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    Description cannot be empty. Please modify/delete field.
                  </div>
                </div>
              )}
              <Stack.Item grow className={styles.StackButtonsContainer}>
                <DefaultButton
                  onClick={reviseKnowledge}
                  className={styles.HRPrimaryButton}
                  onMouseEnter={() => setPrimaryFillColor("black")}
                  onMouseLeave={() => setPrimaryFillColor("white")}
                >
                  <p className={styles.width100}>
                    <span>
                      <SparkleFilled
                        fontSize={"18px"}
                        primaryFill={primaryFillColor}
                        aria-hidden="true"
                        aria-label="HR JD Logo"
                      />
                    </span>
                    <span>Regenerate Knowledge</span>
                  </p>
                </DefaultButton>
              </Stack.Item>
              <Stack.Item grow className={styles.ThreeButtonContainer}>
                <DefaultButton
                  onClick={() => appendKnowledge()}
                  className={styles.HRAddButton}
                >
                  + Add Knowledge
                </DefaultButton>
                <div className={styles.NextButtonsContainer}>
                  <DefaultButton
                    onClick={() => {
                      setOpenContentPage("Experience");
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.PreviousIconContainer}>
                      <span>Previous</span>
                      <span className={styles.PreviousIcon}></span>
                    </div>
                  </DefaultButton>
                  <DefaultButton
                    onClick={() => {
                      validateKnowledge();
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.NextIconContainer}>
                      <span>Continue</span>
                      <span className={styles.NextIcon}></span>
                    </div>
                  </DefaultButton>
                </div>
              </Stack.Item>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  };

  const displayCertificationsPage = () => {
    return (
      <div className={styles.Section}>
        <div>{displayBreadCrumb()}</div>
        <div className={styles.SectionHeading}>
          <span className={styles.HRJDItemHeading}>Certifications:</span>
        </div>
        <div className={styles.FormSection}>
          <Stack
            className={styles.panel1}
            enableScopedSelectors
            tokens={outerStackTokens}
          >
            <Stack enableScopedSelectors tokens={innerStackTokens}>
              {licenseOrCertificationInfo.map(
                (item: IlicenseOrCertificationType) => {
                  return (
                    <Stack.Item
                      grow
                      styles={stackItemStyles}
                      className={styles.slackItemTextAreaStyles}
                    >
                      <div className={styles.QualificationItem}>
                        <div className={styles.EducationLevel}>
                          <div className={styles.EducationLevelText}>
                            <Label className={styles.labelname}>
                              License/Certifications:
                            </Label>
                            <TextField
                              onChange={(e, value) =>
                                onCertificationChange(e, value, item.index)
                              }
                              value={item.licenseOrCertification}
                              placeholder="Add/Update License/Certification"
                              errorMessage={
                                isCertificationEmptyError &&
                                  item.licenseOrCertification === ""
                                  ? "License/Certification must not be empty"
                                  : ""
                              }
                            />
                          </div>
                          <div className={styles.EducationToggle}>
                            <Toggle
                              title="set as Required or unset as Preferred"
                              label="Required"
                              checked={item.requirementLevel == "Required"}
                              inlineLabel
                              onChange={(e, value) => {
                                onCertificationRequired(e, value, item.index);
                              }}
                              styles={educationReqdStyles}
                            />
                          </div>
                          <div className={styles.CertificationDelete}>
                            <IconButton
                              className={
                                isAnimated ? "animated-icon-button" : ""
                              }
                              iconProps={{ iconName: "Delete" }}
                              style={{
                                display: "flex",
                                justifyItems: "center",
                                alignContent: "center",
                                cursor: "pointer",
                                color: "#e20074",
                                padding: "0px",
                              }}
                              onClick={() => {
                                onDeleteCertification(item);
                              }}
                              title="Delete"
                              ariaLabel="Delete"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.CertificationsDescription}>
                        <Label className={styles.labelname}>Description:</Label>
                        <TextField
                          value={item.details}
                          onChange={(e, value) =>
                            onCertificationDetailsChange(e, value, item.index)
                          }
                          multiline
                          autoAdjustHeight
                          styles={{ root: { width: "90% " } }}
                          placeholder="Add/Update description"
                          errorMessage={
                            isCertificationDetailsEmptyError &&
                              item.details === ""
                              ? "Description must not be empty"
                              : ""
                          }
                        />
                      </div>
                      <div className={styles.ReviewItemDividers}>
                        <div className={styles.ReviewItemRectangle8} />
                      </div>
                    </Stack.Item>
                  );
                }
              )}
              {isLCGenerateError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    {errorHRLC || ""}
                  </div>
                </div>
              )}
              {isCertificationEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    License/Certification cannot be empty. Please modify/delete
                    field.
                  </div>
                </div>
              )}
              {isCertificationDetailsEmptyError && (
                <div className={styles.GenerateErrorSection}>
                  <div className={styles.GenerateErrorText}>
                    Description cannot be empty. Please modify/delete field.
                  </div>
                </div>
              )}
              <Stack.Item grow className={styles.StackButtonsContainer}>
                <DefaultButton
                  onClick={reviseCertifications}
                  className={styles.HRPrimaryButton}
                  onMouseEnter={() => setPrimaryFillColor("black")}
                  onMouseLeave={() => setPrimaryFillColor("white")}
                >
                  <p className={styles.width100}>
                    <span>
                      <SparkleFilled
                        fontSize={"18px"}
                        primaryFill={primaryFillColor}
                        aria-hidden="true"
                        aria-label="HR JD Logo"
                      />
                    </span>
                    <span>Regenerate Certifications</span>
                  </p>
                </DefaultButton>
              </Stack.Item>
              <Stack.Item grow className={styles.ThreeButtonContainer}>
                <DefaultButton
                  onClick={() => appendCertification()}
                  className={styles.HRAddButton}
                >
                  + Add Certification
                </DefaultButton>
                <div className={styles.NextButtonsContainer}>
                  <DefaultButton
                    onClick={() => {
                      setOpenContentPage("Knowledge");
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.PreviousIconContainer}>
                      <span>Previous</span>
                      <span className={styles.PreviousIcon}></span>
                    </div>
                  </DefaultButton>
                  <DefaultButton
                    onClick={() => {
                      validateCertifications();
                    }}
                    className={styles.HRNextBackButton}
                  >
                    <div className={styles.NextIconContainer}>
                      <span>Continue</span>
                      <span className={styles.NextIcon}></span>
                    </div>
                  </DefaultButton>
                </div>
              </Stack.Item>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  };

  const displayReviewPage = () => {
    return (
      <div className={styles.Section}>
        <div>{displayBreadCrumb()}</div>
        <div className={styles.ReviewSectionHeading}>
          <span className={styles.HRJDItemHeading}>
            Review Job Description:
          </span>
        </div>
        <div className={styles.JobDetailsSection}>
          <div className={styles.JobDetailsFrame}>
            <div className={styles.Responsibilities}>Job Details</div>
            <div
              className={styles.EditHRJD}
              onClick={() => {
                setOpenContentPage("JobDetails");
              }}
            >
              <div className={styles.ReviewIconLeft}>
                <div className={styles.ReviewIcon}></div>
              </div>
              <div className={styles.EditDivLabel}>
                <div className={styles.EditLabel}>Edit</div>
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            <div>
              <div className={styles.ReviewItemRow}>
                <div className={styles.ReviewItemInputs}>
                  <div className={styles.ReviewItemRow1}>
                    <div className={styles.ReviewItemLabel}>
                      Job Profile Name
                    </div>
                    <div className={styles.ReviewItemInput}>
                      {jobProfileName}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ReviewItemDividers}>
                <div className={styles.ReviewItemRectangle8} />
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            <div>
              <div className={styles.ReviewItemRow}>
                <div className={styles.ReviewItemInputs}>
                  <div className={styles.ReviewItemRow1}>
                    <div className={styles.ReviewItemLabel}>
                      Job Family Group
                    </div>
                    <div className={styles.ReviewItemInput}>
                      {selectedJobFamilyGrp?.text}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ReviewItemDividers}>
                <div className={styles.ReviewItemRectangle8} />
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            <div>
              <div className={styles.ReviewItemRow}>
                <div className={styles.ReviewItemInputs}>
                  <div className={styles.ReviewItemRow1}>
                    <div className={styles.ReviewItemLabel}>Job Family</div>
                    <div className={styles.ReviewItemInput}>
                      {selectedJobFamily?.text}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ReviewItemDividers}>
                <div className={styles.ReviewItemRectangle8} />
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            <div>
              <div className={styles.ReviewItemRow}>
                <div className={styles.ReviewItemInputs}>
                  <div className={styles.ReviewItemRow1}>
                    <div className={styles.ReviewItemLabel}>
                      Job Career Band
                    </div>
                    <div className={styles.ReviewItemInput}>
                      {selectedJobCareerBand?.text
                        ? selectedJobCareerBand?.text
                        : "No band selected."}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ReviewItemDividers}>
                <div className={styles.ReviewItemRectangle8} />
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            <div>
              <div className={styles.ReviewItemRow}>
                <div className={styles.ReviewItemInputs}>
                  <div className={styles.ReviewItemRow1}>
                    <div className={styles.ReviewItemLabel}>
                      Additional Job Details
                    </div>
                    <div className={styles.ReviewItemInput}>
                      {addlJobDetails
                        ? addlJobDetails
                        : "No additional details provided."}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ReviewItemDividers}>
                <div className={styles.ReviewItemRectangle8} />
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            <div>
              <div className={styles.ReviewItemRow}>
                <div className={styles.ReviewItemInputs}>
                  <div className={styles.ReviewItemRow1}>
                    <div className={styles.ReviewItemLabel}>Job Summary</div>
                    <div className={styles.ReviewItemInput}>
                      <TextField
                        value={jobsummary}
                        readOnly
                        multiline
                        autoAdjustHeight
                        styles={{
                          root: { border: "none", borderColor: "white" },
                          wrapper: { border: "inherit" },
                          field: { border: "inherit" },
                          fieldGroup: { border: "inherit" },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ReviewItemDividers}>
                <div className={styles.ReviewItemRectangle8} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ResponsibilitiesSection}>
          <div className={styles.ResponsibilitiesFrame}>
            <div className={styles.Responsibilities}>Responsibilities</div>
            <div
              className={styles.EditHRJD}
              onClick={() => {
                setOpenContentPage("Responsibilities");
              }}
            >
              <div className={styles.ReviewIconLeft}>
                <div className={styles.ReviewIcon}></div>
              </div>
              <div className={styles.EditDivLabel}>
                <div className={styles.EditLabel}>Edit</div>
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            {jobResponsibilities.map((resp: IjobresponsibilityType) => (
              <div>
                <div className={styles.ReviewItemRow}>
                  <div className={styles.ReviewItemInputs}>
                    <div className={styles.ReviewItemRow1}>
                      <div className={styles.ReviewItemLabel}>
                        Responsibility
                      </div>
                      <div className={styles.ReviewItemInput}>
                        {resp.responsibility}
                      </div>
                    </div>
                    <div className={styles.ReviewItemRow2}>
                      <div className={styles.ReviewItemLabel}>Allocation</div>
                      <div className={styles.ReviewItemInput}>
                        {resp.percentOfTime} %
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.ReviewItemDividers}>
                  <div className={styles.ReviewItemRectangle8} />
                </div>
              </div>
            ))}
            {jobResponsibilities.length === 0 && (
              <span className={styles.ReviewItemLabel}>
                There are no relevant responsibilities for this job profile. To
                add new responsibilities, please click 'Edit'.
              </span>
            )}
          </div>
        </div>
        <div className={styles.EducationSection}>
          <div className={styles.QualificationsFrame}>
            <div className={styles.Qualifications}>Education</div>
            <div
              className={styles.EditHRJD}
              onClick={() => {
                setOpenContentPage("Education");
              }}
            >
              <div className={styles.ReviewIconLeft}>
                <div className={styles.ReviewIcon}></div>
              </div>
              <div className={styles.EditDivLabel}>
                <div className={styles.EditLabel}>Edit</div>
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            {educationInfo.map((item: IeducationInfoType) => (
              <div>
                <div className={styles.ReviewItemRow}>
                  <div className={styles.ReviewItemQualificationInputs}>
                    <div className={styles.ReviewQualificationExperience}>
                      <div className={styles.ReviewItemRow1}>
                        <div className={styles.ReviewItemLabel}>
                          Education Level
                        </div>
                        <div className={styles.ReviewItemInput}>
                          {item.educationLevel}
                        </div>
                      </div>
                      <div className={styles.EducationToggle}>
                        <Toggle
                          title="Required/Preferred"
                          label="Required"
                          checked={item.requirementLevel == "Required"}
                          inlineLabel
                          styles={educationReqdStyles}
                        />
                      </div>
                    </div>
                    <div className={styles.ReviewItemRow2}>
                      <div className={styles.ReviewItemLabel}>
                        Area of Study
                      </div>
                      <div className={styles.ReviewItemInput}>
                        {item.areaOfStudy}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.ReviewItemDividers}>
                  <div className={styles.ReviewItemRectangle8} />
                </div>
              </div>
            ))}
            {educationInfo.length === 0 && (
              <span className={styles.ReviewItemLabel}>
                There are no relevant education details for this job profile. To
                add new details, please click 'Edit'.
              </span>
            )}
          </div>
        </div>
        <div className={styles.ExperienceSection}>
          <div className={styles.QualificationsFrame}>
            <div className={styles.Qualifications}>Experience</div>
            <div
              className={styles.EditHRJD}
              onClick={() => {
                setOpenContentPage("Experience");
              }}
            >
              <div className={styles.ReviewIconLeft}>
                <div className={styles.ReviewIcon}></div>
              </div>
              <div className={styles.EditDivLabel}>
                <div className={styles.EditLabel}>Edit</div>
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            {experienceInfo.map((item: IexperienceInfoType) => (
              <div>
                <div className={styles.ReviewItemRow}>
                  <div className={styles.ReviewItemQualificationInputs}>
                    <div className={styles.ReviewQualificationExperience}>
                      <div className={styles.ReviewItemRow1}>
                        <div className={styles.ReviewItemLabel}>Experience</div>
                        <div className={styles.ReviewItemInput}>
                          {item.experience}
                        </div>
                      </div>
                      <div className={styles.EducationToggle}>
                        <Toggle
                          title="Required/Preferred"
                          label="Required"
                          checked={item.requirementLevel == "Required"}
                          inlineLabel
                          styles={educationReqdStyles}
                        />
                      </div>
                    </div>
                    <div className={styles.ReviewItemRow2}>
                      <div className={styles.ReviewItemLabel}>
                        Experience Details
                      </div>
                      <div className={styles.ReviewItemInput}>
                        {item.experienceTime}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.ReviewItemDividers}>
                  <div className={styles.ReviewItemRectangle8} />
                </div>
              </div>
            ))}
            {experienceInfo.length === 0 && (
              <span className={styles.ReviewItemLabel}>
                There are no relevant experience requirements for this job
                profile. To add new details, please click 'Edit'.
              </span>
            )}
          </div>
        </div>
        <div className={styles.KnowledgeSection}>
          <div className={styles.KnowledgeFrame}>
            <div className={styles.Knowledge}>Knowledge</div>
            <div
              className={styles.EditHRJD}
              onClick={() => {
                setOpenContentPage("Knowledge");
              }}
            >
              <div className={styles.ReviewIconLeft}>
                <div className={styles.ReviewIcon}></div>
              </div>
              <div className={styles.EditDivLabel}>
                <div className={styles.EditLabel}>Edit</div>
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            {KSASInfo.map((knowledge: IksasType) => (
              <div>
                <div className={styles.ReviewItemRow}>
                  <div className={styles.ReviewItemQualificationInputs}>
                    <div className={styles.ReviewQualificationExperience}>
                      <div className={styles.ReviewItemRow1}>
                        <div className={styles.ReviewItemLabel}>
                          Knowledge, Skill, Ability
                        </div>
                        <div className={styles.ReviewItemInput}>
                          {knowledge.knowledgeSkillOrAbility}
                        </div>
                      </div>
                      <div className={styles.EducationToggle}>
                        <Toggle
                          title="Required/Preferred"
                          label="Required"
                          checked={knowledge.requirementLevel === "Required"}
                          inlineLabel
                          styles={educationReqdStyles}
                        />
                      </div>
                    </div>
                    <div className={styles.ReviewItemRow2}>
                      <div className={styles.ReviewItemLabel}>Description</div>
                      <div className={styles.ReviewItemInput}>
                        {knowledge.details}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.ReviewItemDividers}>
                  <div className={styles.ReviewItemRectangle8} />
                </div>
              </div>
            ))}
            {KSASInfo.length === 0 && (
              <span className={styles.ReviewItemLabel}>
                There are no relevant knowledge details for this job profile. To
                add new details, please click 'Edit'.
              </span>
            )}
          </div>
        </div>
        <div className={styles.CertificationsSection}>
          <div className={styles.CertificationsFrame}>
            <div className={styles.Certifications}>Certifications</div>
            <div
              className={styles.EditHRJD}
              onClick={() => {
                setOpenContentPage("Certifications");
              }}
            >
              <div className={styles.ReviewIconLeft}>
                <div className={styles.ReviewIcon}></div>
              </div>
              <div className={styles.EditDivLabel}>
                <div className={styles.EditLabel}>Edit</div>
              </div>
            </div>
          </div>
          <div className={styles.SectionFullWidth}>
            {licenseOrCertificationInfo.map(
              (item: IlicenseOrCertificationType) => (
                <div>
                  <div className={styles.ReviewItemRow}>
                    <div className={styles.ReviewItemQualificationInputs}>
                      <div className={styles.ReviewQualificationExperience}>
                        <div className={styles.ReviewItemRow1}>
                          <div className={styles.ReviewItemLabel}>
                            License/Certifications
                          </div>
                          <div className={styles.ReviewItemInput}>
                            {item.licenseOrCertification}
                          </div>
                        </div>
                        <div className={styles.EducationToggle}>
                          <Toggle
                            title="Required/Preferred"
                            label="Required"
                            checked={item.requirementLevel == "Required"}
                            inlineLabel
                            styles={educationReqdStyles}
                          />
                        </div>
                      </div>
                      <div className={styles.ReviewItemRow2}>
                        <div className={styles.ReviewItemLabel}>
                          Description
                        </div>
                        <div className={styles.ReviewItemInput}>
                          {item.details}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.ReviewItemDividers}>
                    <div className={styles.ReviewItemRectangle8} />
                  </div>
                </div>
              )
            )}
            {licenseOrCertificationInfo.length === 0 && (
              <span className={styles.ReviewItemLabel}>
                There are no relevant license details for this job profile. To
                add new details, please click 'Edit'.
              </span>
            )}
          </div>
        </div>
        <Stack
          className={styles.panel1}
          enableScopedSelectors
          tokens={outerStackTokens}
        >
          <Stack enableScopedSelectors tokens={innerStackTokens}>
            <Stack.Item grow className={styles.StackButtonsContainer}>
              {/* <DefaultButton onClick={() => { onDownloadClicked() }} className={styles.HRPrimaryButton}>Download Job Description</DefaultButton> */}
              <MailMergeComponent
                jsondata={{
                  job_summary: jobsummary,
                  job_profile_name: jobProfileName,
                  job_family_group: selectedJobFamilyGrp?.text || "",
                  job_family: selectedJobFamily?.text || "",
                  job_career_band: selectedJobCareerBand?.key as string,
                  educationInfo: educationInfo,
                  experienceInfo: experienceInfo,
                  KSASInfo: KSASInfo,
                  licenseOrCertificationInfo: licenseOrCertificationInfo,
                  responsibilities: jobResponsibilities,
                }}
              />
            </Stack.Item>
            <Stack.Item grow className={styles.StackButtonsContainer}>
              <DefaultButton
                onClick={() => {
                  setOpenContentPage("Certifications");
                }}
                className={styles.HRBackButton}
              >
                <div className={styles.PreviousIconContainer}>
                  <span>Previous</span>
                  <span className={styles.PreviousIcon}></span>
                </div>
              </DefaultButton>
            </Stack.Item>
          </Stack>
        </Stack>
      </div>
    );
  };

  const processblobs = (loadedblobs: any) => {
    const files = [];
    const blobnames = [];
    const indexType = [];
    const blobs = loadedblobs;

    for (const blob of blobs.values) {
      if (blob.embedded == "true") {
        files.push({
          text: blob.indexName,
          key: blob.namespace,
        });
        blobnames.push({
          text: blob.name,
          key: blob.name,
        });
        indexType.push({
          name: blob.name,
          key: blob.namespace,
          iType: blob.indexType,
          indexName: blob.indexName,
          namespace: blob.namespace,
          groupName: blob.groupName,
          owner: blob.owner,
          qa: blob.qa,
          summary: blob.summary,
          indexType: blob.indexType,
          textSpitterType: blob.textSpitterType,
          chunkSize: blob.chunkSize,
          promptType: blob.promptType || "custom",
          singleFile: blob.singleFile,
          chunkOverlap: blob.chunkOverlap,
          job_profile_name: blob.job_profile_name,
          job_family_grp: blob.job_family_grp,
          job_family: blob.job_family,
          job_career_band: blob.job_career_band,
          job_summary: blob.job_summary,
          job_details_optional: "",
        });
      }
    }

    //  there will be only 1 file
    var uniqFilesbykey = files.filter(
      (v, i, a) => a.findIndex((v2) => v2.key === v.key) === i
    );

    //  This is for global setting for HRHome
    setBlobOptions(uniqFilesbykey);
    setBlobsCount(uniqFilesbykey.length);
    setSelectedItemHRJD(uniqFilesbykey[0]);

    //  set all files for currentindex namespace
    var uniqblobsbyname = blobnames.filter(
      (v, i, a) => a.findIndex((v2) => v2.key === v.key) === i
    );
    setBlobNamesOptions(uniqblobsbyname);
    setBlobNamesCount(uniqblobsbyname.length);

    //  store all blobs with metadata into state.
    setAllIndexMappingHRJD(indexType);

    //  setting up the panel
    var defaultKey = "";
    if (uniqFilesbykey.length > 0) {
      defaultKey = uniqFilesbykey[0].key;
      var uniqIndexType = indexType.filter(
        (v, i, a) => a.findIndex((v2) => v2.key === v.key) === i
      );
      for (const item of uniqIndexType) {
        if (item.key == defaultKey) {
          setSelectedIndexHRJD(item.iType);
          setSelectedChunkOverlapHRJD(item.chunkOverlap);
          setSelectedChunkSizeHRJD(item.chunkSize);
          setSelectedPromptTypeHRJD(item.promptType);
          setSelectedDeploymentTypeHRJD(deploymentTypeOptions[0]);
        }
      }
      setIndexMappingHRJD(uniqIndexType);
    }
    setIsBlobsLoaded(true);
  };

  const refreshBlob = async () => {
    const blobs1 = sessionStorage.getItem("HRJDblobs");
    const userRoles = JSON.parse(
      sessionStorage.getItem("userRoles") || "[]"
    ) as string[];
    if (blobs1 != null && blobs1 != undefined) {
      const blobs = JSON.parse(blobs1);
      if (blobs.values != null && blobs.values.length > 0) processblobs(blobs);
      else {
        const blobs = await HRJDRefreshIndex(userRoles || []);
        if (blobs != "Error") {
          sessionStorage.setItem("HRJDblobs", JSON.stringify(blobs));
          processblobs(blobs);
        }
      }
    } else {
      const blobs = await HRJDRefreshIndex(userRoles || []);
      if (blobs != "Error") {
        sessionStorage.setItem("HRJDblobs", JSON.stringify(blobs));
        processblobs(blobs);
      }
    }
  };

  const reLoadBlobs = async () => {
    //  Remove blobs from session storage and state
    sessionStorage.removeItem("HRJDblobs");
    try {
      await HRJDClearblobsIndex(userGroups || []);
    } catch (exception) {
      // console.log(exception);
      //  and continue
    }

    try {
      const blobs = await HRJDRefreshIndex(userGroups || []);
      //  if not error than set the blobs in session storage and state
      if (blobs != "Error") {
        sessionStorage.setItem("HRJDblobs", JSON.stringify(blobs));
        processblobs(blobs);
      }
    } catch (exception) {
      // console.log(exception);
      //  and continue
    }
  };

  const createNewJDClicked = () => {
    setModifyJD(false);
    setIsJobSummaryReady(false);
    clearDetails();
    setSelectedBlobItemHRJD({ key: "", text: "" });
    setOpenCreateNewJD(true);
  };

  const modifyJDClicked = () => {
    setOpenCreateNewJD(false);
    setIsJobSummaryReady(false);
    clearDetails();
    setSelectedBlobItemHRJD({ key: "", text: "" });
    setModifyJD(true);
  };

  const onGenerateJobDescClicked = (text: string) => {
    if (
      (text === 'openCreateNewJD' ? jobProfileName : selectedBlobItemHRJD) &&
      selectedJobFamily &&
      selectedJobFamily?.text.trim().length > 0 &&
      selectedJobFamilyGrp &&
      selectedJobFamilyGrp?.text.trim().length > 0
    ) {
      if(text === 'openCreateNewJD') setMissingJobProfileName(false);
      else if (text === "modifyExistingJD") setMissingExistingJD(false);
      setMissingJobFamily(false);
      setMissingJobFamilyGrp(false);

      setIsJobSummaryLoading(true);
      setGeneratingJDPanelOpen(true);
      if (openModifyJD) {
        setIsContentGenerated(false);
      }
      makeApiRequestHRJDNew();
    } else {
      if (jobProfileName === "") {
        setMissingJobProfileName(true);
      }
      if(selectedBlobItemHRJD?.key === ""){
        setMissingExistingJD(true);
      }
      if (!selectedJobFamilyGrp || selectedJobFamilyGrp.text === "") {
        setMissingJobFamilyGrp(true);
      }
      if (!selectedJobFamily || selectedJobFamily.text === "") {
        setMissingJobFamily(true);
      }
    }
  };

  const onCustomizePromptsClicked = async () => {
    setOpenContentPage("CustomizePrompts");
  };

  const onCustomizeSystemPromptClicked = async () => {
    setOpenContentPage("CustomizeSystemPrompt");
  };
  const onCustomizeCogSearchPromptsClicked = async () => {
    setOpenContentPage("CustomizeCogSearchPrompts");
  };

  const proceedToNextItem = () => {
    if (clickedItem === "create_new") createNewJDClicked();
    else if (clickedItem === "modify_existing") modifyJDClicked();
    setClickedItem("");
    setWarningPanelOpen(false);
  };

  const onExistingBlobNameChange = (
    event?: React.FormEvent<HTMLDivElement> | null,
    item?: IDropdownOption
  ): void => {
    setSummaryFeedback(""); // Clear job summary feedback if blobname is changed. 
    setResponsibilitiesFeedback("");
    setEducationFeedback("");
    setExperienceFeedback("");
    setKnowledgeFeedback("");
    setCertificationFeedback("");
    setMissingExistingJD(false); // Set missing Existing JD to false
    setSelectedBlobItemHRJD(item);
    setIsContentGenerated(false);
    let blobname = item?.key;

    //  for the changed item. get the metadata and update the values for job profile, family grp, family, career band , optional values and Job Summary
    var uniqIndexType = allindexMappingHRJD?.filter(
      (v, i, a) => a.findIndex((v2) => v2.name === blobname) === i
    )[0];
    setJobProfileName(uniqIndexType?.job_profile_name);
    if(uniqIndexType?.job_profile_name) setMissingJobProfileName(false);

    if (uniqIndexType?.job_family_grp) {
      let job_family_item = job_family.filter(
        (v, i, a) =>
          a.findIndex((v2) => v2.key === uniqIndexType?.job_family_grp) === i
      )[0];
      setSelectedJobFamilyGrp(job_family_item);
      let x = job_family_item?.key as string;
      if (x !== undefined && x !== "") {
        const jobs = findJobFamilyByName(x);
        setdynamicOptions(jobs);
        let job_experience_item = jobs?.filter(
          (v, i, a) =>
            a.findIndex((v2) => v2.key === uniqIndexType?.job_family) === i
        )[0];
        setSelectedJobFamily(job_experience_item);
      }
    } else {
      setSelectedJobFamilyGrp({ key: "", text: "" });
      setSelectedJobFamily({ key: "", text: "" });
    }
    if (uniqIndexType?.job_career_band) {
      let career_bands_item = career_bands.filter(
        (v, i, a) =>
          a.findIndex((v2) => v2.key === uniqIndexType?.job_career_band) === i
      )[0];
      setSelectedJobCareerBand(career_bands_item);
    } else {
      setSelectedJobCareerBand({ key: "", text: "" });
    }
    if (uniqIndexType?.job_details_optional) {
      setAddlJobDetails(uniqIndexType?.job_details_optional);
    } else {
      setAddlJobDetails("");
    }
    if (uniqIndexType?.job_summary) {
      setJobSummary(uniqIndexType?.job_summary);
      setPrimaryFillColor("white");
      setIsJobSummaryReady(true);
      setGeneratingJDPanelOpen(false);
    } else {
      setJobSummary("");
      setIsJobSummaryReady(false);
    }
  };

  const onJobFamilyGrpChange = (
    event?: React.FormEvent<HTMLDivElement> | null,
    item?: IDropdownOption
  ): void => {
    setSelectedJobFamilyGrp(item);
    setMissingJobFamilyGrp(false);
    setSelectedJobFamily({ key: "", text: "" });
  };

  const onJobFamilyChange = (
    event?: React.FormEvent<HTMLDivElement> | null,
    item?: IDropdownOption
  ): void => {
    setSelectedJobFamily(item);
    setMissingJobFamily(false);
  };

  const onJobCareerBandChange = (
    event?: React.FormEvent<HTMLDivElement> | null,
    item?: IDropdownOption
  ): void => {
    setSelectedJobCareerBand(item);
  };

  const onJobDetailsChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    newValue !== undefined
      ? setAddlJobDetails(newValue)
      : setAddlJobDetails("");
  };

  const onJobSummaryChange = (
    event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue && newValue !== "") setMissingJobSummary(false);
    newValue != undefined ? setJobSummary(newValue) : setJobSummary("");
  };

  const onChangeJobProfileName = (
    ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    newValue !== undefined
      ? setJobProfileName(newValue)
      : setJobProfileName("");
    if (newValue === "") {
      setMissingJobProfileName(true);
    } else {
      setMissingJobProfileName(false);
    }
  };
  const onChangeHRJDDocs = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedItemHRJD(item);
    const defaultKey = item?.key;
    let indexType = "cogsearchvs";
    indexMappingHRJD?.findIndex((item) => {
      if (item.key == defaultKey) {
        indexType = item.iType;
        setSelectedIndexHRJD(item.iType);
        setSelectedChunkSizeHRJD(item.chunkSize);
        setSelectedChunkOverlapHRJD(item.chunkOverlap);
        setSelectedPromptTypeHRJD(item.promptType);
        setSelectedPromptTypeItemHRJD(
          promptTypeOptionsChatGpt.find((x) => x.key === item.promptType)
        );
        setSelectedDeploymentTypeHRJD(deploymentTypeOptions[0]);
      }
    });
  };



  const onTemperatureChangeHRJD = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTemperatureHRJD(parseInt(newValue || "0.3"));
  };

  const onTokenLengthChangeHRJD = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setTokenLengthHRJD(parseInt(newValue || "500"));
  };

  const onChainChangeHRJD = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedChainHRJD(item);
  };

  const onDeploymentTypeChangeHRJD = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedDeploymentTypeHRJD(item);
  };

  const onEmbeddingChangeHRJD = (
    event?: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedEmbeddingItemHRJD(item);
  };

  useEffect(() => {
    const percentOfTimeAllocated = (jobResponsibilities || []).reduce(
      (sumOfAllocation: number, curr: IjobresponsibilityType) => {
        return (
          sumOfAllocation +
          (curr && curr.percentOfTime ? parseInt(curr?.percentOfTime) : 0)
        );
      },
      0
    );
    setTotalAllocationPercentage(percentOfTimeAllocated);
  }, [jobResponsibilities]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && instance) {
      const account: AccountInfo | null = instance.getActiveAccount();
      setUserName(account?.username);
      if (sessionStorage.getItem("userRoles")) {
        const ur = JSON.parse(
          sessionStorage.getItem("userRoles") || "[]"
        ) as string[];
        setUserGroups(ur);
        GetUseraccessRoles();
      }
    }
  }, [inProgress, instance]);

  useEffect(() => {
    if (userName && userGroups) {
      refreshBlob();
    }
  }, [userName, userGroups]);

  useEffect(() => {
    setOpenContentPage("JobDetails");
    setBlobOptions([]);
    setBlobNamesOptions([]);
    setdynamicOptions(job_family_details["Administrative Services"]);
    setChainTypeOptionsHRJD(chainType);
    setSelectedEmbeddingItemHRJD(embeddingOptions[0]);
    setSelectedDeploymentTypeHRJD(deploymentTypeOptions[0]);
  }, []);

  useEffect(() => {
    let x = selectedJobFamilyGrp?.key as string;
    if (x !== undefined && x !== "") {
      const jobs = findJobFamilyByName(x);
      setdynamicOptions(jobs);
    }
  }, [selectedJobFamilyGrp]);

  useEffect(() => {
    setIsHRConfigPanel(context.isHRConfigPanel);
    setIsHRCustomizationPanel(context.isHRCustomizationPanel);
  }, [context]);

  const checkFeedbackPanelOpen = (panelname: string) => {
    if (isSectionGenerating) {
      switch (panelname) {
      case "JD":
        setReviseJDPanelOpen(true);
        return;
      case "JR":
        setReviseResponsibilitiesPanelOpen(true);
        return;
      }
    } else {
      switch (panelname) {
      case "JD":
        setReviseJDPanelOpen(false);
        return;
      case "JR":
        setReviseResponsibilitiesPanelOpen(false);
        return;
      }
    }
  };

  return (
    <>
      <AuthenticatedTemplate>
        {isGenericAdmin || isGroupAdmin || isGroupUser ? (
          <div className={styles.ParentHRHome}>
            <div className={styles.PageHeading}>
              {
                (openContentPage === "CustomizePrompts" || openContentPage === "CustomizeSystemPrompt" || openContentPage === "CustomizeCogSearchPrompts") &&
                (
                  <div className={styles.HRHomeHeading} onClick={() => { setOpenContentPage("JobDetails"); }} title="Go to HR JD" >
                    <div className={styles.hrHome} /> </div>
                )
              }
              <div className={styles.JobGenHeading}>
                <div className={styles.JobDescriptionGenerator}>
                  Magenta Job Description Generator
                </div>
                <div
                  className={styles.EffortlessAiPoweredJobDescriptionsInMinutes}
                >
                  Effortless AI-powered job descriptions in minutes.
                </div>
              </div>
              {
                openContentPage === "CustomizePrompts" &&
                (
                  <div className={styles.HeadingGap} />
                )
              }
            </div>
            <div className={styles.HomeDesktopCreateNewOrModifyExisting}>
              <div className={styles.BillGreetingAndCard}>
                {openContentPage === "JobDetails" && displayJobDetailPage()}
                {openContentPage === "Responsibilities" && displayResponsibilitiesPage()}
                {openContentPage === "Education" && displayEducationPage()}
                {openContentPage === "Experience" && displayExperiencePage()}
                {openContentPage === "Knowledge" && displayKnowledgePage()}
                {openContentPage === "Certifications" && displayCertificationsPage()}
                {openContentPage === "ReviewPage" && displayReviewPage()}
                {openContentPage === "CustomizePrompts" && (<CustomizePrompts canSavePrompt={isGenericAdmin || isGroupAdmin} />)}
                {openContentPage === "CustomizeSystemPrompt" && (<CustomizeSystemPrompt canSavePrompt={isGenericAdmin || isGroupAdmin} />)}
                {openContentPage === "CustomizeCogSearchPrompts" && (<CustomizeCogSearchPrompts canSavePrompt={isGenericAdmin || isGroupAdmin} />)}

              </div>
              <Panel
                styles={panelStyles}
                headerText="Customize HR Job Description"
                isOpen={isHRCustomizationPanel}
                isLightDismiss={true}
                hasCloseButton={true}
                onDismiss={() => context.onHRCustomizationOpen(false)}
                closeButtonAriaLabel="Close"
              >
                <DefaultButton onClick={onCustomizePromptsClicked} className={styles.customizePromptsButton} > Customize Prompts </DefaultButton>
                <DefaultButton onClick={onCustomizeSystemPromptClicked} className={styles.customizePromptsButton} > Customize System Prompts </DefaultButton>
                <DefaultButton onClick={onCustomizeCogSearchPromptsClicked} className={styles.customizePromptsButton} > Customize Index Search Prompts </DefaultButton>
              </Panel>
              <Panel
                styles={panelStyles}
                headerText="Configure HR Job Description"
                isOpen={isHRConfigPanel}
                isLightDismiss={true}
                hasCloseButton={true}
                onDismiss={() => context.onHRConfigOpen(false)}
                closeButtonAriaLabel="Close"
              >
                <br />

                <div>
                  <DefaultButton
                    onClick={reLoadBlobs}
                    className={styles.refreshDocsButton}
                  >
                    Refresh Docs
                  </DefaultButton>
                  <Dropdown
                    selectedKey={
                      selectedItemHRJD ? selectedItemHRJD.key : bloboptions[0]
                    }
                    onChange={onChangeHRJDDocs}
                    placeholder="Select a PDF"
                    options={bloboptions}
                    styles={dropdownStyles}
                  />
                  &nbsp;
                  <Label className={styles.commandsContainer}>
                    Index Type : {selectedIndexHRJD}{" "}
                  </Label>
                  <Label className={styles.commandsContainer}>
                    Chunk Size : {selectedChunkSizeHRJD} / Chunk Overlap :{" "}
                    {selectedChunkOverlapHRJD}
                  </Label>
                </div>
                <div>
                  <Label>LLM Model</Label>
                  <Dropdown
                    selectedKey={
                      selectedEmbeddingItemHRJD
                        ? selectedEmbeddingItemHRJD.key
                        : embeddingOptions[0].key
                    }
                    onChange={onEmbeddingChangeHRJD}
                    placeholder="Select LLM Model"
                    options={embeddingOptions}
                    disabled={false}
                    styles={dropdownStyles}
                  />
                </div>
                <div>
                  <Label>Deployment Type</Label>
                  <Dropdown
                    selectedKey={
                      selectedDeploymentTypeHRJD
                        ? selectedDeploymentTypeHRJD.key
                        : deploymentTypeOptions[0].key
                    }
                    onChange={onDeploymentTypeChangeHRJD}
                    placeholder="Select Deployment Type"
                    options={deploymentTypeOptions}
                    disabled={
                      (selectedEmbeddingItemHRJD?.key == "openai"
                        ? true
                        : false) ||
                      (Number(selectedChunkSizeHRJD) > 4000 ? true : false)
                    }
                    styles={dropdownStyles}
                  />
                </div>
                <SpinButton
                  className={styles.oneshotSettingsSeparator}
                  label="Set the Temperature:"
                  min={0.0}
                  max={1.0}
                  defaultValue={temperatureHRJD.toString()}
                  onChange={onTemperatureChangeHRJD}
                />
                <SpinButton
                  className={styles.oneshotSettingsSeparator}
                  label="Max Length (Tokens):"
                  min={0}
                  max={4000}
                  defaultValue={tokenLengthHRJD.toString()}
                  onChange={onTokenLengthChangeHRJD}
                />
                <Dropdown
                  label="Chain Type"
                  onChange={onChainChangeHRJD}
                  selectedKey={
                    selectedChainHRJD ? selectedChainHRJD.key : "stuff"
                  }
                  options={chainTypeOptionsHRJD}
                  styles={dropdownStyles}
                />
              </Panel>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={generatingJDPanelOpen}
                isBlocking={true}
                onDismiss={() => setGeneratingJDPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div
                    className="GeneratingYourJobDescription"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <div
                      style={{
                        color: "#E20074",
                        fontSize: "16px",
                        fontWeight: "590",
                        wordWrap: "break-word",
                      }}
                    >
                      <p className={styles.width100}>
                        <span>
                          <SparkleFilled
                            fontSize={"18px"}
                            primaryFill={"#E20074"}
                            aria-hidden="true"
                            aria-label="HR JD Logo"
                          />
                        </span>
                        <span className={styles.generating}>
                          {" "}
                          Generation In Progress
                        </span>
                      </p>
                    </div>
                    <div className={styles.SpinnerParent}>
                      <Spinner
                        styles={{
                          circle: {
                            borderColor: "#e20074 #eeeeee #e20074 #e20074",
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "450",
                        wordWrap: "break-word",
                        textAlign: "left",
                        padding: "16px",
                      }}
                    >
                      <p className={styles.InterimStatus}>
                        <span className={styles.generating}>
                          {" "}
                          Generating Summary{" "}
                        </span>
                        <span className={styles.loadingdots}></span>
                      </p>
                      <p className={styles.InterimStatus}>
                        It may take a few moments. Please wait.
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={generatingJRPanelOpen}
                isBlocking={true}
                onDismiss={() => setGeneratingJRPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div
                    className="GeneratingYourJobDescription"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <div
                      style={{
                        color: "#E20074",
                        fontSize: "16px",
                        fontWeight: "590",
                        wordWrap: "break-word",
                      }}
                    >
                      <p className={styles.width100}>
                        <span>
                          <SparkleFilled
                            fontSize={"18px"}
                            primaryFill={"#E20074"}
                            aria-hidden="true"
                            aria-label="HR JD Logo"
                          />
                        </span>
                        <span className={styles.generating}>
                          {" "}
                          Generation In Progress
                        </span>
                      </p>
                    </div>
                    <div className={styles.SpinnerParent}>
                      <Spinner
                        styles={{
                          circle: {
                            borderColor: "#e20074 #eeeeee #e20074 #e20074",
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "450",
                        wordWrap: "break-word",
                        textAlign: "left",
                        padding: "16px",
                      }}
                    >
                      <p className={styles.InterimStatus}>
                        <span className={styles.generating}>
                          {" "}
                          Generating Responsibilities
                        </span>
                        <span className={styles.loadingdots}></span>
                      </p>
                      <p className={styles.InterimStatus}>
                        {" "}
                        It may take a few moments. Please wait.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={generatingEDUPanelOpen}
                isBlocking={true}
                onDismiss={() => setGeneratingEDUPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div
                    className="GeneratingYourJobDescription"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <div
                      style={{
                        color: "#E20074",
                        fontSize: "16px",
                        fontWeight: "590",
                        wordWrap: "break-word",
                      }}
                    >
                      <p className={styles.width100}>
                        <span>
                          <SparkleFilled
                            fontSize={"18px"}
                            primaryFill={"#E20074"}
                            aria-hidden="true"
                            aria-label="HR JD Logo"
                          />
                        </span>
                        <span className={styles.generating}>
                          {" "}
                          Generation In Progress
                        </span>
                      </p>
                    </div>
                    <div className={styles.SpinnerParent}>
                      <Spinner
                        styles={{
                          circle: {
                            borderColor: "#e20074 #eeeeee #e20074 #e20074",
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "450",
                        wordWrap: "break-word",
                        textAlign: "left",
                        padding: "16px",
                      }}
                    >
                      <p className={styles.InterimStatus}>
                        <span className={styles.generating}>
                          {" "}
                          Generating Education Details
                        </span>
                        <span className={styles.loadingdots}></span>
                      </p>
                      <p className={styles.InterimStatus}>
                        {" "}
                        It may take a few moments. Please wait.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={generatingEXPPanelOpen}
                isBlocking={true}
                onDismiss={() => setGeneratingEXPPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div
                    className="GeneratingYourJobDescription"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <div
                      style={{
                        color: "#E20074",
                        fontSize: "16px",
                        fontWeight: "590",
                        wordWrap: "break-word",
                      }}
                    >
                      <p className={styles.width100}>
                        <span>
                          <SparkleFilled
                            fontSize={"18px"}
                            primaryFill={"#E20074"}
                            aria-hidden="true"
                            aria-label="HR JD Logo"
                          />
                        </span>
                        <span className={styles.generating}>
                          {" "}
                          Generation In Progress
                        </span>
                      </p>
                    </div>
                    <div className={styles.SpinnerParent}>
                      <Spinner
                        styles={{
                          circle: {
                            borderColor: "#e20074 #eeeeee #e20074 #e20074",
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "450",
                        wordWrap: "break-word",
                        textAlign: "left",
                        padding: "16px",
                      }}
                    >
                      <p className={styles.InterimStatus}>
                        <span className={styles.generating}>
                          {" "}
                          Generating Experience Details
                        </span>
                        <span className={styles.loadingdots}></span>
                      </p>
                      <p className={styles.InterimStatus}>
                        {" "}
                        It may take a few moments. Please wait.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={generatingKSPanelOpen}
                isBlocking={true}
                onDismiss={() => setGeneratingKSPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div
                    className="GeneratingYourJobDescription"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <div
                      style={{
                        color: "#E20074",
                        fontSize: "16px",
                        fontWeight: "590",
                        wordWrap: "break-word",
                      }}
                    >
                      <p className={styles.width100}>
                        <span>
                          <SparkleFilled
                            fontSize={"18px"}
                            primaryFill={"#E20074"}
                            aria-hidden="true"
                            aria-label="HR JD Logo"
                          />
                        </span>
                        <span className={styles.generating}>
                          {" "}
                          Generation In Progress
                        </span>
                      </p>
                    </div>
                    <div className={styles.SpinnerParent}>
                      <Spinner
                        styles={{
                          circle: {
                            borderColor: "#e20074 #eeeeee #e20074 #e20074",
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "450",
                        wordWrap: "break-word",
                        textAlign: "left",
                        padding: "16px",
                      }}
                    >
                      <p className={styles.InterimStatus}>
                        <span className={styles.generating}>
                          {" "}
                          Generating Knowledge, Skills and Abilities
                        </span>
                        <span className={styles.loadingdots}></span>
                      </p>
                      <p className={styles.InterimStatus}>
                        {" "}
                        It may take a few moments. Please wait.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={generatingLCPanelOpen}
                isBlocking={true}
                onDismiss={() => setGeneratingLCPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div
                    className="GeneratingYourJobDescription"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <div
                      style={{
                        color: "#E20074",
                        fontSize: "16px",
                        fontWeight: "590",
                        wordWrap: "break-word",
                      }}
                    >
                      <p className={styles.width100}>
                        <span>
                          <SparkleFilled
                            fontSize={"18px"}
                            primaryFill={"#E20074"}
                            aria-hidden="true"
                            aria-label="HR JD Logo"
                          />
                        </span>
                        <span className={styles.generating}>
                          {" "}
                          Generation In Progress
                        </span>
                      </p>
                    </div>
                    <div className={styles.SpinnerParent}>
                      <Spinner
                        styles={{
                          circle: {
                            borderColor: "#e20074 #eeeeee #e20074 #e20074",
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "450",
                        wordWrap: "break-word",
                        textAlign: "left",
                        padding: "16px",
                      }}
                    >
                      <p className={styles.InterimStatus}>
                        <span className={styles.generating}>
                          {" "}
                          Generating License or Certifications Details
                        </span>
                        <span className={styles.loadingdots}></span>
                      </p>
                      <p className={styles.InterimStatus}>
                        {" "}
                        It may take a few moments. Please wait.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={isReviseJDPanelOpen}
                isBlocking={true}
                onDismiss={() => checkFeedbackPanelOpen("JD")}
              >
                <div className={styles.ReviseJD}>
                  <div className={styles.ReviseDescriptionHeading}>
                    <div>Regenerate Job Summary</div>
                    <div>
                      <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={{ root: { color: "black" } }}
                        ariaLabel="Close"
                        onClick={() => checkFeedbackPanelOpen("JD")}
                      />
                    </div>
                  </div>
                  <div className={styles.ReviseJDDescription}>
                    <span>
                      You can use any natural language to provide specific
                      suggestions on what you would like to improve, and we'll
                      generate a new description, for example:
                      <br />
                      <br />
                    </span>
                    <span>
                      Include fewer marketing terms.
                      <br />
                      Do not use the words ‘Job Family Group’.
                      <br />
                      Could you add more technical skills?
                      <br />
                      Include information about owning the backend platform.
                    </span>
                  </div>
                  <div className={styles.Feedback}>
                    <Label className={styles.labelname}>
                      Feedback:<span className={styles.FieldMandatory}>*</span>
                    </Label>
                    <TextField
                      onChange={summaryFeedbackChange}
                      multiline
                      autoAdjustHeight
                      styles={{ root: { minWidth: "80% " } }}
                      value={summaryFeedback}
                      placeholder="Improve this internal job summary by ..."
                      errorMessage={
                        !missingJDFeedback
                          ? ""
                          : "Please provide feedback to improve job description."
                      }
                    />
                  </div>
                  <div className={styles.GenerateDesc}>
                    <DefaultButton
                      onClick={generateNewJDPostFeedback}
                      className={styles.HRPrimaryButton}
                      onMouseEnter={() => setSecondaryFillColor("black")}
                      onMouseLeave={() => setSecondaryFillColor("white")}
                    >
                      {isSectionGenerating ? (
                        <p className={styles.width100}>
                          <span>
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span className={styles.generating}>
                            Regenerating Summary
                          </span>
                          <span className={styles.loadingdots}></span>
                        </p>
                      ) : (
                        <p className={styles.width100}>
                          <span>
                            {" "}
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span> Generate New Summary</span>
                        </p>
                      )}
                    </DefaultButton>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={isReviseResponsibilitiesPanelOpen}
                isBlocking={true}
                onDismiss={() => checkFeedbackPanelOpen("JR")}
              >
                <div className={styles.ReviseJD}>
                  <div className={styles.ReviseDescriptionHeading}>
                    <div>Regenerate Responsibilities</div>
                    <div>
                      <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={{ root: { color: "black" } }}
                        ariaLabel="Close"
                        onClick={() => checkFeedbackPanelOpen("JR")}
                      />
                    </div>
                  </div>
                  <div className={styles.ReviseJDDescription}>
                    <span>
                      You can use any natural language to provide specific
                      suggestions on what you would like to improve, and we'll
                      generate a new description, for example:
                      <br />
                      <br />
                    </span>
                    <span>
                      Include fewer marketing terms.
                      <br />
                      Do not use the words ‘Job Family Group’.
                      <br />
                      Could you add more technical skills?
                      <br />
                      Include information about owning the backend platform.
                    </span>
                  </div>
                  <div className={styles.Feedback}>
                    <Label className={styles.labelname}>
                      Feedback:<span className={styles.FieldMandatory}>*</span>
                    </Label>
                    <TextField
                      onChange={responsibilityFeedbackChange}
                      multiline
                      autoAdjustHeight
                      styles={{ root: { minWidth: "80% " } }}
                      value={responsibilitiesFeedback}
                      placeholder="Improve the responsibilities by ..."
                      errorMessage={
                        !missingResponsibilitiesFeedback
                          ? ""
                          : "Please provide feedback to improve responsibilities."
                      }
                    />
                  </div>
                  <div className={styles.GenerateDesc}>
                    <DefaultButton
                      onClick={generateResponsibilitiesPostFeedback}
                      className={styles.HRPrimaryButton}
                      onMouseEnter={() => setSecondaryFillColor("black")}
                      onMouseLeave={() => setSecondaryFillColor("white")}
                    >
                      {isSectionGenerating ? (
                        <p className={styles.width100}>
                          <span>
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span className={styles.generating}>
                            Regenerating Responsibilities
                          </span>
                          <span className={styles.loadingdots}></span>
                        </p>
                      ) : (
                        <p className={styles.width100}>
                          <span>
                            {" "}
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span>Generate Responsibilities</span>
                        </p>
                      )}
                    </DefaultButton>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={isReviseEducationPanelOpen}
                isBlocking={true}
                onDismiss={() => setReviseEducationPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div className={styles.ReviseDescriptionHeading}>
                    <div>Regenerate Education</div>
                    <div>
                      <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={{ root: { color: "black" } }}
                        ariaLabel="Close"
                        onClick={() => setReviseEducationPanelOpen(false)}
                      />
                    </div>
                  </div>
                  <div className={styles.ReviseJDDescription}>
                    <span>
                      You can use any natural language to provide specific
                      suggestions on what you would like to improve, and we'll
                      generate a new description, for example:
                      <br />
                      <br />
                    </span>
                    <span>
                      Include fewer marketing terms.
                      <br />
                      Do not use the words ‘Job Family Group’.
                      <br />
                      Could you add more technical skills?
                      <br />
                      Include information about owning the backend platform.
                    </span>
                  </div>
                  <div className={styles.Feedback}>
                    <Label className={styles.labelname}>
                      Feedback:<span className={styles.FieldMandatory}>*</span>
                    </Label>
                    <TextField
                      onChange={educationFeedbackChange}
                      multiline
                      autoAdjustHeight
                      styles={{ root: { minWidth: "80% " } }}
                      value={educationFeedback}
                      placeholder="Improve the education by ..."
                      errorMessage={
                        !missingEducationFeedback
                          ? ""
                          : "Please provide feedback to improve education."
                      }
                    />
                  </div>
                  <div className={styles.GenerateDesc}>
                    <DefaultButton
                      onClick={generateEducationPostFeedback}
                      className={styles.HRPrimaryButton}
                      onMouseEnter={() => setSecondaryFillColor("black")}
                      onMouseLeave={() => setSecondaryFillColor("white")}
                    >
                      {isSectionGenerating ? (
                        <p className={styles.width100}>
                          <span>
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span className={styles.generating}>
                            Regenerating Education
                          </span>
                          <span className={styles.loadingdots}></span>
                        </p>
                      ) : (
                        <p className={styles.width100}>
                          <span>
                            {" "}
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span> Generate Education</span>
                        </p>
                      )}
                    </DefaultButton>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={isReviseExperiencePanelOpen}
                isBlocking={true}
                onDismiss={() => setReviseExperiencePanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div className={styles.ReviseDescriptionHeading}>
                    <div>Regenerate Experience</div>
                    <div>
                      <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={{ root: { color: "black" } }}
                        ariaLabel="Close"
                        onClick={() => setReviseExperiencePanelOpen(false)}
                      />
                    </div>
                  </div>
                  <div className={styles.ReviseJDDescription}>
                    <span>
                      You can use any natural language to provide specific
                      suggestions on what you would like to improve, and we'll
                      generate a new description, for example:
                      <br />
                      <br />
                    </span>
                    <span>
                      Include fewer marketing terms.
                      <br />
                      Do not use the words ‘Job Family Group’.
                      <br />
                      Could you add more technical skills?
                      <br />
                      Include information about owning the backend platform.
                    </span>
                  </div>
                  <div className={styles.Feedback}>
                    <Label className={styles.labelname}>
                      Feedback:<span className={styles.FieldMandatory}>*</span>
                    </Label>
                    <TextField
                      onChange={experienceFeedbackChange}
                      multiline
                      autoAdjustHeight
                      styles={{ root: { minWidth: "80% " } }}
                      value={experienceFeedback}
                      placeholder="Improve the experience by ..."
                      errorMessage={
                        !missingExperienceFeedback
                          ? ""
                          : "Please provide feedback to improve experience."
                      }
                    />
                  </div>
                  <div className={styles.GenerateDesc}>
                    <DefaultButton
                      onClick={generateExperiencePostFeedback}
                      className={styles.HRPrimaryButton}
                      onMouseEnter={() => setSecondaryFillColor("black")}
                      onMouseLeave={() => setSecondaryFillColor("white")}
                    >
                      {isSectionGenerating ? (
                        <p className={styles.width100}>
                          <span>
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span className={styles.generating}>
                            Regenerating Experience
                          </span>
                          <span className={styles.loadingdots}></span>
                        </p>
                      ) : (
                        <p className={styles.width100}>
                          <span>
                            {" "}
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span> Generate Experience</span>
                        </p>
                      )}
                    </DefaultButton>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={isReviseKnowledgePanelOpen}
                isBlocking={true}
                onDismiss={() => setReviseKnowledgePanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div className={styles.ReviseDescriptionHeading}>
                    <div>Regenerate Knowledge</div>
                    <div>
                      <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={{ root: { color: "black" } }}
                        ariaLabel="Close"
                        onClick={() => setReviseKnowledgePanelOpen(false)}
                      />
                    </div>
                  </div>
                  <div className={styles.ReviseJDDescription}>
                    <span>
                      You can use any natural language to provide specific
                      suggestions on what you would like to improve, and we'll
                      generate a new description, for example:
                      <br />
                      <br />
                    </span>
                    <span>
                      Include fewer marketing terms.
                      <br />
                      Do not use the words ‘Job Family Group’.
                      <br />
                      Could you add more technical skills?
                      <br />
                      Include information about owning the backend platform.
                    </span>
                  </div>
                  <div className={styles.Feedback}>
                    <Label className={styles.labelname}>
                      Feedback:<span className={styles.FieldMandatory}>*</span>
                    </Label>
                    <TextField
                      onChange={knowledgeFeedbackChange}
                      multiline
                      autoAdjustHeight
                      styles={{ root: { minWidth: "80% " } }}
                      value={knowledgeFeedback}
                      placeholder="Improve the knowledge by ..."
                      errorMessage={
                        !missingKnowledgeFeedback
                          ? ""
                          : "Please provide feedback to improve knowledge."
                      }
                    />
                  </div>
                  <div className={styles.GenerateDesc}>
                    <DefaultButton
                      onClick={generateKnowledgePostFeedback}
                      className={styles.HRPrimaryButton}
                      onMouseEnter={() => setSecondaryFillColor("black")}
                      onMouseLeave={() => setSecondaryFillColor("white")}
                    >
                      {isSectionGenerating ? (
                        <p className={styles.width100}>
                          <span>
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span className={styles.generating}>
                            Regenerating Knowledge
                          </span>
                          <span className={styles.loadingdots}></span>
                        </p>
                      ) : (
                        <p className={styles.width100}>
                          <span>
                            {" "}
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />
                          </span>
                          <span> Generate Knowledge </span>
                        </p>
                      )}
                    </DefaultButton>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={isReviseCertificationsPanelOpen}
                isBlocking={true}
                onDismiss={() => setReviseCertificationssPanelOpen(false)}
              >
                <div className={styles.ReviseJD}>
                  <div className={styles.ReviseDescriptionHeading}>
                    <div>Regenerate Certifications</div>
                    <div>
                      <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={{ root: { color: "black" } }}
                        ariaLabel="Close"
                        onClick={() => setReviseCertificationssPanelOpen(false)}
                      />
                    </div>
                  </div>
                  <div className={styles.ReviseJDDescription}>
                    <span>
                      You can use any natural language to provide specific
                      suggestions on what you would like to improve, and we'll
                      generate a new description, for example:
                      <br />
                      <br />
                    </span>
                    <span>
                      Include fewer marketing terms.
                      <br />
                      Do not use the words ‘Job Family Group’.
                      <br />
                      Could you add more technical skills?
                      <br />
                      Include information about owning the backend platform.
                    </span>
                  </div>
                  <div className={styles.Feedback}>
                    <Label className={styles.labelname}>
                      Feedback:<span className={styles.FieldMandatory}>*</span>
                    </Label>
                    <TextField
                      onChange={certificationsFeedbackChange}
                      multiline
                      autoAdjustHeight
                      styles={{ root: { minWidth: "80% " } }}
                      value={certificationFeedback}
                      placeholder="Improve the certifications by ..."
                      errorMessage={
                        !missingCertificationFeedback
                          ? ""
                          : "Please provide feedback to improve certifications."
                      }
                    />
                  </div>
                  <div className={styles.GenerateDesc}>
                    <DefaultButton
                      onClick={generateCertificationPostFeedback}
                      className={styles.HRPrimaryButton}
                      onMouseEnter={() => setSecondaryFillColor("black")}
                      onMouseLeave={() => setSecondaryFillColor("white")}
                    >
                      {isSectionGenerating ? (
                        <p className={styles.width100}>
                          <span>
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />{" "}
                          </span>
                          <span className={styles.generating}>
                            Regenerating Certifications
                          </span>
                          <span className={styles.loadingdots}></span>
                        </p>
                      ) : (
                        <p className={styles.width100}>
                          <span>
                            {" "}
                            <SparkleFilled
                              fontSize={"18px"}
                              primaryFill={secondaryFillColor}
                              aria-hidden="true"
                              aria-label="HR JD Logo"
                            />{" "}
                          </span>
                          <span> Generate Certifications</span>
                        </p>
                      )}
                    </DefaultButton>
                  </div>
                </div>
              </Modal>
              <Modal
                styles={reviseJDPanelStyles}
                isOpen={warningPanelOpen}
                onDismiss={() => setWarningPanelOpen(false)}
                closeButtonAriaLabel="Close"
              >
                <div className={styles.ReviseJD}>
                  <div className={styles.ReviseDescriptionHeading}>
                    <div />
                    <div>
                      <IconButton
                        onClick={() => setWarningPanelOpen(false)}
                        iconProps={{ iconName: "Cancel" }}
                        styles={{
                          root: { color: "black" },
                          rootHovered: {
                            color: "#e20074",
                            background: "white",
                          },
                        }}
                        ariaLabel="Close"
                      />{" "}
                    </div>
                  </div>
                  <div className={styles.ReviseJDDescription}>
                    <span>
                      {" "}
                      You will lose the entered job details. Do you wish to
                      proceed?{" "}
                    </span>{" "}
                  </div>
                  <div className={styles.NextButtonsContainer}>
                    <DefaultButton
                      onClick={() => setWarningPanelOpen(false)}
                      className={styles.HRSecondaryButton}
                    >
                      {" "}
                      Cancel{" "}
                    </DefaultButton>
                    <DefaultButton
                      onClick={proceedToNextItem}
                      className={styles.HRPrimaryButton}
                    >
                      {" "}
                      Proceed{" "}
                    </DefaultButton>{" "}
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          <div className={styles.parentcontainer}>
            <div className={styles.topcontainer}>
              <div className={styles.accessdeniedcontainer}>
                <div className={styles.accessdeniedbox}>
                  <div className={styles.launchercontainer}>
                    <div className={styles.accessdenieditemheader}>
                      <h1 className={styles.accessdeniedtext}>
                        No access to the page
                      </h1>
                    </div>
                  </div>
                  <div className={styles.accessdenieddesc}>
                    You don't have authorization to access this page.{" "}
                    <p>Please reach out to site admin for access.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className={styles.parentcontainer}>
          <div className={styles.topcontainer}>
            <div className={styles.svgcontainer}></div>
          </div>
          <div className={styles.authcontainer}>
            <div className={styles.box}>
              <div className={styles.launchercontainer}>
                <div className={styles.launcheritemheader}>
                  <h2>T-Mobile Authorization</h2>
                </div>
              </div>
              <div className={styles.launcheritemdesc}>
                Please wait... Application is loading...
              </div>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default HomeHR;
