import { DefaultButton, Dropdown, IDropdownOption, IDropdownStyles, IStackTokens, Label, Stack, TextField, on } from '@fluentui/react'
import styles from './CustomizePrompts.module.css'
import { useEffect, useState } from 'react';
import { GetHRJDPromptTemplates, HRFeedbackTemplates, HRPromptTemplates, UpdatePromptTemplates } from '../../api';


const outerStackTokens: IStackTokens = { childrenGap: 5 };
const innerStackTokens: IStackTokens = { childrenGap: 5, padding: 10 };

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    width: 300,
  },
};
interface Props {
  canSavePrompt: boolean;
}

export const CustomizePrompts = ({ canSavePrompt }: Props) => {

  const [canPromptbeSaved, SetCanPromptbeSaved] = useState<boolean>(canSavePrompt)
  const [defaultPromptKey, SetDefaultPromptKey] = useState<string>("job_summary")

  const [HRDropDownPromptTemplates, setHRDropDownPromptTemplates] = useState<IDropdownOption[]>([])
  const [HRDropDownFeedbackPromptTemplates, setHRDropDownFeedbackPromptTemplates] = useState<IDropdownOption[]>([])

  const [selectedPromptTemplate, SetSelectedPromptTemplate] = useState<IDropdownOption>({ key: "job_summary", text: "Job Summary", data: { value: "" } })
  const [selectedPromptTemplatevalue, SetSelectedPromptTemplateValue] = useState<string>("")
  const [isPromptSaveStatus, SetPromptSaveStatus] = useState<string>("")
  const [isPromptTextChanged, SetPromptTextChanged] = useState<boolean>(false)

  const [selectedFeedbackPromptTemplate, SetSelectedFeedbackPromptTemplate] = useState<IDropdownOption>({ key: "job_summary", text: "Job Summary", data: { value: "" } })
  const [selectedFeedbackPromptTemplatevalue, SetSelectedFeedbackPromptTemplateValue] = useState<string>("")
  const [isFeedbackPromptSaveStatus, SetFeedbackPromptSaveStatus] = useState<string>("")
  const [isFeedbackPromptTextChanged, SetFeedbackPromptTextChanged] = useState<boolean>(false)


  const onPromptChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    if (item) {
      console.log(`Selected option: ${item.text}, Value: ${item.data?.value}`);
      SetPromptSaveStatus("")
      SetSelectedPromptTemplate(item);
      SetSelectedPromptTemplateValue(item.data?.value)

      SetPromptSaveStatus("")
      let defaultFeedbackprompt = HRDropDownFeedbackPromptTemplates.filter((v, i, a) => a.findIndex((v2) => v2.text === item.key) === i)[0];
      SetSelectedFeedbackPromptTemplate(defaultFeedbackprompt)
      SetSelectedFeedbackPromptTemplateValue(defaultFeedbackprompt["data"]["value"])

    }
  }

  /* HRJD Prompts */
  const onPromptTextChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    SetPromptSaveStatus("")
    SetSelectedPromptTemplateValue(newValue || "");
    SetPromptTextChanged(true)
  };

  const onSavePromptClicked = async () => {
    try {
      const result = await UpdatePromptTemplates("Prompt", selectedPromptTemplate?.text, selectedPromptTemplatevalue)
      if (result === "success") {
        // what is the next step
        SetPromptSaveStatus("Prompt Template updated successfully.")

        // refetch from CosmosDB the whole list of prompts
        await GetPromptTemplates()

        let updateditem = HRDropDownPromptTemplates.filter((v, i, a) => a.findIndex((v2) => v2.text === selectedPromptTemplate?.text) === i)[0];
        SetSelectedPromptTemplate(updateditem)
        SetSelectedPromptTemplateValue(updateditem["data"]["value"])

      } else {
        SetPromptSaveStatus("Failed to update the Prompt Template. Please retry.")
      }
    } catch (e) {
      SetPromptSaveStatus("Error in updating Prompt Template.")
    }
  }

  /* HRJD Feedback Prompts */
  const onFeedbackPromptTextChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    SetFeedbackPromptSaveStatus("")
    SetSelectedFeedbackPromptTemplateValue(newValue || "");
    SetFeedbackPromptTextChanged(true)
  };

  const onSaveFeedbackPromptClicked = async () => {
    try {
      const result = await UpdatePromptTemplates("Feedback", selectedFeedbackPromptTemplate?.text, selectedFeedbackPromptTemplatevalue)
      if (result === "success") {
        // what is the next step
        SetFeedbackPromptSaveStatus("Feedback Prompt Template updated successfully.")

        // refetch from CosmosDB the whole list of prompts
        await GetFeedbackPromptTemplates()

        let updateditem = HRDropDownPromptTemplates.filter((v, i, a) => a.findIndex((v2) => v2.text === selectedFeedbackPromptTemplate?.text) === i)[0];
        SetSelectedFeedbackPromptTemplate(updateditem)
        SetSelectedFeedbackPromptTemplateValue(updateditem["data"]["value"])

      } else {
        SetFeedbackPromptSaveStatus("Failed to update the Prompt Template. Please retry.")
      }
    } catch (e) {
      SetFeedbackPromptSaveStatus("Error in updating Prompt Template.")
    }
  }


  /* HRJD Load Prompts */
  const GetPromptTemplates = async () => {
    try {
      const result = await GetHRJDPromptTemplates("Prompt");
      const dropdownOptions: IDropdownOption[] = Object.entries(result).map(([key, value]) => ({
        key,
        text: key, // .charAt(0).toUpperCase() + key.slice(1).replaceAll(/_/g, ' '), //  Convert snake_case to title case
        data: { value }, //  Optionally store the original value in data for reference
      }));
      setHRDropDownPromptTemplates(dropdownOptions)
    } catch (e: any) {
      //setHRPromptTemplates(defaultPromptTemplate)
    }
  }

  /* HRJD Load Feedback Prompts */
  const GetFeedbackPromptTemplates = async () => {
    try {
      const result = await GetHRJDPromptTemplates("Feedback");
      const dropdownOptions: IDropdownOption[] = Object.entries(result).map(([key, value]) => ({
        key,
        text: key, // .charAt(0).toUpperCase() + key.slice(1).replaceAll(/_/g, ' '), //  Convert snake_case to title case
        data: { value }, //  Optionally store the original value in data for reference
      }));
      setHRDropDownFeedbackPromptTemplates(dropdownOptions)
    } catch (e: any) {
      // setHRFeedbackPromptTemplates(defaultPromptTemplate)
    }
  }

  /* Save changes on save button click */
  const onUpdatePromptTemplates = async () => {
    onSavePromptClicked()
    onSaveFeedbackPromptClicked()
  }

  useEffect(() => {
    if (HRDropDownPromptTemplates && HRDropDownPromptTemplates.length > 0) {
      let defaultprompt = HRDropDownPromptTemplates.filter((v, i, a) => a.findIndex((v2) => v2.text === defaultPromptKey) === i)[0];
      SetSelectedPromptTemplate(defaultprompt)
      SetSelectedPromptTemplateValue(defaultprompt["data"]["value"])
    }
  }, [HRDropDownPromptTemplates])

  useEffect(() => {
    if (HRDropDownFeedbackPromptTemplates && HRDropDownFeedbackPromptTemplates.length > 0) {
      let defaultFeedbackprompt = HRDropDownFeedbackPromptTemplates.filter((v, i, a) => a.findIndex((v2) => v2.text === defaultPromptKey) === i)[0];
      SetSelectedFeedbackPromptTemplate(defaultFeedbackprompt)
      SetSelectedFeedbackPromptTemplateValue(defaultFeedbackprompt["data"]["value"])
    }
  }, [HRDropDownFeedbackPromptTemplates])

  useEffect(() => {
    SetCanPromptbeSaved(canSavePrompt)
    SetDefaultPromptKey("job_summary")

    GetPromptTemplates()
    GetFeedbackPromptTemplates()

  }, [])

  return <div className={styles.Section}>
    <div className={styles.ScrollableSection}>
      <div className={styles.SectionHeading}>
        <span className={styles.HRJDItemHeading}>
          Select the prompt for customizing AI responses:
        </span></div>
      <Stack className={styles.panel1} enableScopedSelectors tokens={outerStackTokens} >
        <Stack enableScopedSelectors tokens={innerStackTokens}>

          <Stack.Item grow>
            <Label className={styles.labelname}>Select Prompt:</Label>
            <Dropdown styles={dropdownStyles} className={styles.stackItemStyles1}
              selectedKey={selectedPromptTemplate ? selectedPromptTemplate.key : HRDropDownPromptTemplates[0].key}
              onChange={onPromptChange}
              placeholder="Select prompt to continue"
              options={HRDropDownPromptTemplates} disabled={false} /> </Stack.Item>
          <Stack.Item grow>
            <Label className={styles.labelname}>Prompt Text:</Label>
            <TextField onChange={onPromptTextChange} multiline autoAdjustHeight styles={{ root: { minWidth: "80% " } }} value={selectedPromptTemplatevalue} readOnly={!canPromptbeSaved} placeholder="Write an internal Job Summary for this employee by clearly describing the purpose of the role and it's value to T-Mobile. Return only the job summary, which should be 5 sentences and should avoid gendered language." />
          </Stack.Item>
          <Stack.Item grow>
            <Label className={styles.labelname}>Feedback Prompt Text:</Label>
            <TextField onChange={onFeedbackPromptTextChange} multiline autoAdjustHeight styles={{ root: { minWidth: "80% " } }} value={selectedFeedbackPromptTemplatevalue} readOnly={!canPromptbeSaved} placeholder="Write an internal Job Summary for this employee by clearly describing the purpose of the role and it's value to T-Mobile. Return only the job summary, which should be 5 sentences and should avoid gendered language." />
          </Stack.Item>
          <Stack.Item grow className={styles.StackButtonsContainer}>
            {isPromptSaveStatus.length > 0 && (<div className={styles.PercentAllocationErrorSection}> <div className={styles.PercentAllocationErrorText}> {isPromptSaveStatus} </div> </div>)}
            {isFeedbackPromptSaveStatus.length > 0 && (<div className={styles.PercentAllocationErrorSection}> <div className={styles.PercentAllocationErrorText}> {isFeedbackPromptSaveStatus} </div> </div>)}
            {!canPromptbeSaved && <div className={styles.PercentAllocationErrorSection}> <div className={styles.PercentAllocationErrorText}> <b> You don't have admin privilege to update Prompts.</b> </div> </div>}
            {canPromptbeSaved && <DefaultButton onClick={() => onUpdatePromptTemplates()} className={styles.HRPrimaryButton} title="Click to save the changes">{" "}Save</DefaultButton>}
            {!canPromptbeSaved && <DefaultButton className={styles.HRGrayedButton} title="Need Admin Privilege to Update Prompts " disabled >{" "}Save</DefaultButton>}
          </Stack.Item>
        </Stack>

      </Stack>
    </div></div >
}
