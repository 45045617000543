import { useState } from "react";
import { Outlet, NavLink, Link, useLocation } from "react-router-dom";

import styles from "./Layout.module.css";
import { SettingsButton } from "../../components/SettingsButton/SettingsButton";
import { useIsAuthenticated } from "@azure/msal-react";
import { WelcomeName } from "../../components/WelcomeName/WelcomeName";
import { MenuSettingButton } from "../../components/MenuSettingButton";

const Layout = () => {
    const [isOpenOCSessionPanel, setIsOpenOCSessionPanel] = useState(false);
    const [isOpenOCConfigPanel, setIsOpenOCConfigPanel] = useState(false);
    const [isConfigPanelOpenStream, setIsConfigPanelOpenStream] = useState(false);
    const [isOpenOSSessionPanelStream, setIsOpenOSSessionPanelStream] = useState(false);
    const [isHRConfigPanel, setIsHRConfigPanel] = useState(false);
    const [isHRCustomizationPanelOpen, setIsHRCustomizationPanelOpen] = useState(false);

    const location = useLocation()
    const isAuthenticated = useIsAuthenticated();

    const handleOCSessionOpen = (isOpenOCSessionPanel: any) => {
        setIsOpenOCSessionPanel(isOpenOCSessionPanel)
    }

    const handleOCConfigOpen = (isOpenOCConfigPanel: any) => {
        setIsOpenOCConfigPanel(isOpenOCConfigPanel)
    }

    const handleStreamConfigOpen = (isConfigPanelOpenStream: any) => {
        setIsConfigPanelOpenStream(isConfigPanelOpenStream)
    }

    const handleOSSessionOpen = (isOpenOSSessionPanelStream: any) => {
        setIsOpenOSSessionPanelStream(isOpenOSSessionPanelStream)
    }

    const handleHRPanelOpen = (isHRConfigPanel: any) => {
        setIsHRConfigPanel(isHRConfigPanel)
    }

    const handleHRCustomizationPanelOpen = (isHRCustomizationPanel: boolean) => {
        setIsHRCustomizationPanelOpen(isHRCustomizationPanel)
    }
    return (
        <div className={styles.layout}>
            <header className={styles.header} role="banner">
                <div className={styles.headerContainer}>
                    <div className={styles.logoContainer}>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <div className={styles.logoName}></div>
                        </Link>
                    </div>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/FAQ" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    FAQ
                                </NavLink>
                            </li>
                            {
                                !(document.URL.toLowerCase().indexOf('faq') > -1)
                                    ? <li className={styles.headerNavLeftMargin}>
                                        <div className={styles.settingsContainer}>
                                            {document.URL.toLowerCase().indexOf('hrhome') > -1
                                                && <SettingsButton className={styles.commandButtonSettings} onClick={() => setIsHRConfigPanel(!isHRConfigPanel)} />}
                                            {document.URL.toLowerCase().indexOf('chatgpt/1') > -1
                                                ? <SettingsButton className={styles.commandButtonSettings} onClick={() => setIsConfigPanelOpenStream(!isConfigPanelOpenStream)} />
                                                : (!(document.URL.toLowerCase().indexOf('hrhome') > -1)
                                                    && <SettingsButton className={styles.commandButtonSettings} onClick={() => setIsOpenOCConfigPanel(!isOpenOCConfigPanel)} />)}
                                        </div>
                                    </li>
                                    : ''
                            }
                            <li className={styles.headerNavLeftMargin}>
                                {
                                    !(document.URL.toLowerCase().indexOf('faq') > -1)
                                        && !(document.URL.toLowerCase().indexOf('chatgpt/1') > -1)
                                        && !(document.URL.toLowerCase().indexOf('hrhome') > -1)
                                        ? <MenuSettingButton title="User History" className={styles.commandButton1} onClick={() => setIsOpenOCSessionPanel(!isOpenOCSessionPanel)} />
                                        : (document.URL.toLowerCase().indexOf('hrhome') > -1)
                                        && <MenuSettingButton title="Customize Prompts" className={styles.commandButton1} onClick={() => setIsHRCustomizationPanelOpen(!isHRCustomizationPanelOpen)} />
                                }
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                {isAuthenticated ? <WelcomeName message={'User'} /> : null}
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <Outlet context={{
                isOpenOCSessionPanel: isOpenOCSessionPanel,
                isOpenOCConfigPanel: isOpenOCConfigPanel,
                isConfigPanelOpenStream: isConfigPanelOpenStream,
                isOpenOSSessionPanelStream: isOpenOSSessionPanelStream,
                isHRConfigPanel: isHRConfigPanel,
                isHRCustomizationPanel: isHRCustomizationPanelOpen,
                onOCSessionOpen: handleOCSessionOpen,
                onOCConfigOpen: handleOCConfigOpen,
                onOSConfigOpen: handleStreamConfigOpen,
                onOSSessionOpen: handleOSSessionOpen,
                onHRConfigOpen: handleHRPanelOpen,
                onHRCustomizationOpen: handleHRCustomizationPanelOpen
            }}
            />
        </div>
    );
};

export default Layout;
