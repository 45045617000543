export interface dropDownOption { key: string; text: string; }

export interface JobFamilyDetails {
    [key: string]: Array<dropDownOption>;
}
export function findJobFamilyByName(jobFamilyName: string): Array<{ key: string; text: string }> | undefined {
    for (const key in job_family_details) {
        if (key.toLowerCase() === jobFamilyName.toLowerCase()) {
            return job_family_details[key];
        }
    }
    return undefined;
}

export const job_family = [
    { key: 'Administrative Services', text: 'Administrative Services' },
    { key: 'Business Analysis', text: 'Business Analysis' },
    { key: 'Call Center Operations', text: 'Call Center Operations' },
    { key: 'Communications', text: 'Communications' },
    { key: 'Customer Service', text: 'Customer Service' },
	{ key: 'Data Analysis', text: 'Data Analysis' },
    { key: 'Digital', text: 'Digital' },
    { key: 'Executive', text: 'Executive' },
    { key: 'Finance', text: 'Finance' },
    { key: 'Human Resources', text: 'Human Resources' },
    { key: 'Information Technology', text: 'Information Technology' },
    { key: 'Legal Affairs', text: 'Legal Affairs' },
    { key: 'Marketing', text: 'Marketing' },
    { key: 'Network Engineering', text: 'Network Engineering' },
    { key: 'Network Operations', text: 'Network Operations' },
    { key: 'Product Development', text: 'Product Development' },
    { key: 'Product Management', text: 'Product Management' },
    { key: 'Program Management', text: 'Program Management' },
    { key: 'Project Management', text: 'Project Management' },
    { key: 'Real Estate & Facilities', text: 'Real Estate & Facilities' },
    { key: 'Sales', text: 'Sales' },
    { key: 'Sales Operations', text: 'Sales Operations' },
    { key: 'Strategy & Business Development', text: 'Strategy & Business Development' },
    { key: 'Supply Chain Operations', text: 'Supply Chain Operations' },
	{ key: 'Technology', text: 'Technology' },
    { key: 'Vendor Management', text: 'Vendor Management' }
]
export const job_family_details: JobFamilyDetails =
{
	"Administrative Services": [
        {
            key: 'Executive Support',
            text: 'Executive Support'
        },
        {
            key: 'General Support',
            text: 'General Support'
        }
    ],
	
	"Business Analysis": [
        {
            key: 'Analytics',
            text: 'Analytics'
        },
        {
            key: 'Business Analysis',
            text: 'Business Analysis'
        },
        {
            key: 'Business Intelligence',
            text: 'Business Intelligence'
        },
        {
            key: 'Business Process',
            text: 'Business Process'
        },
        {
            key: 'Data Science',
            text: 'Data Science'
        },
        {
            key: 'Reporting',
            text: 'Reporting'
        }
    ],
    "Call Center Operations": [
        {
            key: 'Call Center Operations',
            text: 'Call Center Operations'
        },
        {
            key: 'Call Quality',
            text: 'Call Quality'
        },
        {
            key: 'Online Services',
            text: 'Online Services'
        },
        {
            key: 'Resource Planning',
            text: 'Resource Planning'
        },
        {
            key: 'Service Partner Management',
            text: 'Service Partner Management'
        }
    ],
    "Communications": [
        {
            key: 'Communications',
            text: 'Communications'
        },
        {
            key: 'Content Management',
            text: 'Content Management'
        }
    ],
    "Customer Service": [
        {
            key: 'General Care',
            text: 'General Care'
        },
        {
            key: 'Technical Care',
            text: 'Technical Care'
        }
    ],
    "Data Analysis": [
        {
            key: 'Data Architecture & Engineering',
            text: 'Data Architecture & Engineering'
        }
    ],
    "Digital": [
        {
            key: 'Digital Strategy & Sales',
            text: 'Digital Strategy & Sales'
        }
    ],
    "Executive": [
        {
            key: 'Executive',
            text: 'Executive'
        }
    ],
    "Finance": [
        {
            key: 'Accounting',
            text: 'Accounting'
        },
        {
            key: 'Audit',
            text: 'Audit'
        },
        {
            key: 'Business Continuity',
            text: 'Business Continuity'
        },
        {
            key: 'Credit Risk',
            text: 'Credit Risk'
        },
        {
            key: 'Credit Risk Underwriting',
            text: 'Credit Risk Underwriting'
        },
        {
            key: 'Financial Compliance',
            text: 'Financial Compliance'
        },
        {
            key: 'Financial Operations',
            text: 'Financial Operations'
        },
        {
            key: 'Financial Planning & Analysis',
            text: 'Financial Planning & Analysis'
        },
        {
            key: 'Investor Relations',
            text: 'Investor Relations'
        },
        {
            key: 'Risk Management',
            text: 'Risk Management'
        },
        {
            key: 'Tax Operations',
            text: 'Tax Operations'
        },
        {
            key: 'Treasury',
            text: 'Treasury'
        }
    ],
    "Human Resources": [
        {
            key: 'Benefits',
            text: 'Benefits'
        },
        {
            key: 'Compensation',
            text: 'Compensation'
        },
        {
            key: 'Employee Relations',
            text: 'Employee Relations'
        },
        {
            key: 'HR Consulting',
            text: 'HR Consulting'
        },
        {
            key: 'HR Shared Services',
            text: 'HR Shared Services'
        },
        {
            key: 'Recruiting',
            text: 'Recruiting'
        },
        {
            key: 'Rewards & Recognition',
            text: 'Rewards & Recognition'
        },
        {
            key: 'Talent Development',
            text: 'Talent Development'
        },
        {
            key: 'Training Delivery',
            text: 'Training Delivery'
        },
        {
            key: 'Training Operations',
            text: 'Training Operations'
        }
    ],
    "Information Technology": [
        {
            key: 'Business Solutions',
            text: 'Business Solutions'
        },
        {
            key: 'Business Systems Analysis',
            text: 'Business Systems Analysis'
        },
        {
            key: 'Cybersecurity Engineering',
            text: 'Cybersecurity Engineering'
        },
        {
            key: 'Data Architecture & Engineering',
            text: 'Data Architecture & Engineering'
        },
        {
            key: 'Database Management',
            text: 'Database Management'
        },
        {
            key: 'End User Support',
            text: 'End User Support'
        },
        {
            key: 'Enterprise Architecture',
            text: 'Enterprise Architecture'
        },
        {
            key: 'Help Desk',
            text: 'Help Desk'
        },
        {
            key: 'Information Security',
            text: 'Information Security'
        },
        {
            key: 'Information Security Job Family',
            text: 'Information Security Job Family'
        },
        {
            key: 'Quality Assurance',
            text: 'Quality Assurance'
        },
        {
            key: 'Software Applications & Support',
            text: 'Software Applications & Support'
        },
        {
            key: 'Software Engineering',
            text: 'Software Engineering'
        },
        {
            key: 'Software Engineering - Full Stack',
            text: 'Software Engineering - Full Stack'
        },
        {
            key: 'Systems Reliability',
            text: 'Systems Reliability'
        }
    ],
    "Legal Affairs": [
        {
            key: 'Counsel',
            text: 'Counsel'
        },
        {
            key: 'Investigations',
            text: 'Investigations'
        },
        {
            key: 'Legal Risk Management',
            text: 'Legal Risk Management'
        },
        {
            key: 'Legal Support',
            text: 'Legal Support'
        },
        {
            key: 'Regulatory',
            text: 'Regulatory'
        }
    ],
    "Marketing": [
        {
            key: 'Advertising',
            text: 'Advertising'
        },
        {
            key: 'Creative Services',
            text: 'Creative Services'
        },
        {
            key: 'Customer Marketing',
            text: 'Customer Marketing'
        },
        {
            key: 'Market Research',
            text: 'Market Research'
        },
        {
            key: 'Marketing Operations',
            text: 'Marketing Operations'
        },
        {
            key: 'Media',
            text: 'Media'
        },
        {
            key: 'Merchandising',
            text: 'Merchandising'
        },
        {
            key: 'Segment Marketing',
            text: 'Segment Marketing'
        },
        {
            key: 'Social Media',
            text: 'Social Media'
        }
    ],
    "Network Engineering": [
        {
            key: 'Network Engineering',
            text: 'Network Engineering'
        },
        {
            key: 'Network Systems Design & Strategy',
            text: 'Network Systems Design & Strategy'
        },
        {
            key: 'Radio Frequency',
            text: 'Radio Frequency'
        }
    ],
    "Network Operations": [
        {
            key: 'Cell Site Development',
            text: 'Cell Site Development'
        },
        {
            key: 'Data Center Operations',
            text: 'Data Center Operations'
        },
        {
            key: 'Field Tech Engineering',
            text: 'Field Tech Engineering'
        },
        {
            key: 'Network Ops Center',
            text: 'Network Ops Center'
        },
        {
            key: 'Network Support',
            text: 'Network Support'
        },
        {
            key: 'Switch Tech Engineering',
            text: 'Switch Tech Engineering'
        }
    ],
    "Product Development": [
        {
            key: 'User Experience',
            text: 'User Experience'
        }
    ],
    "Product Management": [
        {
            key: 'Product Management',
            text: 'Product Management'
        },
        {
            key: 'Technical Product Management',
            text: 'Technical Product Management'
        },
        {
            key: 'Technology Solutions',
            text: 'Technology Solutions'
        }
    ],
    "Program Management": [
        {
            key: 'Business Operations',
            text: 'Business Operations'
        },
        {
            key: 'Program Management',
            text: 'Program Management'
        }
    ],
    "Project Management": [
        {
            key: 'Construction',
            text: 'Construction'
        },
        {
            key: 'Development',
            text: 'Development'
        },
        {
            key: 'General Project Management',
            text: 'General Project Management'
        },
        {
            key: 'Technical',
            text: 'Technical'
        }
    ],
    "Real Estate & Facilities": [
        {
            key: 'Facilities',
            text: 'Facilities'
        },
        {
            key: 'Flight Operations',
            text: 'Flight Operations'
        },
        {
            key: 'Lease Management',
            text: 'Lease Management'
        },
        {
            key: 'Real Estate',
            text: 'Real Estate'
        }
    ],
    "Sales": [
        {
            key: 'Account Management',
            text: 'Account Management'
        },
        {
            key: 'Direct Sales',
            text: 'Direct Sales'
        },
        {
            key: 'Inside Sales',
            text: 'Inside Sales'
        },
        {
            key: 'Partner Solutions Sales',
            text: 'Partner Solutions Sales'
        },
        {
            key: 'Retail',
            text: 'Retail'
        },
        {
            key: 'Sales Engineering',
            text: 'Sales Engineering'
        },
        {
            key: 'Telesales',
            text: 'Telesales'
        },
        {
            key: 'Wholesale',
            text: 'Wholesale'
        },
        {
            key: 'Wireline',
            text: 'Wireline'
        },
        {
            key: '',
            text: ''
        }
    ],
    "Sales Operations": [
        {
            key: 'Commissions',
            text: 'Commissions'
        },
        {
            key: 'Fraud',
            text: 'Fraud'
        },
        {
            key: 'Retail Formats',
            text: 'Retail Formats'
        },
        {
            key: 'Sales Support',
            text: 'Sales Support'
        }
    ],
    "Strategy & Business Development": [
        {
            key: 'Business Development',
            text: 'Business Development'
        },
        {
            key: 'Corporate Strategy',
            text: 'Corporate Strategy'
        }
    ],
    "Supply Chain Operations": [
        {
            key: 'General Supply Chain',
            text: 'General Supply Chain'
        },
        {
            key: 'Logistics',
            text: 'Logistics'
        },
        {
            key: 'Procurement',
            text: 'Procurement'
        },
        {
            key: 'Product Planning',
            text: 'Product Planning'
        },
        {
            key: 'Replenishment',
            text: 'Replenishment'
        }
    ],
    "Technology": [
        {
            key: 'Data Architecture & Engineering',
            text: 'Data Architecture & Engineering'
        }
    ],
    "Vendor Management": [
        {
            key: 'Vendor Management',
            text: 'Vendor Management'
        }
    ]
	
}

export const career_bands = [
    { key: 'L01', text: 'L01 CEO' },
    { key: 'L02', text: 'L02 EVP' },
    { key: 'L03', text: 'L03 SVP' },
    { key: 'L04', text: 'L04 VP' },
    { key: 'L05', text: 'L05 Sr. Director' },
    { key: 'L06', text: 'L06 Director or Functional Expert' },
    { key: 'L07', text: 'L07 Sr. Manager or Professional' },
    { key: 'L08', text: 'L08 Manager or Professional' },
    { key: 'L09', text: 'L09 Coach/Supervisor or Professional/Technical' },
    { key: 'L10', text: 'L10 Support/Services/Technical' },
    { key: 'L11', text: 'L11 Support/Services' },
    { key: 'SL07', text: 'SL07 Sr. Manager or Professional' },
    { key: 'SL08', text: 'SL08 Manager or Professional' },
    { key: 'SL09', text: 'SL09 Store Manager or Professional' },
    { key: 'SL10', text: 'SL10 Retail Associate Manager / Sales Professional' },
    { key: 'SL11', text: 'SL11 Mobile Expert' }
]

// export const HRPromptTemplates = {
//     "job_summary": "Write an internal Job Summary for this employee by clearly describing the purpose of the role and it's value to T-Mobile. Return only the job summary, which should be 5 sentences and should avoid gendered language.",
//     "responsibilities": "Write the job responsibilities and percent of work time each will take for the given job summary. Responsibilities should be written as if you are speaking to the employee and should be organized from most relevant to least relevent in terms of the percent of time an employee spends on each responsibility. Do not mention specific systems or programs unless a specific expertise is needed and it will be a large percentage of the employee's role. Not items should be less than 5%. Include up to 9 responsibilities in the following format:\n RESPONSIBILITY: <Verb followed by an objective result and activity>\n PERCENT OF TIME: <##>%\n Only respond in this format, do not use a numbered list.",
//     "experiences": "Identify 3 prior experiences that would be needed to perform for the given job summary using the following format: \n EXPERIENCE: <Brief summary>\n EXPERIENCE TIME: One of 0-2 years, 2-4 years, 4-7 years, 7-10 years, 10+ years\n REQUIREMENT LEVEL: One of Preferred or Required",
//     "education": "Identify 2-3 education levels that would be needed to perform for the given job summary using the following format: \n EDUCATION LEVEL: One of High School Diploma, Associate Degree, Bachelor's Degree, Master's Degree, PhD, Juris Doctor\n AREA OF STUDY: <ANSWER>\n REQUIREMENT LEVEL: One of Preferred or Required",
//     "ksas": "Identify 5-10 knowledges, skills, or abilities that would be needed for the given job summary using the following format:\n KNOWLEDGE, SKILL, OR ABILITY: <title>\n DETAILS: <brief summary>\n REQUIREMENT LEVEL: One of Preferred or Required\n\n The output should explicitly say KNOWLEDGE, SKILL, OR ABILITY.",
//     "lc": "Identify 1-3 licenses or certifications that would be needed for the given job summary using the following format:\n LICENSE OR CERTIFICATION: <title>\n DETAILS: <brief summary>\n REQUIREMENT LEVEL: One of Preferred or Required",
// }

// export const HRFeedbackTemplates = {
//     "summary_feedback_prompt": "Enhance and re-write the job summary:{job_summary} based on specific user feedback:{user_feedback} provided for the job role:{job_profile_name}, job family group: {job_family_group},job family: {job_family}. Ensure the summary accurately reflects the user feedback::{user_feedback} provided and impact of the job role:{job_profile_name} within T-Mobile, highlighting key technical and operational aspects as mentioned by users. The summary should be clear and jargon-free, tailored to the nuances of each role, and should maintain neutrality in language. It should encapsulate the essence of the position in a concise manner, with a limit of five-six sentences. Apply the feedback to enhance precision and relevance to the specific job function, contributing to a comprehensive understanding of the role's value to the organization.",
//     "responsibilities_feedback_prompt": "Regenerate the job responsibilities: {job_responsibilities} using the job summary:{job_summary} and user feedback: {user_feedback}. The feedback should influence the responsibilities directly, either by refining existing ones or introducing new responsibilities that are essential to the job role: {job_profile_name}, as per the feedback specifics. The revised responsibilities should follow the established format of stating each responsibility with its corresponding percentage of time commitment. Ensure no single item is less than 5% and include up to 9 responsibilities. The language should remain direct and professional, as if speaking to the employee, and should reflect the specific expertise mentioned in the user feedback.",
//     "experience_feedback_prompt": "Revise and rewrite the experience requirements:{job_experience} for the specified job role: {job_profile_name} in light of the user feedback: {user_feedback} provided. The update should reflect three prior experiences essential for the job, presented in the established format. Each experience should be succinctly summarized and paired with an appropriate experience time category (0-2 years, 2-4 years, 4-7 years, 7-10 years, 10+ years). Additionally, classify each experience as either 'Preferred' or 'Required' according to the job's needs and the nuances brought forth by the user feedback. Ensure that the updated experiences align with the original job summary: {job_summary} and the additional insights given by the user, providing a clear and accurate picture of the background needed for the role.",
//     "education_feedback_prompt": "Refine and rewrite the educational requirements:{job_education} for the job role: {job_profile_name} according to the new user feedback: {user_feedback}. Update the section to reflect the necessary educational background based on job summary: {job_summary}, making sure to specify the education level (e.g., High School Diploma, Bachelor's Degree), the area of study if any particular field is required or preferred, and the requirement level (Required or Preferred). The regeneration should include: A minimum education level that is considered essential for the job, along with any specific area of study, if noted by the user. Additional education qualifications that enhance the candidacy for the role, with details on areas of study and whether these are required or simply preferred. Align this section with the detailed feedback provided, ensuring that the educational requirements are accurately represented and formatted as follows for each entry: EDUCATION LEVEL: [Level] AREA OF STUDY: [Field] REQUIREMENT LEVEL: [Required/Preferred]",
//     "ksas_feedback_prompt": "Revise and rewrite the list of knowledges, skills, and abilities: {job_ksas} for the job role:{job_profile_name}, incorporating the specific user feedback:{user_feedback} received. The updated KSAs should closely align with the demands of the job role as detailed in the job summary:{job_summary}. For each KSA, provide a title, a concise summary, and specify whether it is 'Preferred' or 'Required'. Follow the original format: KNOWLEDGE, SKILL, OR ABILITY: [title] DETAILS: [brief summary] REQUIREMENT LEVEL: [Preferred/Required] The feedback should be reflected in both the selection of KSAs and their descriptions, ensuring relevance and accuracy in relation to the job. Adjust the list to include 5-10 KSAs, making sure each is clearly labeled as 'KNOWLEDGE, SKILL, OR ABILITY' and the details are sufficient for understanding the importance and application of each in the job role.",
//     "lc_feedback_prompt": "Regenerate the list of required licenses or certifications:{job_lc} for the specified job role:{job_profile_name} to reflect the additional user feedback:{user_feedback}. Ensure that each entry details the title of the license or certification, along with a brief summary that explains its relevance to the job summary: {job_summary}. Specify whether each is 'Preferred' or 'Required' based on the job's demands and the user's suggestions. The format should remain consistent with the initial input: LICENSE OR CERTIFICATION: [title] DETAILS: [brief summary] REQUIREMENT LEVEL: [Preferred/Required] This revision should include 1-3 licenses or certifications, accurately reflecting the professional qualifications necessary for the position as enhanced by the user input.",
//     "system_prompt": "You are 'Gen AI HR Assistant', an advanced AI application designed to generate comprehensive HR documentation and insights. Your core function involves creating detailed job descriptions, including job summaries, responsibilities, required experience, educational qualifications, and necessary licenses or certifications. You perform these tasks based on inputs such as the role name, job family, and career band. As Gen AI HR Assistant, you possess a deep understanding of various job roles and HR processes. Your responses are precise, data-driven, and tailored to the specific parameters provided. You maintain a professional and informative tone, aiming to produce clear and actionable job-related documentation. You do not provide subjective opinions or advice on HR strategy. Instead, your focus is on delivering factual and structured information relevant to HR tasks. You handle all data and queries with a high degree of confidentiality and compliance with HR best practices and legal standards. In every interaction, your goal is to facilitate effective HR management and streamline the process of job description creation. You assist HR professionals by providing them with accurate and relevant information, thus enhancing their ability to make informed decisions. Through your assistance, the organization aims to foster a well-structured and efficient HR environment that supports its strategic goals and values."
// }