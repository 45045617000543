import { DefaultButton, Dropdown, IDropdownOption, IDropdownStyles, IStackTokens, Label, Stack, TextField, on } from '@fluentui/react'
import styles from './CustomizeCogSearchPrompts.module.css'
import { useEffect, useState } from 'react';
import { GetHRJDPromptTemplates, UpdatePromptTemplates } from '../../api';


const outerStackTokens: IStackTokens = { childrenGap: 5 };
const innerStackTokens: IStackTokens = { childrenGap: 5, padding: 10 };

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    width: 300,
  },
};
interface Props {
  canSavePrompt: boolean;
}

export const CustomizeCogSearchPrompts = ({ canSavePrompt }: Props) => {

  const [canPromptbeSaved, SetCanPromptbeSaved] = useState<boolean>(canSavePrompt)
  const [defaultPromptKey, SetDefaultPromptKey] = useState<string>("job_summary")


  const [HRCogSearchVSDropDownPromptTemplates, SetHRCogSearchVSDropDownPromptTemplates] = useState<IDropdownOption[]>([])

  const [selectedPromptTemplate, SetSelectedPromptTemplate] = useState<IDropdownOption>({ key: "job_summary", text: "Job Summary", data: { value: "" } })
  const [selectedPromptTemplatevalue, SetSelectedPromptTemplateValue] = useState<string>("")
  const [isPromptSaveStatus, SetPromptSaveStatus] = useState<string>("")
  const [isPromptTextChanged, SetPromptTextChanged] = useState<boolean>(false)

  const onPromptChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    if (item) {
      console.log(`Selected option: ${item.text}, Value: ${item.data?.value}`);
      SetPromptSaveStatus("")
      SetSelectedPromptTemplate(item);
      SetSelectedPromptTemplateValue(item.data?.value)

      SetPromptSaveStatus("")

    }
  }

  /* Cog Search Prompts */
  const onPromptTextChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    SetPromptSaveStatus("")
    SetSelectedPromptTemplateValue(newValue || "");
    SetPromptTextChanged(true)
  };

  const onSavePromptClicked = async () => {
    try {
      const result = await UpdatePromptTemplates("CogSearchVS", selectedPromptTemplate?.text, selectedPromptTemplatevalue)
      if (result === "success") {
        // what is the next step
        SetPromptSaveStatus("Document Search Prompt Template updated successfully.")

        // refetch from CosmosDB the whole list of prompts
        await GetPromptTemplates()

        let updateditem = HRCogSearchVSDropDownPromptTemplates.filter((v, i, a) => a.findIndex((v2) => v2.text === selectedPromptTemplate?.text) === i)[0];
        SetSelectedPromptTemplate(updateditem)
        SetSelectedPromptTemplateValue(updateditem["data"]["value"])

      } else {
        SetPromptSaveStatus("Failed to update the Document Search Prompt Template. Please retry.")
      }
    } catch (e) {
      SetPromptSaveStatus("Error in updating Document Search Prompt Template.")
    }
  }



  /* HRJD Load Prompts */
  const GetPromptTemplates = async () => {
    try {
      const result = await GetHRJDPromptTemplates("CogSearchVS");
      const dropdownOptions: IDropdownOption[] = Object.entries(result).map(([key, value]) => ({
        key,
        text: key, // .charAt(0).toUpperCase() + key.slice(1).replaceAll(/_/g, ' '), //  Convert snake_case to title case
        data: { value }, //  Optionally store the original value in data for reference
      }));
      SetHRCogSearchVSDropDownPromptTemplates(dropdownOptions)
    } catch (e: any) {
      //setHRPromptTemplates(defaultPromptTemplate)
    }
  }

  /* Save changes on save button click */
  const onUpdatePromptTemplates = async () => {
    onSavePromptClicked()
  }

  useEffect(() => {
    if (HRCogSearchVSDropDownPromptTemplates && HRCogSearchVSDropDownPromptTemplates.length > 0) {
      let defaultprompt = HRCogSearchVSDropDownPromptTemplates.filter((v, i, a) => a.findIndex((v2) => v2.text === defaultPromptKey) === i)[0];
      SetSelectedPromptTemplate(defaultprompt)
      SetSelectedPromptTemplateValue(defaultprompt["data"]["value"])
    }
  }, [HRCogSearchVSDropDownPromptTemplates])


  useEffect(() => {
    SetCanPromptbeSaved(canSavePrompt)
    SetDefaultPromptKey("job_summary")

    GetPromptTemplates()

  }, [])

  return <div className={styles.Section}>
    <div className={styles.ScrollableSection}>
      <div className={styles.SectionHeading}>
        <span className={styles.HRJDItemHeading}>
          Select Prompt for customizing AI responses:
        </span></div>
      <Stack className={styles.panel1} enableScopedSelectors tokens={outerStackTokens} >
        <Stack enableScopedSelectors tokens={innerStackTokens}>

          <Stack.Item grow>
            <Label className={styles.labelname}>Select Prompt:</Label>
            <Dropdown styles={dropdownStyles} className={styles.stackItemStyles1}
              selectedKey={selectedPromptTemplate ? selectedPromptTemplate.key : HRCogSearchVSDropDownPromptTemplates[0].key}
              onChange={onPromptChange}
              placeholder="Select Document Search prompt to continue"
              options={HRCogSearchVSDropDownPromptTemplates} disabled={false} /> </Stack.Item>
          <Stack.Item grow>
            <Label className={styles.labelname}>Prompt Text:</Label>
            <TextField onChange={onPromptTextChange} multiline autoAdjustHeight styles={{ root: { minWidth: "80% " } }} value={selectedPromptTemplatevalue} readOnly={!canPromptbeSaved} placeholder="Write an internal Job Summary for this employee by clearly describing the purpose of the role and it's value to T-Mobile. Return only the job summary, which should be 5 sentences and should avoid gendered language." />
          </Stack.Item>
          <Stack.Item grow className={styles.StackButtonsContainer}>
            {isPromptSaveStatus.length > 0 && (<div className={styles.PercentAllocationErrorSection}> <div className={styles.PercentAllocationErrorText}> {isPromptSaveStatus} </div> </div>)}
            {!canPromptbeSaved && <div className={styles.PercentAllocationErrorSection}> <div className={styles.PercentAllocationErrorText}> <b> You don't have admin privilege to update Prompts.</b> </div> </div>}
            {canPromptbeSaved && <DefaultButton onClick={() => onUpdatePromptTemplates()} className={styles.HRPrimaryButton} title="Click to save the changes">{" "}Save</DefaultButton>}
            {!canPromptbeSaved && <DefaultButton className={styles.HRGrayedButton} title="Need Admin Privilege to Update Prompts" disabled >{" "}Save</DefaultButton>}
          </Stack.Item>
        </Stack>

      </Stack>
    </div></div >
}
