import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import copy from 'copy-to-clipboard';
import styles from "./CopyCodeMarkdown.module.css";
interface CopyCodeMarkdownProps {
  markdownContent: string;
}

const CopyCodeMarkdown: React.FC<CopyCodeMarkdownProps> = ({ markdownContent }) => {
  const [copied, setCopied] = useState<{ [key: string]: boolean }>({});

  const handleCopyClick = (code: string) => {
    copy(code);
    setCopied((prevCopied) => ({ ...prevCopied, [code]: true }));

    // Reset the "Copied" state after a short delay
    setTimeout(() => {
      setCopied((prevCopied) => ({ ...prevCopied, [code]: false }));
    }, 2000);
  };


  const components = {
    code: ({ node, inline, className, children, ...props }: any) => {
      const isCopied = copied[children] || false;
      // console.log('className', className);

      return (
        (className ?
          <div className={styles.codecontainer}>
            <div className={styles.codeblock}>
              <div className={styles.language}>{className.replace('language-', '')}</div>
              <button className={styles.buttoncopy} onClick={() => handleCopyClick(children)} disabled={isCopied}>
                {isCopied ? 'Copied!' : 'Copy'}
              </button>
            </div>
            <pre>
              <code className={className} {...props}>{children}</code>
            </pre>
          </div>
          :
          <div className={styles.codecontainer}>
            <pre>
              <code className={className} {...props}>{children}</code>
            </pre>
          </div>
        )
      )
    }
  };
  // return (
  //   <div className="copy-code-markdown-container">
  //     <ReactMarkdown components={components} children={markdownContent} rehypePlugins={[rehypeRaw]} />
  //   </div>
  // );

  // return (
  //   <div>
  //     <div>
  //       <span>{language}</span>
  //       <button>
  //         Copy
  //       </button>
  //       <div className="copy-code-markdown-container">
  //         <ReactMarkdown children={markdownContent} rehypePlugins={[rehypeRaw]} />
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="copy-code-markdown-container">
      <ReactMarkdown components={components}>{markdownContent}</ReactMarkdown>
    </div>
  );
};

export default CopyCodeMarkdown;
