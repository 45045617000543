import { atom } from 'jotai';

export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export const enum SearchTypes {
    Similarity = "similarity",
    Hybrid = "hybrid",
    HybridReRank = "hybridrerank"
}

export type ChatMessage = {
    id: string; // Guid
    type: string; // "Message"
    role: string; // "User" || "Assistant"
    sessionId: string; // Guid (same as ChatSession.id)
    tokens: number; // Number of tokens in the message
    timestamp: string; // ISO 8601
    content: string;
};

export type ChatSession = {
    id: string; // Guid
    type: string; // "Session"
    userName: string;
    sessionId: string; // Guid
    feature: string; // "chat" || "ask" || "sql"
    tokenUsed: number; // Number of tokens in all the message
    name: string;
    timestamp: string; // ISO 8601
    indexType: string; // "pinecone" || "cogsearch" || "cogsearchvs"
    indexName: string;
    indexId: string; // Guid
    llmModel: string; // "openai" || "azureopenai"
    chainType: string // "stuff" || "refine" || "mapreduce"
    embeddingModelType: string; // "azureopenai" || "openai"
    messages?: ChatMessage[];
};

export const chatSessionsAtom = atom<ChatSession[]>([]);

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    chainType?: string;
    tokenLength?: number;
    indexType?: string;
    indexes?: any;
    autoSpeakAnswers?: boolean;
    embeddingModelType?: string;
    firstSession?: boolean;
    session?: string;
    sessionId?: string;
    functionCall?: boolean;
    useInternet?: boolean;
    deploymentType?: string;
    fileName?: string;
    topics?: string[],
    searchType?: SearchTypes;
    userName?: string;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
    sources?: string;
    nextQuestions?: string;
};

export type EvalRunResponse = {
    statusUri: string;
    error?: string;
}

export type SqlResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
    sources?: string;
    nextQuestions?: string;
    toolInput?: string;
    observation?: string;
};

export type SpeechTokenResponse = {
    Token: string;
    Region: string
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type ChatRespValues = {
    recordId: number,
    data: AskResponse
};

export type ChatResponse = {
    values: ChatRespValues[];
};

export type EvalRespValues = {
    recordId: number,
    data: EvalRunResponse
};

export type EvalResponse = {
    values: EvalRespValues[];
};

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export type HRJDRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    chainType?: string;
    tokenLength?: number;
    indexType?: string;
    indexes?: any;
    autoSpeakAnswers?: boolean;
    embeddingModelType?: string;
    deploymentType?: string;
    fileName?: string;
    topics?: string[],
    searchType?: SearchTypes;
    userName?: string;
    job_profile_name?: string;
    job_family_group?: string;
    job_family?: string;
    job_career_band?: string;
    job_details_optional?: string;
    job_summary?: string
    existingdata?: string;
    userFeedback?: string;
    promptKey?: string
    responseKey?: string
};

export type HRJDRequest = {
    approach: Approaches;
    overrides?: HRJDRequestOverrides;
};
export type HRJDJSData = {
    jobSummary: string;
}

export type jobresponsibilityResponseType = {
    index?: number;
    responsibility: string;
    percent_of_time: string;
}
export type educationResponseType = {
    index?: number;
    education_level: string;
    area_of_study: string;
    requirement_level: string;
}
export type experienceResponseType = {
    index?: number;
    experience: string;
    experience_time: string;
    requirement_level: string;
}
export type ksasResponseType = {
    index?: number;
    knowledge_skill_or_ability: string;
    details: string;
    requirement_level: string;
}
export type licenseOrCertificationResponseType = {
    index?: number;
    license_or_certification: string;
    details: string;
    requirement_level: string;
}

export type HRJDData = {
    jobSummary: string;
    jobSummaryException: string;
    jobEducation: educationResponseType[];
    jobEducationException: string;
    jobExperience: experienceResponseType[];
    jobExperienceException: string;
    jobResponsibilities: jobresponsibilityResponseType[];
    jobResponsibilitiesException: string;
    jobKsas: ksasResponseType[];
    jobKsasException: string;
    jobLc: licenseOrCertificationResponseType[];
    jobLcException: string;
}
export type HRJDAnswer = {
    answer: HRJDData;
    error: string;
    data_points: string;
    thoughts: string;
    sources: string;
    nextQuestions: string;
};

export type HRJDRespValues = {
    recordId: number,
    data: HRJDAnswer
};

export type HRJDResponse = {
    values: HRJDRespValues[];
};


export type HRPromptTemplates = {
    "job_summary": string;
    "job_responsibilities": string;
    "job_experience": string;
    "job_education": string;
    "job_ksas": string;
    "job_lc": string;
}

export type HRFeedbackTemplates = {
    "job_summary": string;
    "job_responsibilities": string;
    "job_experience": string;
    "job_education": string;
    "job_ksas": string;
    "job_lc": string;
}