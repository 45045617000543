// src/utils/AuthUtils.ts

function checkRole(userAppRoles: string[], roleName: string, excludeRoleName: string) {
  return userAppRoles.some((str: string) =>
    str.includes(roleName) || (str.endsWith(roleName) && str !== excludeRoleName)
  );
}

export function userAuthorization(pageCalledFrom: string, requiredRoles: string[]) {
  console.log("userAuthorization called from " + pageCalledFrom);
  // const savedRoles = sessionStorage.getItem("mappedRoles");
  // if (savedRoles != null && savedRoles !== undefined) {
  //   return JSON.parse(savedRoles) as boolean[];
  // }
  const mappedRoles: boolean[] = [];
  const userAppRoles1 = sessionStorage.getItem("userRoles");
  const userAppRoles: string[] = JSON.parse(userAppRoles1 || "[]");

  // loop through each role in requiredRoles and check if it exists in Generic App Roles
  requiredRoles.forEach((role) => {
    const roleFound = userAppRoles.some((userRole) => userRole.toLowerCase().includes(role.toLowerCase()));
    mappedRoles.push(roleFound);
  });

  // Check for Admin role
  const isAdmin = checkRole(userAppRoles, "Admin", "OpenAIAdmin");
  mappedRoles.push(isAdmin);

  // Check for Users role
  const isUsers = checkRole(userAppRoles, "Users", "OpenAIUsers");
  mappedRoles.push(isUsers);

  // sessionStorage.setItem("mappedRoles", JSON.stringify(mappedRoles));
  return mappedRoles;
}
