import { useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
// import ChatGpt from "./pages/chatgpt/ChatGpt";
import Upload from "./pages/upload/Upload";
import FAQ from "./pages/FAQs/FAQs";
import HRHome from "./pages/homeHR/HomeHR";
import DeveloperTools from "./pages/developertools/DeveloperTools";
// Application Insights
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./auth/ApplicationInsightsService";
// MSAL imports
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "msal";
import { scopes } from "./auth/authConfig";
import Home from "./pages/home/Home";

initializeIcons();

function App() {
  //Authentication using azure AD
  const { login, result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    { scopes: scopes }
  );
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, { scopes: scopes });
    }
  }, [error]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* <Route path="upload" element={<Upload />} /> */}
            <Route path="chatGpt/:chatID" element={<Chat />} />
            <Route path="developer" element={<DeveloperTools />} />
            {/* <Route path="admin" element={<Admin />} /> */}
            <Route path="upload" index element={<Upload />} />
            <Route path="faq" index element={<FAQ />} />
            <Route path="*" element={<NoPage />} />
            <Route path="hrhome" index element={<HRHome />} />
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </HashRouter>
    </AppInsightsContext.Provider>
  );
}

export default App;
