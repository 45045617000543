import React, { useState } from 'react';
import { saveAs } from 'file-saver'
import { docxTemplate } from '../../api';
import styles from "./MailMerge.module.css";
import { DefaultButton } from '@fluentui/react';
import { SparkleFilled } from "@fluentui/react-icons";
interface jobresponsibilityType { index?: number; responsibility: string; percentOfTime: string; }
interface educationInfoType { index?: number; educationLevel: string; areaOfStudy: string; requirementLevel: string; }
interface experienceInfoType { index?: number; experience: string; experienceTime: string; requirementLevel: string; }
interface ksasType { index?: number; knowledgeSkillOrAbility: string; details: string; requirementLevel: string; }
interface licenseOrCertificationType { index?: number; licenseOrCertification: string; details: string; requirementLevel: string; }

interface Props {
  jsondata: {
    "job_summary": string,
    "job_profile_name": string,
    "job_family_group": string,
    "job_family": string,
    "job_career_band": string,
    "responsibilities": jobresponsibilityType[],
    "licenseOrCertificationInfo": licenseOrCertificationType[],
    "educationInfo": educationInfoType[],
    "experienceInfo": experienceInfoType[],
    "KSASInfo": ksasType[]
  };
}
const MailMergeComponent: React.FC<Props> = ({ jsondata }) => {
  const [data, setData] = useState(jsondata);
  const [downloadingDocument, setDownloadingDocument] = useState(false);
  const [primaryFillColor, setPrimaryFillColor] = useState("white");

  const generateDocument = async () => {
    if (data.responsibilities.length === 0) {
      data.responsibilities.push({ index: 0, responsibility: "NA", percentOfTime: "" });
    }
    if (data.licenseOrCertificationInfo.length === 0) {
      data.licenseOrCertificationInfo.push({ index: 0, licenseOrCertification: "NA", details: "NA", requirementLevel: "" });
    }
    if (data.educationInfo.length === 0) {
      data.educationInfo.push({ index: 0, educationLevel: "NA", areaOfStudy: "NA", requirementLevel: "" });
    }
    if (data.experienceInfo.length === 0) {
      data.experienceInfo.push({ index: 0, experience: "NA", experienceTime: "NA", requirementLevel: "" });
    }
    if (data.KSASInfo.length === 0) {
      data.KSASInfo.push({ index: 0, knowledgeSkillOrAbility: "NA", details: "NA", requirementLevel: "" });
    }

    data.job_profile_name = data.job_profile_name.replaceAll('&', 'and');

    setDownloadingDocument(true);
    const outputBuffer = await docxTemplate(data)
    // Use FileSaver to save the Blob as a file
    const safe_job_profile_name = jsondata.job_profile_name.replace(/[^a-zA-Z0-9]/g, '');
    await saveAs(outputBuffer, `${safe_job_profile_name}.docx`);
    setDownloadingDocument(false);
  };

  return (
    <div>
      <DefaultButton
        className={styles.HRPrimaryButton}
        onClick={() => {
          generateDocument();
        }}
        onMouseEnter={() => setPrimaryFillColor("black")}
        onMouseLeave={() => setPrimaryFillColor("white")}
      >
        {downloadingDocument ? (
          <p className={styles.width100}>
            <span>
              <SparkleFilled
                fontSize={"20px"}
                primaryFill={primaryFillColor}
                aria-hidden="true"
                aria-label="HR JD Logo"
              />
            </span>
            <span>Downloading                 </span>
            <span className={styles.loadingdots}></span>
          </p>
        ) : (
          <p className={styles.width100}>
            <span>
              <SparkleFilled
                fontSize={"20px"}
                primaryFill={primaryFillColor}
                aria-hidden="true"
                aria-label="HR JD Logo"
              />
            </span>
            <span> Download Job Description</span>
          </p>
        )}
      </DefaultButton>
    </div>
  );
};

export default MailMergeComponent;
