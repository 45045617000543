import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// MSAL imports

import { createMsalInstance, loginRedirectRequest } from "./auth/authConfig";
import { MsalProvider, useMsalAuthentication } from '@azure/msal-react';
import { getAADCreds } from './api';

// get the client id and authority from the api
const AADresponse = await getAADCreds()
const ClientID: string = AADresponse.CLIENTID;
const Authority: string = AADresponse.AUTHORITY;

// get the instance of the msal object from the MSAL context
export const msalInstance = createMsalInstance(ClientID, Authority);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);


