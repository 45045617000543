import { useState, useEffect } from "react";
import {
  Label,
  Dropdown, IDropdownStyles, IDropdownOption,
  ILabelStyles,
  IPivotItemProps, Pivot, PivotItem,
  PrimaryButton, Checkbox, DefaultButton, TextField, Spinner,
  IPivotStyleProps, IStyleFunctionOrObject, IPivotStyles,
  Stack, IStackStyles, IStackTokens, IStackItemStyles, ILabelProps, SpinnerSize
} from '@fluentui/react';
import { Card, CardFooter, makeStyles } from "@fluentui/react-components";

import { BarcodeScanner24Filled } from "@fluentui/react-icons";
import { processDoc, uploadFile, uploadBinaryFile, refreshIndex, verifyPassword } from "../../api";

import styles from "./Upload.module.css";

import { useDropzone } from 'react-dropzone'
import { uploadoptions, uploadconnectors, embeddingOptions, textSplitterOptions } from "../../utils/appObjects";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { userAuthorization } from "../../auth/AuthUtils";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const buttonStyles = makeStyles({
  innerWrapper: {
    columnGap: "15px",
    display: "flex",
  },
  outerWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "15px",
  },
});

const Upload = () => {

  const [files, setFiles] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [selectedPdf, setSelectedPdf] = useState<IDropdownOption>();

  const [selectedUserGroupItem, setSelectedUserGroup] = useState<IDropdownOption>();
  const [optionsGroup, setOptionsUserGroups] = useState<any>([])
  const [isBlobLoading, setIsBlobLoading] = useState<boolean>(false);
  const [optionsPdf, setOptionsPdf] = useState<any>([])
  const [indexMapping, setIndexMapping] = useState<{ key: string; iType: string; name: string; indexName: string; embedded: boolean }[]>();
  const [embedded, setEmbedded] = useState(false);
  const [indexNs, setIndexNs] = useState('');
  const [existingIndexName, setExistingIndexName] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<string>();

  const [selectedItem, setSelectedItem] = useState<IDropdownOption>();
  const [selectedEmbeddingItem, setSelectedEmbeddingItem] = useState<IDropdownOption>();
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const [multipleDocs, setMultipleDocs] = useState(false);
  const [existingIndex, setExistingIndex] = useState(false);

  const [indexName, setIndexName] = useState('');
  const [uploadText, setUploadText] = useState('');
  const [lastHeader, setLastHeader] = useState<{ props: IPivotItemProps } | undefined>(undefined);
  const [missingIndexName, setMissingIndexName] = useState(false)
  const [parsedWebUrls, setParsedWebUrls] = useState<String[]>([''])
  const [webPages, setWebPages] = useState('')

  const [selectedConnector, setSelectedConnector] = useState<IDropdownOption>();
  const [connectorOptions, setConnectorOptions] = useState<any>([])
  const [blobConnectionString, setBlobConnectionString] = useState('');
  const [blobContainer, setBlobContainer] = useState('');
  const [blobPrefix, setBlobPrefix] = useState('');
  const [blobName, setBlobName] = useState('');
  const [s3Bucket, setS3Bucket] = useState('');
  const [s3Key, setS3Key] = useState('');
  const [s3AccessKey, setS3AccessKey] = useState('');
  const [s3SecretKey, setS3SecretKey] = useState('');
  const [s3Prefix, setS3Prefix] = useState('');

  const [uploadPassword, setUploadPassword] = useState('');
  const [missingUploadPassword, setMissingUploadPassword] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const [selectedTextSplitterItem, setSelectedTextSplitterItem] = useState<IDropdownOption>();

  // const labelStyles: Partial<IStyleSet<ILabelStyles>> = {

  const userGroups = JSON.parse(sessionStorage.getItem('userRoles') || '[]') as string[]
  const requiredRoles = ['OpenAIAdmin', 'OpenAIUsers'];
  const [isGenericAdmin, setIsGenericAdmin] = useState(false)
  const [isGroupAdmin, setIsGroupAdmin] = useState(false)
  const [userName, setUserName] = useState('')

  const stackStyles: IStackStyles = { root: { height: '100%', } };
  const stackItemStyles: IStackItemStyles = { root: { alignItems: 'left', display: 'flex', justifyContent: 'left', flexDirection: 'column', label: { color: 'white' } } };

  const checkboxStyles: Partial<ILabelStyles> = { root: { label: { div: { border: '1px solid rgb(255,255,255)' }, span: { color: 'white' } } } };
  const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = { link: { selectors: { '&.is-selected::before': { content: "''", position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: 'red', borderBottom: '2px solid #e20074' } } } };

  // Tokens definition
  const outerStackTokens: IStackTokens = { childrenGap: 5 };
  const innerStackTokens: IStackTokens = { childrenGap: 5, padding: 10, };


  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 100000000,
    accept: {
      'application/pdf': ['.pdf'],
      'application/word': ['.doc', '.docx'],
      'application/csv': ['.csv'],
      'text/plain': ['.txt']
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file)))
      setIndexName(acceptedFiles[0].name.split('.').slice(0, -1).join('.'));
    }
  })

  const renderFilePreview = (file: File) => {
    if (file.type.startsWith('image')) {
      return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />
    } else {
      return <BarcodeScanner24Filled />
    }
  }

  const processblobs = (loadedblobs: any, indexT: string) => {
    const files = []
    const indexType = []
    const blobs = loadedblobs
    for (const blob of blobs.values) {
      if (blob.indexType == indexT) {
        files.push({ text: blob.indexName, key: blob.namespace })
      }
      indexType.push({ key: blob.namespace, iType: blob.indexType, summary: blob.summary, qa: blob.qa, name: blob.name, indexName: blob.indexName, indexGroup: blob.groupName, indexOwner: blob.owner, embedded: blob.embedded = (blob.embedded == "true") })

    }
    var uniqFiles = files.filter((v, i, a) => a.findIndex(v2 => (v2.key === v.key)) === i)
    setOptionsPdf(uniqFiles)
    var defaultKey = uniqFiles[0].key

    if (uniqFiles.length > 0) {
      defaultKey = uniqFiles[0].key
      setSelectedPdf(uniqFiles[0])

      var uniqIndexType = indexType.filter((v, i, a) => a.findIndex(v2 => (v2.key === v.key)) === i)

      for (const item of uniqIndexType) {
        if (item.key == defaultKey) {
          setSelectedIndex(item.iType)
          setExistingIndexName(item.indexName)
          if (existingIndex)
            setIndexName(item.indexName)
          setIndexNs(item.key)
          setEmbedded(item.embedded)
        }
      }

      if (!existingIndex)
        setIndexName('')
      setIndexMapping(uniqIndexType)
      setIsBlobLoading(false)
    }
    setIsBlobLoading(false)
  }

  const refreshBlob = async (indexT: string) => {
    const blobs1 = sessionStorage.getItem('blobs')
    if (blobs1 != null && blobs1 != undefined) {
      const blobs = JSON.parse(blobs1)
      if (blobs.values != null && blobs.values.length > 0)
        processblobs(blobs, indexT)
      else {
        const blobs = await refreshIndex(userGroups)
        if (blobs != 'Error') {
          sessionStorage.setItem('blobs', JSON.stringify(blobs))
          processblobs(blobs, indexT)
        }
      }
    }
    else {
      const blobs = await refreshIndex(userGroups)
      if (blobs != 'Error') {
        sessionStorage.setItem('blobs', JSON.stringify(blobs))
        processblobs(blobs, indexT)
      }
    }
  }

  const onChangePdf = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    setSelectedPdf(item);
    const defaultKey = item?.key
    const defaultName = item?.text
    if (defaultKey == undefined || defaultKey == '') {
      indexMapping?.findIndex((item) => {
        if (item.indexName == defaultName) {
          setSelectedIndex(item.iType)
          setExistingIndexName(item.indexName)
          if (existingIndex)
            setIndexName(item.indexName)
          setIndexNs(item.key)
          setEmbedded(item.embedded)
        }
      })
      if (!existingIndex)
        setIndexName('')
    }
    else {
      indexMapping?.findIndex((item) => {
        if (item.key == defaultKey) {
          setSelectedIndex(item.iType)
          setExistingIndexName(item.indexName)
          if (existingIndex)
            setIndexName(item.indexName)
          setIndexNs(item.key)
          setEmbedded(item.embedded)
        }
      })
      if (!existingIndex)
        setIndexName('')
    }
  };


  const handleRemoveFile = (file: File) => {
    const uploadedFiles = files
    //const filtered = uploadedFiles.filter(i => i.name !== file.name)
    const filtered = uploadedFiles.filter((i: { name: string; }) => i.name !== file.name)
    setFiles([...filtered])
  }

  const handleRemoveAllFiles = () => {
    setFiles([])
    setIndexName('')
  }
  const fileList = files.map((file: File, index: number) => (
    <div key={index}>
      <div className='file-details'>
        <div className='file-preview'>{renderFilePreview(file)}</div>
        <div key={file.name}>
          {file.name}
          &nbsp;
          {Math.round(file.size / 100) / 10 > 1000
            ? (Math.round(file.size / 100) / 10000).toFixed(1) + ' MB'
            : (Math.round(file.size / 100) / 10).toFixed(1) + ' KB'}
        </div>
      </div>
      <DefaultButton onClick={() => handleRemoveFile(file)} disabled={loading ? true : false}>Remove File</DefaultButton>
    </div>
  ));
  

  const handleUploadFiles = async () => {
    if (uploadPassword == '') {
      setMissingUploadPassword(true)
      return
    }

    if (files.length > 1) {
      setMultipleDocs(true)
      if (indexName == '') {
        setMissingIndexName(true)
        return
      }
    }

    if (existingIndex && existingIndexName == '') {
      setMissingIndexName(true)
      return
    }

    setLoading(true)
    await verifyPassword("upload", uploadPassword)
      .then(async (verifyResponse: string) => {
        if (verifyResponse == "Success") {
          setUploadText("Password verified")
          setUploadText('Uploading your document...')
          let count = 0
          await new Promise((resolve) => {
            files.forEach(async (element: File) => {
              //await uploadFileToBlob(element)
              try {
                const formData = new FormData();
                formData.append('file', element);
                formData.append('groupName', selectedUserGroupItem?.key as string)
                formData.append('userName', userName as string)
                formData.append('roleGroup', JSON.stringify(userGroups))
                await uploadBinaryFile(formData, indexName)
              }
              finally {
                count += 1
                if (count == files.length) {
                  resolve(element)
                }
              }
            })
          })

          setUploadText("File uploaded successfully.  Now indexing the document.")

          await processDoc(String(selectedItem?.key), "files", (files.length > 1 ? "true" : "false"),
            existingIndex ? existingIndexName : indexName, files,
            blobConnectionString, blobContainer, blobPrefix, blobName,
            selectedUserGroupItem?.key as string, userName as string,
            s3Bucket, s3Key, s3AccessKey, s3SecretKey, s3Prefix,
            existingIndex ? "true" : "false", existingIndex ? indexNs : '',
            String(selectedEmbeddingItem?.key), String(selectedTextSplitterItem?.key))
            .then((response: string) => {
              if (response == "Success") {
                setUploadText("Completed Successfully.  You can now search for your document.")
              }
              else {
                setUploadText(response)
              }
              setFiles([])
              setLoading(false)
              setMissingIndexName(false)
              setMultipleDocs(false)
              setIndexName('')
            })
            .catch((error: string) => {
              setUploadText(error)
              setFiles([])
              setLoading(false)
              setMissingIndexName(false)
              setMultipleDocs(false)
              setIndexName('')
            })
          refreshBlob(String(selectedItem?.key))
        }
        else {
          setUploadText(verifyResponse)
        }
      })
      .catch((error: string) => {
        setUploadText(error)
        setFiles([])
        setLoading(false)
        setMissingIndexName(false)
        setMultipleDocs(false)
        setIndexName('')
      })
    setLoading(false)
  }

  const onProcessWebPages = async () => {
    if (uploadPassword == '') {
      setMissingUploadPassword(true)
      return
    }

    if (existingIndex && existingIndexName == '') {
      setMissingIndexName(true)
      return
    }

    const processPage = parsedWebUrls.filter(function (e) { return e })
    if (processPage?.length == 0) {
      setUploadText('Provide the list of URL to Process...')
      return
    }
    else {
      if (indexName == '') {
        setMissingIndexName(true)
        return
      }
      setLoading(true)
      await verifyPassword("upload", uploadPassword)
        .then(async (verifyResponse: string) => {
          if (verifyResponse == "Success") {
            setUploadText("Password verified")

            setUploadText('Uploading your document...')

            const fileContentsAsString = "Will Process the Webpage and index it with IndexName as " + indexName + " and the URLs are " + processPage
            await uploadFile(indexName + ".txt", fileContentsAsString, "text/plain")
              .then(async () => {
                setUploadText("File uploaded successfully.  Now indexing the document.")
                await processDoc(String(selectedItem?.key), "webpages", "false", existingIndex ? existingIndexName : indexName,
                  processPage, blobConnectionString,
                  blobContainer, blobPrefix, blobName,
                  selectedUserGroupItem?.key as string, userName as string,
                  s3Bucket, s3Key, s3AccessKey,
                  s3SecretKey, s3Prefix, existingIndex ? "true" : "false", existingIndex ? indexNs : '',
                  String(selectedEmbeddingItem?.key), String(selectedTextSplitterItem?.key))
                  .then((response) => {
                    if (response == "Success") {
                      setUploadText("Completed Successfully.  You can now search for your document.")
                    }
                    else {
                      setUploadText("Failure to upload the document.")
                      setUploadError(true)
                    }
                    setWebPages('')
                    setParsedWebUrls([''])
                    setLoading(false)
                    setMissingIndexName(false)
                    setIndexName('')
                  })
                  .catch((error: string) => {
                    setUploadText(error)
                    setUploadError(true)
                    setWebPages('')
                    setParsedWebUrls([''])
                    setLoading(false)
                    setMissingIndexName(false)
                    setIndexName('')
                  })
                refreshBlob(String(selectedItem?.key))
              })
          }
          else {
            setUploadText(verifyResponse)
          }
        })
        .catch((error: string) => {
          setUploadText(error)
          setUploadError(true)
          setWebPages('')
          setParsedWebUrls([''])
          setLoading(false)
          setMissingIndexName(false)
          setIndexName('')
        })
      setLoading(false)
    }
  }

  const onProcessConnectors = async () => {
    if (uploadPassword == '') {
      setMissingUploadPassword(true)
      return
    }
    if (indexName == '') {
      setMissingIndexName(true)
      return
    }
    if (blobConnectionString == '' && (selectedConnector?.key == 'adlsfile' || selectedConnector?.key == 'adlscontainer')) {
      setMissingIndexName(true)
      return
    }
    if (blobContainer == '' && (selectedConnector?.key == 'adlsfile' || selectedConnector?.key == 'adlscontainer')) {
      setMissingIndexName(true)
      return
    }
    if (blobName == '' && selectedConnector?.key == 'adlsfile') {
      setMissingIndexName(true)
      return
    }
    if (s3AccessKey == '' && (selectedConnector?.key == 's3file' || selectedConnector?.key == 's3container')) {
      setMissingIndexName(true)
      return
    }
    if (s3SecretKey == '' && (selectedConnector?.key == 's3file' || selectedConnector?.key == 's3container')) {
      setMissingIndexName(true)
      return
    }
    if (s3Bucket == '' && (selectedConnector?.key == 's3file' || selectedConnector?.key == 's3container')) {
      setMissingIndexName(true)
      return
    }
    if (s3Key == '' && (selectedConnector?.key == 's3file')) {
      setMissingIndexName(true)
      return
    }

    if (existingIndex && existingIndexName == '') {
      setMissingIndexName(true)
      return
    }

    setLoading(true)
    await verifyPassword("upload", uploadPassword)
      .then(async (verifyResponse: string) => {
        if (verifyResponse == "Success") {
          setUploadText("Password verified")

          setUploadText('Uploading your document...')

          const fileContentsAsString = "Will Process the connector document and index it with IndexName as " + indexName
          await uploadFile(indexName + ".txt", fileContentsAsString, "text/plain")
            .then(async () => {
              setUploadText("File uploaded successfully.  Now indexing the document.")
              setUploadText('Processing data from your connector...')
              await processDoc(String(selectedItem?.key), String(selectedConnector?.key), "false", existingIndex ? existingIndexName : indexName,
                '', blobConnectionString,
                blobContainer, blobPrefix, blobName,
                selectedUserGroupItem?.key as string, userName as string,
                s3Bucket, s3Key, s3AccessKey,
                s3SecretKey, s3Prefix, existingIndex ? "true" : "false", existingIndex ? indexNs : '',
                String(selectedEmbeddingItem?.key), String(selectedTextSplitterItem?.key))
                .then((response) => {
                  if (response == "Success") {
                    setUploadText("Completed Successfully.  You can now search for your document.")
                  }
                  else {
                    setUploadText("Failure to upload the document.")
                  }
                  setLoading(false)
                  setMissingIndexName(false)
                  setIndexName('')
                  setBlobConnectionString('')
                  setBlobContainer('')
                  setBlobPrefix('')
                  setBlobName('')
                  setS3Bucket('')
                  setS3Key('')
                  setS3AccessKey('')
                  setS3SecretKey('')
                  setS3Prefix('')
                })
                .catch((error: string) => {
                  setUploadText(error)
                  setLoading(false)
                  setMissingIndexName(false)
                  setIndexName('')
                  setBlobConnectionString('')
                  setBlobContainer('')
                  setBlobPrefix('')
                  setBlobName('')
                  setS3Bucket('')
                  setS3Key('')
                  setS3AccessKey('')
                  setS3SecretKey('')
                  setS3Prefix('')
                })
              refreshBlob(String(selectedItem?.key))
            })
        }
        else {
          setUploadText(verifyResponse)
        }
      })
      .catch((error: string) => {
        setUploadText(error)
        setLoading(false)
        setMissingIndexName(false)
        setIndexName('')
        setBlobConnectionString('')
        setBlobContainer('')
        setBlobPrefix('')
        setBlobName('')
        setS3Bucket('')
        setS3Key('')
        setS3AccessKey('')
        setS3SecretKey('')
        setS3Prefix('')
      })
    setLoading(false)
  }

  const onMultipleDocs = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    setMultipleDocs(!!checked);
  };

  const onExistingIndex = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    setExistingIndex(!!checked);
    checked ? setIndexName(selectedPdf ? selectedPdf.text as string : optionsPdf[0].text as string) : setIndexName('')
  };

  const onrefreshBlobChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    setSelectedItem(item);
    refreshBlob(String(item?.key))
  };

  const onGroupChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    setSelectedUserGroup(item);
  };

  const onEmbeddingChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    setSelectedEmbeddingItem(item);
  };

  const onTextSplitterChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    setSelectedTextSplitterItem(item);
  };

  const onChangeIndexName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setIndexName(newValue || '');
  };

  const onWebPageChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    let webPage = newValue?.split("\n")
    webPage = webPage == undefined ? [''] : webPage.filter(function (e) { return e });
    setParsedWebUrls(webPage);
  };

  const onConnectorChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    setSelectedConnector(item);
  };

  const onBlobConnectionString = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setBlobConnectionString(newValue || "");
  };

  const onBlobContainer = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setBlobContainer(newValue || "");
  };

  const onBlobPrefix = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setBlobPrefix(newValue || "");
  };

  const onUploadPassword = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setUploadPassword(newValue || "");
    if (newValue == '') {
      setMissingUploadPassword(true)
    }
    else {
      setMissingUploadPassword(false)
    }
  };

  const onBlobName = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setBlobName(newValue || "");
  };

  const onS3Bucket = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setS3Bucket(newValue || "");
  };

  const onS3Key = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setS3Key(newValue || "");
  };

  const onS3AccessKey = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setS3AccessKey(newValue || "");
  };

  const onS3SecretKey = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setS3SecretKey(newValue || "");
  };

  const onS3Prefix = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setS3Prefix(newValue || "");
  };

  const setUserGroups = async () => {
    const groupsOptions = []
    let userGroups1 = sessionStorage.getItem("userRoles")
    if (userGroups1 != null && userGroups1 != undefined) {
      const userGroups = JSON.parse(userGroups1) as string[]
      for (const group of userGroups) {
        if (group.toLowerCase().includes("admin")) {
          groupsOptions.push({
            text: group.replace("OpenAI", ""),
            key: group
          })
        }
        setSelectedUserGroup(groupsOptions[0])
        setOptionsUserGroups(groupsOptions)
      }
      const roleStatus = userAuthorization("Upload", requiredRoles);

      // get the account info from the cache
      const account1 = sessionStorage.getItem("userAccount")
      if (account1 != null && account1 != undefined) {
        const account: AccountInfo = JSON.parse(account1)
        setUserName(account?.username);
        console.log(userName)
      }

      // Check if the user has the specific role you want
      const isGenericAdmin = roleStatus[0];
      const isGroupAdmin = roleStatus[2];

      setIsGenericAdmin(isGenericAdmin);
      setIsGroupAdmin(isGroupAdmin);

    }
  }

  useEffect(() => {
    setSelectedItem(uploadoptions[0])
    setConnectorOptions(uploadconnectors)
    setSelectedConnector(uploadconnectors[0])
    setSelectedEmbeddingItem(embeddingOptions[0])
    setSelectedTextSplitterItem(textSplitterOptions[0])
    setUserGroups()
    refreshBlob(uploadoptions[0].key as string)
  }, [])

  return (
    <>
      <AuthenticatedTemplate>
        {
          (/Mobi|Android/i.test(navigator.userAgent))
            ? (
              <div className={styles.NotAuthorizedParentcontainer}>
                <div className={styles.NotAuthorizedTopcontainer}>
                  <div className={styles.svgcontainer}></div>
                </div>
                <div className={styles.NotAuthorizedContainer}>
                  <div className={styles.NotAuthorizedBox}>
                    <div className={styles.NotAuthorizedTextContainer}>
                      <div className={styles.NotAuthorizedPTextheader}>
                        <h2>Upload Documents.</h2>
                      </div>
                    </div>
                    <div className={styles.NotAuthorizedText}>Upload functionality is not available on mobile devices. Please use laptop/desktop to upload documents.</div>
                  </div>
                </div>
              </div>
            )
            : null
        }
        {
          (isGenericAdmin || isGroupAdmin)
            ? (
              <div className={styles.chatAnalysisPanel}>
                <Stack className={styles.panel1} enableScopedSelectors tokens={outerStackTokens}>
                  <Stack enableScopedSelectors tokens={innerStackTokens}>
                    <Stack.Item grow className={styles.stackItemStyles1}>
                      <Label className={styles.labelname}>Index Type</Label>
                      <Dropdown
                        className={styles.stackItemStyles1}
                        selectedKey={selectedItem ? selectedItem.key : undefined}
                        onChange={onrefreshBlobChange}
                        defaultSelectedKey="cogsearchvs"
                        placeholder="Select an Index Type"
                        options={uploadoptions}
                        disabled={false}
                        styles={dropdownStyles}
                      />
                    </Stack.Item>
                    <Stack.Item grow styles={stackItemStyles}>
                      <Label className={styles.labelname}>Embedding Model</Label>
                      <Dropdown
                        className={styles.stackItemStyles1}
                        selectedKey={selectedEmbeddingItem ? selectedEmbeddingItem.key : undefined}
                        onChange={onEmbeddingChange}
                        defaultSelectedKey="azureopenai"
                        placeholder="Select an Embedding Model"
                        options={embeddingOptions}
                        disabled={false}
                        styles={dropdownStyles}
                      />
                    </Stack.Item>
                    <Stack.Item grow styles={stackItemStyles}>
                      <Label className={styles.labelname}>Upload Password:</Label>
                      <TextField onChange={onUploadPassword}
                        errorMessage={!missingUploadPassword ? '' : "Note - Upload Password is required for Upload Functionality"} />
                    </Stack.Item>
                    <Stack.Item grow styles={stackItemStyles}>
                      <Checkbox boxSide="end" label="Existing Index?" checked={existingIndex} onChange={onExistingIndex} styles={checkboxStyles} />
                      {existingIndex ? (
                        <Dropdown
                          className={styles.stackItemStyles1}
                          selectedKey={selectedPdf ? selectedPdf.key : undefined}
                          // eslint-disable-next-line react/jsx-no-bind
                          onChange={onChangePdf}
                          placeholder="Select an PDF"
                          options={optionsPdf}
                          styles={dropdownStyles}
                        />) : (
                        <Stack.Item grow styles={stackItemStyles}>
                          <Label className={styles.labelname}>Upload Group:</Label>
                          <Dropdown
                            className={styles.stackItemStyles1}
                            selectedKey={selectedUserGroupItem ? selectedUserGroupItem.key : undefined}
                            placeholder="User Group"
                            onChange={onGroupChange}
                            options={optionsGroup}
                            styles={dropdownStyles} />
                        </Stack.Item>
                      )}
                    </Stack.Item>
                    <Stack.Item grow styles={stackItemStyles}>
                      <Label className={styles.labelname}>Chunk Document using :</Label>
                      <Dropdown
                        className={styles.stackItemStyles1}
                        selectedKey={selectedTextSplitterItem ? selectedTextSplitterItem.key : undefined}
                        onChange={onTextSplitterChange}
                        defaultSelectedKey="azureopenai"
                        placeholder="Select text splitter"
                        options={textSplitterOptions}
                        disabled={false}
                        styles={dropdownStyles}
                      />
                    </Stack.Item>
                  </Stack>
                </Stack>
                <Pivot styles={pivotStyles} className={styles.tabpivot} aria-label="Document Upload" onLinkClick={setLastHeader}>
                  <PivotItem headerText="Files" headerButtonProps={{ 'data-order': 1, }} >
                    <Stack enableScopedSelectors tokens={outerStackTokens}>
                      <Stack enableScopedSelectors styles={stackStyles} tokens={innerStackTokens}>
                        <Stack.Item grow={2} styles={stackItemStyles}>
                          <Checkbox label="Multiple Documents" checked={multipleDocs} onChange={onMultipleDocs} styles={checkboxStyles} />
                        </Stack.Item>
                        <Stack.Item grow={2} styles={stackItemStyles}>
                          <TextField onChange={onChangeIndexName} value={indexName}
                            errorMessage={!missingIndexName ? '' : "Index name is required"}
                            label="Index Name" />
                        </Stack.Item>
                      </Stack>
                    </Stack>
                    <div className={styles.commandsContainer}></div>
                    <div>
                      <h2 className={styles.chatEmptyStateSubtitle}>Upload your PDF/text/CSV/Word Document file</h2>
                      <h4 {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        Drop PDF/text/CSV/Word Document file here or click to upload. (Max file size 100 MB)
                      </h4>
                      {files.length ? (
                        <Card>
                          {fileList}
                          <br />
                          <CardFooter>
                            <DefaultButton onClick={handleRemoveAllFiles} disabled={loading ? true : false}>Remove All</DefaultButton>
                            <DefaultButton onClick={handleUploadFiles} disabled={loading ? true : false}>
                              <span>Upload File</span>
                            </DefaultButton>
                          </CardFooter>
                        </Card>
                      ) : null}
                      <br />
                      {loading ? <div><span>Please wait, Uploading and Processing your file</span><Spinner /></div> : null}
                      <hr />
                      <h2 className={styles.chatEmptyStateSubtitle}>
                        <TextField disabled={true} label={uploadError ? '' : uploadText} errorMessage={!uploadError ? '' : uploadText} />
                      </h2>
                    </div>
                  </PivotItem>
                  <PivotItem headerText="Web Pages">
                    <Stack enableScopedSelectors tokens={outerStackTokens}>
                      <Stack enableScopedSelectors styles={stackStyles} tokens={innerStackTokens}>
                        <Stack.Item grow={2} styles={stackItemStyles}>
                          <TextField onChange={onWebPageChange} multiline autoAdjustHeight styles={{ root: { width: '700px' } }}
                            label="List of Urls (followed by newline)"
                            defaultValue=""
                          />
                        </Stack.Item>
                        <Stack.Item grow styles={stackItemStyles}>
                          <TextField onChange={onChangeIndexName}
                            styles={{ root: { width: '400px' } }}
                            errorMessage={!missingIndexName ? '' : "Index name is required"}
                            value={indexName}
                            label="Index Name" />
                        </Stack.Item>
                        <Stack.Item grow styles={stackItemStyles}>
                          <PrimaryButton text="Process Pages" onClick={onProcessWebPages} />
                          <h2 className={styles.chatEmptyStateSubtitle}>
                            <TextField disabled={true} label={uploadText} />
                          </h2>
                        </Stack.Item>
                      </Stack>
                    </Stack>
                  </PivotItem>
                  <PivotItem headerText="Connectors">
                    <Stack enableScopedSelectors tokens={outerStackTokens}>
                      <Stack enableScopedSelectors styles={stackStyles} tokens={innerStackTokens}>
                        <Stack.Item grow={2} styles={stackItemStyles}>
                          <Dropdown
                            className={styles.stackItemStyles1}
                            selectedKey={selectedConnector ? selectedConnector.key : undefined}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={onConnectorChange}
                            placeholder="Select an Connector"
                            options={connectorOptions}
                            styles={dropdownStyles}
                          />
                          <h4 className={styles.chatEmptyStateSubtitle}>
                            Note : Currently only PDF files are supported from cloud storage services
                          </h4>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                          <TextField onChange={onChangeIndexName}
                            styles={{ root: { width: '400px' } }}
                            errorMessage={!missingIndexName ? '' : "Index name is required"}
                            value={indexName}
                            label="Index Name" />
                        </Stack.Item>
                        <Stack.Item grow styles={stackItemStyles}>
                          {(selectedConnector?.key === 'adlscontainer' || selectedConnector?.key === 'adlsfile') && (
                            <div>
                              <TextField onChange={onBlobConnectionString}
                                styles={{ root: { width: '700px' } }}
                                errorMessage={!missingIndexName ? '' : "Connection String is required"}
                                value={blobConnectionString}
                                label="Connection String" />
                              <div>
                                <TextField onChange={onBlobContainer}
                                  styles={{ root: { width: '200px' } }}
                                  errorMessage={!missingIndexName ? '' : "Container Name required"}
                                  value={blobContainer}
                                  label="Container Name" />
                              </div>
                            </div>
                          )}
                          {(selectedConnector?.key === 'adlscontainer') && (
                            <div>
                              <TextField onChange={onBlobPrefix}
                                styles={{ root: { width: '150px' } }}
                                value={blobPrefix}
                                label="Prefix Name" />
                            </div>
                          )}
                          {(selectedConnector?.key === 'adlsfile') && (
                            <div>
                              <TextField onChange={onBlobName}
                                styles={{ root: { width: '450px' } }}
                                value={blobName}
                                errorMessage={!missingIndexName ? '' : "Blob Name required"}
                                label="Blob Name" />
                            </div>
                          )}
                          {(selectedConnector?.key === 's3file' || selectedConnector?.key === 's3Container') && (
                            <div>
                              <TextField onChange={onS3Bucket}
                                styles={{ root: { width: '200px' } }}
                                errorMessage={!missingIndexName ? '' : "S3 Bucket is required"}
                                value={s3Bucket}
                                label="S3 Bucket" />
                              <div>
                                <TextField onChange={onS3AccessKey}
                                  styles={{ root: { width: '300px' } }}
                                  errorMessage={!missingIndexName ? '' : "S3 Access Key required"}
                                  value={s3AccessKey}
                                  label="S3 Access Key" />
                                <TextField onChange={onS3SecretKey}
                                  styles={{ root: { width: '400px' } }}
                                  errorMessage={!missingIndexName ? '' : "S3 Secret Key required"}
                                  value={s3SecretKey}
                                  label="S3 Secret Key" />
                              </div>
                            </div>
                          )}
                          {(selectedConnector?.key === 's3Container') && (
                            <div>
                              <TextField onChange={onS3Prefix}
                                styles={{ root: { width: '150px' } }}
                                value={s3Prefix}
                                label="Prefix Name" />
                            </div>
                          )}
                          {(selectedConnector?.key === 's3file') && (
                            <div>
                              <TextField onChange={onS3Key}
                                styles={{ root: { width: '450px' } }}
                                value={s3Key}
                                errorMessage={!missingIndexName ? '' : "S3 Key is required"}
                                label="S3 Key" />
                            </div>
                          )}
                        </Stack.Item>
                        <Stack.Item grow>
                          <PrimaryButton text="Process Documents" onClick={onProcessConnectors} />
                          <h2 className={styles.chatEmptyStateSubtitle}>
                            <TextField disabled={true} label={uploadText} />
                          </h2>
                        </Stack.Item>
                      </Stack>
                    </Stack>
                  </PivotItem>
                </Pivot>
              </div>
            )
            : (
              <div className={styles.NotAuthorizedParentcontainer}>
                <div className={styles.NotAuthorizedTopcontainer}>
                  <div className={styles.svgcontainer}></div>
                </div>
                <div className={styles.NotAuthorizedContainer}>
                  <div className={styles.NotAuthorizedBox}>
                    <div className={styles.NotAuthorizedTextContainer}>
                      <div className={styles.NotAuthorizedPTextheader}>
                        <h2>Access Denied.</h2>
                      </div>
                    </div>
                    <div className={styles.NotAuthorizedText}>Access to Upload feature is denied. Please reach out to admin for access.</div>
                  </div>
                </div>
              </div>
            )
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className={styles.NotAuthorizedParentcontainer}>
          <div className={styles.NotAuthorizedTopcontainer}>
            <div className={styles.svgcontainer}></div>
          </div>
          <div className={styles.NotAuthorizedContainer}>
            <div className={styles.NotAuthorizedBox}>
              <div className={styles.NotAuthorizedTextContainer}>
                <div className={styles.NotAuthorizedPTextheader}>
                  <h2>T-Mobile Authorization</h2>
                </div>
              </div>
              <div className={styles.NotAuthorizedText}>Please wait... Application is loading...</div>
              {/* <a className={styles.launcherlink} onClick={()=>handleLogin("redirect")}>Sign In</a> */}
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  )

};

export default Upload;

