
// *** Source: https://github.com/microsoft/applicationinsights-react-js/blob/main/sample/applicationinsights-react-sample/src/ApplicationInsightsService.tsx ***
// *** source: https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=react ***


import {ApplicationInsights, ITelemetryItem} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "af7c46ce-0720-4df5-bbf3-f618647d7d86",
    connectionString: "InstrumentationKey=af7c46ce-0720-4df5-bbf3-f618647d7d86;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/",
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env:ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags["ai.cloud.role"] = "testTag";
});

export { reactPlugin, appInsights };