import styles from "./Home.module.css";
import { Link } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { useEffect, useState } from "react";

const Home = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [userGroups, setUserGroups] = useState<string[]>([]);

  //Get the instance of the msal object from the MSAL context
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && instance) {
      const account: AccountInfo | null = instance.getActiveAccount();
      setIsAuthenticated(true);
      if (sessionStorage.getItem("userRoles")) {
        const ur = JSON.parse(sessionStorage.getItem("userRoles") || "[]") as string[];
        setUserGroups(ur);
      }
    }
  }, [inProgress, instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <div
          id="ContentWrapperDiv"
          className={`${styles.contentWrapper} ${styles.homePageBackGround}`}
        >
          <div className={styles.content}>
            <div className={styles.homeTopContainer}>
              <div className={styles.homeItemContainer}>
                <div className={`${styles.hexRow} ${styles.clearfix} ${styles.ml95}`} >
                  <Link to="https://openaiserverapp01.azurewebsites.net/" target="_blank" rel="noreferrer noopener" className={`${styles.hex}`}>
                    <div
                      className={`${styles.middle} ${styles.clearfix} ${styles.middleColor} ${styles.hexMehroon} `} >
                      Ask Robby
                    </div>
                  </Link>
                  <a href="#" className={`${styles.hex} ${styles.noHover}`}>
                    <div
                      className={`${styles.middle} ${styles.middleColor} ${styles.hexLightGrey}`}
                    ></div>
                  </a>
                </div>
                <div className={`${styles.hexRow}${styles.clearfix}`}>
                  <Link to="/chatGpt/0" className={`${styles.hex}`}>
                    <div
                      className={`${styles.middle} ${styles.clearfix} ${styles.middleColor}  ${styles.hexMehroon}`}
                    >
                      Chat GPT
                    </div>
                  </Link>
                  <a className={`${styles.hex} ${styles.noHover}`}>
                    <div className={`${styles.middle} ${styles.middleColor}`}>

                    </div>
                  </a>
                  <a className={`${styles.hex}  ${styles.noHover}`}>
                    <div
                      className={`${styles.middle} ${styles.middleColor}  ${styles.hexDrakGrey}`}>
                    </div>
                  </a>
                </div>
                <div className={`${styles.hexRow} ${styles.clearfix} ${styles.ml95}`}>
                  <a
                    href="#"
                    className={`${styles.hex} ${styles.noHover} `}
                  >
                    <div
                      className={`${styles.middle} ${styles.middleColor}  ${styles.hexDrakGrey}`}
                    ></div>
                  </a>
                  <Link to="/chatGpt/1" className={`${styles.hex}`}>
                    <div
                      className={`${styles.middle} ${styles.middleColor}  ${styles.hexDarkMehroon}`}
                    >
                      T-Mobile Data
                    </div>
                  </Link>
                </div>
                <div className={`${styles.hexRow} ${styles.clearfix}`}>
                  <a className={`${styles.hex}  ${styles.noHover}`}>
                    <div className={`${styles.middle} ${styles.middleColor} `}>

                    </div>
                  </a>
                  <a href="#" className={`${styles.hex}`}>
                    <div
                      className={`${styles.middle} ${styles.clearfix} ${styles.middleColor} ${styles.tmobilelogoMegentaColor}`}
                    ></div>
                  </a>
                  <a
                    href="#"
                    className={`${styles.hex} ${styles.noHover}`}
                  >
                    <div
                      className={`${styles.middle} ${styles.middleColor}`}
                    ></div>
                  </a>
                </div>
                <div className={`${styles.hexRow} ${styles.clearfix} ${styles.ml95}`}>
                  <a
                    href="#"
                    className={`${styles.hex} ${styles.noHover}`}
                  >
                    <div
                      className={`${styles.middle} ${styles.clearfix} ${styles.middleColor}  ${styles.hexDarkMehroon}`}
                    ></div>
                  </a>
                  <a
                    href="#"
                    className={`${styles.hex} ${styles.noHover} `}
                  >
                    <div
                      className={`${styles.middle} ${styles.middleColor}  ${styles.hexMehroon}`}
                    ></div>
                  </a>
                </div>
                <div className={`${styles.hexRow} ${styles.clearfix}`}>
                  <a className={`${styles.hex} ${styles.noHover}`}>
                    <div
                      className={`${styles.middle} ${styles.clearfix} ${styles.middleColor}  ${styles.hexLightGrey}`}
                    >
                    </div>
                  </a>
                  <a
                    href="#"
                    className={`${styles.hex} ${styles.noHover}`}
                  >
                    <div
                      className={`${styles.middle} ${styles.clearfix} ${styles.middleColor} ${styles.hexLightGrey}`}
                    ></div>
                  </a>
                  <a className={`${styles.hex}  ${styles.noHover}`}>
                    <div className={`${styles.middle} ${styles.middleColor}  ${styles.hexDarkMehroon}`}>

                    </div>
                  </a>
                </div>
                <div className={`${styles.hexRow} ${styles.clearfix} ${styles.ml95}`} >
                  <Link to="/hrhome" className={`${styles.hex}`}>
                    <div
                      className={`${styles.middle} ${styles.clearfix} ${styles.middleColor}  ${styles.hexMehroon}`}
                    >
                      HR JD
                    </div>
                  </Link>
                  <a className={`${styles.hex}  ${styles.noHover}`}>
                    <div className={`${styles.middle} ${styles.middleColor}`}>

                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className={styles.parentcontainer}>
          <div className={styles.topcontainer}>
            <div className={styles.svgcontainer}></div>
          </div>
          <div className={styles.container}>
            <div className={styles.box}>
              <div className={styles.launchercontainer}>
                <div className={styles.launcheritemheader}>
                  <h2>T-Mobile Authorization</h2>
                </div>
              </div>
              <div className={styles.launcheritemdesc}>
                Please wait... Application is loading...
              </div>
              {/* <a className={styles.launcherlink} onClick={()=>handleLogin("redirect")}>Sign In</a> */}
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Home;
