import { AskResponse, ChatRequest, ChatResponse, AskRequest, UserInfo, HRJDRequest, HRJDResponse, HRJDAnswer, HRJDData, HRPromptTemplates, HRFeedbackTemplates, HRJDJSData } from "./models";

export async function askApi(options: AskRequest, indexNs: string, indexType: string, chainType: string): Promise<AskResponse> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch("/ask", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      question: options.question,
      chainType: chainType,
      indexType: indexType,
      indexNs: indexNs,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: '',
              approach: options.approach,
              overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                chainType: options.overrides?.chainType,
                tokenLength: options.overrides?.tokenLength,
                embeddingModelType: options.overrides?.embeddingModelType,
              }
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse.values[0].data
}

export async function promptGuru(task: string, modelName: string, embeddingModelType: string): Promise<AskResponse> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/promptGuru', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      task: task,
      modelName: modelName,
      embeddingModelType: embeddingModelType,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: '',
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse.values[0].data
}

// export async function askAgentApi(options: AskRequest): Promise<AskResponse> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");

//   if (userName === null) {
//     throw Error("No User value");
//   }

//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/askAgent', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       postBody: {
//         values: [
//           {
//             recordId: 0,
//             data: {
//               text: '',
//               question: options.question,
//               approach: options.approach,
//               overrides: {
//                 indexType: options.overrides?.indexType,
//                 indexes: options.overrides?.indexes,
//                 semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 chainType: options.overrides?.chainType,
//                 tokenLength: options.overrides?.tokenLength,
//                 embeddingModelType: options.overrides?.embeddingModelType,
//               }
//             }
//           }
//         ]
//       }
//     })
//   });
//   const parsedResponse: ChatResponse = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse.values[0].data
// }

// export async function smartAgent(options: AskRequest): Promise<AskResponse> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");

//   if (userName === null) {
//     throw Error("No User value");
//   }

//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/smartAgent', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken, "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       postBody: {
//         values: [
//           {
//             recordId: 0,
//             data: {
//               text: '',
//               question: options.question,
//               approach: options.approach,
//               overrides: {
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 chainType: options.overrides?.chainType,
//                 tokenLength: options.overrides?.tokenLength,
//                 embeddingModelType: options.overrides?.embeddingModelType,
//               }
//             }
//           }
//         ]
//       }
//     })
//   });
//   const parsedResponse: ChatResponse = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse.values[0].data
// }

// export async function askTaskAgentApi(options: AskRequest): Promise<AskResponse> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");

//   if (userName === null) {
//     throw Error("No User value");
//   }

//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/askTaskAgent', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken, "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       postBody: {
//         values: [
//           {
//             recordId: 0,
//             data: {
//               text: '',
//               question: options.question,
//               approach: options.approach,
//               overrides: {
//                 indexType: options.overrides?.indexType,
//                 indexes: options.overrides?.indexes,
//                 semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 chainType: options.overrides?.chainType,
//                 tokenLength: options.overrides?.tokenLength,
//                 embeddingModelType: options.overrides?.embeddingModelType,
//               }
//             }
//           }
//         ]
//       }
//     })
//   });
//   const parsedResponse: ChatResponse = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse.values[0].data;
// }

export async function chatStreamApi(options: ChatRequest, indexNs: string, indexType: string): Promise<Response> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  return await fetch('/chatStream', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      indexNs: indexNs,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              history: options.history,
              approach: 'rrr',
              overrides: {
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                promptTemplate: options.overrides?.promptTemplate,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                embeddingModelType: options.overrides?.embeddingModelType,
                firstSession: options.overrides?.firstSession,
                session: options.overrides?.session,
                sessionId: options.overrides?.sessionId,
                deploymentType: options.overrides?.deploymentType,
                chainType: options.overrides?.chainType,
                userName: options.overrides?.userName,
              }
            }
          }
        ]
      }
    })
  });
}

export async function openchatBingApi(question: string, options: ChatRequest, indexNs: string, indexType: string): Promise<AskResponse> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }

  const response = await fetch("/openchatBing", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      indexNs: indexNs,
      question: question,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              history: options.history,
              approach: 'rrr',
              overrides: {
                temperature: options.overrides?.temperature,
                tokenLength: options.overrides?.tokenLength,
                promptTemplate: options.overrides?.promptTemplate,
                embeddingModelType: options.overrides?.embeddingModelType,
                firstSession: options.overrides?.firstSession,
                session: options.overrides?.session,
                sessionId: options.overrides?.sessionId,
                deploymentType: options.overrides?.deploymentType,
                functionCall: options.overrides?.functionCall,
                excludeCategory: options.overrides?.excludeCategory,
                top: options.overrides?.top,

                semanticRanker: options.overrides?.semanticRanker,
                semanticCaptions: options.overrides?.semanticCaptions,
                suggestFollowupQuestions: options.overrides?.suggestFollowupQuestions,
                autoSpeakAnswers: options.overrides?.autoSpeakAnswers,
                searchType: options.overrides?.searchType,
                userName: options.overrides?.userName,
              }
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.values[0].data.error || "Unknown error");
  }
  return parsedResponse.values[0].data;
}

export async function chatDataApi(question: string, options: ChatRequest, indexNs: string, indexType: string): Promise<AskResponse> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch("/chatData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType, indexNs: indexNs, question: question, postBody: {
        values: [
          {
            recordId: 0, data: {
              history: options.history, approach: "rrr", overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                embeddingModelType: options.overrides?.embeddingModelType,
                userName: options.overrides?.userName
              },
            },
          },],
      },
    }),
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.values[0].data.error || "Unknown error");
  }
  return parsedResponse.values[0].data;
}

export async function HRJDApi(options: HRJDRequest, indexNs: string, indexType: string): Promise<HRJDAnswer> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch("/HRJDData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType, indexNs: indexNs,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              indexType: indexType,
              indexNs: indexNs,
              approach: "rrr",
              overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                tokenLength: options.overrides?.tokenLength,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                embeddingModelType: options.overrides?.embeddingModelType,
                userName: options.overrides?.userName,
                job_profile_name: options.overrides?.job_profile_name,
                job_family_group: options.overrides?.job_family_group,
                job_family: options.overrides?.job_family,
                job_career_band: options.overrides?.job_career_band,
                job_details_optional: options.overrides?.job_details_optional
              }
            }
          }
        ]
      }
    })
  });
  const parsedResponse: HRJDResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.values[0].data.error || "Unknown error");
  }
  return parsedResponse.values[0].data;
}
export async function HRJDINDApi(options: HRJDRequest, indexNs: string, indexType: string): Promise<HRJDAnswer> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch("/HRJDINDData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType, indexNs: indexNs,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              indexType: indexType,
              indexNs: indexNs,
              approach: "rrr",
              overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                tokenLength: options.overrides?.tokenLength,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                embeddingModelType: options.overrides?.embeddingModelType,
                userName: options.overrides?.userName,
                job_profile_name: options.overrides?.job_profile_name,
                job_family_group: options.overrides?.job_family_group,
                job_family: options.overrides?.job_family,
                job_career_band: options.overrides?.job_career_band,
                job_details_optional: options.overrides?.job_details_optional,

                job_summary: options.overrides?.job_summary,
                promptKey: options.overrides?.promptKey,
                responseKey: options.overrides?.responseKey
              }
            }
          }
        ]
      }
    })
  });
  const parsedResponse: HRJDResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.values[0].data.error || "Unknown error");
  }
  return parsedResponse.values[0].data;
}

// export async function HRJDLogException(methodCalled: string, request: string, errorMessage: string): Promise<any> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");

//   if (userName === null) {
//     throw Error("No User value");
//   }

//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/HRJDLogException', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       userName: userName,
//       methodCalled: methodCalled,
//       request: request,
//       errorMessage: errorMessage
//     })
//   });

//   const parsedResponse: any = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse;
// }

export async function HRJD_Revision_Api(options: HRJDRequest, indexNs: string, indexType: string): Promise<HRJDAnswer> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch("/HRJDRevision", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType, indexNs: indexNs,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              indexType: indexType,
              indexNs: indexNs,
              approach: "rrr",
              overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                tokenLength: options.overrides?.tokenLength,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                embeddingModelType: options.overrides?.embeddingModelType,
                userName: options.overrides?.userName,
                job_profile_name: options.overrides?.job_profile_name,
                job_family_group: options.overrides?.job_family_group,
                job_family: options.overrides?.job_family,
                job_career_band: options.overrides?.job_career_band,
                job_details_optional: options.overrides?.job_details_optional,
                existingdata: options.overrides?.existingdata,
                userFeedback: options.overrides?.userFeedback,
                promptKey: options.overrides?.promptKey,
                responseKey: options.overrides?.responseKey,
                job_summary: options.overrides?.job_summary,
              }
            }
          }
        ]
      }
    })
  });
  const parsedResponse: HRJDResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.values[0].data.error || "Unknown error");
  }
  return parsedResponse.values[0].data;
}

export async function HRJDRefreshIndex(roleGroup: string[]): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  } const response = await fetch("/HRJDRefreshIndex", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      roleGroup: roleGroup
    }),
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return result;
}

export async function HRJDClearblobsIndex(roleGroup: string[]): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  } const response = await fetch("/HRJDClearblobsIndex", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      roleGroup: roleGroup,
    }),
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return "success";
}

export async function clearblobsIndex(roleGroup: string[]): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  } const response = await fetch("/clearblobsIndex", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      roleGroup: roleGroup,
    }),
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return "success";
}

export async function refreshIndex(roleGroup: string[]): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  } const response = await fetch("/refreshIndex", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      roleGroup: roleGroup
    }),
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return result;
}

export async function refreshQuestions(indexType: string, indexName: string): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch("/refreshQuestions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken, "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType, indexName: indexName, postBody: {
        values: [
          {
            recordId: 0, data: {
              text: "",
            },
          },],
      },
    }),
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return result;
}

export async function refreshIndexQuestions(indexType: string): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/refreshIndexQuestions', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: ''
            }
          }
        ]
      }
    })
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return result;
}

export async function kbQuestionManagement(documentsToDelete: any): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/kbQuestionManagement', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      documentsToDelete: documentsToDelete,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: ''
            }
          }
        ]
      }
    })
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return result;
}

export async function uploadFile(fileName: string, fileContent: any, contentType: string): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/uploadFile', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      fileName: fileName,
      fileContent: fileContent,
      contentType: contentType
    })
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return "Success";
}
export async function uploadBinaryFile(formData: any, indexName: string): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  // console.log(formData);

  const response = await fetch('/uploadBinaryFile', {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: formData
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return "Success";
}

export async function uploadSummaryBinaryFile(formData: any): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/uploadSummaryBinaryFile', {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: formData
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return "Success";
}

export async function processDoc(indexType: string, loadType: string, multiple: string, indexName: string, files: any,
  blobConnectionString: string, blobContainer: string, blobPrefix: string, blobName: string,
  groupName: string, userName: string,
  s3Bucket: string, s3Key: string, s3AccessKey: string, s3SecretKey: string, s3Prefix: string,
  existingIndex: string, existingIndexNs: string, embeddingModelType: string, textSplitter: string): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const euserName = sessionStorage.getItem("userName");

  if (euserName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch("/processDoc", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": euserName
    },
    body: JSON.stringify({
      indexType: indexType,
      multiple: multiple,
      loadType: loadType,
      indexName: indexName,
      existingIndex: existingIndex,
      existingIndexNs: existingIndexNs,
      embeddingModelType: embeddingModelType,
      textSplitter: textSplitter,
      postBody: {
        values: [
          {
            recordId: 0, data: {
              text: files,
              blobConnectionString: blobConnectionString,
              blobContainer: blobContainer,
              blobPrefix: blobPrefix,
              blobName: blobName,
              groupName: groupName,
              userName: userName,
              s3Bucket: s3Bucket,
              s3Key: s3Key,
              s3AccessKey: s3AccessKey,
              s3SecretKey: s3SecretKey,
              s3Prefix: s3Prefix,
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  } else {
    if (parsedResponse.values[0].data.error) {
      return parsedResponse.values[0].data.error;
    }
    return 'Success';
  }
}

export async function processSummary(loadType: string, multiple: string, files: any, embeddingModelType: string, chainType: string): Promise<AskResponse> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/processSummary', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      multiple: multiple,
      loadType: loadType,
      embeddingModelType: embeddingModelType,
      chainType: chainType,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: files,
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  return parsedResponse.values[0].data;
}

export async function convertCode(inputLanguage: string, outputLanguage: string, inputCode: string, modelName: string, embeddingModelType: string): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/convertCode', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      inputLanguage: inputLanguage,
      outputLanguage: outputLanguage,
      modelName: modelName,
      embeddingModelType: embeddingModelType,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: inputCode
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse.values[0].data.answer
}

export async function indexManagement(indexType: string, indexName: string, blobName: string, indexNs: string, operation: string): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/indexManagement', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      blobName: blobName,
      indexNs: indexNs,
      indexName: indexName,
      operation: operation,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: ''
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  } else {
    if (parsedResponse.values[0].data.error) {
      return parsedResponse.values[0].data.error;
    }
    return 'Success';
  }
}

// export async function secSearch(indexType: string, indexName: string, question: string, top: string, embeddingModelType: string): Promise<any> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");
//
//   if (userName === null) {
//     throw Error("No User value");
//   }
//
//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/secSearch', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       indexType: indexType,
//       indexName: indexName,
//       question: question,
//       top: top,
//       embeddingModelType: embeddingModelType,
//       postBody: {
//         values: [
//           {
//             recordId: 0,
//             data: {
//               text: ''
//             }
//           }
//         ]
//       }
//     })
//   });
//   const result = await response.json();
//   if (response.status > 299 || !response.ok) {
//     return "Error";
//   }
//   return result;
// }

// export async function sqlChat(question: string, top: number, embeddingModelType: string): Promise<SqlResponse> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");
//
//   if (userName === null) {
//     throw Error("No User value");
//   }
//
//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/sqlChat', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       question: question,
//       top: top,
//       embeddingModelType: embeddingModelType,
//       postBody: {
//         values: [
//           {
//             recordId: 0,
//             data: {
//               text: ''
//             }
//           }
//         ]
//       }
//     })
//   });
//   const parsedResponse: ChatResponse = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse.values[0].data
// }


// export async function sqlChain(question: string, top: number, embeddingModelType: string): Promise<SqlResponse> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");
//
//   if (userName === null) {
//     throw Error("No User value");
//   }
//
//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/sqlChain', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       question: question,
//       top: top,
//       embeddingModelType: embeddingModelType,
//       postBody: {
//         values: [
//           {
//             recordId: 0,
//             data: {
//               text: ''
//             }
//           }
//         ]
//       }
//     })
//   });
//   const parsedResponse: ChatResponse = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse.values[0].data
// }

export async function verifyPassword(passType: string, password: string): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/verifyPassword', {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      passType: passType,
      password: password,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: ''
            }
          }
        ]
      }
    })
  });
  const parsedResponse: ChatResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  } else {
    if (parsedResponse.values[0].data.error) {
      return parsedResponse.values[0].data.error;
    }
    return 'Success';
  }
}

// export async function getSpeechToken(): Promise<SpeechTokenResponse> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");
//
//   if (userName === null) {
//     throw Error("No User value");
//   }
//
//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/speechToken', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//   });
//   const parsedResponse: SpeechTokenResponse = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse
// }

export async function summarizer(options: AskRequest, requestText: string, promptType: string, promptName: string, docType: string, chainType: string, embeddingModelType: string): Promise<string> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/summarizer', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      docType: docType,
      chainType: chainType,
      promptType: promptType,
      promptName: promptName,
      postBody: {
        values: [
          {
            recordId: 0,
            data: {
              text: requestText,
              overrides: {
                temperature: options.overrides?.temperature,
                tokenLength: options.overrides?.tokenLength,
                embeddingModelType: embeddingModelType,
              }
            }
          }
        ]
      }
    })
  });
  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse.values[0].data.text
}

// export async function summaryAndQa(indexType: string, indexNs: string, embeddingModelType: string, requestType: string, chainType: string): Promise<string> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");
//
//   if (userName === null) {
//     throw Error("No User value");
//   }
//
//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/summaryAndQa', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       indexType: indexType,
//       indexNs: indexNs,
//       embeddingModelType: embeddingModelType,
//       requestType: requestType,
//       chainType: chainType,
//       postBody: {
//         values: [
//           {
//             recordId: 0,
//             data: {
//               text: ''
//             }
//           }
//         ]
//       }
//     })
//   });
//   const parsedResponse: any = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   if (requestType === 'summary') {
//     return parsedResponse.values[0].summary
//   }
//   else if (requestType === 'qa') {
//     return parsedResponse.values[0].qa
//   }
//   else
//     return ''
// }

// export async function textAnalytics(documentText: string): Promise<string> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");
//
//   if (userName === null) {
//     throw Error("No User value");
//   }
//
//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/textAnalytics', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       documentText: documentText
//     })
//   });
//   const parsedResponse: any = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse.TextAnalytics
// }

export async function getSpeechApi(text: string): Promise<string | null> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  return await fetch("/speech", {
    method: "POST",
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken, "X-Api-User": userName },
    body: JSON.stringify({ text: text })
  }).then((response) => {
    if (response.status == 200) {
      return response.blob();
    } else {
      console.error("Unable to get speech synthesis.");
      return null;
    }
  }).then((blob) => blob ? URL.createObjectURL(blob) : null);
}

export async function getAADCreds(): Promise<any> {
  const response = await fetch('/getAADCreds', {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  const result = await response.json();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return result;
}

export function getCitationFilePath(citation: string): string {
  return `/content/${citation}`;
}

/* all Session related */

export async function getAllSessions(indexType: string, feature: string, type: string): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/getAllSessions', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      feature: feature,
      type: type,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}
export async function getAllIndexSessions(indexNs: string, indexType: string, feature: string, type: string): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/getAllIndexSessions', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      indexNs: indexNs,
      feature: feature,
      type: type,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}
export async function getIndexSession(indexNs: string, indexType: string, sessionName: string, sessionId: String): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/getIndexSession', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      indexNs: indexNs,
      sessionName: sessionName,
      sessionId: sessionId,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}
export async function deleteIndexSession(indexNs: string, indexType: string, sessionName: string, sessionId: String): Promise<String> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/deleteIndexSession', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      indexType: indexType,
      indexNs: indexNs,
      sessionName: sessionName,
      sessionId: sessionId,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}
export async function renameIndexSession(oldSessionName: string, newSessionName: string, sessionId: String): Promise<String> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/renameIndexSession', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      oldSessionName: oldSessionName,
      newSessionName: newSessionName,
      sessionId: sessionId,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}
export async function getIndexSessionDetail(sessionId: string): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/getIndexSessionDetail', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      sessionId: sessionId,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}

/* upsert prompts templates */
export async function GetHRJDPromptTemplates(promptType: string): Promise<HRPromptTemplates> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/GetHRJDPromptTemplates', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      promptType: promptType,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}

// export async function GetHRJDFeedbackTemplates(): Promise<HRFeedbackTemplates> {
//   const accessToken = sessionStorage.getItem("accessToken");
//   const userName = sessionStorage.getItem("userName");

//   if (userName === null) {
//     throw Error("No User value");
//   }

//   if (accessToken === null) {
//     throw Error("Access Token Exipred. Please Sign Out and Sign In");
//   }
//   const response = await fetch('/GetHRJDFeedbackTemplates', {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " + accessToken,
//       "X-Api-User": userName
//     },
//     body: JSON.stringify({
//       promptType: "Feedback",
//       userName: userName
//     })
//   });

//   const parsedResponse: any = await response.json();
//   if (response.status > 299 || !response.ok) {
//     throw Error("Unknown error");
//   }
//   return parsedResponse;
// }

export async function UpdatePromptTemplates(promptType: string, modifiedKey: string, modifiedContent: string): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/UpdatePromptTemplates', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      promptType: promptType,
      modifiedKey: modifiedKey,
      modifiedContent: modifiedContent,
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}
export async function CreatePromptTemplates(): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }
  const response = await fetch('/CreatePromptTemplates', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      userName: userName
    })
  });

  const parsedResponse: any = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error("Unknown error");
  }
  return parsedResponse;
}
/* mail merge*/
export async function docxTemplate(data: any): Promise<any> {
  const accessToken = sessionStorage.getItem("accessToken");
  const userName = sessionStorage.getItem("userName");

  if (userName === null) {
    throw Error("No User value");
  }

  if (accessToken === null) {
    throw Error("Access Token Exipred. Please Sign Out and Sign In");
  }

  const response = await fetch("/docxTemplate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      "X-Api-User": userName
    },
    body: JSON.stringify({
      jsondata: data,
      userName: userName
    })
  });
  const result = await response.blob();
  if (response.status > 299 || !response.ok) {
    return "Error";
  }
  return result;
}