import { useState, useEffect, useRef } from "react"


// Msal imports
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { loginRedirectRequest } from "../../auth/authConfig";
import { FiLogOut } from "react-icons/fi";
import styles from "./WelcomeName.module.css";
import axios from 'axios';
import { msalInstance } from "../..";

interface Props {
    message: string;
}

interface UserProfile {
    displayName: string;
    photo?: string;
    jobTitle?: string;
    mail?: string;
    officeLocation?: string;
}

export const WelcomeName = ({ message }: Props) => {
    const { instance, inProgress } = useMsal();

    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [image, setImage] = useState(undefined);
    const [imageStatus, setImageStatus] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [shortName, setShortName] = useState("");

    const handleDropdownToggle = () => { setIsOpen((prevIsOpen) => !prevIsOpen); };
    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({ mainWindowRedirectUri: "/" });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }
    const processPhoto = async (response: AuthenticationResult) => {

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-type", 'image/jpeg');

        const options = {
            method: "GET",
            headers: headers,
        };

        await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    response.blob().then((data) => {
                        const reader = new FileReader()
                        reader.readAsDataURL(data)
                        reader.onload = () => {
                            const base64data: any = reader.result;
                            setImage(base64data);
                            setImageStatus(true);
                        }
                    })
                } else {
                    setImage(undefined);
                    setImageStatus(false);
                }
            })
            .catch((e) => {
                setImage(undefined);
                setImageStatus(false);
            })

    }
    const getPhoto = async () => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        await msalInstance.acquireTokenSilent({
            ...loginRedirectRequest,
            account: account
        })
            .then((response: AuthenticationResult) => { processPhoto(response) })
            .catch((e: any) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRedirectRequest,
                        account: msalInstance.getActiveAccount() as AccountInfo
                    });
                }
            });

    };

    const processProfile = async (response: AuthenticationResult) => {
        // Fetch user profile data from Microsoft Graph API
        axios.get('https://graph.microsoft.com/v1.0/me', {
            headers: {
                Authorization: `Bearer ${response.accessToken}`,
            },
        })
            .then((response) => {
                const { displayName, photo, jobTitle, mail, officeLocation } = response.data;
                setUserProfile({ displayName, photo, jobTitle, mail, officeLocation });
                setShortName(displayName.split(" ").map((n: string) => n[0]).join(""));
            })
            .catch((error) => {
                console.error('Error fetching user profile:', error);
            });
    }

    const profildetails = async () => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        await msalInstance.acquireTokenSilent({
            ...loginRedirectRequest,
            account: account
        })
            .then((response: AuthenticationResult) => { processProfile(response) })
            .catch((e: any) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRedirectRequest,
                        account: msalInstance.getActiveAccount() as AccountInfo
                    });
                }
            });

    }
    const dropDownRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (ev: { target: any; }) => {
        if (dropDownRef.current && !(dropDownRef?.current.contains(ev.target))) {
            handleDropdownToggle();
        }
    }


    useEffect(() => {
        if (!userProfile && inProgress === InteractionStatus.None) {
            getPhoto();
            profildetails();
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [inProgress, userProfile, instance]);


    return (
        <div className={styles.dropdownmenucontainer}>
            <div className={styles.dropdownmenutrigger} onClick={handleDropdownToggle}>
                {
                    <div>
                        {imageStatus ? (
                            <img style={{ height: 44, width: 44 }} key={image} src={image} title={userProfile?.displayName} />
                        ) : { shortName } ? (<>{shortName}</>) : (null)
                        }
                    </div>
                }
            </div>
            {
                isOpen && (
                    <div className={styles.dropdownmenu} ref={dropDownRef}>
                        <ul className={styles.dropdown}>
                            <li className={styles.dropdownItem} ><div>Name: <strong> {userProfile?.displayName} </strong></div></li>
                            <li className={styles.dropdownItem} ><div>Title: <strong>{userProfile?.jobTitle}</strong></div></li>
                            {/* <li className={styles.dropdownItem} ><div> Email: {userProfile?.mail}</div></li>
                            <li className={styles.dropdownItem} ><div> Location: {userProfile?.officeLocation}</div></li> */}
                        </ul>
                        <ul className={styles.dropdownul}>
                            <li className={styles.dropdownItem} >
                                <div className={styles.dropdownSignOutItem} onClick={() => handleLogout("redirect")}>
                                    <div><FiLogOut /> Sign Out</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
        </div>
    );
};

export async function callMsGraph() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRedirectRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = response.accessToken;
    return bearer;

}