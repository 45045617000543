import { useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import CopyCodeMarkdown from "./CopyCodeMarkdown";
import copy from 'copy-to-clipboard';

interface Props { answer: AskResponse; isSelected?: boolean; isSpeaking?: boolean; onCitationClicked: (filePath: string) => void; onThoughtProcessClicked: () => void; onSupportingContentClicked: () => void; onSpeechSynthesisClicked: () => void; onFollowupQuestionClicked?: (question: string) => void; showFollowupQuestions?: boolean; showSupportingContentIcon?: boolean }

export const Answer = ({ answer, isSelected, isSpeaking, onCitationClicked, onThoughtProcessClicked, onSupportingContentClicked, onSpeechSynthesisClicked, onFollowupQuestionClicked, showFollowupQuestions, showSupportingContentIcon }: Props) => {
    const [copied, setCopied] = useState<{ [key: string]: boolean }>({});

    const handleCopyClick = (results: string) => {
        copy(results);
        setCopied((prevCopied) => ({ ...prevCopied, [results]: true }));

        // Reset the "Copied" state after a short delay
        setTimeout(() => {
            setCopied((prevCopied) => ({ ...prevCopied, [results]: false }));
        }, 2000);
    };

    function getHttpOrHttpsLink(sentence: string): string {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const matches = urlRegex.exec(sentence);

        if (matches && matches.length > 0) {
            return matches[0].trim().replace(/[.,;:!?()]+$/, '');
        }
        return ""
    }

    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked, answer.sources ? answer.sources : '', answer.nextQuestions ? answer.nextQuestions : ''), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div className={styles.answerbox}>
                        <IconButton style={{ color: "#E20074" }} iconProps={{ iconName: "Copy" }} title="Copy results to clipboard" ariaLabel="Copy results to clipboard" onClick={() => handleCopyClick(sanitizedAnswerHtml)} />
                        <IconButton style={{ color: "#E20074" }} iconProps={{ iconName: "Lightbulb" }} title="Show thought process" ariaLabel="Show thought process" onClick={() => onThoughtProcessClicked()} disabled={!answer.thoughts} />
                        {showSupportingContentIcon && <IconButton style={{ color: "#E20074" }} iconProps={{ iconName: "ClipboardList" }} title="Show supporting content" ariaLabel="Show supporting content" onClick={() => onSupportingContentClicked()} disabled={!answer.data_points.length} />}
                        {/* {isSpeaking && (<IconButton style={{ color: "#E20074" }} iconProps={{ iconName: "Volume3" }} title="Speak answer" ariaLabel="Speak answer" onClick={() => onSpeechSynthesisClicked()} />)} */}
                        {/* {!isSpeaking && (<IconButton style={{ color: "#E20074" }} iconProps={{ iconName: "Volume3" }} title="Speak answer" ariaLabel="Speak answer" onClick={() => onSpeechSynthesisClicked()} />)} */}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText}>
                    {/* <ReactMarkdown children={sanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} /> */}
                    <CopyCodeMarkdown markdownContent={sanitizedAnswerHtml} />
                </div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const y = x.trim()
                            const path = getCitationFilePath(y).trim();

                            // const xlink = x.substr(x.indexOf('http'),x.length).trim()
                            let xlink = getHttpOrHttpsLink(y).trim()
                            let xtitle = ''
                            if (xlink !== null || xlink !== undefined) {
                                xtitle = y.replace(xlink, '').trim()
                            }
                            if (xtitle.length <= 4) {
                                xtitle = y
                            }
                            xtitle = xtitle.replace('.pdf).', '.pdf')
                            return (
                                <a key={i} className={styles.citation} title={y} onClick={() => onCitationClicked(path)}>
                                    {++i}. {xtitle}
                                </a>
                            )
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            // let str = '1. Hello 2. World';
                            // str = str.replace(/\b\d+\.\s/g, '');
                            // console.log(str); // Outputs: Hello World
                            return (
                                <div key={i} className={styles.followupQuestion} title={x}>
                                    <div>{`${++i}.`}&nbsp;</div>
                                    <div onClick={() => onFollowupQuestionClicked(x)}>
                                        {` ${x.replace(/\b\d+\.\s/g, '')}`}
                                    </div>
                                </div>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
