import { Text } from "@fluentui/react";
import * as Icons from "@fluentui/react-icons";

import styles from "./MenuSettingButton.module.css";

interface Props {
    className?: string;
    title: string;
    onClick: () => void;
}

export const MenuSettingButton = ({ className, title, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`} title={title} onClick={onClick}>
            <Icons.TextAlignJustify24Regular title={title} />
            {/* <Text className={styles.settingstyle} >{"Settings"}</Text> */}
        </div>
    );
};
