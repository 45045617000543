import styles from "./FAQs.module.css";
import { Link } from "react-router-dom";
import { SearchBox, ISearchBoxStyles } from "@fluentui/react";
import { useState } from "react";
const FAQ = () => {
  const faqQuestionSummaryList = [
    {
      ques: "What is T-Mobile ChatGPT all about?",
      ans: "The hottest new programing language is “English”! ChatGPT enables users to interact with Artificial Intelligence (Large Language Models) using the English language. As a creative and helpful collaborator, T-Mobile ChatGPT can supercharge your imagination, boost your productivity, and help you bring your ideas to life—whether you want help planning the perfect marketing campaign, creating a pro & con list for a business decision, or understanding complex 5G topics simply.",
      isOpen: true
    },
    {
      ques: "Can I use T-Mobile data?",
      ans: "Yes, T-Mobile’s ChatGPT is a T-Mobile specific instance of the OpenAI ChatGPT hosted in T-Mobile’s cloud environment and is not accessible outside of the T-Mobile network.",
    },
    {
      ques: "Can I ask T-Mobile specific questions?",
      ans: "Yes, T-Mobile’s ChatGPT is a T-Mobile specific instance of the OpenAI ChatGPT hosted in T-Mobile’s cloud environment and has access to a variety of T-Mobile documents and data. If you don’t find what you’re looking for, or have specific requests, please send us a message on Slack.",
    },
    {
      ques: "Can T-Mobile ChatGPT make mistakes?",
      ans: "T-Mobile ChatGPT is a large language model (LLM) that can make mistakes, such as providing inaccurate, misleading, or false information. T-Mobile ChatGPT is trained on a massive dataset of text and code. This dataset includes a variety of sources, including news articles, books, and websites. However, it is possible that some of the information in this dataset is inaccurate or outdated.",
    },
    {
      ques: "What if I don’t get good answers?",
      ans: "T-Mobile ChatGPT is not perfect. It is possible for T-Mobile ChatGPT to misunderstand your questions or to provide responses that are not relevant to your needs. It is important to use your own judgment when evaluating the information that T-Mobile ChatGPT provides. If you are unsure about the accuracy of a particular piece of information, you should do your own research to verify it. Use your own judgment when evaluating the information that T-Mobile ChatGPT provides. If you are unsure about the accuracy of a particular piece of information, you should do your own research to verify the informastion. Be critical of the information that T-Mobile ChatGPT provides. Just because T-Mobile ChatGPT provides information does not mean that it is accurate or reliable.",
    },
    {
      ques: "How to use T-Mobile ChatGPT effectively?",
      ans: `1. Start with a clear question or instruction. What do you want the language model to do? Be as specific as possible.\n
            2. Use keywords and examples. The more information you give the language model, the better it will be able to understand what you want.\n
            3. Be clear about the tone and style of the response you want. Do you want a poem, a story, or a factual answer?\n
            4. Break down complex tasks into smaller steps. This will make it easier for the language model to understand what you want. \n
            5. Use simple language. The language model is trained on a massive dataset of text, but it's still not perfect at understanding complex language. \n
            6. Experiment! There's no one right way to create a prompt. Try different things and see what works best. \n
        `,
    },
    {
      ques: "How to use context with your prompts?",
      ans: `1.Start by thinking about the context of your prompt. What is the overall goal of your prompt? What do you want the language model to do?\n
            2. Once you have a good understanding of the context, you can start to add examples. Examples can help the language model understand what you want it to do. For example, if you want the language model to write a poem about a cat, you could give it examples of other poems about cats.\n
            3. It's important to use clear and concise examples. The language model doesn't have the same understanding of the world as you do, so it's important to be as clear as possible.\n
            4. You can also use context and examples to help the language model understand the tone and style of your prompt. For example, if you want the language model to write marketing copy for a new service, you could add examples of other marketing copy that has proven effective.\n
        `,
    },
  ];
  const [searchText, setSearchText] = useState("");
  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      borderColor: "#e20074",
      selectors: {
        ":hover": {
          borderColor: "#e20074",
        },
        ":after": {
          borderColor: "#e20074",
        },
      }
    },
    iconContainer: {
      color: "#e20074",
      selectors: {
        ":hover": {
          color: "#e20074",
        },
        ":after": {
          color: "#e20074",
        },
      }
    },
    icon: {
      color: "#e20074",
      selectors: {
        ":hover": {
          color: "#e20074",
        },
        ":after": {
          color: "#e20074",
        },
      }
    }
  };

  return (
    // <div className={styles.faqouter}>
    <div className={styles.faq}>
        <div className={styles.faqheading}>Frequently Asked Questions</div>
      <div className={styles.faqButtons}>
        <Link to="/">
          <div className={styles.faqHome} />
        </Link>
        <div className={styles.searchBox}>
          <SearchBox
            styles={searchBoxStyles}
            placeholder="Search"
            showIcon={true}
            underlined={true}
            disableAnimation={true}
            onClear={(e) => setSearchText("")}
            onSearch={(newValue) => setSearchText(newValue)}
          />
        </div>
      </div>

      <div className={styles.faqholder}>
        {(faqQuestionSummaryList || [])
          .filter(
            (item) =>
              item.ques.indexOf(searchText) > -1 ||
              item.ans.indexOf(searchText) > -1
          )
          .map((detail, index) => (
            <details className={styles.faqdetail} key={index} open={detail.isOpen}>
              <summary className={styles.faqsummary}>
                <span className={styles.faqquestion}>{detail.ques}</span>
              </summary>
              {detail.ans.split("\n").map((str) => (
                <p className={styles.faqtext}>{str}</p>
              ))}
            </details>
          ))}

        {/* <details className={styles.faqdetail} open={true}>
                    <summary className={styles.faqsummary}><span className={styles.faqquestion}>What is T-Mobile ChatGPT all about?</span></summary>
                    <p className={styles.faqtext}>The hottest new programing language is “English”! ChatGPT enables users to interact with Artificial Intelligence (Large Language Models) using the English language. As a creative and helpful collaborator, T-Mobile ChatGPT can supercharge your imagination, boost your productivity, and help you bring your ideas to life—whether you want help planning the perfect marketing campaign, creating a pro & con list for a business decision, or understanding complex 5G topics simply.</p>
                </details>

                <details className={styles.faqdetail}>
                    <summary className={styles.faqsummary}><span className={styles.faqquestion}>Can I use T-Mobile data?</span></summary>
                    <p className={styles.faqtext}>Yes, T-Mobile’s ChatGPT is a T-Mobile specific instance of the OpenAI ChatGPT hosted in T-Mobile’s cloud environment and is not accessible outside of the T-Mobile network.</p>
                </details>

                <details className={styles.faqdetail}>
                    <summary className={styles.faqsummary}><span className={styles.faqquestion}>Can I ask T-Mobile specific questions?</span></summary>
                    <p className={styles.faqtext}>Yes, T-Mobile’s ChatGPT is a T-Mobile specific instance of the OpenAI ChatGPT hosted in T-Mobile’s cloud environment and has access to a variety of T-Mobile documents and data. If you don’t find what you're looking for, or have specific requests, please send us a message on Slack.</p>
                </details>

                <details className={styles.faqdetail}>
                    <summary className={styles.faqsummary}><span className={styles.faqquestion}>Can T-Mobile ChatGPT make mistakes?</span></summary>
                    <p className={styles.faqtext}>T-Mobile ChatGPT is a large language model (LLM) that can make mistakes, such as providing inaccurate, misleading, or false information. T-Mobile ChatGPT is trained on a massive dataset of text and code. This dataset includes a variety of sources, including news articles, books, and websites. However, it is possible that some of the information in this dataset is inaccurate or outdated.</p>
                </details>

                <details className={styles.faqdetail}>
                    <summary className={styles.faqsummary}><span className={styles.faqquestion}>What if I don’t get good answers?</span></summary>
                    <p className={styles.faqtext}>T-Mobile ChatGPT is not perfect. It is possible for T-Mobile ChatGPT to misunderstand your questions or to provide responses that are not relevant to your needs. It is important to use your own judgment when evaluating the information that T-Mobile ChatGPT provides. If you are unsure about the accuracy of a particular piece of information, you should do your own research to verify it. Use your own judgment when evaluating the information that T-Mobile ChatGPT provides. If you are unsure about the accuracy of a particular piece of information, you should do your own research to verify the informastion. Be critical of the information that T-Mobile ChatGPT provides. Just because T-Mobile ChatGPT provides information does not mean that it is accurate or reliable.</p>
                </details>
                <details className={styles.faqdetail}>
                    <summary className={styles.faqsummary}><span className={styles.faqquestion}>How to use T-Mobile ChatGPT effectively?</span></summary>
                    <p className={styles.faqtext}>
                        <ol>
                            <li>
                                Start with a clear question or instruction. What do you want the language model to do? Be as specific as possible.
                            </li>
                            <li>
                                Use keywords and examples. The more information you give the language model, the better it will be able to understand what you want.
                            </li>
                            <li>
                                Be clear about the tone and style of the response you want. Do you want a poem, a story, or a factual answer?
                            </li>
                            <li>
                                Break down complex tasks into smaller steps. This will make it easier for the language model to understand what you want.
                            </li>
                            <li>
                                Use simple language. The language model is trained on a massive dataset of text, but it's still not perfect at understanding complex language.
                            </li>
                            <li>
                                Experiment! There's no one right way to create a prompt. Try different things and see what works best.
                            </li>
                        </ol>
                    </p>
                </details> */}
        {/* <details className={styles.faqdetail}>
          <summary className={styles.faqsummary}>
            <span className={styles.faqquestion}>
              How to use context with your prompts?
            </span>
          </summary>
          <p className={styles.faqtext}>
            <ol>
              <li>
                Start by thinking about the context of your prompt. What is the
                overall goal of your prompt? What do you want the language model
                to do?
              </li>
              <li>
                Once you have a good understanding of the context, you can start
                to add examples. Examples can help the language model understand
                what you want it to do. For example, if you want the language
                model to write a poem about a cat, you could give it examples of
                other poems about cats.
              </li>
              <li>
                It's important to use clear and concise examples. The language
                model doesn't have the same understanding of the world as you
                do, so it's important to be as clear as possible.
              </li>
              <li>
                You can also use context and examples to help the language model
                understand the tone and style of your prompt. For example, if
                you want the language model to write marketing copy for a new
                service, you could add examples of other marketing copy that has
                proven effective.
              </li>
            </ol>
          </p>
        </details> */}
      </div>
    </div>
    // </div>
  );
};

export default FAQ;
