import React, { useEffect } from "react";
import { DefaultButton, DirectionalHint, FocusZone, IContextualMenuListProps, IContextualMenuProps, IRenderFunction, ISearchBoxStyles, SearchBox } from "@fluentui/react";
import styles from "./DropdownSearch.module.css";

export const DropdownSearch = (props: { items: any[]; value: string | undefined; placeHolder: any; disabled?: boolean; errorMessage?: any; noMatchText: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; onChange: (arg0: any) => void; width: any; maxHeight: any; }) => {
  // console.log("DropdownSearch(props)", props);
  let [items, setItems] = React.useState(props.items);

  const filteredItems = props.items.filter(({ key }) =>
    key.toLowerCase().includes(props?.value?.toLowerCase())
  );
  let [value, setValue] = React.useState(
    props.value
      ? filteredItems.length === 0
        ? props.placeHolder
        : filteredItems[0].text
      : props.placeHolder
  );

  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {width : (props.width - 20), borderColor: "rgb(138, 136, 134)"}, field: {width: (props.width - 20)}, icon: {color: "#e20074"}, iconContainer: {borderColor: "#e20074"}}

  const onRenderMenuList: any = (itemProps: any, defaultRender: (arg0: any) => any) => {
    // console.log(itemProps);
    return (
      <FocusZone className={styles.width100}>
        <SearchBox autoComplete="off" onChange={onSearch} styles={searchBoxStyles} showIcon={true} placeholder="Enter text to search"/>
        {itemProps.totalItemCount > 0 &&
          itemProps.items[0].key != "NOMATCH" &&
          defaultRender(itemProps)}
        {itemProps.totalItemCount == 1 &&
          itemProps.items[0].key == "NOMATCH" && (
            <p>
              {props.noMatchText
                ? props.noMatchText
                : "No Match, Refine search."}
            </p>
          )}
      </FocusZone>
    );
  };

  const onSearch = (e: any, v: any) => {
    const newItems = props.items.filter(({ text }) =>
      text.toLowerCase().replace(/_/g, ' ').includes(v.toLowerCase())
    );
    if (newItems.length == 0) {
      setItems([
        {
          text: "No Match",
          key: "NOMATCH"
        }
      ]);
    } else {
      setItems(newItems);
    }
  };

  const menuProps: IContextualMenuProps = {
    items: items,
    onItemClick: (e, i) => {
      setItems(props.items);
      setValue(i?.text);
      const selItems = props.items.filter(({ text }) =>
        text.toLowerCase().includes(i?.text?.toLowerCase())
      );
      props.onChange(selItems[0]);
    },
    onRenderMenuList: onRenderMenuList,
    styles: { container: { width:(props.width - 20), maxHeight: props.maxHeight }, root: {width: (props.width - 20)} },
    directionalHint: DirectionalHint.bottomAutoEdge,
    directionalHintFixed: true
  };

  return (
    <div>
    <DefaultButton
      styles={{
        root: {
          width:"100%",
          textAlign: "left",
          paddingRight: "5px",
          paddingLeft: "5px",
          borderColor: `${props.errorMessage ? "rgb(164, 38, 44)" : "" }`
        }, flexContainer: {width : "100%"}, 
        label: {fontWeight: "normal"}
      }}
      menuIconProps={{ iconName: "ChevronDown" }}
      text={ props.value
        ? filteredItems.length === 0
          ? props.placeHolder
          : filteredItems[0].text
        : props.placeHolder}
      menuProps={menuProps}
      disabled={props.disabled}
      onAfterMenuDismiss={() => {
        setItems(props.items);
      }}
      onMenuClick={()=> setItems(props.items)}
    />
    <div role="alert" id="Dropdown-errorMessage" className="errorMessage-145">{props.errorMessage}</div></div>
  );
};